import Image from "../Image";
import RightArrowIcon from "../../assets/Icons/RightArrowIcon";
import { useMemo } from "react";
import moment from "moment";
import { useAppSelector } from "../../config/hooks";

const Log = ({ log, logs, index }) => {
  const logsSelector = useAppSelector((state) => state?.jobs?.applicationLog);
  const { sort } = logsSelector || {};

  const createdAt = useMemo(
    () => moment(log?.createdAt).format("DD MMMM YYYY hh:mm A"),
    [log?.createdAt]
  );

  return (
    <div className="flex items-start justify-start gap-2">
      <div className="w-fit">
        {log?.createdByDetails?.profileImage ? (
          <div className="w-8 h-8 rounded-full">
            <Image
              url={log?.createdByDetails?.profileImage}
              alt="profile"
              borderRadius="1000px"
            />
          </div>
        ) : log?.createdByDetails?.firstName &&
          log?.createdByDetails?.lastName ? (
          <div className="w-8 h-8 flex items-center justify-center bg-primary-400 rounded-full">
            <h1 className="text-exwhite-100 text-sm font-semibold">{`${log?.createdByDetails?.firstName[0].toUpperCase()}${log?.createdByDetails?.lastName[0].toUpperCase()}`}</h1>
          </div>
        ) : (
          <div className="h-8 w-8 rounded-full bg-DefaultProfileImg bg-cover bg-center bg-no-repeat"></div>
        )}
      </div>

      <div className="flex-1">
        <div className="flex items-center justify-start gap-2">
          <h1 className="text-base font-semibold text-secondary-800">
            {`${log?.createdByDetails?.firstName} ${log?.createdByDetails?.lastName}`}{" "}
            <span className="text-base font-normal text-exgray-400">
              {log?.actionType}
            </span>
          </h1>

          <h1 className="text-base font-semibold text-secondary-800">
            {createdAt}
          </h1>
        </div>
        <div className="flex items-center justify-start gap-2">
          {sort === "asc" ? (
            <h1 className="text-base text-exgray-400 font-normal">
              {index === 0 ? "None" : logs[index - 1]?.status}
            </h1>
          ) : (
            <h1 className="text-base text-exgray-400 font-normal">
              {index === logs?.length - 1 ? "None" : logs[index + 1]?.status}
            </h1>
          )}
          <RightArrowIcon color="black" width="20" height="20" />
          <h1 className="text-base text-secondary-400 font-semibold">
            {log?.status}
          </h1>
        </div>
      </div>
    </div>
  );
};

export default Log;
