import { AxiosPromise } from "axios";
import nocoApiClient from "../config/local-axios-config/nocoApiConfig";

class NocoService {
  getAllDesignations() {
    return nocoApiClient.get(
      `/v1/configuration/api/v1/db/data/v1/exoTalent/Designations-List?limit=500&fields=Id,Title`
    );
  }

  getAllIndustries() {
    return nocoApiClient.get(
      `/v1/configuration/api/v1/db/data/v1/exoTalent/Industries-List?limit=500&fields=Id,Name`
    );
  }

  getAllCategories() {
    return nocoApiClient.get(
      `/v1/configuration/api/v1/db/data/v1/exoTalent/Categories-List?limit=500&fields=Id,Categories`
    );
  }

  getAllFunctions() {
    return nocoApiClient.get(
      `/v1/configuration/api/v1/db/data/v1/exoTalent/Functions-List?limit=500&fields=Id,Name`
    );
  }

  getAllCountries() {
    return nocoApiClient.get(
      `/v1/configuration/api/v1/db/data/v1/exoTalent/Countries-List?limit=500&fields=Id,CountryName,PhoneCode,Iso3,Iso2`
    );
  }
  getAllCurrencyType() {
    return nocoApiClient.get(
      `/v1/configuration/api/v1/db/data/v1/exoTalent/Currency-Type?limit=500&fields=Id,Title`
    );
  }
  getSalaryRange() {
    return nocoApiClient.get(
      `/v1/configuration/api/v1/db/data/v1/exoTalent/Salary-Range-Min?limit=500&fields=Id,Title,value`
    );
  }

  getAllJobType() {
    return nocoApiClient.get(
      `/v1/configuration/api/v1/db/data/v1/exoTalent/Job-Types?limit=500&fields=Id,Title`
    );
  }
  getCurrencyType() {
    return nocoApiClient.get(
      `/v1/configuration/api/v1/db/data/v1/exoTalent/Currency-Type?limit=500&fields=Id,Title`
    );
  }
  getAllWorkplaceType() {
    return nocoApiClient.get(
      `/v1/configuration/api/v1/db/data/v1/exoTalent/Workplace-Type?limit=500&fields=Id,Title`
    );
  }
  getExperienceMinRange() {
    return nocoApiClient.get(
      `/v1/configuration/api/v1/db/data/v1/exoTalent/Experience-Range-Min?limit=500&fields=Id,Title,value`
    );
  }
  getExperienceMaxRange() {
    return nocoApiClient.get(
      `/v1/configuration/api/v1/db/data/v1/exoTalent/Experience-Range-Max?limit=500&fields=Id,Title,value`
    );
  }
  getAllCities(req: { offset: number }) {
    return nocoApiClient.get(
      `/v1/configuration/api/v1/db/data/v1/exoTalent/Cities-List?&offset=${req.offset}&limit=500`
    );
  }
  getLanguageList() {
    return nocoApiClient.get(
      `/v1/configuration/api/v1/db/data/v1/exoTalent/Language-List?limit=500&fields=Id,Title`
    );
  }

  getMinSalaryRange() {
    return nocoApiClient.get(
      `/v1/configuration/api/v1/db/data/v1/exoTalent/Salary-Range-Min?limit=500&fields=Title,value`
    );
  }

  getSalaryInThousandRange() {
    return nocoApiClient.get(
      `/v1/configuration/api/v1/db/data/v1/exoTalent/Thousand-Range?limit=500&fields=Id,Title,Value`
    );
  }

  getMaxSalaryRange() {
    return nocoApiClient.get(
      `/v1/configuration/api/v1/db/data/v1/exoTalent/Salary-Range-Max?limit=500&fields=Id,Title,value`
    );
  }

  getCompanySizeRange() {
    return nocoApiClient.get(
      `/v1/configuration/api/v1/db/data/v1/exoTalent/Employee-Size?limit=500&fields=Id,Title`
    );
  }

  getCompanyTypes() {
    return nocoApiClient.get(
      `/v1/configuration/api/v1/db/data/v1/exoTalent/Company-Types?limit=500&fields=Id,Title`
    );
  }

  getDepartment(): AxiosPromise<DepartmentResponse> {
    return nocoApiClient.get(
      `/v1/configuration/api/v1/db/data/v1/exoTalent/Department?limit=500&fields=Id,Title`
    );
  }

  getAccessRole(): AxiosPromise<AccessRoleResponse> {
    return nocoApiClient.get(
      `/v1/configuration/api/v1/db/data/v1/exoTalent/Role-List?limit=500&fields=Id,Title`
    );
  }
  getBandList(): AxiosPromise<BandListResponse> {
    return nocoApiClient.get(
      `/v1/configuration/api/v1/db/data/v1/exoTalent/Band-List?limit=500&fields=Id,Title`
    );
  }
  getGenderList(): AxiosPromise<GenderResponse> {
    return nocoApiClient.get(
      `/v1/configuration/api/v1/db/data/v1/exoTalent/Gender?limit=500&fields=Id,Title`
    );
  }

  getDesignationList(): AxiosPromise<DesignationList> {
    return nocoApiClient.get(
      `/v1/configuration/api/v1/db/data/v1/exoTalent/Exo-Designation-List?limit=500&fields=Id,Title`
    );
  }

  getSkills(): AxiosPromise<SkillsResponse> {
    return nocoApiClient.get(
      `/v1/configuration/api/v1/db/data/v1/linkcxo/Skills?limit=100&fields=Id,Name`
    );
  }

  getQualification(): AxiosPromise<QualificationResponse> {
    return nocoApiClient.get(
      `/v1/configuration/api/v1/db/data/v1/exoTalent/Qualifications?limit=1000&fields=Id,Title`
    );
  }

  getEmployement(): AxiosPromise<EmplopyementTypeResponse> {
    return nocoApiClient.get(
      `/v1/configuration/api/v1/db/data/v1/exoTalent/Job-Types?limit=100&fields=Id,Title`
    );
  }

  getWorkplaceType(): AxiosPromise<WorkplaceTypeResponse> {
    return nocoApiClient.get(
      `/v1/configuration/api/v1/db/data/v1/exoTalent/Workplace-Type?limit=100&fields=Id,Title`
    );
  }

  getWorkLocation(): AxiosPromise<WorkLocationsResponse> {
    return nocoApiClient.get(
      `/v1/configuration/api/v1/db/data/v1/exoTalent/Office-Locations?limit=100&fields=Id,Title`
    );
  }

  getLeaveType(): AxiosPromise<WorkLocationsResponse> {
    return nocoApiClient.get(
      `/v1/configuration/api/v1/db/data/v1/exoTalent/Leave-Type?limit=100&fields=Id,Title`
    );
  }

  getHolidayList(
    location: string
  ): AxiosPromise<HolidayListResponse> {
    return nocoApiClient.get(
      `/v1/configuration/api/v1/db/data/v1/exoTalent/Holiday-List?limit=1000&where=(Location,like,${location})&fields=Id,Ocassion,Location,Month,Date,Day`
    );
  }
}

export interface HolidayListResponse {
  list: HolidayList[];
}

export interface HolidayList {
  Location: string;
  Month: string;
  Date: Date;
  Day: string;
  Ocassion: string;
  Id: number;
}

export interface WorkLocationsResponse {
  list: AccessRole[];
}

export interface EmplopyementTypeResponse {
  list: AccessRole[];
}

export interface WorkplaceTypeResponse {
  list: AccessRole[];
}

export interface DesignationList {
  list: AccessRole[];
}

export interface BandListResponse {
  list: AccessRole[];
}

export interface GenderResponse {
  list: AccessRole[];
}

export interface AccessRoleResponse {
  list: AccessRole[];
}

export interface AccessRole {
  Title: string;
  Id: number;
}

export interface DepartmentResponse {
  list: Department[];
}

export interface Department {
  Title: string;
  Id: number;
}

export interface SkillsResponse {
  list: Skill[];
}
export interface Skill {
  Name: string;
  Id: string;
}
export interface QualificationResponse {
  list: Qualification[];
}
export interface Qualification {
  Id: string;
  Title: string;
}

const nocoService = new NocoService();
export default nocoService;
