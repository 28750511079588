import React, { useState } from "react";

const AssetForm = ({ togglePopupHandler }) => {
  const [formData, setFormData] = useState({
    assetName: "",
    assetCode: "",
    serialNo: "",
    vendor: "",
    warrantyPeriod: "",
    dateOfIssue: "",
    assetValue: "",
    status: "",
    remarks: "",
    attachment: null,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (e) => {
    setFormData({ ...formData, attachment: e.target.files[0] });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <>
      <form
        onSubmit={handleSubmit}
        className="p-6 bg-white border rounded-lg shadow-md max-w-xl max-h-[80vh] mx-auto overflow-y-auto scroll- "
      >
        <h1 className="text-primary-400 font-semibold text-xl pb-2">
          Add New Asset
        </h1>
        <div className="grid grid-cols-2 gap-4">
          <div>
            <label className="text-exgray-200">Asset Name:</label>
            <input
              type="text"
              name="assetName"
              placeholder="Type here..."
              value={formData.assetName}
              onChange={handleChange}
              className="border p-2 rounded w-full"
            />
          </div>

          <div>
            <label className="text-exgray-200">Asset Code:</label>
            <input
              type="text"
              name="assetCode"
              placeholder="Type here..."
              value={formData.assetCode}
              onChange={handleChange}
              className="border p-2 rounded w-full"
            />
          </div>

          <div>
            <label className="text-exgray-200">Serial No:</label>
            <input
              type="text"
              name="serialNo"
              placeholder="Type here..."
              value={formData.serialNo}
              onChange={handleChange}
              className="border p-2 rounded w-full"
            />
          </div>

          <div>
            <label className="text-exgray-200">Vendor:</label>
            <input
              type="text"
              name="vendor"
              placeholder="Type here..."
              value={formData.vendor}
              onChange={handleChange}
              className="border p-2 rounded w-full"
            />
          </div>

          <div>
            <label className="text-exgray-200">Warranty Period:</label>
            <select
              name="warrantyPeriod"
              value={formData.warrantyPeriod}
              onChange={handleChange}
              className="border p-2 rounded w-full"
            >
              <option value="">Select Warranty Period</option>
              <option value="1 Year">1 Year</option>
              <option value="2 Years">2 Years</option>
              <option value="3 Years">3 Years</option>
            </select>
          </div>

          <div>
            <label className="text-exgray-200">Date of Issue:</label>
            <input
              type="date"
              name="dateOfIssue"
              value={formData.dateOfIssue}
              onChange={handleChange}
              className="border p-2 rounded w-full"
            />
          </div>

          <div>
            <label className="text-exgray-200">Asset Value:</label>
            <input
              type="text"
              name="assetValue"
              placeholder="Type here..."
              value={formData.assetValue}
              onChange={handleChange}
              className="border p-2 rounded w-full"
            />
          </div>

          <div>
            <label className="text-exgray-200">Status:</label>
            <input
              type="text"
              name="status"
              placeholder="Type here..."
              value={formData.status}
              onChange={handleChange}
              className="border p-2 rounded w-full"
            />
          </div>
        </div>

        <div>
          <label className="text-exgray-200">Remarks:</label>
          <textarea
            name="remarks"
            placeholder="Type here..."
            value={formData.remarks}
            onChange={handleChange}
            className="border p-2 rounded w-full mt-4"
          />
        </div>

        <div className="mt-4 border p-4 rounded-lg text-center">
          {/* <label className="block text-exgray-200">Attachment:</label> */}
          <input type="file" onChange={handleFileChange} className="" />
        </div>
        <div className="flex justify-end">
          <button
            type="submit"
            className="mt-4 bg-primary-600 text-white px-5 py-2 rounded"
          >
            Save
          </button>
        </div>
      </form>
    </>
  );
};

export default AssetForm;
