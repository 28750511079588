import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../config/hooks";
import { Controller, useForm } from "react-hook-form";
import ExDropdown from "../../../../../components/basic-components/ExDropdown";
import * as constants from "../../../../../constant";
import ExMultiDropdown from "../../../../../components/basic-components/ExMultiDropdown";
import { getMembersApi } from "../../../../../slices/companyMemberSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { asignJobApi } from "../../../../../slices/jobSlice";
import { toast } from "sonner";

const rolesOptions = [
  { Id: 1, Title: "Recruiter" },
  { Id: 2, Title: "Company" },
];

const AssignJobForm = ({
  onClose = () => {},
  getAssignedMembersByJobId = () => {},
  jobId,
}) => {
  const dispatch = useAppDispatch();
  const userId = useAppSelector((state) => state?.login?.session?.userId);
  const loginSelector = useAppSelector((state) => state?.login?.session);
  const memberSelector = useAppSelector(
    (state) => state?.member?.memberDetails
  );

  const [companyMembers, setCompanyMembers] = useState(null);

  const form = useForm({
    defaultValues: {
      role: { Id: 1, Title: "Recruiter" },
      emails: null,
    },
  });

  const { handleSubmit, control, formState, watch } = form;
  const { errors } = formState;

  useEffect(() => {
    const fetchData = async () => {
      if (loginSelector?.who === constants.COMPANY.toLowerCase()) {
        try {
          const response = await dispatch(
            getMembersApi({ companyId: userId })
          ).then(unwrapResult);
          const mappedMembers = response.data.data.filter(
            (memberDetail) => memberDetail.designation !== constants.ADVISER
          );
          setCompanyMembers(mappedMembers);
        } catch (error) {}
      } else if (
        loginSelector?.who === constants.COMPANY_MEMBER.toLowerCase()
      ) {
        try {
          const response = await dispatch(
            getMembersApi({ companyId: memberSelector?.companyId })
          ).then(unwrapResult);
          const mappedMembers = response.data.data.filter(
            (memberDetail) => memberDetail.designation !== constants.ADVISER
          );
          setCompanyMembers(mappedMembers);
        } catch (error) {}
      }
    };

    fetchData();
  }, []);

  const onSubmit = async (formData) => {
    const members =
      formData.emails.length > 0 &&
      formData.emails.map((memberEmail) => ({
        email: memberEmail.emailId,
        memberId: memberEmail.id,
      }));

    try {
      await dispatch(
        asignJobApi({
          jobId: jobId,
          members: members,
          assignedBy: userId,
          assignTo:
            formData.role.Title === "Recruiter"
              ? constants.COMPANY_MEMBER.toLowerCase()
              : constants.COMPANY.toLowerCase(),
        })
      ).then(unwrapResult);
      toast.success("Job assigned successfully");
      getAssignedMembersByJobId();
      onClose();
    } catch (error) {
      toast.error("Somthing went wrong");
    }
  };

  return (
    <div className="space-y-4">
      <div>
        <h1 className="text-xl text-expurple-800 font-semibold text-start">
          Assign Job
        </h1>
      </div>

      <form onSubmit={handleSubmit(onSubmit)} className="space-y-2">
        <div>
          <Controller
            name="role"
            control={control}
            rules={{
              required: {
                value: true,
                message: "required",
              },
            }}
            render={({ field: { onChange, value } }) => (
              <ExDropdown
                title="Assign Job to"
                onChange={onChange}
                placeholder="Select"
                value={value || null}
                optionValue="Id"
                optionLabel="Title"
                options={rolesOptions}
                dropDownHeight="40px"
                dropDownBorderWidth="0px"
                dropDownBorderColor="#696969"
                error={errors?.role?.message}
              />
            )}
          />
        </div>

        <div>
          <Controller
            name="emails"
            control={control}
            rules={{
              required: {
                value: true,
                message: "required",
              },
            }}
            render={({ field: { onChange, value } }) => (
              <ExMultiDropdown
                title="Email ID"
                onChange={onChange}
                placeholder="Select"
                value={value || null}
                optionValue="emailId"
                optionLabel="emailId"
                menuListHeight="140px"
                options={
                  companyMembers
                    ? companyMembers
                    : [{ emailId: "Loading...", isDisabled: true }]
                }
                dropDownHeight="auto"
                dropDownBorderWidth="0px"
                dropDownBorderColor="#696969"
                closeMenuOnSelect={true}
                error={errors?.emails?.message}
              />
            )}
          />
        </div>

        <div className="pt-2">
          <div className="w-fit ml-auto mr-0">
            <button
              type="submit"
              className="py-2 px-8 rounded-sm text-base text-exwhite-100 font-semibold text-center bg-expurple-800"
            >
              Assign
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AssignJobForm;
