import React, { useMemo } from "react";
import ArrowIcon from "../../assets/Icons/ArrowIcon";
import { Link, useLocation, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../config/hooks";
import { toggleOption } from "../../slices/sideMenuSlice";
import { v4 as uuidV4 } from "uuid";
import * as constants from "../../constant";

const SideMenu = () => {
  const dispatch = useAppDispatch();

  const { pathname } = useLocation();
  const { memberId, jobId, applicantId, applicationId } = useParams();

  const sideMenuSelector = useAppSelector((state) => state.sideMenu);
  const member = useAppSelector((state) => state.company.memberDetails);
  const who = useAppSelector((state) => state?.login?.session?.who);
  const loginUserID = useAppSelector((state) => state?.login?.session?.userId);
  const companyDetails = useAppSelector(
    (state) => state.company.companyDetails
  );

  const userId =
    who === constants.COMPANY.toLowerCase()
      ? companyDetails?.employeeId
      : loginUserID;

  const memberSelector = useAppSelector(
    (state) => state?.member?.memberDetails
  );

  const { toggleIndex } = sideMenuSelector;

  const toggleHandler = (index) => {
    if (index == toggleIndex) {
      dispatch(toggleOption({}));
    } else {
      dispatch(toggleOption({ index }));
    }
  };

  const sideMenuOptions = useMemo(() => {
    return [
      {
        title: "Profile",
        options: [
          {
            title: "Dashboard",
            redirectTo: "/employee-dashboard",
            childrenRoutes: ["/employee-dashboard"],
          },
          {
            title: "My Profile",
            redirectTo: `/employee-dashboard/employee-profile/${userId}`,
            childrenRoutes: [
              `/employee-dashboard/employee-profile/${userId}`,
              // "/employee-dashboard/employee-profile/employee-detail",
              // "/employee-dashboard/employee-profile/employmentDetails",
              // "/employee-dashboard/employee-profile/medical-health",
              // "/employee-dashboard/employee-profile/document-management"
            ],
          },
          {
            title: "Attendance",
            redirectTo: "/employee-dashboard/employee-attendance",
            childrenRoutes: ["/employee-dashboard/employee-attendance"],
          },
          {
            title: "Timesheet",
            redirectTo: "/employee-dashboard/employee-timesheet",
            childrenRoutes: ["/employee-dashboard/employee-timesheet"],
          },
          {
            title: "Pending Approvals",
            redirectTo:
              "/employee-dashboard/pending-aprovals/regularization-request",
            childrenRoutes: [
              "/employee-dashboard/pending-aprovals/regularization-request",
              "/employee-dashboard/pending-aprovals/leave-request-list",
              "/employee-dashboard/pending-aprovals/reimbursement-request-list",
            ],
          },
          {
            title: "My Request",
            redirectTo:
              "/employee-dashboard/request-list/my-regularization-request",
            childrenRoutes: [
              "/employee-dashboard/request-list/my-regularization-request",
              "/employee-dashboard/request-list/my-leave-request",
              "/employee-dashboard/request-list/my-reimbursement-request",
            ],
          },
          {
            title: "Holiday List",
            redirectTo: "/employee-dashboard/holiday-list",
            childrenRoutes: ["/employee-dashboard/holiday-list"],
          },
          // {
          //   title: "Leave",
          //   redirectTo: "/employee-dashboard/employee-leave-management",
          //   childrenRoutes: ["/employee-dashboard/employee-leave-management"],
          // },
          // {
          //   title: "My Payroll",
          //   redirectTo: "/employee-dashboard/employee-payroll",
          //   childrenRoutes: ["/employee-dashboard/employee-payroll"],
          // },
        ],
      },
      {
        title: "Company",
        options: [
          {
            title: "Company Details",
            redirectTo: "/company-admin",
            childrenRoutes: ["/company-admin"],
          },
          {
            title: "Employees",
            redirectTo: "/company-admin/employees",
            childrenRoutes: [
              "/company-admin/employees",
              "/company-admin/employees/teams",
              "/company-admin/employees/adviser",
              `/company-admin/adviser-profile/${memberId}`,
              `/company-admin/member-details/${memberId}`,
              `/company-admin/adviser-assign-candidates/${jobId}`,
            ],
          },
          {
            title: "Legal Documents",
            redirectTo: "/company-admin/docs",
            childrenRoutes: ["/company-admin/docs"],
          },
        ],
      },
      {
        title: "ATS",
        options:
          (who === constants.COMPANY_MEMBER.toLowerCase() &&
            memberSelector?.designation !== constants.ADVISER) ||
          who === constants.COMPANY.toLowerCase()
            ? [
                // {
                //   title: "Dashboard",
                //   redirectTo: "/company-admin/ATS-Dashboard",
                //   childrenRoutes: ["/company-admin/ATS-Dashboard"],
                // },
                {
                  title: "Job Board",
                  redirectTo: "/company-admin/jobs",
                  childrenRoutes: [
                    "/company-admin/jobs",
                    "/company-admin/jobs/approvals",
                    "/company-admin/create-job",
                    `/company-admin/job/${jobId}`,
                    `/company-admin/job/${jobId}/approvals`,
                    `/company-admin/job/${jobId}/tagged-list`,
                    `/company-admin/job/${jobId}/tagged-list/approvals`,
                    `/company-admin/applicant/${applicantId}`,
                    `/company-admin/job/${jobId}/applicant/${applicationId}/user/${applicantId}/resume`,
                    `/company-admin/applicant/${applicantId}/interview-stages`,
                    `/company-admin/job/${jobId}/applicant/${applicationId}/user/${applicantId}`,
                  ],
                },
                {
                  title: "Candidate Pool",
                  redirectTo: "/company-admin/candidate-pool",
                  childrenRoutes: [
                    "/company-admin/candidate-pool",
                    "/company-admin/bulk-upload",
                    "/company-admin/success",
                    `/company-admin/job-seekers/${userId}`,
                  ],
                },
                {
                  title: "Candidate Search",
                  redirectTo: "/company-admin/candidate-search",
                  childrenRoutes: [`/company-admin/candidate-search`],
                },

                // {
                //   title: "Global Search",
                //   redirectTo: `/company-admin/global-search`,
                //   childrenRoutes: [
                //     `/company-admin/global-search`,
                //     `/company-admin/global-search/${jobId}`,
                //   ],
                // },

                // {
                //   title: "Team Performance",
                //   redirectTo: "/company-admin/team-performance",
                //   childrenRoutes: ["/company-admin/team-performance"],
                // },
                // {
                //   title: "Client Records",
                //   redirectTo: "/company-admin/client-records",
                //   childrenRoutes: ["/company-admin/client-records"],
                // },
                // {
                //   title: "Interviews",
                //   redirectTo: "/company-admin/interviews",
                //   childrenRoutes: ["/company-admin/interviews"],
                // },
                // {
                //   title: "Global Search",
                //   redirectTo: "/company-admin/interviews",
                //   childrenRoutes: ["/company-admin/interviews"],
                // },
              ]
            : [
                {
                  title: "Job Board",
                  redirectTo: "/company-admin/jobs",
                  childrenRoutes: [
                    "/company-admin/jobs",
                    "/company-admin/jobs/approvals",
                    "/company-admin/create-job",
                    `/company-admin/job/${jobId}`,
                    `/company-admin/job/${jobId}/tagged-list`,
                    `/company-admin/applicant/${applicantId}`,
                    `/company-admin/applicant/${applicantId}/resume`,
                    `/company-admin/applicant/${applicantId}/interview-stages`,
                    `/company-admin/job/${jobId}/applicant/${applicantId}`,
                  ],
                },
              ],
      },
      {
        title: "CRMS",
        options: [
          // {
          //   title: "Dashboard",
          //   redirectTo: "/company-admin/sales",
          //   childrenRoutes: ["/company-admin/sales"],
          // },
          {
            title: "Lead Management",
            redirectTo: "/company-admin/sales/lead-management",
            childrenRoutes: ["/company-admin/sales/lead-management"],
          },
          // {
          //   title: "Onboarding Process",
          //   redirectTo: "/company-admin/sales/onboarding-process",
          //   childrenRoutes: ["/company-admin/sales/onboarding-process"],
          // },
         
          {
            title: "Client Management",
            redirectTo: "/company-admin/sales/client-management",
            childrenRoutes: ["/company-admin/sales/client-management"],
          },
          {
            title: "Client List",
            redirectTo: "/company-admin/sales/client-list",
            childrenRoutes: ["/company-admin/sales/client-list"],
          },
        ],
      },
      {
        title: "HRMS",
        options: [
          // {
          //   title: "Dashboard",
          //   redirectTo: "/company-admin/admin-dashboard",
          //   childrenRoutes: [
          //     "/company-admin/admin-dashboard",
          //     // "/company-admin/employee-list/teams",
          //     // `/company-admin/member-details/${memberId}`,
          //   ],
          // },
          {
            title: "Employee Directory",
            redirectTo: "/company-admin/employee-list",
            childrenRoutes: [
              "/company-admin/employee-list",
              "/company-admin/employee-list/teams",
              "/company-admin/employee-list/adviser",
              `/company-admin/adviser-profile/${memberId}`,
              `/company-admin/member-details/${memberId}`,
              `/company-admin/adviser-assign-candidates/${jobId}`,
            ],
          },
          {
            title: "Onboarding",
            redirectTo: "/company-admin/onboarding",
            childrenRoutes: [
              `/company-admin/onboarding`,
              //"/company-admin/onboarding-management",
              `/company-admin/employee-profile/${memberId}`,
              // `/company-admin/onboarding-management/document/${memberId}`,
              // `/company-admin/onboarding-management/kyc-document/${memberId}`,
              // `/company-admin/onboarding-management/statutory-document/${memberId}`
            ],
          },
          {
            title: "Asset Management",
            redirectTo: "/company-admin/asset-management",
            childrenRoutes: [`/company-admin/asset-management`],
          },
          {
            title: "Attendance",
            redirectTo: "/company-admin/employees-attendance-list",
            childrenRoutes: ["/company-admin/employees-attendance-list"],
          },
          {
            title: "Monthly Timesheet",
            redirectTo: "/company-admin/all-employee-timesheet",
            childrenRoutes: ["/company-admin/all-employee-timesheet"],
          },
          // {
          //   title: "Exit",
          //   redirectTo: "/company-admin/attendance-list",
          //   childrenRoutes: ["/company-admin/attendance-list"],
          // },
          // {
          //   title: "Notification",
          //   redirectTo: "/company-admin/notification",
          //   childrenRoutes: ["/company-admin/notification"],
          // },
        ],
      },
    ];
  }, [applicantId, jobId, memberId, userId]);

  return (
    <div className="bg-exwhite-100 py-6 self-start rounded-sm">
      <div className="space-y-8">
        {sideMenuOptions.map((menu, index) => {
          if (
            who === "company_member" &&
            (member?.departments.includes(menu.title) ||
              (menu.title == "Profile" && member?.designation !== "Adviser"))
          ) {
            return (
              <div className="space-y-2" key={uuidV4()}>
                <div className="px-6">
                  <button
                    className="flex items-center justify-between w-full text-lg text-primary-600 font-semibold tracking-wide"
                    onClick={() => toggleHandler(index)}
                  >
                    {menu.title}
                    <div
                      className={`${
                        toggleIndex === index
                          ? "rotate-180 transition-transform duration-500"
                          : "transition-transform duration-500"
                      }`}
                    >
                      <ArrowIcon />
                    </div>
                  </button>
                </div>
                {toggleIndex === index && (
                  <ul className="flex flex-col">
                    {menu.options.map((subOpt) => (
                      <Link
                        to={subOpt.redirectTo}
                        className={`
                          ${
                            subOpt.childrenRoutes.includes(pathname) &&
                            "bg-secondary-200 text-primary-400"
                          } 
                        pl-10 py-4 text-exgray-300 text-base hover:text-primary-400`}
                        key={uuidV4()}
                      >
                        {subOpt.title}
                      </Link>
                    ))}
                  </ul>
                )}
              </div>
            );
          } else if (who === "company") {
            return (
              <div className="space-y-2" key={uuidV4()}>
                <div className="px-6">
                  <button
                    className="flex items-center justify-between w-full text-lg text-primary-600 font-semibold tracking-wide"
                    onClick={() => toggleHandler(index)}
                  >
                    {menu.title}
                    <div
                      className={`${
                        toggleIndex === index
                          ? "rotate-180 transition-transform duration-500"
                          : "transition-transform duration-500"
                      }`}
                    >
                      <ArrowIcon />
                    </div>
                  </button>
                </div>
                {toggleIndex === index && (
                  <ul className="flex flex-col">
                    {menu.options.map((subOpt) => (
                      <Link
                        to={subOpt.redirectTo}
                        className={`
                          ${
                            subOpt.childrenRoutes.includes(pathname) &&
                            "bg-secondary-200 text-primary-400"
                          } 
                        pl-10 py-4 text-exgray-300 text-base hover:text-primary-400`}
                        key={uuidV4()}
                      >
                        {subOpt.title}
                      </Link>
                    ))}
                  </ul>
                )}
              </div>
            );
          }
        })}
      </div>
    </div>
  );
};

export default SideMenu;
