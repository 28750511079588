import nocoApiClient from "../config/local-axios-config/nocoApiConfig";

class SalesNocoService {
  getSalesEngangementType() {
    return nocoApiClient.get(
      `/v1/configuration/api/v1/db/data/v1/exoTalent/Sales-Engagement-Type?limit=500&fields=Id,Title,value`
    );
  }
  getSalesEngageWith() {
    return nocoApiClient.get(
      `/v1/configuration/api/v1/db/data/v1/exoTalent/Sales-Engage-With?limit=500&fields=Id,Title,value`
    );
  }
  // getSalesIndustry() {
  //   return nocoApiClient.get(
  //     `/v1/configuration/api/v1/db/data/v1/exoTalent/Sales-Industry?limit=500&fields=Id,Title`
  //   );
  // }
  // getSalesSubIndustry() {
  //   return nocoApiClient.get(
  //     `/v1/configuration/api/v1/db/data/v1/exoTalent/Sales-SubIndustry?limit=500&fields=Id,Title`
  //   );
  // }

  getSalesStages() {
    return nocoApiClient.get(
      `/v1/configuration/api/v1/db/data/v1/exoTalent/Sales-Stages?limit=500&fields=Id,Title,value`
    );
  }

  getSalesSubStages() {
    return nocoApiClient.get(
      `/v1/configuration/api/v1/db/data/v1/exoTalent/Sales-Sub-Stages?limit=500&fields=Id,Title,value`
    );
  }

  getSalesStatus() {
    return nocoApiClient.get(
      `/v1/configuration/api/v1/db/data/v1/exoTalent/Sales-Status?limit=500&fields=Id,Title,value`
    );
  }

  getSalesInteractionType() {
    return nocoApiClient.get(
      `/v1/configuration/api/v1/db/data/v1/exoTalent/Sales-Interaction-Type?limit=500&fields=Id,Title`
    );
  }

  //Agreement

  getAgreementPaymentTerms() {
    return nocoApiClient.get(
      `/v1/configuration/api/v1/db/data/v1/exoTalent/Agreement-Payment-Terms?limit=500&fields=Id,Title`
    );
  }
  getAgreementFreeReplacementPeriod() {
    return nocoApiClient.get(
      `/v1/configuration/api/v1/db/data/v1/exoTalent/Agreement-Free-Replacement-Period?limit=500&fields=Id,Title`
    );
  }
  getAgreementPaymentSchedule() {
    return nocoApiClient.get(
      `/v1/configuration/api/v1/db/data/v1/exoTalent/Agreement-Payment-Schedule?limit=500&fields=Id,Title`
    );
  }
  getAgreementProfileValidity() {
    return nocoApiClient.get(
      `/v1/configuration/api/v1/db/data/v1/exoTalent/Agreement-Profile-Validity?limit=500&fields=Id,Title`
    );
  }
  getAgreementCTCRange() {
    return nocoApiClient.get(
      `/v1/configuration/api/v1/db/data/v1/exoTalent/Agreement-CTC-Range?limit=500&fields=Id,Title,value`
    );
  }
  getAgreementSeniorityLevel() {
    return nocoApiClient.get(
      `/v1/configuration/api/v1/db/data/v1/exoTalent/Agreement-Seniority-Level?limit=500&fields=Id,Title`
    );
  }
  

}

const salesnocoService = new SalesNocoService();
export default salesnocoService;