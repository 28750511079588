import React, { useState } from 'react'
import ServiceAgreementForm from './ServiceAgreementForm';
import AllAgreements from './AllAgreements';

const ServiceAgreement = () => {
    const [activeTab, setActiveTab] = useState(0);
  return (
    <div className="flex flex-col  mb-4">
        <div>
            <button
            className={`py-2 px-4 text-sm font-medium ${
                activeTab === 0 ? 'border-b-2 border-blue-500 text-primary-600' : 'text-exgray-300'
            }`}
            onClick={() => setActiveTab(0)}
            >
            New Agreement
            </button>
            <button
            className={`py-2 px-4 text-sm font-medium ${
                activeTab === 1 ? 'border-b-2 border-blue-500 text-primary-600' : 'text-exgray-300'
            }`}
            onClick={() => setActiveTab(1)}
            >
            All Agreements
            </button>
       </div>
     <div>
      {activeTab === 0 && (
        <ServiceAgreementForm setActiveTab={setActiveTab}/>
      )}
         {activeTab === 1 && (
        <AllAgreements/>
      )}
     </div>
  </div>
  )
}

export default ServiceAgreement
