import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as constants from "../../../constant";
import moment from "moment";
import { useAppDispatch, useAppSelector } from "../../../config/hooks";
import { getAllRegularizeRequestApi, updateRegularizationApi } from "../../../slices/attendanceSlice";
import { leaveRequestListApi, updateLeaveRequestApi } from "../../../slices/leaveSlice";
import Popup from "../../../components/popup/Popup";
import { Controller, useForm } from "react-hook-form";
import CrossIcon from "../../../assets/Icons/CrossIcon";



const LeaveRequestList = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [togglePopup, setTogglePopup] = useState(false);
  const memberDetail = useAppSelector((state) => state.company.memberDetails); //that is login user details
  const leaveRequestList = useAppSelector((state) => state.leave.leaveRequestList);
  const [leaveId, setLeaveId] = useState('');

  const form = useForm({
    defaultValues: {
      rejectReason: "",
    },
  });

  const { handleSubmit, control, formState, setValue } = form;
  const { errors } = formState;

  useEffect(() => {
    getMyAllLeaveReaquestList();
  }, []);

  const getMyAllLeaveReaquestList = () => {
    dispatch(
      leaveRequestListApi(
        {
          size: 100,
          approverId: memberDetail?.id,
        }
      )
    )
  }

  const updateLeaveRequest = (leaveId, status) => {
    dispatch(updateLeaveRequestApi({
      id: leaveId,
      status: status,
    }))
  }
  const togglePopupHandler = (leaveId) => {
    setLeaveId(leaveId);
    setTogglePopup(!togglePopup);
  };

  const onSubmit = (formData) => {
    dispatch(updateLeaveRequestApi({
      rejectedReason: formData.rejectReason,
      id: leaveId,
      status: "Rejected"
    }))
    setTogglePopup(!togglePopup);
  }

  // <button onClick={() => updateLeaveRequest(leave.id, "Rejected")} className="bg-red-700 px-2 py-1 mr-2 text-exwhite-100 rounded-lg">


  return (
    <>
      <div className="mt-3">
        <table className="w-full mt-2 text-center rounded-sm">
          <thead className="py-3">
            <tr className="bg-secondary-800 rounded-lg">
              <th className="text-sm  text-exwhite-100 border border-exgray-100 font-medium p-3">Employee Name</th>
              <th className="text-sm  text-exwhite-100 border border-exgray-100 font-medium p-3">Aplication Date</th>
              <th className="text-sm  text-exwhite-100 border border-exgray-100 font-medium p-3">Leave Type</th>
              <th className="text-sm  text-exwhite-100 border border-exgray-100 font-medium p-3">From Date</th>
              <th className="text-sm  text-exwhite-100 border border-exgray-100 font-medium p-3">To Date</th>
              <th className="text-sm  text-exwhite-100 border border-exgray-100 font-medium p-3">Days</th>
              <th className="text-sm  text-exwhite-100 border border-exgray-100 font-medium p-3">Reason</th>
              <th className="text-sm  text-exwhite-100 border border-exgray-100 font-medium p-3">Action</th>
            </tr>
          </thead>
          <tbody className="items-center justify-center ">
            {
              leaveRequestList?.map((leave, index) => {
                return (
                  <tr key={leave.id} className="items-center border border-exgray-100">
                    <td className="text-sm text-nowrap items-center border border-exgray-100 p-3">{leave.employee.firstName} {leave.employee.lastName}</td>
                    <td className="text-sm text-nowrap items-center border border-exgray-100 p-3">{moment(leave.createdAt).format('DD/MM/YYYY')}</td>
                    <td className="text-sm text-nowrap items-center border border-exgray-100 p-3">{leave.leaveType}</td>
                    {/* <td className="text-sm items-center py-3 text-secondary-500 font-medium">{attendance?.originalCheckIn}</td> */}
                    <td className="text-sm text-nowrap items-center border border-exgray-100 p-3">{moment(leave.startDate).format('DD/MM/YYYY')}</td>
                    <td className="text-sm text-nowrap items-center border border-exgray-100 p-3">{moment(leave.endDate).format('DD/MM/YYYY')}</td>
                    <td className="text-sm text-nowrap items-center border border-exgray-100 p-3 ">{leave.totalDays}</td>
                    <td className="truncate max-w-xs text-sm p-3 hover:max-w-full hover:overflow-visible hover:whitespace-normal hover:font-medium group">
                      {leave.reason}
                      <div className="relative inline-block group">
                        <div className="absolute right-32 overflow-visible hidden  group-hover:block w-84 p-4 mt-2 bg-secondary-200 border rounded-lg shadow-lg">
                          <p className="text-sm">{leave.reason}</p>
                        </div>
                      </div>
                    </td>
                    <td className="text-sm items-center border border-exgray-100 py-3 px-1">
                      {
                        leave.status == "Pending" ?
                          <div className="flex items-center justify-between">
                            <button onClick={() => togglePopupHandler(leave.id)} className="bg-red-700 px-2 py-1 mr-2 text-exwhite-100 rounded-lg text-xs">
                              Reject
                            </button>
                            <button onClick={() => updateLeaveRequest(leave.id, "Approved")} className="bg-green-600 px-2 py-1 text-exwhite-100 rounded-lg text-xs">
                              Approve
                            </button>
                          </div>
                          :
                          <label className={`${leave.status == "Approved" ? "text-green-600" : "text-red-700"} font-medium text-sm`}>
                            {leave.status}
                          </label>
                      }
                      {
                        leave.status == "Rejected" &&
                        <p style={{ fontSize: 10 }} >{leave.rejectedReason}</p>
                      }
                    </td>

                  </tr>
                )
              })
            }
          </tbody>
        </table>
        <Popup onClose={togglePopupHandler} visible={togglePopup}>
          <div className="px-10 py-6 w-[60%] mx-auto bg-white shadow-md rounded-lg overflow-y-auto no-scrollbar">
            <div className="flex flex-row items-center justify-between">
              <h1 className="text-xl text-primary-400 font-medium mb-1">
                Apply Leave
              </h1>
              <div onClick={() => setTogglePopup(false)}>
                <CrossIcon />
              </div>
            </div>
            <form onSubmit={handleSubmit(onSubmit)} className="space-y-2 mt-4">
              <div className="w-full">
                <label className="text-exgray-200/70 text-base font-semibold">
                  Leave Reason *
                </label>
                <Controller
                  name="rejectReason"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: "Please enter your leave reason",
                    },
                  }}
                  render={({ field: { onChange, value } }) => (
                    <textarea
                      //className=""
                      value={value}
                      onChange={onChange}
                      //onInput={handleInput}
                      placeholder="Type here..."
                      rows={5} // Start with a single row, but it will expand automatically
                      className="expandable-textarea border border-exgray-200 w-full rounded-md p-2 h-22"
                    />
                  )}
                />
                <h1 className="text-red-400">{errors?.rejectReason?.message}</h1>
              </div>
              <div className="flex items-end justify-end pt-5">
                <button
                  onClick={() => handleSubmit(onSubmit)}
                  //disabled={(startDateSelected == null && todayAttendance?.length == 0 || (todayAttendance?.length > 0 && todayAttendance[0]?.status))}
                  className={`bg-primary-500 w-2/12 h-11 rounded-lg text-exwhite-100 text-lg`}>
                  Submit
                </button>
              </div>
            </form>
          </div>
        </Popup>

      </div >
    </>
  );
};

export default LeaveRequestList;
