import { useRef, useState } from "react";
import { toast } from "sonner";
import uploadContent from "../../utils/upload-content/uploadContent";
import { useAppDispatch, useAppSelector } from "../../config/hooks";
import CommercialRateForm from "./CommercialRateForm";
import moment from "moment";
import { createAgreementApi, updateAgreementApi } from "../../slices/salesSlice";
import { useParams } from "react-router-dom";
import { Agreement } from "../../models/interaction.model";
import CrossIcon from "../../assets/Icons/CrossIcon";



const ServiceAgreementForm = ({setActiveTab,update=false,agreementData,togglePopupHandler,agreementPopupHandler}:{setActiveTab?:any,update?:boolean,agreementData?:Agreement,togglePopupHandler?:any,agreementPopupHandler?:any}) => {
  const dispatch=useAppDispatch()
  const { userId, who } = useAppSelector((state) => state.login?.session);
  const { clientDetail,agreementDetail } = useAppSelector((state) => state.sales);
  const { salesEngageWith, salesEngangementType, salesSubStages ,AgCTCRange,AgFreeReplacementPeriod,AgPaymentSchedule,AgPaymentTerms,AgProfileValidity,AgSeniorityLevel} =
    useAppSelector((state) => state.appConfig);
  const imageInputRef = useRef(null);
  const {clientId} =useParams()
  const [formData, setFormData] = useState({
    agreementType:agreementData?.agreementType|| "",
    newOrRenew: agreementData?.newOrRenew ||"",
    agreementStage: agreementData?.agreementStage ||"",
    agreementStartDate: moment(agreementData?.agreementStartDate).format("YYYY-MM-DD")||"",
    agreementEndDate: moment(agreementData?.agreementEndDate).format("YYYY-MM-DD")|| "",
    paymentTerms: agreementData?.paymentSchedule||"",
    paymentSchedulePercentage: agreementData?.paymentSchedulePercentage||"",
    paymentScheduleAmount: agreementData?.paymentScheduleAmount||"",
    latePaymentClause:agreementData?.latePaymentClause|| "",
    nonDisclosureAgreement: agreementData?.nonDisclosureAgreement||"",
    // commercialRates: "",
    freeReplacementPeriod: agreementData?.freeReplacementPeriod||"",
    conditionForReplacement:agreementData?.conditionsForReplacement|| "",
    profileValidity:agreementData?.profileValidity||"",
    totalRevenue: agreementData?.totalRevenueFromAgreement||"",
    bonusCommission:agreementData?.bonusCommission|| "",
    agreementFile:agreementData?.agreementFile|| null,
    domesticType: agreementData?.domesticType||"ctc", // Default is "ctc"
    internationalType:agreementData?.internationalType|| "", // Empty initially for international CTC range or seniority
    ctcRange: agreementData?.ctcRange || {
      domestic: [{ minRange: "", maxRange:"", percentage: "", amount: "" }], // Start with one default CTC entry
      international:[{ minRange: "", maxRange:"", percentage: "", amount: "" }] // Empty initially for international CTC ranges
    },
    seniorityLevels: agreementData?.seniorityLevels || {
      domestic: [{ minLevel:"" , maxLevel:"", percentage: "", amount: "" }], // Start with one default Seniority entry
      international:[{ minLevel: "", maxLevel:"", percentage: "", amount: "" }] // Empty initially for international seniority levels
    },
    copyToInternational:agreementData?.copyToInternational || false // Flag to copy data to international
  });

  
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
   formData.agreementStartDate=moment(formData.agreementStartDate).set({hour: 12,minute: 12,second: 12,millisecond: 267,}).toISOString()
   formData.agreementEndDate=moment(formData.agreementEndDate).set({hour: 12,minute: 12,second: 12,millisecond: 267,}).toISOString()

    console.log("Form Submitted", formData);

    if(update){
      console.log("UPDATEEE")
      dispatch(updateAgreementApi({clientId,agreementId:agreementData.id,...formData}))
      togglePopupHandler()
      agreementPopupHandler()
    }
    else{
      dispatch(createAgreementApi({clientId,...formData}))
      setActiveTab(1)
    }
   
  };

  const handleFileChange = (e) => {
    const files = e.target.files;
    // console.log(files, "FIles")
    const toastId = toast.loading("Uploading...");
    uploadContent.uploadCRMSContent(userId, e.target.files, (response) => {
      setFormData({ ...formData, agreementFile: e.target.files[0] });
      toast.dismiss(toastId);
    });
    setFormData({ ...formData, agreementFile: e.target.files[0] });
  };

  
 
//  // Handle input change for CTC/Seniority details
//  const handleInputChange = (index, field, value, type, section) => {
//   const updatedData = { ...formData };
//   if (type === "ctc") {
//     updatedData.ctcRange[section][index][field] = value;
//   } else if (type === "seniority") {
//     updatedData.seniorityLevels[section][index][field] = value;
//   }
//   setFormData(updatedData);
// };

//  // Handle adding new options for CTC/Seniority
//  const handleAddOption = (type, section) => {
//   const updatedData = { ...formData };
  
//   // Ensure the section is initialized before adding a new entry
//   if (!updatedData[type + "Range"][section]) {
//     updatedData[type + "Range"][section] = []; // Initialize if not yet defined
//   }

//   const newEntry = type === "ctc" ? { range: "", percentage: "", amount: "" } : { level: "", percentage: "", amount: "" };
//   updatedData[type + "Range"][section].push(newEntry);
//   setFormData(updatedData);
// };

// // Handle removing a CTC/Seniority option
// const handleRemoveOption = (index, type, section) => {
//   const updatedData = { ...formData };
//   updatedData[type + "Range"][section].splice(index, 1);
//   setFormData(updatedData);
// };
  
 
  return (
    <>
      {/* {!update && <div className=" flex justify-end items-center  py-4 mx-auto  border-black">
        <div className="space-x-4">
          <label
            onClick={() => imageInputRef.current.click()}
            className="px-6 bg-primary-500 cursor-pointer hover:bg-secondary-900 text-white p-2 rounded "
          >
            Upload Agreement:
          </label>
          <input
            ref={imageInputRef}
            type="file"
            name="agreementFile"
            accept=".pdf,.doc,.docx"
            onChange={handleFileChange}
            className="hidden "
          />
        </div>
      </div>} */}
     
      <form
        onSubmit={handleSubmit}
        className={`p-6 bg-white rounded shadow-md flex flex-col gap-2 ${update?"h-[90vh] w-[80vw] overflow-auto":""} `}
      >
         {
            update &&
            <div className="flex items-center justify-between mb-2">
               <h1 className="text-lg font-semibold text-primary-500">Edit Agreement</h1>
               <div onClick={togglePopupHandler} className="cursor-pointer hover:scale-90">
                <CrossIcon color="#1f3265"/>
               </div>
            </div>
          }
        
        <div className="flex items-center space-x-2">
          <label className="w-1/5 text-exgray-400">Agreement Type:</label>
          <select
            name="agreementType"
            value={formData.agreementType}
            onChange={handleChange}
            className="border p-2 flex-1"
          >
            <option  value="">
              Select Agreement Type
            </option>
            
            {salesEngangementType
              .filter((ele) => ele.value == clientDetail?.engageWith)
              .map((eng, index) => (
                <option key={index} value={eng.Id}>
                  {eng.Title}
                </option>
              ))}
          </select>
        </div>

        <div className="flex items-center space-x-2">
          <label className="w-1/5 text-exgray-400">New or Renew:</label>
          <select
            name="newOrRenew"
            value={formData.newOrRenew}
            onChange={handleChange}
            className="border p-2 flex-1"
          >
            <option  value="">
              Select
            </option>
            <option value="New">New Agreement</option>
            <option value="Renew">Renew Agreement</option>
          </select>
        </div>

        <div className="flex items-center space-x-2">
          <label className="w-1/5 text-exgray-400">Agreement Stage:</label>
          <select
            name="agreementStage"
            value={formData.agreementStage}
            onChange={handleChange}
            className=" border rounded-md p-2 flex-1"
          >
            <option  value="">
              Select Agreement Stage
            </option>
            {salesSubStages
              .filter((ele) => ele.value == "2")
              .map((stage, index) => (
                <option key={index} value={stage.Id}>
                  {stage.Title}
                </option>
              ))}
          </select>
        </div>

        <div className="flex items-center space-x-2">
          <label className="w-1/5 text-exgray-400">Agreement Start Date:</label>
          <input
            type="date"
            name="agreementStartDate"
            value={formData.agreementStartDate}
            onChange={handleChange}
            className="border p-2 flex-1"
          />
        </div>

        <div className="flex items-center space-x-2">
          <label className="w-1/5 text-exgray-400">Agreement End Date:</label>
          <input
            type="date"
            name="agreementEndDate"
            value={formData.agreementEndDate}
            onChange={handleChange}
            className="border p-2 flex-1"
          />
        </div>

        <div className="flex items-center space-x-2">
          <label className="w-1/5 text-exgray-400">Payment Terms:</label>
          <select
            name="paymentTerms"
            value={formData.paymentTerms}
            onChange={handleChange}
            className="border p-2 flex-1"
          >
            <option value="">Select</option>
            {AgPaymentTerms
              .map((Agm, index) => (
                <option key={index} value={Agm.Id}>
                  {Agm.Title}
                </option>
              ))}
          </select>
        </div>

        <div className="flex items-center space-x-2">
          <label className="w-1/5 text-exgray-400">Payment Schedule:</label>
          <select
            name="paymentSchedule"
            value={formData.paymentSchedule}
            onChange={handleChange}
            className="border p-2 flex-1"
          >
            <option value="">Select</option>
             {AgPaymentSchedule
              .map((Agm, index) => (
                <option key={index} value={Agm.Id}>
                  {Agm.Title}
                </option>
              ))}
          </select>
        </div>

       { formData.paymentSchedule &&  <div className="flex gap-20 items-center  space-x-2 pl-[20.9%]">
          <div className="">
          <label htmlFor="">Percentage : {"  "}
          <input
              type="number"
              name="paymentSchedulePercentage"
              value={formData.paymentSchedulePercentage}
              placeholder="0"
              onChange={handleChange}
              className="border p-2 w-28"
              onWheel={(e) => e.target.blur()}
            />
           {" "} %
          </label>
          </div>
          <div>
          <label htmlFor="">Amount : {"  "}
          <input
            type="text"
            name="paymentScheduleAmount"
            value={formData.paymentScheduleAmount}
            placeholder="Amount"
            onChange={handleChange}
            className="border p-2 w-28"
          />
          {" "} -/Rs
           </label>
           </div>
        </div>}

        <div className="flex items-center space-x-2">
          <label className="w-1/5 text-exgray-400">Late Payment Clause:</label>
          <input
            type="text"
            name="latePaymentClause"
            value={formData.latePaymentClause}
            onChange={handleChange}
            className="border p-2 flex-1"
          />
        </div>

        <div className="flex items-center space-x-2">
          <label className="w-1/5 text-exgray-400">
            Non-Disclosure Agreement:
          </label>
          <select
            name="nonDisclosureAgreement"
            value={formData.nonDisclosureAgreement}
            onChange={handleChange}
            className="border p-2 flex-1"
          >
            <option value="">Select</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        </div>

        {/* <div className="flex items-center space-x-2">
        <label className="w-1/5 text-exgray-400">Commercial Rates:</label>
        <input type="number" name="commercialRates" onChange={handleChange} className="border p-2 flex-1" />
      </div> */}
        {/* ---------------------------------------- */}
 
 
            <CommercialRateForm formData={formData} setFormData={setFormData}/>
    
    
        {/* --------------------------------- */}
        <div className="flex items-center space-x-2">
          <label className="w-1/5 text-exgray-400">
            Free Replacement Period:
          </label>
          <select
            name="freeReplacementPeriod"
            value={formData.freeReplacementPeriod}
            onChange={handleChange}
            className="border p-2 flex-1"
          >
            <option value="">Select</option>
             {AgFreeReplacementPeriod
              .map((Agm, index) => (
                <option key={index} value={Agm.Id}>
                  {Agm.Title}
                </option>
              ))}
          </select>
        </div>

        <div className="flex items-center space-x-2">
          <label className="w-1/5 text-exgray-400">
            Condition for Replacement:
          </label>
          <input
            type="text"
            name="conditionForReplacement"
            value={formData.conditionForReplacement}
            onChange={handleChange}
            className="border p-2 flex-1"
          />
        </div>

        <div className="flex items-center space-x-2">
          <label className="w-1/5 text-exgray-400">Profile Validity:</label>
          <select
            name="profileValidity"
            value={formData.profileValidity}
            onChange={handleChange}
            className="border p-2 flex-1"
          >
            <option value="">Select</option>
             {AgProfileValidity
              .map((Agm, index) => (
                <option key={index} value={Agm.Id}>
                  {Agm.Title}
                </option>
              ))}
          </select>
        </div>

        <div className="flex items-center space-x-2">
          <label className="w-1/5 text-exgray-400">Total Revenue:</label>
          <input
            type="number"
            name="totalRevenue"
            value={formData.totalRevenue}
            onChange={handleChange}
            className="border p-2 flex-1"
            onWheel={(e) => e.target.blur()}
          />
        </div>

        <div className="flex items-center space-x-2">
          <label className="w-1/5 text-exgray-400">Bonus Commission:</label>
          <input
            type="text"
            name="bonusCommission"
            value={formData.bonusCommission}
            onChange={handleChange}
            className="border p-2 flex-1"
          />
        </div>

        <div className="flex justify-end">
          <button
            type="submit"
            className="mt-4 px-6 bg-primary-500 hover:scale-95 text-white p-2 rounded "
          >
          { !update?  `Submit`:"Save"}
          </button>
        </div>
      </form>
    </>
  );
};

export default ServiceAgreementForm;
