import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../config/hooks";
import { getMemberByIdApi } from "../../../slices/companyMemberSlice";
import {
  getApplicantsApi,
  getAssignJobsToMemberApi,
} from "../../../slices/jobSlice";
import JobCard from "../../ats/pages/job-search/components/JobCard";
import CandidateCard from "../../../components/candidate-card/CandidateCard";
import InfiniteScroll from "react-infinite-scroll-component";

const AdviserAssignCandidateList = () => {
  const location = useLocation();
  const { memberId } = location.state || {};
  const { jobId } = useParams();

  const dispatch = useAppDispatch();
  const [togglePopup, setTogglePopup] = useState(false);
  const [isProfessional, setIsProfessional] = useState(false);
  const assignJobList = useAppSelector((state) => state.jobs.assignJobList);
  const applicants = useAppSelector(
    (state) => state.jobs.jobApplicants.applicants
  );

  useEffect(() => {
    getApplicants();
  }, [togglePopup, isProfessional]);

  const getApplicants = () => {
    dispatch(
      getApplicantsApi({
        jobId: jobId,
        adviserId: memberId,
      })
    ).then((result) => {});
  };

  // const getAssignJobs = () => {
  //   dispatch(getAssignJobsToMemberApi({
  //     memberId: memberId,
  //     //search: debouncedQuery,
  //   }))

  // }

  const getMoreDataHandler = () => {
    // try {
    //   dispatch(getNextApplicantsApi());
    // } catch (error) {
    //   toast.error("Unable to fetch data");
    // }
  };

  return (
    <>
      <div>
        <h1 className="text-lg font-semibold my-2 text-exgray-600">
          Assign Candidates
        </h1>
        <InfiniteScroll
          className="overflow-x-auto"
          dataLength={
            applicants?.applications?.length
              ? applicants?.applications?.length
              : 0
          }
          next={() => getMoreDataHandler()}
          hasMore={applicants?.metadata?.nextResultURL}
          // hasMore={true}
          height={"calc(100vh - 155px)"}
          style={{ overflow: "auto" }}
          loader={""}
        >
          <table className="min-w-full" cellSpacing="10">
            <thead>
              <tr className="text-left">
                <th className="text-nowrap px-4 py-2 text-base text-exgray-200/70 font-semibold">
                  Candidate Name
                </th>
                <th className="text-nowrap px-4 py-2 text-base text-exgray-200/70 font-semibold">
                  Email Id
                </th>
                <th className="text-nowrap px-4 py-2 text-base text-exgray-200/70 font-semibold">
                  Location
                </th>
                <th className="text-center text-nowrap px-4 py-2 text-base text-exgray-200/70 font-semibold">
                  Yrs of Experience
                </th>
                <th className="text-center text-nowrap px-4 py-2 text-base text-exgray-200/70 font-semibold">
                  Notice Period
                </th>
                <th className="text-center text-nowrap px-4 py-2 text-base text-exgray-200/70 font-semibold">
                  Current CTC
                </th>
                <th className="text-center text-nowrap px-4 py-2 text-base text-exgray-200/70 font-semibold">
                  Expected CTC
                </th>
                <th className="text-start text-nowrap px-4 py-2 text-base text-exgray-200/70 font-semibold">
                  Approver Name
                </th>
                <th className="text-start text-nowrap px-4 py-2 text-base text-exgray-200/70 font-semibold">
                  Assigned Date & Time
                </th>
                <th className="text-center text-nowrap px-4 py-2 text-base text-exgray-200/70 font-semibold">
                  Approver Status
                </th>
                <th className="text-center text-nowrap px-4 py-2 text-base text-exgray-200/70 font-semibold">
                  Action Taken On
                </th>
              </tr>
            </thead>
            <tbody className="">
              {applicants?.applications?.map((candidate) => (
                <CandidateCard
                  candidate={candidate}
                  setSelectedCandidates={undefined}
                  selectedCandidates={undefined}
                  candidateDetails={candidate.author}
                  key={candidate.id}
                  setAppliedCadidateViaRefer={undefined}
                  appliedCadidateViaRefer={undefined}
                  showStatus={true}
                />
              ))}
            </tbody>
          </table>
        </InfiniteScroll>
      </div>
    </>
  );
};

export default AdviserAssignCandidateList;
