import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../config/hooks";
import EditIcon from "../../../assets/Icons/EditIcon";
import uploadContent from "../../../utils/upload-content/uploadContent";
import { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { updateUserProfileApi } from "../../../slices/candidateProfileSlice";
import { getMemberByIdApi, updateMembersApi } from "../../../slices/companyMemberSlice";
import * as constants from "../../../constant";
import { member } from "../../../models/companyMember.model";
import moment from "moment";
import BasicDataView from "../../../components/BasicDataView";

export interface EmployeeDetailProps {
  memberDetail: member;
}

const EmployeeDetail = ({ memberDetail }: EmployeeDetailProps) => {
  const navigate = useNavigate();
  //const memberDetail = useAppSelector((state) => state.company.memberDetails);
  const dispatch = useAppDispatch();
  // const uploadProfilePicture = () => {
  //   uploadContent.
  // }
  const [profileUrl, setProfileUrl] = useState('');
  const hiddenFileInput = useRef(null);
  const token = useAppSelector((state) => state?.login?.session?.token);
  const userId = useAppSelector((state) => state?.login?.session?.userId);

  // Programatically click the hidden file input element
  // when the Button component is clicked
  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  const handleChange = (event) => {
    const fileUploaded = event.target.files[0];
    if (fileUploaded) {
      uploadContent.uploadProfilePicture(memberDetail.id, fileUploaded, handleFileUpload)
    }
  };

  const handleFileUpload = (response) => {
    setProfileUrl(response[0].url);
    dispatch(updateMembersApi({
      memberId: memberDetail.id,
      profileImage: response[0].url
    }));
    dispatch(getMemberByIdApi({
      memberId: memberDetail.id
    }))
  }

  return (
    <>
      <div className="p-3">
        <div className="flex">
          <div className="w-10/12">
            <div className="flex items-center">
              <div className="flex w-9/12">
                <BasicDataView
                  title={"First Name"}
                  value={memberDetail?.firstName}
                />
              </div>
              <div className="flex w-6/12">
                <div className="flex w-full">
                  <h1 className="text-sm text-exgray-400 mt-2 w-6/12">Last Name  :</h1>
                  <h1 className="text-sm text-primary-500 mt-2 font-semibold ml-4 w-6/12">{memberDetail?.lastName}</h1>
                </div>
                {/* <BasicDataView
                  title={"Last Name"}
                  value={memberDetail?.lastName}
                /> */}
              </div>
            </div>
            <div className="flex mt-3 items-center">
              <div className="flex w-9/12">
                <BasicDataView
                  title={"Date of Birth"}
                  value={moment(memberDetail?.birthDate).format("DD/MM/YYYY")}
                />
              </div>
              <div className="flex w-6/12">
                {/* <BasicDataView
                  title={"Gender"}
                  value={memberDetail?.gender}
                /> */}
                <div className="flex w-full">
                  <h1 className="text-sm text-exgray-400 mt-2 w-6/12">Gender  :</h1>
                  <h1 className="text-sm text-primary-500 mt-2 font-semibold ml-4 w-6/12">{memberDetail?.gender}</h1>
                </div>
              </div>
            </div>
            <div className="flex mt-3 items-center">
              <div className="flex w-9/12">
                <BasicDataView
                  title={"Marital Status"}
                  value={memberDetail?.maritalStatus}
                />
              </div>
              <div className="flex w-6/12">
                <div className="flex w-full">
                  <h1 className="text-sm text-exgray-400 mt-2 w-6/12">Nationality  :</h1>
                  <h1 className="text-sm text-primary-500 mt-2 font-semibold ml-4 w-6/12">{memberDetail?.nationality}</h1>
                </div>
                {/* <BasicDataView
                  title={"Nationality"}
                  value={memberDetail?.nationality}
                /> */}
              </div>
            </div>
          </div>
          <div onClick={handleClick} className="w-2/12 items-center justify-center text-center">
            {!memberDetail?.profileImage ?
              <div className="h-28 w-28 rounded-full bg-primary-400 flex items-center justify-center text-3xl text-exwhite-100 font-semibold">
                {`${memberDetail?.firstName[0].toUpperCase()}${memberDetail?.lastName[0].toUpperCase()}`}
              </div>
              :
              <img
                src={`${constants.baseUrl}/${memberDetail?.profileImage}?t=${token}`}
                alt="profile"
                className="h-28 w-28 rounded-full"
              />
            }
            {
              memberDetail?.id === userId &&
              <div style={{ marginTop: -22 }} className="flex justify-center">
                <div className="bg-secondary-400 rounded-full p-2">
                  <EditIcon color={"#FFFFFF"} />
                </div>
              </div>
            }

            <input
              type="file"
              onChange={handleChange}
              ref={hiddenFileInput}
              style={{ display: "none" }} // Make the file input element invisible
            />
          </div>
        </div>

        <div>
          <div className="flex mt-3 items-center">
            <div className="flex w-6/12">
              <BasicDataView
                title={"Phone Number"}
                value={memberDetail?.mobileNo}
              />
            </div>
            <div className="flex w-6/12">
              <BasicDataView
                title={"Email ID"}
                value={memberDetail?.emailId}
              />
            </div>
          </div>
          <div className="flex mt-3 items-center">
            <div className="flex w-6/12">
              <BasicDataView
                title={"Current Address"}
                value={memberDetail?.currentAddress?.street}
              />
            </div>
            <div className="flex w-6/12">
              <BasicDataView
                title={"Permanent Address"}
                value={memberDetail?.permanentAddress?.street}
              />
            </div>
          </div>
          {/* <h1 className="text-exgray-400 font-bold text-sm mt-5 mb-3">Emergency  Details :</h1>
          <div className="flex items-center">
            <div className="flex w-6/12">
              <h1 className="text-sm  text-exgray-400 mt-2">First Name : </h1>
              <h1 className="text-sm text-primary-500 mt-2 font-semibold"> {memberDetail?.firstName}</h1>
            </div>
            <div className="flex w-6/12">
              <h1 className="text-sm text-exgray-400 mt-2">Last Name : </h1>
              <h1 className="text-sm text-primary-500 mt-2 font-semibold"> {memberDetail?.lastName}</h1>
            </div>
          </div>
          <div className="flex mt-3 items-center">
            <div className="flex w-6/12">
              <h1 className="text-sm  text-exgray-400 mt-2">Relationship :   </h1>
              <h1 className="text-sm text-primary-500 mt-2 font-semibold"> {memberDetail?.firstName}</h1>
            </div>
            <div className="flex w-6/12">
              <h1 className="text-sm text-exgray-400 mt-2">Phone :  </h1>
              <h1 className="text-sm text-primary-500 mt-2 font-semibold"> {memberDetail?.lastName}</h1>
            </div>
          </div>
          <div className="flex mt-3 items-center">
            <div className="flex w-6/12">
              <h1 className="text-sm  text-exgray-400 mt-2">Current Address  :    </h1>
              <h1 className="text-sm text-primary-500 mt-2 font-semibold"> Flat No. 101, Sunshine Apartments, MG Road, Bengaluru, Karnataka - 560001</h1>
            </div>
            <div className="flex w-6/12">
              <h1 className="text-sm text-exgray-400 mt-2">Permanent Address :   </h1>
              <h1 className="text-sm text-primary-500 mt-2 font-semibold"> Flat No. 101, Sunshine Apartments, MG Road, Bengaluru, Karnataka - 560001</h1>
            </div>
          </div> */}

          {/* <div className="flex mt-3 justify-between items-center">
            <h1 className="text-sm text-exgray-400 mt-2">Address : {memberDetail?.address}</h1>
            <h1 className="text-sm text-exgray-400 mt-2">Date Of Birth : {memberDetail?.dob}</h1>
            <h1 className="text-sm text-exgray-400 mt-2">Gender : {memberDetail?.gender}</h1>
          </div> */}
        </div>

      </div >
    </>
  );
};

export default EmployeeDetail;
