import { useAppSelector } from "../../config/hooks";

const useLocations = () => {
  const locationsArray = useAppSelector((state) => state.appConfig.cities);

  const defaultLocation = (id) => {
    if (isNaN(id)) {
      const filteredLocation = locationsArray.find(
        (location) => location.Name === id
      );

      if (filteredLocation) {
        return filteredLocation;
      }
      return { Id: id, Name: id, State: id };
    }
    const filteredLocation = locationsArray.find(
      (location) => location.Id == id
    );
    return filteredLocation;
  };

  const defaultLocations = (dafaultIds) => {
    const selectedIndustry = dafaultIds.map((id) => {
      if (isNaN(id)) {
        const filteredLocation = locationsArray.find(
          (location) => location.Name === id
        );

        if (filteredLocation) {
          return filteredLocation;
        }

        return { Id: id, Name: id, State: id };
      }

      const filteredLocation = locationsArray.find(
        (location) => location.Id == id
      );
      return filteredLocation;
    });

    const filteredArr = selectedIndustry.filter((item) => item !== undefined);

    return filteredArr;
  };

  return { locationsArray, defaultLocation, defaultLocations };
};

export default useLocations;
