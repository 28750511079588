import React from "react";

const ForwardIcon = ({ width = "20", height = "18", color = "#0A65CC" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.1282 2.33117L16.0249 5.77657C17.5638 7.13712 18.3332 7.81742 18.3332 8.69375C18.3332 9.57017 17.5638 10.2504 16.0249 11.611L12.1282 15.0564C11.4258 15.6774 11.0746 15.988 10.785 15.8586C10.4954 15.7293 10.4954 15.2619 10.4954 14.3271V12.0393C6.96386 12.0393 3.138 13.7121 1.6665 16.5C1.6665 7.57858 6.89846 5.34827 10.4954 5.34827V3.06047C10.4954 2.12568 10.4954 1.65829 10.785 1.52895C11.0746 1.39961 11.4258 1.71013 12.1282 2.33117Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ForwardIcon;
