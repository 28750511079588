import React, { useEffect, useMemo, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import ExInput from "../../../components/basic-components/ExInput";
import ExDropdown from "../../../components/basic-components/ExDropdown";
import { useAppDispatch, useAppSelector } from "../../../config/hooks";
import * as constants from "../../../constant";
import {
  createMemberApi,
  getAllDocummentApi,
  updateDocumentStatusApi,
  updateMembersApi,
  uploadDocumentsApi,
} from "../../../slices/companyMemberSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { toast } from "sonner";
import FileIcon from "../../../assets/Icons/FileIcon";
import DownloadIcon from "../../../assets/Icons/DownloadIcon";
import MenuIcon from "../../../assets/Icons/MenuIcon";
import DownloadHrmsIcon from "../../../assets/Icons/DownloadHrmsIcon";
import UploadIcon from "../../../assets/Icons/UploadIcon";
import Popup from "../../../components/popup/Popup";
import { DropdownButton } from "./DropdownButton";
import uploadContent from "../../../utils/upload-content/uploadContent";
import { useParams } from "react-router-dom";
import moment from "moment";

const documentListName = [
  {
    Id: 1,
    Title: "Resume/CV",
    supportedDoc: "pdf,docx",
  },
  {
    Id: 2,
    Title: "Signed Offer Letter",
    supportedDoc: "pdf,docx",
  },
  {
    Id: 3,
    Title: "Passport Photo",
    supportedDoc: "jpg,jpeg,png",
  },
  {
    Id: 4,
    Title: "Education Certificates",
    supportedDoc: "pdf,docx",
  },
  {
    Id: 5,
    Title: "Relieving / Experience Letter",
    supportedDoc: "pdf,docx",
  },
  {
    Id: 6,
    Title: "Last 3 Months Salary Slips",
    supportedDoc: "pdf,docx",
  },
];

const docOptions = [
  {
    Id: 1,
    Title: "Approved",
    key: "approved",
  },
  {
    Id: 2,
    Title: "Reject",
    key: "reject",
  },
];

const DocumentList = () => {
  const dispatch = useAppDispatch();

  const memberSelector = useAppSelector((state) => state?.member.memberDetails);

  const { memberId } = useParams();

  const documentList = useAppSelector((state) => state.member.documentList);

  const userId = useAppSelector((state) => state.login.session.userId);

  const [doc, setDocument] = useState(null);

  const form = useForm({
    defaultValues: {
      emailId: "",
      role: "",
      firstName: "",
      lastName: "",
      mobileNo: null,
      department: "",
      joiningDate: "",
      reportingManager: "",
      employeeCode: "",
    },
  });

  const menuRef = useRef(null);
  const menuParentRef = useRef(null);
  const [isToggleModalOpen, setIsToggleModalOpen] = useState(false);
  const hiddenFileInput = useRef(null);
  const [selectedRole, setRole] = useState(null);

  useEffect(() => {
    const toggleHandler = (e) => {
      if (
        menuRef.current &&
        menuParentRef &&
        !menuRef.current.contains(e.target) &&
        !menuParentRef.current.contains(e.target)
      ) {
        // close popup
        setIsToggleModalOpen(false);
      }
    };
    document.addEventListener("mousedown", toggleHandler);

    return () => {
      document.removeEventListener("mousedown", toggleHandler);
    };
  });

  useEffect(() => {
    dispatch(
      getAllDocummentApi({
        userID: memberSelector?.id,
        documentType: "",
        documentName: "",
        documentURL: "",
        uploadedBy: "",
      })
    );
  }, []);

  const toggleModalHandler = () => {
    setIsToggleModalOpen(!isToggleModalOpen);
  };

  const handleClick = (role) => {
    setRole(role);
    hiddenFileInput.current.click();
  };

  const handleChange = (event) => {
    const fileUploaded = event.target.files[0];
    // if (fileUploaded.) {
    //   uploadContent.uploadEmployeeDocument(memberSelector.id, fileUploaded, handleFileUpload)
    // }
    // else {

    // }

    if (
      selectedRole.Title === "Resume/CV" ||
      selectedRole.Title === "Signed Offer Letter" ||
      selectedRole.Title === "Education Certificates" ||
      selectedRole.Title === "Relieving / Experience Letter" ||
      selectedRole.Title === "Last 3 Months Salary Slips"
      //
    ) {
      if (
        fileUploaded.type.split("/")[1] === "pdf" ||
        fileUploaded.type.split("/")[1] === "docx"
      ) {
        uploadContent.uploadEmployeeDocument(
          memberSelector.id,
          fileUploaded,
          handleFileUpload
        );
      } else {
        toast.error("Please Select file type pdf or docx");
      }
    } else if (selectedRole.Title === "Passport Photo") {
      if (
        fileUploaded.type.split("/")[1] === "jpeg" ||
        fileUploaded.type.split("/")[1] === "png" ||
        fileUploaded.type.split("/")[1] === "jpg"
      ) {
        uploadContent.uploadEmployeeDocument(
          memberSelector.id,
          fileUploaded,
          handleFileUpload
        );
      } else {
        toast.error("Please Select file type png,jpeg or jpg");
      }
    }
  };

  const handleFileUpload = (response) => {
    dispatch(
      uploadDocumentsApi({
        userID: userId,
        documentType: "personal_document",
        documentName: selectedRole?.Title,
        documentURL: response[0].url,
        uploadedBy: memberSelector.id,
        status: "In-Review",
      })
    );
  };

  const getStatus = (title) => {
    let doc = documentList?.filter((doc) => doc.documentName == title);
    if (doc) {
      return doc[0]?.status;
    } else {
      return "Not Uploaded";
    }
  };

  const getUploadedDate = (title) => {
    let doc = documentList?.filter((doc) => doc.documentName == title);
    if (doc) {
      return moment(doc[0]?.uploadedAt).format("DD/MM/YYYY HH:mm");
    } else {
      return null;
    }
  };

  const getDocument = (title) => {
    let doc = documentList?.filter((doc) => doc.documentName == title);
    if (doc) {
      return doc[0];
    }
  };

  const updateDocumentStatus = (item, role) => {
    const doc = getDocument(role.Title);
    dispatch(
      updateDocumentStatusApi({
        id: doc.id,
        userID: doc.userId,
        status: item.Title,
        updatedBy: userId,
      })
    );
  };

  return (
    <div className="px-3 mx-auto">
      <div className="relative overflow-auto">
        <table className="w-full text-left text-gray-500">
          <thead className="text-xs text-gray-400">
            <tr>
              <th className="px-6 py-3 text-base w-4/12">Documents</th>
              <th className="px-6 py-3 text-base w-3/12">Uploader</th>
              {/* <th className="px-6 py-3 text-base w-3/12">Reviewer</th> */}
              <th className="px-6 py-3 text-base w-3/12">Status</th>
              <th className="px-6 py-3 text-base w-2/12">Action</th>
            </tr>
          </thead>
          <tbody>
            {documentListName?.map((role) => {
              return (
                <tr className="bg-white border-b border-exgray-200/60 text-primary-400">
                  <td className="py-3">
                    <div className="flex items-center">
                      <FileIcon />
                      <div className="ml-3">
                        <h1 className="">{role?.Title}</h1>
                        {documentList?.map((doc) => {
                          if (doc.documentName === role?.Title) {
                            return (
                              <h1 className="text-xs text-exgray-200">
                                Uploaded on{" "}
                                {moment(doc.uploadedAt).format(
                                  "DD/MM/YYYY HH:mm"
                                )}
                              </h1>
                            );
                          }
                        })}
                      </div>
                    </div>
                  </td>
                  <td className="px-6 py-3">
                    {memberSelector?.firstName} {memberSelector?.lastName}
                  </td>
                  {/* <td className="px-6 py-3">?</td> */}
                  <td className="px-3 py-3">
                    {getStatus(role.Title) ? (
                      <h1 className="text-secondary-400 font-medium">
                        {getStatus(role.Title)}
                      </h1>
                    ) : (
                      "Not Uploaded"
                    )}
                  </td>
                  {/* {
                      documentList?.map((doc => {
                        if (doc?.documentName == role.Title) {
                          return (<td className="px-6 py-3">{doc?.status}</td>)
                        }
                        else {
                          return (<td className="px-6 py-3"></td>)
                        }

                      }))
                    } */}

                  {memberId == userId ? (
                    <td className="justify-center s-center">
                      <div
                        className="my-2 items-center justify-center ml-5"
                        onClick={() => handleClick(role)}
                      >
                        <div className="items-center justify-center ml-4">
                          <UploadIcon color="#0A65CC" height="20" width="20" />
                        </div>
                        <div className="mt-2">
                          <h1 className="text-sm">{role.supportedDoc}</h1>
                        </div>
                      </div>
                    </td>
                  ) : (
                    <td>
                      <div className="flex justify-between items-center">
                        <div className="text-center">
                          <DownloadHrmsIcon />
                        </div>
                        <DropdownButton
                          buttonLabel={"Dropdown"}
                          role={role}
                          items={docOptions}
                          defaultAction={(item, role) =>
                            updateDocumentStatus(item, role)
                          }
                        />
                      </div>
                    </td>
                  )}
                </tr>
              );
            })}
          </tbody>
        </table>
        <input
          type="file"
          onChange={(event) => handleChange(event)}
          ref={hiddenFileInput}
          style={{ display: "none" }} // Make the file input element invisible
        />
      </div>
    </div>
  );
};

export default DocumentList;
