import moment from 'moment';
import { useEffect } from 'react';
import ReactQuill from 'react-quill';
import { useAppDispatch, useAppSelector } from '../../config/hooks';
import { getTimesheetApi } from '../../slices/attendanceSlice';
import { useParams } from 'react-router-dom';

const EmployeeTimesheetHrView = () => {
  const dispatch = useAppDispatch();
  const { employeeId } = useParams();

  console.log(employeeId, "you are in employee timesheet view")
  const userId = useAppSelector((state) => state.login.session.userId);
  const timesheet = useAppSelector((state) => state.attendance.timesheet);

  useEffect(() => {
    let month = moment().month() > 8 ? moment().month() + 1 : 0 + moment().month() + 1;
    let year = moment().year();
    dispatch(getTimesheetApi({
      dateFrom: moment(`${month}/01/${year}`).format('YYYY-MM-DD'),
      dateTo: moment().format('YYYY-MM-DD'),
      employeeId: employeeId,
    }))
  }, [])

  return (
    <>
      <div className='flex justify-between items-center'>
        <h1 className='font-medium text-xl'>Timesheet Of {timesheet && timesheet[0]?.employee?.firstName} {timesheet && timesheet[0]?.employee?.lastName}</h1>
        <div className='flex items-center'>
          {/* <div className='flex items-center border border-exgray-300 rounded-md py-1 px-4'>
            <FilterIcon />
            <h1 className='text-exgray-300 ml-2'>Filter</h1>
          </div> */}
          {/* <button className='bg-secondary-700 text-exwhite-100 py-1 px-5 rounded-md ml-4 border border-primary-500'>
            Export
          </button> */}
        </div>
      </div>
      <div>
        <table className="w-full mt-4 rounded-sm border border-exgray-100">
          <thead className="py-3">
            <tr className="border border-exgray-100">
              <th className="text-sm text-exgray-200 font-medium py-3 px-2 w-2/12 text-start">Date</th>
              <th className="text-sm text-exgray-200 font-medium py-3 px-2 w-2/12 text-start">Start-Time</th>
              <th className="text-sm text-exgray-200 font-medium py-3 px-2 w-2/12 text-start">End-Time</th>
              <th className="text-sm text-exgray-200 font-medium py-3 px-2 w-2/12 text-center">Working Hrs</th>
              <th className="text-sm text-exgray-200 font-medium py-3 px-2 w-6/12 text-start">Task List</th>
            </tr>
          </thead>
          <tbody className="items-center justify-center ">
            {
              timesheet?.map((t) => {
                var startTime = moment(t.checkIn);
                var endTime = moment(t.checkOut);
                var dif = moment.duration(endTime.diff(startTime));
                return (
                  <tr className="items-center bg-exwhite-100 border-b-2 border-bg-primary-100/50">
                    <td className="items-center py-3 px-2">
                      {moment(t.date).format('DD/MM/YYYY')}
                    </td>
                    <td className="px-2 text-start py-3 text-secondary-500 font-medium">
                      {moment(t.checkIn).format('LT')}
                    </td>
                    <td className="px-2 text-start py-3 text-secondary-500 font-medium">
                      {t?.status ? moment(t.checkOut).format('LT') : ''}
                    </td>
                    <td className="px-2 text-center py-3 text-secondary-500 font-medium">
                      {t?.status ? `${dif.hours()}:${dif.minutes() < 10 ? "0" + dif.minutes() : dif.minutes()}` : ''}
                    </td>
                    <td className="px-2 items-center">
                      <ReactQuill
                        style={{ paddingBottom: -15, marginBottom: -35 }}
                        value={t?.dailyReport?.dailyReport[0]}
                        readOnly={true}
                        theme={"bubble"}
                      />
                    </td>
                  </tr>
                );
              })
            }
            {/* <tr className="items-center bg-exwhite-100 border-b-4 border-bg-primary-100/50">
              <td className="items-center py-3 px-2">
                22 January 2025
              </td>
              <td className="px-2 text-start py-3 text-secondary-500 font-medium">
                09:00 am
              </td>
              <td className="px-2 text-start py-3">
                09:00 pm
              </td>
              <td className="px-2 items-center py-3">
                Wireframe Design for New Project
                3 candidates have interviews scheduled for tomorrow. Ensure feedback forms are ready...
              </td>
            </tr>
            <tr className="items-center border-b-4 border-bg-primary-100/50 bg-exwhite-100 mt-3">
              <td className="items-center py-3 px-2">
                22 January 2025
              </td>
              <td className="px-2 text-start py-3 text-secondary-500 font-medium">
                09:00 am
              </td>
              <td className="px-2 text-start py-3">
                09:00 pm
              </td>
              <td className="px-2 items-center py-3">
                Wireframe Design for New Project
                3 candidates have interviews scheduled for tomorrow. Ensure feedback forms are ready...
              </td>
            </tr>
            <tr className="items-center border-b-4 border-bg-primary-100/50 bg-exwhite-100 mt-3">
              <td className="items-center py-3 px-2">
                22 January 2025
              </td>
              <td className="px-2 text-start py-3 text-secondary-500 font-medium">
                09:00 am
              </td>
              <td className="px-2 text-start py-3">
                09:00 pm
              </td>
              <td className="px-2 items-center py-3">
                Wireframe Design for New Project
                3 candidates have interviews scheduled for tomorrow. Ensure feedback forms are ready...
              </td>
            </tr> */}
          </tbody>
        </table>
      </div>
    </>
  )
}

export default EmployeeTimesheetHrView
