import React, { useEffect, useMemo, useRef, useState } from "react";
import RightArrowIcon from "../../../../../assets/Icons/RightArrowIcon";
import { Controller, useForm } from "react-hook-form";
import ExInput from "../../../../../components/basic-components/ExInput";
import ExCheckBox from "../../../../../components/basic-components/ExCheckBox";
import useJobTypes from "../../../../../utils/dropdown-options/useJobTypes";
import ExDropdown from "../../../../../components/basic-components/ExDropdown";
import useWorkPlaceTypes from "../../../../../utils/dropdown-options/useWorkPlaceTypes";
import useLocations from "../../../../../utils/dropdown-options/useLocations";
import useMinExperiences from "../../../../../utils/dropdown-options/useMinExperiences";
import useIndustries from "../../../../../utils/dropdown-options/useIndustries";
import ExRadioBtnGroup from "../../../../../components/basic-components/ExRadioBtnGroup";
import useSalaries from "../../../../../utils/dropdown-options/useSalaries";
import ExTextarea from "../../../../../components/basic-components/ExTextarea";
import { useAppDispatch, useAppSelector } from "../../../../../config/hooks";
import {
  asignJobApi,
  createJobApi,
  getJobDetailApi,
  updateJobApi,
} from "../../../../../slices/jobSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { useNavigate, useParams } from "react-router-dom";
import * as constants from "../../../../../constant";
import uploadContent from "../../../../../utils/upload-content/uploadContent";
import UploadIcon from "../../../../../assets/Icons/UploadIcon";
import { getJdDetailsApi } from "../../../../../slices/aiSlice";
import ExMultiDropdown from "../../../../../components/basic-components/ExMultiDropdown";
import CrossIcon from "../../../../../assets/Icons/CrossIcon";
import { v4 as uuidv4 } from "uuid";
import { toast } from "sonner";
import useCurrencies from "../../../../../utils/dropdown-options/useCurrencies";
import useQualifications from "../../../../../utils/dropdown-options/useQualifications";
import useFunctions from "../../../../../utils/dropdown-options/useFunctions";
import useSalariesInThousands from "../../../../../utils/dropdown-options/useSalariesInThousands";

const CreateJob = () => {
  const { jobId } = useParams();
  const inputRef = useRef(null);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [isFileLoading, setIsFileLoading] = useState(false);
  const [interestValue, setInterestValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const loginSelector = useAppSelector((state) => state?.login?.session);
  const memberSelector = useAppSelector(
    (state) => state?.member?.memberDetails
  );

  const { jobTypesArray, defaultJobType } = useJobTypes();
  const { workplaceTypesArray, defaultWorkPlaceType } = useWorkPlaceTypes();
  const { locationsArray, defaultLocations } = useLocations();
  const { minExperienceArray, defaultMinExperience } = useMinExperiences();
  const { industriesArray, defaultIndustries } = useIndustries();
  const { minSalaries, defualtSalary } = useSalaries();
  const { currenciesArray, defaultCurrency } = useCurrencies();
  const { qualificationsArray, defaultQualifications } = useQualifications();
  const { functionsArray, defaultFunctions } = useFunctions();
  const { salaryInThousands, defualtSalaryInThousand } =
    useSalariesInThousands();

  const form = useForm({
    defaultValues: async () => {
      if (jobId) {
        const response = await dispatch(getJobDetailApi({ jobId })).then(
          unwrapResult
        );
        const data = response.data.data[0];
        return {
          jobTitle: data.title,
          qualification:
            data?.qualifications && data?.qualifications.length > 0
              ? defaultQualifications(data?.qualifications)
              : null,
          companyName: data.company,
          hideCompany: data.hideCompany,
          jobType: defaultJobType(data.jobType),
          workplaceType: defaultWorkPlaceType(data.workplaceType),
          minExperience: defaultMinExperience(data.experience.min),
          maxExperience: defaultMinExperience(data.experience.max),
          industry: data.industries && defaultIndustries(data.industries),
          location: defaultLocations(data.location),
          state: [],
          country: ["India"],
          hiringFor: data.hiringFor === "COMPANY" ? "Company" : "Client",
          skill: data.skills,
          minSalary: defualtSalary(data.salary.min),
          minSalaryInThousand: defualtSalaryInThousand(
            data?.salary?.minThousand
          ),
          maxSalary: defualtSalary(data.salary.max),
          maxSalaryInThousand: defualtSalaryInThousand(
            data?.salary?.maxThousand
          ),
          currency: defaultCurrency(data.currency),
          hideSalary: data.hideSalary,
          jobDescription: data.description,
          createdAt: data.createdAt,
          jobStatus: data.jobStatus,
          file: {
            name: "",
            url: "",
            contentType: "",
            size: 0,
          },
          functions:
            data?.functions && data?.functions.length > 0
              ? defaultFunctions(data?.functions)
              : null,
        };
      } else {
        return {
          jobTitle: "",
          companyName: "",
          hideCompany: false,
          jobType: null,
          workplaceType: null,
          minExperience: null,
          maxExperience: null,
          industry: null,
          location: null,
          hiringFor: "Company",
          skill: [],
          minSalary: null,
          maxSalary: null,
          minSalaryInThousand: {
            Title: 0,
            Value: 0,
          },
          maxSalaryInThousand: {
            Title: 0,
            Value: 0,
          },

          currency: {
            Id: currenciesArray[0]?.Title,
            Title: currenciesArray[0]?.Title,
          },
          hideSalary: false,
          jobDescription: "",
          qualification: [],
          state: [],
          country: ["India"],
          jobStatus: "",
          file: {
            name: "",
            url: "",
            contentType: "",
            size: 0,
          },
          functions: [],
        };
      }
    },
  });

  const { handleSubmit, control, formState, watch, setValue } = form;
  const { errors, isDirty } = formState;

  // const uploadedFile = watch("file");
  const allSkillsValues = watch("skill");
  const selectedMinExperience = watch("minExperience");
  const selectedMaxExperience = watch("maxExperience");
  const selectedMinSalary = watch("minSalary");
  const selectedMaxSalary = watch("maxSalary");
  const selectedLocations = watch("location");

  useEffect(() => {
    if (selectedLocations) {
      const states = selectedLocations.map((location) => {
        return location?.State;
      });
      setValue("state", states);
    }
  }, [selectedLocations, setValue]);

  const maxExpRange = useMemo(() => {
    if (selectedMinExperience) {
      const targetIndex = minExperienceArray.findIndex(
        (exp) => exp.Id === selectedMinExperience.Id
      );
      if (targetIndex) return minExperienceArray.slice(targetIndex + 1);
      return minExperienceArray;
    }
    return minExperienceArray;
  }, [selectedMinExperience]);

  const minExpRange = useMemo(() => {
    if (selectedMaxExperience) {
      const targetIndex = minExperienceArray.findIndex(
        (exp) => exp.Id === selectedMaxExperience.Id
      );
      if (targetIndex) return minExperienceArray.slice(0, targetIndex);
      else return minExperienceArray;
    }
    return minExperienceArray;
  }, [selectedMaxExperience]);

  const maxSalRange = useMemo(() => {
    if (selectedMinSalary) {
      const targetIndex = minSalaries.findIndex(
        (sal) => sal.value === selectedMinSalary.value
      );
      if (targetIndex === 0 || targetIndex)
        return minSalaries.slice(targetIndex + 1);
      else return minSalaries;
    }
    return minSalaries;
  }, [minSalaries, selectedMinSalary]);

  const minSalRange = useMemo(() => {
    if (selectedMaxSalary) {
      const targetIndex = minSalaries.findIndex(
        (sal) => sal.value === selectedMaxSalary.value
      );
      if (targetIndex) return minSalaries.slice(0, targetIndex);
      else return minSalaries;
    }
    return minSalaries;
  }, [minSalaries, selectedMaxSalary]);

  const triggerInputfileHanlder = () => {
    if (inputRef.current) inputRef.current.click();
  };

  const navigateHandler = () => {
    if (loginSelector.who === "company") {
      navigate("/company-admin/jobs");
    } else if (loginSelector.who === "company_member") {
      navigate("/company-admin/jobs");
    }
  };

  const getJdDetailsHandler = async (response, onChange, toastId) => {
    const formData = new FormData();
    formData.append(
      "jd_file",
      `${constants.baseUrl}/${response[0].url}?t=${loginSelector.token}`
    );
    try {
      const res = await dispatch(getJdDetailsApi(formData)).then(unwrapResult);
      const jd = res.data;

      setIsLoading(false);
      toast.dismiss(toastId);
      toast.success("Jd Extracted Successfully");

      if (jd?.title) {
        setValue("jobTitle", jd.title);
      }

      if (jd?.qualification && jd.qualification.length > 0) {
        const updatedQualfications = defaultQualifications(jd.qualification);
        setValue("qualification", updatedQualfications);
      }

      // if (jd?.currency) {
      //   setValue("currency", { Id: jd.currency, Title: jd.currency });
      // }

      if (jd?.company) {
        setValue("companyName", jd.company);
      }

      if (jd?.skills && jd?.skills?.length > 0) {
        setValue("skill", jd?.skills);
      }

      if (jd?.hideCompany) {
        setValue("hideCompany", true);
      }
      if (jd?.hideSalary) {
        setValue("hideSalary", true);
      }

      if (jd?.description && jd?.description.length > 0) {
        const formattedString = jd?.description
          .map((item, index) => `\u2022  ${item}\n`)
          .join("");
        setValue("jobDescription", formattedString);
      }

      if (jd?.experience?.min) {
        const minExp = defaultMinExperience(jd?.experience?.min);
        if (minExp) {
          setValue("minExperience", minExp);
        }
      }
      if (jd?.experience.max) {
        const maxExp = defaultMinExperience(jd?.experience?.max);
        if (maxExp) {
          setValue("maxExperience", maxExp);
        }
      }

      if (jd.salary.min) {
        const defaultSal = defualtSalary(jd.salary.min);
        setValue("minSalary", defaultSal);
      }

      if (jd.salary.max) {
        const defaultSal = defualtSalary(jd.salary.max);
        setValue("maxSalary", defaultSal);
      }

      if (jd.industries && jd.industries.length > 0) {
        const uniqueArray = [...new Set(jd.industries)];
        const industries = defaultIndustries(uniqueArray);

        setValue("industry", industries);
      }

      if (jd.location && jd.location.length > 0) {
        const defaultLocationsJd = defaultLocations(jd.location);

        if (defaultLocationsJd.length > 0) {
          const uniqueArray = [...new Set(defaultLocationsJd)];
          setValue("location", uniqueArray);
        }
      }
      if (jd.jobType) {
        const jobType = defaultJobType(jd.jobType);
        setValue("jobType", jobType);
      }

      if (jd.workplaceType) {
        const workplaceType = defaultWorkPlaceType(jd.workplaceType);
        setValue("workplaceType", workplaceType);
      }

      onChange({
        name: response[0].orginalFileName,
        url: response[0].url,
        contentType: response[0].contentType,
        size: response[0].size,
      });
      setIsFileLoading(false);
    } catch (error) {
      setIsFileLoading(false);
      setIsLoading(false);
      toast.dismiss(toastId);
      toast.error("Job description extraction failed.");
    }
  };

  const onChangeInterestValue = (e) => {
    const fieldValue = e.target.value;
    setInterestValue(fieldValue);
  };

  const addInterestHandler = () => {
    setValue("skill", [...allSkillsValues, interestValue], {
      shouldDirty: true,
    });
    setInterestValue("");
  };

  const removeInterestHandler = (index) => {
    const newInterestArray = allSkillsValues?.filter((_, i) => i !== index);
    setValue("skill", newInterestArray, { shouldDirty: true });
  };

  const onSubmit = async (formData) => {
    setIsLoading(true);
    let createJobData;
    const industriesArray =
      formData.industry && formData.industry.length > 0
        ? formData.industry.map((ind) => ind.Id.toString())
        : [];

    const locationsArray =
      formData.location && formData.location.length > 0
        ? formData.location.map((loc) => loc.Id.toString())
        : [];

    const selectedFunctions =
      formData?.functions && formData?.functions.length > 0
        ? formData?.functions.map((func) => func.Id.toString())
        : [];

    if (loginSelector.who === constants.COMPANY.toLowerCase()) {
      createJobData = {
        authorId: loginSelector.userId,
        companyId: loginSelector.userId,
        authorType: constants.COMPANY,
        title: formData.jobTitle,
        company: formData.companyName,
        hideCompany: formData.hideCompany,
        qualification: formData.qualification[0].Title,
        qualifications: formData.qualification.map((qua) => qua.Id.toString()),
        jobType: formData.jobType.Id.toString(),
        workplaceType: formData.workplaceType.Id.toString(),
        experience: {
          min: formData.minExperience.value,
          max: formData.maxExperience.value,
        },
        currency: formData.currency.Title,
        salary: {
          min: formData.minSalary.value,
          minThousand: formData?.minSalaryInThousand?.Value,
          max: formData.maxSalary.value,
          maxThousand: formData?.maxSalaryInThousand?.Value,
        },
        hideSalary: formData.hideSalary,
        hiringFor: formData.hiringFor.toUpperCase(),
        description: formData.jobDescription,
        industries: industriesArray,
        // functions: [""],
        skills: formData.skill,
        location: locationsArray,
        jobStatus: formData.jobStatus,

        //addtional fields

        functions: selectedFunctions,
        // attachment: null,
        // createdBy: "",
        // noOfApplicants: 0,
        // oldId: "",
        country: ["India"],
        state: formData.state,
      };
    } else if (loginSelector.who === constants.COMPANY_MEMBER.toLowerCase()) {
      createJobData = {
        authorId: loginSelector.userId,
        companyId: memberSelector.companyId,
        authorType: constants.COMPANY_MEMBER,
        title: formData.jobTitle,
        company: formData.companyName,
        hideCompany: formData.hideCompany,
        qualification: formData.qualification[0].Title,
        qualifications: formData.qualification.map((qua) => qua.Title),
        jobType: formData.jobType.Id.toString(),
        workplaceType: formData.workplaceType.Id.toString(),
        experience: {
          min: formData.minExperience.value,
          max: formData.maxExperience.value,
        },
        currency: formData.currency.Title,
        salary: {
          min: formData.minSalary.value,
          minThousand: formData?.minSalaryInThousand?.Value,
          max: formData.maxSalary.value,
          maxThousand: formData?.maxSalaryInThousand?.Value,
        },
        hideSalary: formData.hideSalary,
        hiringFor: formData.hiringFor.toUpperCase(),
        description: formData.jobDescription,
        industries: industriesArray,
        // functions: [""],
        skills: formData.skill,
        location: locationsArray,
        jobStatus: formData.jobStatus,

        //addtional fields
        functions: selectedFunctions,
        attachment: null,
        createdBy: "",
        noOfApplicants: 0,
        oldId: "",
        country: ["India"],
        state: formData.state,
      };
    }

    if (jobId) {
      try {
        const response = await dispatch(
          updateJobApi({
            ...createJobData,
            jobId: jobId,
            createdAt: formData.createdAt,
          })
        ).then(unwrapResult);
        setIsLoading(false);
        toast.success("Updated successfully!");
        if (response) navigate(`/company-admin/job/${jobId}`);
      } catch (error) {
        setIsLoading(false);
        toast.error("Something went wrong");
      }
    } else {
      try {
        const response = await dispatch(createJobApi(createJobData)).then(
          unwrapResult
        );
        setIsLoading(false);
        toast.success("Job created successfully!");
        if (response) {
          if (loginSelector.who === constants.COMPANY_MEMBER.toLowerCase()) {
            try {
              await dispatch(
                asignJobApi({
                  jobId: response.data.data.id,
                  members: [
                    {
                      email: memberSelector.emailId,
                      memberId: loginSelector?.userId,
                    },
                  ],
                  assignedBy: loginSelector?.userId,
                  assignTo: constants.COMPANY_MEMBER.toLowerCase(),
                })
              ).then(unwrapResult);
              navigate(`/company-admin/jobs`);
            } catch (error) {
              toast.error("Job Assignment failed");
            }
          } else {
            navigate(`/company-admin/jobs`);
          }
        }
        // else if (loginSelector.who === constants.COMPANY.toLowerCase()) {
        //   navigate("/company-admin/jobs");
        // }
      } catch (error) {
        setIsLoading(false);
        toast.error("Something went wrong");
      }
    }
  };

  return (
    <div className="flex items-start justify-center gap-4 p-4 bg-exwhite-100 ">
      <div
        className="w-fit rotate-180 self-start mt-1 cursor-pointer"
        onClick={navigateHandler}
      >
        <RightArrowIcon color="#696969" />
      </div>
      <div className="flex-1 space-y-2">
        <h1 className="text-xl text-expurple-600 font-semibold text-start px-2">
          Create job
        </h1>

        <form onSubmit={handleSubmit(onSubmit)} className="space-y-2 px-2">
          {!jobId && (
            <div className="w-full">
              <Controller
                name="file"
                control={control}
                rules={{
                  required: {
                    value: false,
                    message: "Required",
                  },
                }}
                render={({ field: { onChange, value } }) => (
                  <div>
                    <div className="hidden">
                      <input
                        // title="Add Resume / CV"
                        placeholder="Browse file"
                        name="file"
                        id="file"
                        type="file"
                        ref={inputRef}
                        accept=".pdf, .docx"
                        onChange={(e) => {
                          setIsLoading(true);
                          const toastId = toast.loading("Uploading...");
                          uploadContent.uploadJd(
                            loginSelector.userId,
                            e.target.files,
                            (response) => {
                              if (!response[0].error) {
                                getJdDetailsHandler(
                                  response,
                                  onChange,
                                  toastId
                                );
                              } else {
                                setIsLoading(false);
                                toast.dismiss(toastId);
                                toast.success(
                                  "Job description extraction failed."
                                );
                              }
                            }
                          );
                        }}
                        // value={value}
                      />
                    </div>
                    <div className="w-full border-2 border-dashed border-secondary-400 bg-secondary-100 rounded-lg flex items-center justify-center py-8">
                      <div className="space-y-2">
                        <div
                          className="p-3 rounded-full bg-secondary-700 cursor-pointer w-fit mx-auto active:scale-95"
                          onClick={triggerInputfileHanlder}
                        >
                          <UploadIcon height="32" width="32" color="white" />
                        </div>
                        <p className="text-base text-secondary-400 font-semibold text-center">
                          Please upload your Job Description as a PDF or DOCX
                          file
                        </p>
                      </div>
                      {/* <p className="text-expurple-700 text-base line-clamp-1">
                        {uploadedFile?.name
                          ? uploadedFile?.name
                          : "Browse file. Only pdf"}
                      </p> */}
                      {/* {isFileLoading && (
                        <div>
                          <LoadingIcon />
                        </div>
                      )} */}
                    </div>
                    {errors?.file?.message && (
                      <p className="text-red-400">{errors?.file?.message}</p>
                    )}
                  </div>
                )}
              />
            </div>
          )}

          {!jobId && (
            <div className="flex items-center justify-center">
              <div className="flex-1 border-2 border-exgray-100/50 rounded-full"></div>
              <h1 className="text-lg text-exgray-200 px-2 w-fit mx-auto">Or</h1>
              <div className="flex-1 border-2 border-exgray-100/50 rounded-full"></div>
            </div>
          )}

          <div>
            <Controller
              name="jobTitle"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "Job title is required",
                },
              }}
              render={({ field: { onChange, value } }) => (
                <ExInput
                  placeholder="Ex. React Js Developer"
                  title="Job Title"
                  name="jobTitle"
                  isMandatory={true}
                  value={value || ""}
                  onChange={onChange}
                  borderWidth="border-2"
                  borderColor="border-exgray-100"
                  error={errors?.jobTitle?.message}
                />
              )}
            />
          </div>

          <div className="space-y-1">
            <Controller
              name="companyName"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "Company name is required",
                },
              }}
              render={({ field: { onChange, value } }) => (
                <ExInput
                  placeholder="Enter your company name"
                  title="Company Name"
                  name="companyName"
                  isMandatory={true}
                  value={value || ""}
                  onChange={onChange}
                  borderWidth="border-2"
                  borderColor="border-exgray-100"
                  error={errors?.companyName?.message}
                />
              )}
            />

            <Controller
              name="hideCompany"
              control={control}
              render={({ field: { onChange, value } }) => (
                <ExCheckBox
                  title="You can choose to hide the company name from candidates."
                  name="hideCompany"
                  labelStyle="text-exgray-200/70 text-base font-semibold italic"
                  onChange={onChange}
                  value={value}
                />
              )}
            />
          </div>

          <h1 className="text-xl text-expurple-600 font-semibold text-start">
            Advance Information
          </h1>

          <div className="grid grid-cols-3 auto-rows-auto gap-2">
            <div>
              <Controller
                name="jobType"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: "Job type is required",
                  },
                }}
                render={({ field: { onChange, value } }) => (
                  <ExDropdown
                    title="Job Type"
                    onChange={onChange}
                    placeholder="Select"
                    value={value || null}
                    optionValue="Id"
                    optionLabel="Title"
                    options={jobTypesArray}
                    isMandatory={true}
                    name="jobType"
                    dropDownHeight="40px"
                    dropDownBorderWidth="2px"
                    dropDownBorderColor="#CDCED2"
                    error={errors?.jobType?.message}
                  />
                )}
              />
            </div>

            <div>
              <Controller
                name="workplaceType"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: "Work place type is required",
                  },
                }}
                render={({ field: { onChange, value } }) => (
                  <ExDropdown
                    title="Work Place Type"
                    onChange={onChange}
                    placeholder="Select"
                    value={value || null}
                    optionValue="Id"
                    optionLabel="Title"
                    isMandatory={true}
                    options={workplaceTypesArray}
                    dropDownHeight="40px"
                    dropDownBorderWidth="2px"
                    dropDownBorderColor="#CDCED2"
                    error={errors?.workplaceType?.message}
                  />
                )}
              />
            </div>

            <div>
              <Controller
                name="location"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: "Location is required",
                  },
                }}
                render={({ field: { onChange, value } }) => (
                  <ExMultiDropdown
                    title="Location"
                    onChange={onChange}
                    placeholder="Select"
                    value={value || null}
                    optionValue="Id"
                    optionLabel="Name"
                    options={locationsArray}
                    closeMenuOnSelect={false}
                    isMandatory={true}
                    dropDownHeight="auto"
                    dropDownBorderWidth="2px"
                    dropDownBorderColor="#CDCED2"
                    menuListHeight="250px"
                    error={errors?.location?.message}
                  />
                )}
              />
            </div>

            <div>
              <Controller
                name="minExperience"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: "Experience is required",
                  },
                }}
                render={({ field: { onChange, value } }) => (
                  <ExDropdown
                    title="Minimum Experience"
                    onChange={onChange}
                    placeholder="Select"
                    value={value || null}
                    optionValue="Id"
                    isMandatory={true}
                    optionLabel="Title"
                    options={minExpRange}
                    dropDownHeight="40px"
                    dropDownBorderWidth="2px"
                    dropDownBorderColor="#CDCED2"
                    error={errors?.minExperience?.message}
                  />
                )}
              />
            </div>

            <div>
              <Controller
                name="maxExperience"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: "Experience is required",
                  },
                }}
                render={({ field: { onChange, value } }) => (
                  <ExDropdown
                    title="Maximum Experience"
                    onChange={onChange}
                    placeholder="Select"
                    value={value || null}
                    optionValue="Id"
                    optionLabel="Title"
                    options={maxExpRange}
                    isMandatory={true}
                    dropDownHeight="40px"
                    dropDownBorderWidth="2px"
                    dropDownBorderColor="#CDCED2"
                    error={errors?.maxExperience?.message}
                  />
                )}
              />
            </div>

            <div>
              <Controller
                name="industry"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: "Industry is required",
                  },
                }}
                render={({ field: { onChange, value } }) => (
                  <ExMultiDropdown
                    title="Industry"
                    onChange={onChange}
                    placeholder="Select"
                    value={value || null}
                    optionValue="Id"
                    optionLabel="Name"
                    options={industriesArray}
                    closeMenuOnSelect={false}
                    isMandatory={true}
                    dropDownHeight="auto"
                    dropDownBorderWidth="2px"
                    dropDownBorderColor="#CDCED2"
                    menuListHeight="250px"
                    error={errors?.industry?.message}
                  />
                )}
              />
            </div>

            <div className="col-span-2">
              <Controller
                name="functions"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: "Industry is required",
                  },
                }}
                render={({ field: { onChange, value } }) => (
                  <ExMultiDropdown
                    title="Functions"
                    onChange={onChange}
                    placeholder="Select"
                    value={value || null}
                    optionValue="Id"
                    optionLabel="Name"
                    options={functionsArray}
                    closeMenuOnSelect={false}
                    dropDownHeight="auto"
                    isMandatory={true}
                    dropDownBorderWidth="2px"
                    dropDownBorderColor="#CDCED2"
                    menuListHeight="250px"
                    error={errors?.functions?.message}
                  />
                )}
              />
            </div>

            <div className="">
              <Controller
                name="hiringFor"
                control={control}
                rules={{
                  required: {
                    value: false,
                    message: "Hiring is required",
                  },
                }}
                render={({ field: { onChange, value } }) => (
                  <ExRadioBtnGroup
                    title="Hiring For"
                    onChange={onChange}
                    selectedOption={value}
                    options={["Company", "Client"]}
                    error={errors?.hiringFor?.message}
                  />
                )}
              />
            </div>
          </div>

          <div>
            <Controller
              name="qualification"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "Qualification is required",
                },
              }}
              render={({ field: { onChange, value } }) => (
                <ExMultiDropdown
                  title="Qualification"
                  onChange={onChange}
                  placeholder="Select"
                  value={value || null}
                  optionValue="Id"
                  optionLabel="Title"
                  options={qualificationsArray}
                  closeMenuOnSelect={false}
                  dropDownHeight="auto"
                  isMandatory={true}
                  dropDownBorderWidth="2px"
                  dropDownBorderColor="#CDCED2"
                  menuListHeight="250px"
                  error={errors?.qualification?.message}
                />
              )}
            />
          </div>

          {/* <div>
            <Controller
              name="skill"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "Skill is required",
                },
              }}
              render={({ field: { onChange, value } }) => (
                <ExInput
                  placeholder="Ex. React Js"
                  title="Skill"
                  name="skill"
                  value={value || ""}
                  onChange={onChange}
                  error={errors?.skill?.message}
                />
              )}
            />
          </div> */}

          <div>
            <Controller
              name="skill"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "Skill is required",
                },
              }}
              render={({ field: { onChange, value } }) => (
                <div className="space-y-1">
                  <label
                    htmlFor={"skill"}
                    className={`text-exgray-200/70 text-base font-semibold`}
                  >
                    Skills *
                  </label>
                  <div className="relative">
                    <input
                      type="text"
                      className={`w-full h-10 px-3 bg-exwhite-100 rounded-md outline-none border-2 ${
                        errors?.skill?.message && allSkillsValues.length === 0
                          ? "border-[#dc2623]"
                          : "border-exgray-100"
                      } text-exgray-800 placeholder:text-gray-400 text-base`}
                      id="skill"
                      placeholder="Add Skill"
                      autoComplete="off"
                      name="skill"
                      onKeyDown={(e) => {
                        if (e.key === "Enter" && interestValue) {
                          e.preventDefault();
                          addInterestHandler();
                        }
                      }}
                      onChange={onChangeInterestValue}
                      value={interestValue}
                    />
                    <div className="absolute h-full top-0 flex items-center justify-center right-2">
                      <button
                        type="button"
                        disabled={!interestValue}
                        onClick={addInterestHandler}
                        className="py-1 w-fit px-4  text-exgray-800 font-semibold underline text-sm rounded-sm"
                      >
                        Add
                      </button>
                    </div>
                  </div>
                  {errors?.skill?.message && allSkillsValues.length === 0 && (
                    <p className="text-red-400">{errors?.skill?.message}</p>
                  )}

                  {allSkillsValues && allSkillsValues.length > 0 && (
                    <div className="flex flex-wrap gap-2 py-1">
                      {allSkillsValues?.map((skill, index) => (
                        <div
                          className="px-3 py-1 bg-secondary-400 text-exwhite-100 w-fit rounded-md text-sm flex items-center justify-center gap-4"
                          key={uuidv4()}
                        >
                          <h1>{skill}</h1>
                          <div
                            className="cursor-pointer"
                            onClick={() => removeInterestHandler(index)}
                          >
                            <CrossIcon width="10" height="10" />
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              )}
            />
          </div>

          <h1 className="text-xl text-expurple-600 font-semibold text-start">
            Salary
          </h1>

          <div className="grid grid-cols-7 auto-rows-auto gap-2">
            <div>
              <Controller
                name="currency"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: "Currency is required",
                  },
                }}
                render={({ field: { onChange, value } }) => (
                  <ExDropdown
                    title="Currency"
                    onChange={onChange}
                    placeholder="Select"
                    value={value || null}
                    optionValue="Title"
                    optionLabel="Title"
                    isMandatory={true}
                    options={currenciesArray}
                    dropDownHeight="40px"
                    dropDownBorderWidth="2px"
                    dropDownBorderColor="#CDCED2"
                    error={errors?.currency?.message}
                  />
                )}
              />
            </div>

            <div
              className={`flex items-center justify-end  ${
                errors?.maxSalary?.message || errors?.minSalary?.message
                  ? "pb-5"
                  : "pt-5"
              }`}
            >
              <h1 className="text-exgray-200 text-base font-semibold">
                Minimum Salary
              </h1>
            </div>

            <div className="">
              <Controller
                name="minSalary"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: "Minimum salary is required",
                  },
                }}
                render={({ field: { onChange, value } }) => (
                  <ExDropdown
                    title="Lacs"
                    onChange={onChange}
                    placeholder="Select"
                    value={value || null}
                    optionValue="value"
                    optionLabel="Title"
                    options={minSalRange}
                    isMandatory={true}
                    dropDownHeight="40px"
                    dropDownBorderWidth="2px"
                    dropDownBorderColor="#CDCED2"
                    error={errors?.minSalary?.message}
                  />
                )}
              />
            </div>

            <div>
              <Controller
                name="minSalaryInThousand"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: "Minimum salary is required",
                  },
                }}
                render={({ field: { onChange, value } }) => (
                  <ExDropdown
                    title="Thousand"
                    onChange={onChange}
                    placeholder="Select"
                    value={value || null}
                    optionValue="Value"
                    optionLabel="Title"
                    options={salaryInThousands}
                    isMandatory={true}
                    dropDownHeight="40px"
                    dropDownBorderWidth="2px"
                    dropDownBorderColor="#CDCED2"
                    error={errors?.minSalaryInThousand?.message}
                  />
                )}
              />
            </div>

            <div
              className={`flex items-center justify-end  ${
                errors?.maxSalary?.message || errors?.minSalary?.message
                  ? "pb-5"
                  : "pt-5"
              }`}
            >
              <h1 className="text-exgray-200 text-base font-semibold">
                Maximum Salary
              </h1>
            </div>

            <div className="">
              <Controller
                name="maxSalary"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: "Required",
                  },
                }}
                render={({ field: { onChange, value } }) => (
                  <ExDropdown
                    title="Lacs"
                    onChange={onChange}
                    placeholder="Select"
                    value={value || null}
                    optionValue="value"
                    optionLabel="Title"
                    options={maxSalRange}
                    isMandatory={true}
                    dropDownHeight="40px"
                    dropDownBorderWidth="2px"
                    dropDownBorderColor="#CDCED2"
                    error={errors?.maxSalary?.message}
                  />
                )}
              />
            </div>

            <div>
              <Controller
                name="maxSalaryInThousand"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: "Required",
                  },
                }}
                render={({ field: { onChange, value } }) => (
                  <ExDropdown
                    title="Thousand"
                    onChange={onChange}
                    placeholder="Select"
                    value={value || null}
                    optionValue="Value"
                    optionLabel="Title"
                    options={salaryInThousands}
                    isMandatory={true}
                    dropDownHeight="40px"
                    dropDownBorderWidth="2px"
                    dropDownBorderColor="#CDCED2"
                    error={errors?.maxSalaryInThousand?.message}
                  />
                )}
              />
            </div>
          </div>

          <div>
            <Controller
              name="hideSalary"
              control={control}
              render={({ field: { onChange, value } }) => (
                <ExCheckBox
                  title="You can choose to hide salary range from candidates."
                  name="hideSalary"
                  labelStyle="text-expurple-800 text-base italic"
                  onChange={onChange}
                  value={value}
                />
              )}
            />
          </div>

          <h1 className="text-xl text-expurple-600 font-semibold text-start">
            Description & Responsibility
          </h1>

          <div>
            <Controller
              name="jobDescription"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "Job Description is required",
                },
                maxLength: {
                  value: 6000,
                  message: "Only 6000 characters are allowed",
                },
              }}
              render={({ field: { onChange, value } }) => (
                <ExTextarea
                  rows={10}
                  name="jobDescription"
                  value={value || ""}
                  title="Job Description"
                  maxLength={6000}
                  onChange={onChange}
                  isMandatory={true}
                  placeholder="Enter here"
                  borderWidth="border-2"
                  borderColor="border-exgray-100"
                  error={errors?.jobDescription?.message}
                />
              )}
            />
          </div>

          <div className="">
            <div className="flex justify-start items-center">
              {jobId ? (
                <button
                  disabled={!isDirty || isLoading}
                  type="submit"
                  className="px-8 py-2 rounded-md bg-secondary-400 text-base text-exwhite-100 text-center font-semibold"
                >
                  Save
                </button>
              ) : (
                <button
                  type="submit"
                  disabled={isLoading}
                  className={`flex items-center justify-center gap-1 pl-7 pr-5 py-3 rounded-md ${
                    isLoading ? "bg-exgray-200" : "bg-secondary-400"
                  } text-base text-exwhite-100 text-center font-semibold`}
                >
                  Post Job <RightArrowIcon />
                </button>
              )}
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateJob;
