import moment from "moment";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../config/hooks";
import {
  getAllAttendanceApi,
  getDailyReportApi,
  getTimesheetApi,
} from "../../../slices/attendanceSlice";
import EmployeeCommenView from "../components/EmployeeCommenView";
import ReactQuill from "react-quill";
import { getMembersApi } from "../../../slices/companyMemberSlice";
import * as constants from "../../../constant";
import RegularizationIcon from "../../../assets/Icons/RegularizationIcon";
import LeaveRequestIcon from "../../../assets/Icons/LeaveRequestIcon";
import { getMemberDetailsByIdApi } from "../../../slices/companySlice";
import { getLeaveBalanceApi } from "../../../slices/leaveSlice";

const content =
  "\u003col\u003e\u003cli\u003esdfgddgdhh\u003c/li\u003e\u003cli\u003efjghjgjgjkh\u003c/li\u003e\u003cli\u003ehjkhkhkhl\u003c/li\u003e\u003cli\u003ekjljkljkljkl\u003c/li\u003e\u003cli\u003ejljkljkljlj\u003c/li\u003e\u003cli\u003elkjljljkljkljk\u003c/li\u003e\u003cli\u003eljkljljlkjljkl\u003c/li\u003e\u003cli\u003ejkljljljljl\u003c/li\u003e\u003cli\u003eljkljljkljl\u003c/li\u003e\u003cli\u003ejljljljlj\u003c/li\u003e\u003cli\u003eljljlj\u003c/li\u003e\u003c/ol\u003e";

const EmplpoyeeDashboard = () => {
  const navigate = useNavigate();

  let month = moment().month();
  let year = moment().year();
  const [togglePopup, setTogglePopup] = useState(false);
  const dispatch = useAppDispatch();
  const [date, setDate] = useState(new Date());
  const [startDateSelected, setStartDateSelected] = useState(null);
  const userId = useAppSelector((state) => state?.login?.session?.userId);
  const loginSelector = useAppSelector((state) => state?.login?.session);
  const memberSelector = useAppSelector((state) => state.member);
  const timesheet = useAppSelector((state) => state.attendance.timesheet);
  const memberDetail = useAppSelector((state) => state.company.memberDetails);
  const companyDetails = useAppSelector((state) => state.company.companyDetails);
  const totalLeaveBalance = useAppSelector((state) => state.leave.totalLeaveBalance);

  useEffect(() => {
    let month =
      moment().month() > 8 ? moment().month() + 1 : 0 + moment().month() + 1;
    let year = moment().year();

    dispatch(
      getAllAttendanceApi({
        dateFrom: moment(`01/${month}/${year}`).format("YYYY-MM-DD"),
        dateTo: moment().format("YYYY-MM-DD"),
        employeeId: userId,
      })
    );
    // let month = moment().month() > 8 ? moment().month() + 1 : 0 + moment().month() + 1;
    // let year = moment().year();
    dispatch(
      getTimesheetApi({
        // dateFrom: moment(`${month}/01/${year}`).format('YYYY-MM-DD'),
        // dateTo: moment().format('YYYY-MM-DD'),
        employeeId: userId,
      })
    );

    dispatch(
      getLeaveBalanceApi({
        employeeId: userId,
        companyId: memberDetail?.companyId
      })
    );
  }, []);

  useEffect(() => {
    var timer = null;
    if (!startDateSelected) {
      timer = setInterval(() => setDate(new Date()), 1000);
    }
    return function cleanup() {
      clearInterval(timer);
    };
  });


  useEffect(() => {
    dispatch(
      getMembersApi({
        companyId:
          loginSelector.who === constants.COMPANY.toLowerCase()
            ? userId
            : memberSelector?.memberDetails?.companyId,
      })
    );
  }, []);

  // useEffect(() => {
  //   dispatch(getMemberDetailsByIdApi())
  // }, [])

  const togglePopupHandler = () => {
    setTogglePopup(!togglePopup);
  };

  const navigateHandler = (path) => {
    navigate(path);
  };

  const dailyReport = useAppSelector((state) => state.attendance.dailyReport);

  return (
    <>
      <EmployeeCommenView />
      <div className="flex space-x-2 mt-3">
        {/* <div className="w-3/12">
          <div className="bg-primary-200 px-3 py-4  rounded-lg border border-secondary-200 shadow shadow-2">
            <h1 className="text-sm text-exblue-700 font-medium">Performance Report</h1>
            <div className="px-3">
              <h1 className="flex text-sm my-4 items-center">
                <h1 className="text-secondary-500 text-lg font-medium mr-3">4</h1>
                Daily Target
              </h1>
              <h1 className="flex text-sm my-4 items-center">
                <h1 className="text-secondary-500 text-lg font-medium mr-3">4</h1>
                Achieved Today
              </h1>
              <h1 className="flex text-sm my-4 items-center">
                <h1 className="text-secondary-500 text-lg font-medium mr-3">4</h1>
                Deficit
              </h1>
            </div>
          </div>
          <div className="bg-primary-200 px-3 py-4  rounded-lg mt-3 border border-secondary-200 shadow shadow-2">
            <h1 className="text-sm text-exblue-700 font-medium">Actions</h1>
            <div className="px-3">
              <h1 className="flex text-sm my-4 items-center">
                <h1 className="text-secondary-500 text-lg font-medium mr-3">4</h1>
                Daily Target
              </h1>
              <h1 className="flex text-sm my-4 items-center">
                <h1 className="text-secondary-500 text-lg font-medium mr-3">4</h1>
                Achieved Today
              </h1>
              <h1 className="flex text-sm my-4 items-center">
                <h1 className="text-secondary-500 text-lg font-medium mr-3">4</h1>
                Deficit
              </h1>
            </div>
          </div>
        </div> */}
        <div className="w-10/12">
          <div className="px-3 py-4 bg-exwhite-100 rounded-lg border border-secondary-200 shadow shadow-2">
            <div className="flex items-center justify-between mb-2">
              <h1 className="font-medium">Daily Report</h1>
              <Link
                to={"/employee-dashboard/employee-timesheet"}
                className="text-secondary-500 text-sm font-semibold"
              >
                <h1>View All</h1>
              </Link>
            </div>
            {timesheet?.map((report, index) => {
              if (
                index < 3 &&
                moment() !== report.date &&
                report?.dailyReport?.dailyReport
              ) {
                return (
                  <div
                    className={`${index < 2 ? "border-b" : ""
                      } border-exgray-100 pt-1`}
                  >
                    <h1 className="text-secondary-500 text-sm font-medium">
                      {moment(report.date).format("MMMM Do YYYY")}
                    </h1>
                    <ReactQuill
                      style={{ marginBottom: -35 }}
                      value={report?.dailyReport?.dailyReport[0]}
                      readOnly={true}
                      theme={"bubble"}
                    />
                    {/* <div
                        dangerouslySetInnerHTML={{
                          __html: report.dailyReport[0] // This will render the HTML content from Quill
                        }}
                      /> */}

                    {/* <h1 className="text-sm font-medium">
                        dangerouslySetInnerHTML={{
                          __html: report.dailyReport, // This will render the HTML content from Quill
                        }}
                      </h1> */}
                  </div>
                );
              }
            })}


            {/* <div className="border-b border-exgray-100 pb-2 pt-2">
              <h1 className="text-secondary-500 text-sm font-medium">15  Dec 2024</h1>
              <h1 className="text-sm font-medium">Pending Interviews for Job ID ...</h1>
              <h1 className="text-xs">3 candidates have interviews scheduled for tomorrow. Ensure feedback forms are ready...</h1>
            </div>
            <div className="">
              <h1 className="text-secondary-500 text-sm font-medium pt-2">15  Dec 2024</h1>
              <h1 className="text-sm font-medium">Pending Interviews for Job ID ...</h1>
              <h1 className="text-xs">3 candidates have interviews scheduled for tomorrow. Ensure feedback forms are ready...</h1>
            </div> */}
          </div>



          {/* <div className="bg-primary-200 px-3 py-4 rounded-lg mt-3 border border-secondary-200 shadow shadow-2">
            <div className="flex items-center justify-between">
              <h1 className="font-medium">To Do List</h1>
              <div className="text-secondary-500 text-sm font-semibold">
                <h1>Add To Do</h1>
              </div>
            </div>
            <div className="border-b border-exgray-100 pb-2">
              <h1 className="text-secondary-500 text-sm font-medium">15  Dec 2024</h1>
              <h1 className="text-sm font-medium">Pending Interviews for Job ID ...</h1>
              <h1 className="text-xs">3 candidates have interviews scheduled for tomorrow. Ensure feedback forms are ready...</h1>
            </div>
            <div className="border-b border-exgray-100 pb-2 pt-2">
              <h1 className="text-secondary-500 text-sm font-medium">15  Dec 2024</h1>
              <h1 className="text-sm font-medium">Pending Interviews for Job ID ...</h1>
              <h1 className="text-xs">3 candidates have interviews scheduled for tomorrow. Ensure feedback forms are ready...</h1>
            </div>
            <div className="">
              <h1 className="text-secondary-500 text-sm font-medium pt-2">15  Dec 2024</h1>
              <h1 className="text-sm font-medium">Pending Interviews for Job ID ...</h1>
              <h1 className="text-xs">3 candidates have interviews scheduled for tomorrow. Ensure feedback forms are ready...</h1>
            </div>
          </div> */}
        </div>
        <div className="w-3/12">
          {memberDetail?.teamMembers && (
            <div className="px-3 py-4 bg-exwhite-100 rounded-lg border border-secondary-200 shadow shadow-2">
              <h1 className="text-base font-medium mb-3">Request List</h1>
              <div className="flex flex-wrap">
                <div className="mr-2">
                  <Link to={"/employee-dashboard/pending-aprovals/regularization-request"}>
                    <RegularizationIcon />
                  </Link>
                </div>
                <div>
                  <Link to={"/employee-dashboard/pending-aprovals/leave-request-list"}>
                    <LeaveRequestIcon />
                  </Link>
                </div>
              </div>
            </div>
          )}

          {/* <div className="mt-3 flex bg-exwhite-100 py-3 px-7 rounded-md w-full items-center border border-secondary-200 shadow shadow-2">
            <h1 className="text-3xl text-secondary-500 font-medium w-4/12">{totalLeaveBalance}</h1>
            <div className="w-8/12">
              <h1 className="text-sm text-exblue-600 font-medium">
                Leave Balance
              </h1>
              <h1 className="text-xs">
                Till this month
              </h1>
            </div>
          </div> */}

          {/* <div className="bg-primary-200 px-3 py-4 rounded-lg border border-secondary-200 shadow shadow-2">
            <h1 className="text-sm text-exblue-700 font-medium">Notification</h1>
            <div className="flex items-center mt-3">
              <div className="text-center w-2/12 mr-2">
                <h1 className="text-lg text-secondary-500 font-medium">
                  15
                </h1>
                <h1 style={{ fontSize: 8 }}>Dec 2024</h1>
              </div>
              <div className="w-10/12">
                <h1 className="text-xs font-medium">Pending Interviews for Job ID ...</h1>
                <h1 style={{ fontSize: 8 }}>3 candidates have interviews scheduled for tomorrow. Ensure feedback forms are ready...</h1>
              </div>
            </div>
            <div className="flex items-center mt-3">
              <div className="text-center w-2/12 mr-2">
                <h1 className="text-lg text-secondary-500 font-medium">
                  15
                </h1>
                <h1 style={{ fontSize: 8 }}>Dec 2024</h1>
              </div>
              <div className="w-10/12">
                <h1 className="text-xs font-medium">Pending Interviews for Job ID ...</h1>
                <h1 style={{ fontSize: 8 }}>3 candidates have interviews scheduled for tomorrow. Ensure feedback forms are ready...</h1>
              </div>
            </div>
            <div className="flex items-center mt-3">
              <div className="text-center w-2/12 mr-2">
                <h1 className="text-lg text-secondary-500 font-medium">
                  15
                </h1>
                <h1 style={{ fontSize: 8 }}>Dec 2024</h1>
              </div>
              <div className="w-10/12">
                <h1 className="text-xs font-medium">Pending Interviews for Job ID ...</h1>
                <h1 style={{ fontSize: 8 }}>3 candidates have interviews scheduled for tomorrow. Ensure feedback forms are ready...</h1>
              </div>
            </div>
            <div className="flex items-center mt-3">
              <div className="text-center w-2/12 mr-2">
                <h1 className="text-lg text-secondary-500 font-medium">
                  15
                </h1>
                <h1 style={{ fontSize: 8 }}>Dec 2024</h1>
              </div>
              <div className="w-10/12">
                <h1 className="text-xs font-medium">Pending Interviews for Job ID ...</h1>
                <h1 style={{ fontSize: 8 }}>3 candidates have interviews scheduled for tomorrow. Ensure feedback forms are ready...</h1>
              </div>
            </div>
          </div> */}
        </div>
      </div>

    </>
  );
};

export default EmplpoyeeDashboard;
