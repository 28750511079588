import moment from "moment"; // Import moment.js

export function formatDate(inputDate) {
  const date = moment(inputDate); // Create a moment object from the input date
  const today = moment().startOf("day"); // Start of today (midnight)

  // If the date is today
  if (date.isSame(today, "day")) {
    return date.format("HH:mm"); // Show time in HH:mm format
  }
  // If the date is yesterday
  else if (date.isSame(today.subtract(1, "days"), "day")) {
    return "Yesterday";
  }
  // For any other day
  else {
    return date.fromNow(); // Show relative time like "X days ago"
  }
}

// Example usage
const inputDate = "2024-12-13T10:20:05.842Z";
