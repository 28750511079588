import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../config/hooks";
import { useEffect, useState } from "react";
import { getBulkCandidateDetailApi } from "../../slices/candidateProfileSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { toast } from "sonner";
import LoadingIcon from "../../assets/Icons/LoadingIcon";
import NoDataFoundIcon from "../../assets/Icons/NoDataFoundIcon";
import Pdf from "../pdf-viewer/Pdf";
import DownloadIcon from "../../assets/Icons/DownloadIcon";
import axios from "axios";
import * as constants from "../../constant";

const UserResume = () => {
  const dispatch = useAppDispatch();

  const { userId } = useParams();

  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const token = useAppSelector((state) => state?.login?.session?.token);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await dispatch(
          getBulkCandidateDetailApi({ userId })
        ).then(unwrapResult);
        setUser(response.data.data);
        setLoading(false);
      } catch (error) {
        if (error?.error?.code === 404) toast.error("User Not Found");
        else toast.error("Something went wrong");
        setLoading(false);
        setError(true);
      }
    };

    fetchData();
  }, [dispatch, userId]);

  const handleDownload = async () => {
    if (user?.userAdditionalInfo?.resume?.url) {
      axios
        .get(
          `${constants.baseUrl}/${user?.userAdditionalInfo?.resume?.url}?t=${token}`,
          {
            headers: {
              "Content-Type": "application/octet-stream",
            },
            responseType: "blob",
          }
        )
        .then((response) => {
          const a = document.createElement("a");
          const url = window.URL.createObjectURL(response.data);
          a.href = url;
          a.download = user?.userAdditionalInfo?.resume?.name;
          a.click();
        })
        .catch((err) => {
          toast.error("Unable to download resume");
        });
    } else {
      toast("Resume not found");
    }
  };

  if (error) {
    return (
      <div className="w-full">
        <div className="mx-auto w-fit py-5">
          <div className="flex items-center justify-center">
            <NoDataFoundIcon />
          </div>
          <div>
            <h1 className="text-3xl text-expurple-800/50 font-semibold text-center">
              No Data Found
            </h1>
            <p className="text-base text-exgray-200 font-medium text-center max-w-sm mx-auto">
              Could not find any data. Please try again.
            </p>
          </div>
        </div>
      </div>
    );
  }

  if (loading) {
    return (
      <div className="py-20 flex items-center justify-center">
        <LoadingIcon />
      </div>
    );
  }

  return (
    <div className="">
      <div className="flex items-center justify-between mb-2">
        <button
          className="w-fit ml-auto p-1 border-2 border-secondary-400 mr-4 rounded-md active:scale-95"
          onClick={handleDownload}
        >
          <DownloadIcon />
        </button>
      </div>

      <div className="w-fit mx-auto h-screen overflow-y-auto no-scrollbar">
        <Pdf
          src={`${constants.baseUrl}/${user?.userAdditionalInfo?.resume?.url}?t=${token}`}
        />
      </div>
    </div>
  );
};

export default UserResume;
