import moment from "moment";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import 'react-quill/dist/quill.snow.css';
import CrossIcon from "../../../assets/Icons/CrossIcon";
import CustomDatePicker from "../../../components/basic-components/CustomDatePicker";
import ExDropdown from "../../../components/basic-components/ExDropdown";
import ExRadioBtnGroup from "../../../components/basic-components/ExRadioBtnGroup";
import { useAppDispatch, useAppSelector } from "../../../config/hooks";
import { applyLeaveApi } from "../../../slices/leaveSlice";

export interface LeaveRequeastProps {
  onClose: Function;
}

const leavedaysType = [
  {
    id: 1,
    Title: "First Half"
  },

  {
    id: 2,
    Title: "Second Half"
  }
]

const halfDayType = [
  {
    Id: 1,
    Title: "First Half"
  },

  {
    Id: 2,
    Title: "Second Half"
  }
]

const LeaveRequestForm = ({ onClose }: LeaveRequeastProps) => {
  const dispatch = useAppDispatch();
  const form = useForm({
    defaultValues: {
      fromDate: null,
      toDate: null,
      reason: "",
      leavType: "",
      startDateType: "",
      endDateType: "",
      startDateHalfType: "",
      endDateHalfType: ""
    },
  });
  const { handleSubmit, control, formState, setValue } = form;
  const { errors } = formState;
  const [fromDateValue, setFromDateValue] = useState(null);
  const [toDate, setToDate] = useState(null);
  const memberDetail = useAppSelector((state) => state.company.memberDetails);
  const leaveRequestList = useAppSelector((state) => state.leave.leaveRequestList);
  const leaveTypes = useAppSelector((state) => state.appConfig.leaveTypes)
  const [selectedOption, setSelectedOption] = useState("Full Day");

  const [firstDateLeaveDay, setFirstDateLeaveDay] = useState('');
  const [secondDateLeaveDay, setSecondDateLeaveDay] = useState('');


  const onSubmit = (formData) => {
    let formdate = moment(formData.fromDate);
    let todate = moment(formData.toDate);
    var diff = todate.diff(formdate, 'days');

    if (selectedOption == "Half Day") {
      diff -= 0.5;
    }

    dispatch(applyLeaveApi({
      employeeId: memberDetail.id,
      companyId: memberDetail.companyId,
      leaveType: formData.leavType.Title,
      startDate: new Date(formData.fromDate),
      endDate: new Date(formData.toDate),
      reason: formData.reason,
      status: "Pending",
      approverId: "",
      totalDays: diff + 1
    }))
    onClose();
  }

  const handleFromDateChange = (date) => {
    let checkAtt = leaveRequestList?.filter((leave) => moment(leave.startDate).format("DD/MM/YYYY") == moment(date).format("DD/MM/YYYY") || moment(leave.endDate).format("DD/MM/YYYY") == moment(date).format("DD/MM/YYYY"));
    if (checkAtt?.length > 0) {
      alert("This date's leave request already exists.")
    }
    else {
      setFromDateValue(new Date(date));
      setValue("fromDate", new Date(date));
      setValue("toDate", null);
    }
  }

  const handleToDateChange = (date) => {
    let checkAtt = leaveRequestList?.filter((leave) => moment(leave.startDate).format("DD/MM/YYYY") == moment(date).format("DD/MM/YYYY") || moment(leave.endDate).format("DD/MM/YYYY") == moment(date).format("DD/MM/YYYY"));
    if (checkAtt?.length > 0) {
      alert("This date's leave request already exists.")
    }
    else {
      setToDate(new Date(date));
      setValue("toDate", date);
    }
  }

  const handleChange = (event) => {
    setSelectedOption(event.target.value);
  };

  return (
    <div className="px-10 py-6 w-[60%] mx-auto bg-white shadow-md rounded-lg overflow-y-auto no-scrollbar">
      <div className="flex flex-row items-center justify-between">
        <h1 className="text-xl text-primary-400 font-medium mb-1">
          Apply Leave
        </h1>
        <div onClick={() => onClose()}>
          <CrossIcon />
        </div>
      </div>
      <div className="h-full flex-1 justify-between">
        <form onSubmit={handleSubmit(onSubmit)} className="space-y-2 mt-4">
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            <Controller
              name="fromDate"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "Please select date for regularization",
                },
              }}
              render={({ field: { onChange, value } }) => (
                <CustomDatePicker
                  title="Date From"
                  name="fromDate"
                  onChange={(date) => handleFromDateChange(date)}
                  selected={fromDateValue}
                  //maxDate={new Date()}
                  //minDate={moment().subtract(25, 'days').format()}
                  minDate={moment().subtract(7, 'days').format()}
                  showYearDropdown={true}
                  dropdownMode="select"
                  placeholderText="Ex. mm/dd/yyyy"
                  popperPlacement="bottom-end"
                  dateFormat="dd/MM/yyyy"
                  showMonthYearPicker={false}
                  error={errors?.fromDate?.message}
                  isMandatory={true}
                />
              )}
            />
            <Controller
              name="toDate"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "Please Select to date",
                },
              }}
              render={({ field: { onChange, value } }) => (
                <CustomDatePicker
                  title="Date To"
                  name="toDate"
                  onChange={(date) => handleToDateChange(date)}
                  selected={value}
                  //maxDate={new Date()}
                  //minDate={moment().subtract(25, 'days').format()}
                  minDate={fromDateValue}
                  showYearDropdown={true}
                  dropdownMode="select"
                  placeholderText="Ex. mm/dd/yyyy"
                  popperPlacement="bottom-end"
                  dateFormat="dd/MM/yyyy"
                  showMonthYearPicker={false}
                  error={errors?.fromDate?.message}
                  isMandatory={true}
                />
              )}
            />
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-5 mb-3">
            <Controller
              name="leavType"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "Please select leave type",
                },
              }}
              render={({ field: { onChange, value } }) => (
                <ExDropdown
                  title="Leave Type" //this is used attribute for designatiion
                  placeholder="Select"
                  onChange={onChange}
                  value={value || null}
                  optionValue="Id"
                  optionLabel="Title"
                  options={leaveTypes}
                  dropDownHeight="40px"
                  dropDownBorderWidth="1px"
                  dropDownBorderColor="#8C8C8C"
                  error={errors?.leavType?.message}
                  isMandatory={true}
                />
              )}
            />
            {
              moment(fromDateValue).format("DD/MM/YYYY") == moment(toDate).format("DD/MM/YYYY") &&
              <Controller
                name="toDate"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: "Please Select to date",
                  },
                }}
                render={({ field: { onChange, value } }) => (
                  <div className="mt-7">
                    <ExRadioBtnGroup
                      title={undefined}
                      error={undefined}
                      options={["Full Day", "Half Day"]}
                      selectedOption={selectedOption}
                      onChange={handleChange}
                    />
                  </div>
                )}
              />
            }
          </div>
          {
            (selectedOption === "Half Day" && fromDateValue && moment(fromDateValue).format("DD/MM/YYYY") == moment(toDate).format("DD/MM/YYYY")) &&
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-5">
              <Controller
                name="startDateType"
                control={control}
                rules={{
                  required: {
                    value: (selectedOption === "Half Day"),
                    message: "Please select which half you are taking leave",
                  },
                }}
                render={({ field: { onChange, value } }) => (
                  <div className="">
                    {/* <label>{moment(fromDateValue).format("DD/MM/YYYY")}</label> */}
                    <ExDropdown
                      title={moment(fromDateValue).format("DD/MM/YYYY")} //this is used attribute for designatiion
                      placeholder="Select"
                      onChange={onChange}
                      value={value || null}
                      optionValue="id"
                      optionLabel="Title"
                      options={leavedaysType}
                      dropDownHeight="40px"
                      dropDownBorderWidth="1px"
                      dropDownBorderColor="#8C8C8C"
                      error={errors?.startDateType?.message}
                      isMandatory={true}
                    />
                  </div>
                )}
              />
              {/* {
                (firstDateLeaveDay == "Half Day") &&
                <Controller
                  name="startDateHalfType"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: "Please select which alf",
                    },
                  }}
                  render={({ field: { onChange, value } }) => (
                    <div className="">
                      <ExDropdown
                        //className="w-10/12"
                        title="Select Half" //this is used attribute for designatiion
                        placeholder="Select"
                        onChange={onChange}
                        value={value || null}
                        optionValue="Id"
                        optionLabel="Title"
                        options={halfDayType}
                        dropDownHeight="40px"
                        dropDownBorderWidth="1px"
                        dropDownBorderColor="#8C8C8C"
                        error={errors?.leavType?.message}
                        isMandatory={true}
                      />
                    </div>
                  )}
                />
              } */}
            </div>
          }

          <div className="w-full">
            <label className="text-exgray-200/70 text-base font-semibold">
              Leave Reason *
            </label>
            <Controller
              name="reason"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "Please enter your leave reason",
                },
              }}
              render={({ field: { onChange, value } }) => (
                // <ReactQuill
                //   //onFocus={ }
                //   placeholder="Type Here"
                //   //style={{ height: 100 }}
                //   value={value}
                //   onChange={onChange}
                //   theme="snow" // You can choose between 'snow' and 'bubble' themes
                // />
                <textarea
                  //className=""
                  value={value}
                  onChange={onChange}
                  //onInput={handleInput}
                  placeholder="Type here..."
                  rows={5} // Start with a single row, but it will expand automatically
                  className="expandable-textarea border border-exgray-200 w-full rounded-md p-2 h-22"
                />
              )}
            />
            <h1 className="text-red-400">{errors?.reason?.message}</h1>
          </div>
          <div className="flex items-end justify-end pt-5">
            <button
              onClick={() => handleSubmit(onSubmit)}
              //disabled={(startDateSelected == null && todayAttendance?.length == 0 || (todayAttendance?.length > 0 && todayAttendance[0]?.status))}
              className={`bg-primary-500 w-2/12 h-11 rounded-lg text-exwhite-100 text-lg`}>
              Submit
            </button>
          </div>
        </form>
      </div>


    </div>
  );
};

export default LeaveRequestForm;
