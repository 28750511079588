import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ContactDetail } from "../models/contact.model";
import { Agreement, AgreementData, CreateInteractionReq, Interaction } from "../models/interaction.model";
import { Client, FilterClientData, PaginatedClientResponse } from "../models/sales.model"
import salesService from "../services/sales.service";
import interactionService from "../services/interaction.service";
import contactService from "../services/contact.service";
import { CompanyProfile } from "../models/company.model";
import { logout } from "./loginSlice";
import { toast } from "sonner";

export interface SalesReduxState{
    clients:PaginatedClientResponse;
    clientDetail:Client|null;
    interactions:Array<Interaction>;
    onboardingInteractions:Array<Interaction>,
    contacts:Array<ContactDetail>;
    domain:CompanyProfile | null;
    leadFilters: Record<string, string>;
    agreements:Array<Agreement>;
    agreementDetail:Agreement
}

const initialState:SalesReduxState={
    clients:null,
    clientDetail: null,
    interactions:[],
    onboardingInteractions:[],
    contacts:[],
    domain:null,
    leadFilters: null,
    agreements:[],
    agreementDetail:null
}


export const createClientApi = createAsyncThunk(
    "sales/createClientApi",
    async (data: Client, { getState, rejectWithValue }) => {
      try {
        const response = await salesService.createClient(data);
        return response;
      } catch (error) {
        return rejectWithValue(error.response?.data);
      }
    }
  );

export const updateClientApi = createAsyncThunk(
  "sales/updateClientApi",
  async (data: Client, { getState, rejectWithValue }) => {
    try {
      const response = await salesService.updateClient(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);


export const getAllClientsApi = createAsyncThunk(
    "sales/getAllClientsApi",
    async (data: null, { getState, rejectWithValue }) => {
      try {
        const response = await salesService.getAllClients();
        return response;
      } catch (error) {
        return rejectWithValue(error.response?.data);
      }
    }
  );

  export const getfilteredClientsApi = createAsyncThunk(
    "sales/getfilteredClientsApi",
    async (data: FilterClientData, { getState, rejectWithValue }) => {
      try {
        const response = await salesService.getfilteredClients(data);
        return response;
      } catch (error) {
        return rejectWithValue(error.response?.data);
      }
    }
  );

export const getNextClientsApi = createAsyncThunk(
  "sales/getNextClientsApi",
  async (_data, { getState, rejectWithValue }) => {
    try {
      const state: any = getState();
      // console.log(state ,"NEXT URL")
      // console.log(state?.sales?.clients?.metadata?.nextResultURL)
      const response = await salesService.getClientByUrl(
        state?.sales?.clients?.metadata?.nextResultURL
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
  );
export const getClientByIdApi = createAsyncThunk(
    "sales/getClientByIdApi",
    async (clientId: string, { getState, rejectWithValue }) => {
      try {
        const response = await salesService.getClientById(clientId);
        return response;
      } catch (error) {
        return rejectWithValue(error.response?.data);
      }
    }
  );

export const checkDomainApi = createAsyncThunk(
    "sales/checkDomainApi",
    async (domain: string, { getState, rejectWithValue }) => {
      try {
        const response = await salesService.checkDomain(domain);
        return response;
      } catch (error) {
        return rejectWithValue(error.response?.data);
      }
    }
);

export const createInteractionApi = createAsyncThunk(
    "sales/createInteractionApi",
    async (data: CreateInteractionReq, { getState, rejectWithValue }) => {
      try {
        const response = await interactionService.createInteraction(data);
        return response;
      } catch (error) {
        return rejectWithValue(error.response?.data);
      }
    }
  );


export const getAllInteractionsApi = createAsyncThunk(
    "sales/getAllInteractionsApi",
    async (clientId: string, { getState, rejectWithValue }) => {
      try {
        const response = await interactionService.getAllInteractions(clientId);
        return response;
      } catch (error) {
        return rejectWithValue(error.response?.data);
      }
    }
  );

export const getAllOnboardingInteractionsApi = createAsyncThunk(
    "sales/getAllOnboardingInteractionsApi",
    async (clientId: string, { getState, rejectWithValue }) => {
      try {
        const response = await interactionService.getAllOnboardingInteractions(clientId);
        return response;
      } catch (error) {
        return rejectWithValue(error.response?.data);
      }
    }
  );

export const createContactApi = createAsyncThunk(
    "sales/createContactApi",
    async (data: ContactDetail, { getState, rejectWithValue }) => {
      try {
        const response = await contactService.createContact(data);
        return response;
      } catch (error) {
        return rejectWithValue(error.response?.data);
      }
    }
  );

  export const updateContactApi = createAsyncThunk(
    "sales/updateContactApi",
    async (data: ContactDetail, { getState, rejectWithValue }) => {
      try {
        const response = await contactService.updateContact(data);
        return response;
      } catch (error) {
        return rejectWithValue(error.response?.data);
      }
    }
  );


export const getAllContactsApi = createAsyncThunk(
    "sales/getAllContactsApi",
    async (clientId: string, { getState, rejectWithValue }) => {
      try {
        const response = await contactService.getAllContacts(clientId);
        return response;
      } catch (error) {
        return rejectWithValue(error.response?.data);
      }
    }
  );


  export const createAgreementApi = createAsyncThunk(
    "sales/createAgreementApi",
    async (data: Agreement, { getState, rejectWithValue }) => {
      try {
        const response = await interactionService.createAgreement(data);
        return response;
      } catch (error) {
        return rejectWithValue(error.response?.data);
      }
    }
  );

  export const updateAgreementApi = createAsyncThunk(
    "sales/updateAgreementApi",
    async (data: Agreement, { getState, rejectWithValue }) => {
      try {
        const response = await interactionService.updateAgreement(data);
        return response;
      } catch (error) {
        return rejectWithValue(error.response?.data);
      }
    }
  );

  export const getAllAgreementsApi = createAsyncThunk(
    "sales/getAllAgreementsApi",
    async (clientId: string, { getState, rejectWithValue }) => {
      try {
        const response = await interactionService.getAllAgreements(clientId);
        return response;
      } catch (error) {
        return rejectWithValue(error.response?.data);
      }
    }
  );

  export const getAgreementByIdApi = createAsyncThunk(
    "sales/getAgreementByIdApi",
    async (_data: AgreementData, { getState, rejectWithValue }) => {
      try {
        const response = await interactionService.getAgreementById(_data);
        return response;
      } catch (error) {
        return rejectWithValue(error.response?.data);
      }
    }
  );

// export const getClientsDataApi = createAsyncThunk(
//     "sales/getClientsDataApi",
//     async (data: any, { rejectWithValue }) => {
//       try {
//         const response = await salesService.getClientsData(data);
//         return response.data;  // Assuming the API response is in the `data` field
//       } catch (error) {
//         // You can handle errors more precisely here
//         return rejectWithValue(error.response?.data || error.message);
//       }
//     }
// );

export const getClientsDataApi = createAsyncThunk(
      "sales/getClientsDataApi",
      async (data: any, { dispatch,rejectWithValue }) => {

        const formData = new FormData();
  
  // Append text data to the FormData object with the key 'files'
       formData.append('files', data.text);

        try {
          const response = await fetch(`https://api.exotalent.in/v1/client_upload`, {
            method: 'POST',
            body: formData,  // The body will be the FormData object
            headers: {
              // No need to set Content-Type manually when using FormData
            },
          });
          if(response.ok){
            // console.log("GOOGOGOGOFJJF")
            // // const responseData = await response.json();
            // console.log("CLIENATS DSJDH",response.body)

            // responseData.forEach((ele) => {
            //   console.log(ele)
            //   dispatch(createClientApi(ele));
            // });
          }
      
          if (!response.ok) {
            // If response is not successful, throw an error
            throw new Error('Failed to upload data');
          }
      
          const responseData = await response.json();  // Parse JSON response
          return responseData;
        } catch (error) {
          // You can handle errors more precisely here
          return rejectWithValue(error.response?.data || error.message);
        }
      }
  );
  

  export const salesSlice=createSlice({
    name:'sales',
    initialState,
    reducers:{
      addFilters: (state, action) => {
        state.leadFilters={
          ...state.leadFilters,
          ...action.payload,
        }
      },
      resetFilters: (state) => {
        state.leadFilters={}
      },
    },
   extraReducers:(builder)=>{
    builder.addCase(getAllClientsApi.fulfilled,(state,action)=>{
        console.log(action.payload.data)
        
        const client = action?.payload?.data;
        if (client) {      
          state.clients= client 
        }else{
          state.clients=null
        }       
    })
    builder.addCase(getfilteredClientsApi.fulfilled,(state,action)=>{
      console.log(action.payload.data)
      
      const client = action?.payload?.data;
      if (client) {      
        state.clients= client 
      } 
      else{
        state.clients=null
      }       
  })
    builder.addCase(createClientApi.fulfilled,(state,action)=>{
      // console.log(action.payload)
      // console.log(action.payload.data.clients)
      const client = action.payload.data;
      console.log(client)
      if (client && state?.clients?.clients) {
        state.clients.clients.unshift(client);  // Ensure that 'clients' is an array
      }  
    })
    builder.addCase(createClientApi.rejected,(state,action)=>{
      // console.log(action.payload)
      // console.log(action.payload.data.clients)
      const client = action.payload;
      toast.error(client?.message)
      console.log(client)
        
    })

    builder.addCase(updateClientApi.fulfilled,(state,action)=>{
      console.log(action.payload.data)
        state.clientDetail=action.payload.data  
    })
    builder.addCase(getClientByIdApi.fulfilled,(state,action)=>{
        // console.log(action.payload.data,"GETCLIENTBYID")
        state.clientDetail=action.payload.data
    })
    builder.addCase(checkDomainApi.fulfilled,(state,action)=>{
        // console.log(action.payload,"CHECKO DOMAIN")
        // state.clients.clients.push(action.payload.data.data)
    })
    builder.addCase(getAllInteractionsApi.fulfilled,(state,action)=>{
        console.log(action.payload.data,"ALL INTERACTION")
        if(action.payload.data!==null){
          state.interactions=action.payload.data
        }
        else{
          state.interactions=[]
        }
        // Y
        // state.clients.clients.push(action.payload.data.data)
    })

    builder.addCase(getAllOnboardingInteractionsApi.fulfilled,(state,action)=>{
     
      if(action.payload.data!==null){
        state.onboardingInteractions=action.payload.data
      }
      else{
        state.onboardingInteractions=[]
      }
      // Y
      // state.clients.clients.push(action.payload.data.data)
  })

    builder.addCase(getAllContactsApi.fulfilled,(state,action)=>{
        console.log(action.payload.data,"CONTACTS")
        if(action.payload.data){
          state.contacts=action.payload.data
        }
        else{
          state.contacts=[]
        }
        // state.clients.clients.push(action.payload.data.data)
    })

    builder.addCase(updateContactApi.fulfilled,(state,action)=>{
      console.log(action.payload.data,"CONTACTS")
      if(action.payload.data){
       const foundIndex= state.contacts.findIndex(item=>item.id===action.payload.data.id)
       if (foundIndex !== -1) {
        // Replace the entire object at the found index
        state.contacts[foundIndex] = action.payload.data;
    }
        // state.contacts=action.payload.data
      }
      // else{
      //   state.contacts=[]
      // }
      // state.clients.clients.push(action.payload.data.data)
  })

     builder.addCase(getNextClientsApi.fulfilled, (state, action) => {
          const response = action.payload.data;
          console.log(response,"RESPONSEEDD")
          if (response?.clients && response?.clients.length > 0) {
            state.clients.clients = state.clients.clients.concat(response.clients);
          }
          state.clients.metadata = response.metadata;
    });

    builder.addCase(createContactApi.fulfilled,(state,action)=>{
      console.log(action.payload.data,"CONTACTS")
      if(action.payload.data){
        state.contacts=[...state.contacts,action.payload.data]
      }
    })

    builder.addCase(createInteractionApi.fulfilled,(state,action)=>{
      console.log(action.payload.data,"Interactions",action.payload.data.stages)
      if(action.payload.data.stages=='2' && action.payload.data){
        state.onboardingInteractions=[action.payload.data,...state.onboardingInteractions]
        toast.success("Onboarding Interaction Created Successfully")

      }
      if(action.payload.data.stages=='1' && action.payload.data){
        state.interactions=[action.payload.data,...state.interactions]
        toast.success("Interaction Created Successfully")
      }
    })
    builder.addCase(createInteractionApi.rejected,(state,action)=>{
      
      toast.error(action.payload.error)
    })

    builder.addCase(getAllAgreementsApi.fulfilled,(state,action)=>{
      if(action.payload.data){

        state.agreements=[...action.payload.data]
      }
    
      // toast.error(action.payload.error)
    })

    builder.addCase(getAgreementByIdApi.fulfilled,(state,action)=>{
      // console.log(action.payload.data,"Interactions reject ch")
      // state.agreements=[...action.payload.data]
      state.agreementDetail=action.payload.data
      // toast.error(action.payload.error)
    })

     builder.addCase(logout, (state) => {
          // Reset this slice's state to initial state
          return initialState;
    });

   }
})

export const { addFilters,  resetFilters } = salesSlice.actions;

export default salesSlice.reducer;