import { useEffect, useMemo, useRef, useState } from "react";
import LocationIcon from "../../../../../assets/Icons/LocationIcon";
import VerticleThreeDotsIcon from "../../../../../assets/Icons/VerticleThreeDotsIcon";
import moment from "moment";
import useLocations from "../../../../../utils/dropdown-options/useLocations";
import { useAppDispatch, useAppSelector } from "../../../../../config/hooks";
import {
  getInterviewStagesApi,
  tagApplicantApi,
  updateApplicantStageApi,
  updateApplicantStatusApi,
} from "../../../../../slices/jobSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { toast } from "sonner";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import * as constants from "../../../../../constant";
import { v4 as uuidV4 } from "uuid";
import Image from "../../../../../components/Image";
import StarIcon from "../../../../../assets/Icons/StarIcon";
import StarsIcon from "../../../../../assets/Icons/StarsIcon";

const StagedApplicantCard = ({
  applicant,
  nextStage = null,
  prevStage = null,
  fetchData = () => {},
  currentStageId = "",
}) => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();
  const { jobId } = useParams();
  const { pathname } = useLocation();

  const menuRef = useRef(null);
  const menuParentRef = useRef(null);

  const toggleStatusMenuRef = useRef(null);
  const toggleStatusMenuParentRef = useRef(null);

  const loginSelector = useAppSelector((state) => state?.login?.session);
  const memberSelector = useAppSelector((state) => state.member.memberDetails);

  const [isToggleModalOpen, setIsToggleModalOpen] = useState(false);
  const [toggleStatusModal, setToggleStatusModal] = useState(false);

  const { defaultLocation } = useLocations();

  const applicantLocation = useMemo(() => {
    if (applicant?.author?.location)
      return defaultLocation(applicant?.author?.location);
    else return null;
  }, [applicant?.author?.location, defaultLocation]);

  useEffect(() => {
    const toggleHandler = (e) => {
      if (
        menuRef.current &&
        menuParentRef &&
        !menuRef.current.contains(e.target) &&
        !menuParentRef.current.contains(e.target)
      ) {
        // close popup
        setIsToggleModalOpen(false);
      }
    };
    document.addEventListener("mousedown", toggleHandler);

    return () => {
      document.removeEventListener("mousedown", toggleHandler);
    };
  });

  useEffect(() => {
    const toggleHandler = (e) => {
      if (
        toggleStatusMenuRef.current &&
        toggleStatusMenuParentRef &&
        !toggleStatusMenuRef.current.contains(e.target) &&
        !toggleStatusMenuParentRef.current.contains(e.target)
      ) {
        // close popup
        setToggleStatusModal(false);
      }
    };
    document.addEventListener("mousedown", toggleHandler);

    return () => {
      document.removeEventListener("mousedown", toggleHandler);
    };
  });

  const toggleModalHandler = () => {
    setIsToggleModalOpen(!isToggleModalOpen);
  };

  const updateStateHandler = async (applicantStatus) => {
    setToggleStatusModal(false);
    if (
      loginSelector?.who === constants.COMPANY.toLowerCase() ||
      loginSelector?.who === constants.COMPANY_MEMBER.toLowerCase()
    ) {
      if (applicant.job.jobStatus === "OPEN") {
        try {
          await dispatch(
            updateApplicantStatusApi({
              jobId: applicant.jobId,
              applicationId: applicant.id,
              status: applicantStatus,
              subStatus: "APPLICATION_VIEWED", //APPLICATION_CONTACT_VIEWED
            })
          ).then(unwrapResult);
          fetchJobInterviewStages();
          toast.success("Status updated successfully");
        } catch (error) {
          toast.error("Something went wrong");
        }
      } else {
        toast.error("Job closed");
      }
    }
    //  else if (
    //   (loginSelector?.who === constants.COMPANY_MEMBER.toLowerCase() &&
    //     memberSelector.designation === constants.CANDIDATE_MANEGMENT) ||
    //   loginSelector?.who === constants.RECRUITER.toLowerCase()
    // ) {
    //   const resp = await dispatch(
    //     updateApplicantStatusByMemberApi({
    //       jobId: candidate.jobId,
    //       applicationId: candidate.applications[0].id,
    //       status: applicantStatus,
    //       subStatus: "APPLICATION_VIEWED", //APPLICATION_CONTACT_VIEWED
    //     })
    //   ).then(unwrapResult);
    //   const newArray = appliedCadidateViaRefer.referrals.map((ref) => {
    //     return {
    //       ...ref,
    //       applications: ref.applications.map((apps) => {
    //         if (apps.id === resp.data.data.id) {
    //           return { ...apps, status: resp.data.data.status };
    //         }
    //       }),
    //     };
    //   });
    //   setAppliedCadidateViaRefer({
    //     ...appliedCadidateViaRefer,
    //     referrals: newArray,
    //   });
    //   dispatch(
    //     updateReferalStatusApi({
    //       referalId: candidate.id,
    //       status: applicantStatus,
    //       subStatus: "APPLICATION_VIEWED",
    //     })
    //   ).then(unwrapResult);
    // }
  };

  const toggleStatusHandler = () => {
    setToggleStatusModal(!toggleStatusModal);
  };

  const fetchJobInterviewStages = async () => {
    try {
      await dispatch(
        getInterviewStagesApi({
          jobId: applicant.jobId,
        })
      ).then(unwrapResult);
    } catch (error) {
      toast.error("Unabel to fetch tagged list");
    }
  };

  const moveForwardHandler = async () => {
    if (nextStage && nextStage?.id) {
      if (
        (!currentStageId && !applicant.stageId && nextStage && nextStage?.id) ||
        (currentStageId && applicant.stageId && nextStage && nextStage?.id)
      ) {
        try {
          await dispatch(
            updateApplicantStageApi({
              stageId: nextStage.id,
              applicantId: applicant.id,
            })
          ).then(unwrapResult);
          toast.success(`Applicant moved to ${nextStage.stageName}`);
          fetchJobInterviewStages();
        } catch (error) {
          toast.error("Something went wrong");
        }
        setIsToggleModalOpen(false);
      } else {
        toast.error("Unable to forward applicant");
      }
    }
  };

  const moveBackwardHandler = async () => {
    if (prevStage) {
      try {
        await dispatch(
          updateApplicantStageApi({
            stageId: prevStage.id,
            applicantId: applicant.id,
          })
        ).then(unwrapResult);
        toast.success(`Applicant moved to ${prevStage.stageName}`);
        fetchJobInterviewStages();
      } catch (error) {
        toast.error("Something went wrong");
      }
      setIsToggleModalOpen(false);
    } else {
      if (!applicant?.stageId && !currentStageId) {
        try {
          await dispatch(
            tagApplicantApi({
              applicantId: applicant?.id,
              isTagged: false,
              taggedBy: loginSelector?.userId,
            })
          ).then(unwrapResult);
          toast.success("Candidate Removed successfully");
          if (
            pathname === `/company-admin/job/${jobId}/tagged-list/approvals`
          ) {
            navigate(`/company-admin/job/${jobId}/approvals`);
          } else {
            navigate(`/company-admin/job/${jobId}`);
          }
        } catch (error) {
          toast.error("Something went wrong");
        }
      }
    }
  };

  return (
    <div className="bg-exwhite-100 p-4 space-y-2 rounded-md">
      <div className="flex items-center justify-between">
        {applicant.percentageMatch && (
          <div className="bg-[#8833FF33] text-[#8833FF] text-base font-semibold text-center rounded-full px-3 py-1 flex items-center justify-between gap-1">
            {`${applicant.percentageMatch} %`}
            <StarsIcon />
          </div>
        )}
        <div className="ml-auto mr-0 cursor-pointer relative">
          <div
            className={`rotate-90 ${
              loginSelector?.who === constants.COMPANY.toLowerCase() ||
              (loginSelector?.who === constants.COMPANY_MEMBER.toLowerCase() &&
                memberSelector?.designation !== constants.ADVISER) ||
              (loginSelector?.who === constants.COMPANY_MEMBER.toLowerCase() &&
                memberSelector?.designation === constants.ADVISER &&
                applicant?.taggedBy === loginSelector?.userId)
                ? (applicant?.stageId && currentStageId) ||
                  (!applicant?.stageId && !currentStageId)
                  ? ""
                  : "hidden"
                : "hidden"
            }`}
            onClick={toggleModalHandler}
            ref={menuParentRef}
          >
            <VerticleThreeDotsIcon />
          </div>

          {isToggleModalOpen && (
            <div
              className="absolute w-40 bg-exwhite-100 right-0 top-4 rounded-md z-50"
              style={{
                boxShadow: "0px 12px 32px 0px #18191C14",
              }}
              ref={menuRef}
            >
              {(loginSelector?.who === constants.COMPANY.toLowerCase() ||
                (loginSelector?.who ===
                  constants.COMPANY_MEMBER.toLowerCase() &&
                  memberSelector?.designation !== constants.ADVISER)) && (
                <button
                  className="w-full py-2 text-exgray-200 text-base font-semibold rounded-t-md hover:bg-secondary-100 hover:text-secondary-400"
                  onClick={moveForwardHandler}
                >
                  Move Forward
                </button>
              )}
              {((!applicant?.stageId &&
                !currentStageId &&
                applicant?.taggedBy === loginSelector?.userId) ||
                (applicant?.stageId && currentStageId)) && (
                <button
                  className="w-full py-2 text-exgray-200 text-base font-semibold rounded-b-md hover:bg-secondary-100 hover:text-secondary-400"
                  onClick={moveBackwardHandler}
                >
                  Move Backward
                </button>
              )}
            </div>
          )}
        </div>
      </div>

      <Link
        className="text-start text-base text-exgray-600 font-semibold cursor-pointer"
        to={`/company-admin/job/${applicant.jobId}/applicant/${applicant.id}/user/${applicant.userId}`}
        state={{ candidate: applicant }}
      >
        {`${applicant.author.firstName} ${applicant.author.lastName}`}
      </Link>

      <div className="flex items-center justify-start gap-1">
        <div className="w-fit">
          <LocationIcon width="15" height="15" color="#696969" />
        </div>
        <p className="flex-1 line-clamp-1 text-base text-exgray-400 cursor-default">
          {applicantLocation?.Name}
        </p>
      </div>

      <div className="border-b border-exgray-100 pb-2">
        <div className="relative">
          <button
            className="text-[#0BA02C] bg-[#E7F6EA]/50 w-fit px-4 py-1 rounded-full"
            onClick={toggleStatusHandler}
            ref={toggleStatusMenuParentRef}
          >
            {applicant.status}
          </button>
          {toggleStatusModal && (
            <div
              className="absolute bg-exwhite-100 top-8 right-2 w-60 rounded-lg overflow-hidden z-50 max-h-56 overflow-y-auto"
              style={{
                boxShadow: "0px 0px 15px 1px rgba(0, 0, 0, 0.4)",
              }}
              ref={toggleStatusMenuRef}
            >
              {constants.CANDIDATE_STATUS.map((status) => (
                <button
                  className="p-2 w-full text-start text-base text-expurple-600 font-semibold hover:bg-expurple-700/50"
                  key={uuidV4()}
                  onClick={() => updateStateHandler(status)}
                >
                  {status}
                </button>
              ))}
            </div>
          )}
        </div>
      </div>

      <div className="flex items-center justify-between">
        {applicant?.recruiterDetails?.firstName &&
        applicant?.recruiterDetails?.lastName ? (
          <div className="flex-1 flex items-center justify-center gap-2">
            {applicant?.recruiterDetails?.profileImage ? (
              <div className="w-5 h-5">
                <Image
                  url={applicant?.recruiterDetails?.profileImage}
                  alt="profile"
                  borderRadius="4px"
                />
              </div>
            ) : (
              applicant?.recruiterDetails?.firstName &&
              applicant?.recruiterDetails?.lastName && (
                <div className="w-6 h-6 flex items-center justify-center bg-primary-400 rounded-sm">
                  <h1 className="text-exwhite-100 text-xs font-semibold">{`${applicant?.recruiterDetails?.firstName[0].toUpperCase()}${applicant?.recruiterDetails?.lastName[0].toUpperCase()}`}</h1>
                </div>
              )
            )}

            <div className="flex-1 max-w-28 pb-[1px]">
              <h1 className="text-sm text-exgray-200 font-semibold text-start cursor-default break-words line-clamp-1">
                {`${applicant?.recruiterDetails?.firstName} ${applicant?.recruiterDetails?.lastName}`}
              </h1>
            </div>
          </div>
        ) : (
          <div className="flex-1"></div>
        )}

        <div className="flex-1">
          <h1 className="text-sm text-exgray-200 font-normal text-end cursor-default">
            {moment(applicant.createdAt).format("DD MMMM YYYY")}
          </h1>
        </div>
      </div>
    </div>
  );
};

export default StagedApplicantCard;
