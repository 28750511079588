import ExcelJS from 'exceljs';
import moment from 'moment';
import { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import ExDropdown from '../../components/basic-components/ExDropdown';
import { useAppDispatch, useAppSelector } from '../../config/hooks';
import * as constants from "../../constant";
import { getConsolidateViewApi } from '../../slices/attendanceSlice';
import { Link, useNavigate } from 'react-router-dom';

const monthList = [
  {
    title: "Janaury",
    value: 1
  },
  {
    title: "February",
    value: 2
  },
  {
    title: "March",
    value: 3
  },
  {
    title: "April",
    value: 4
  },
  {
    title: "May",
    value: 5
  },
  {
    title: "June",
    value: 6
  },
  {
    title: "July",
    value: 7
  },
  {
    title: "August",
    value: 8
  },
  {
    title: "September",
    value: 9
  },
  {
    title: "October",
    value: 10
  },
  {
    title: "November",
    value: 11
  },
  {
    title: "December",
    value: 12
  }

]

const yearList = [
  {
    title: "2025",
    value: 2025
  }
]

const AllEmployeeTimesheet = () => {
  const dispatch = useAppDispatch();
  const timesheet = useAppSelector((state) => state.attendance.timesheet);
  const memberDetail = useAppSelector((state) => state.company.memberDetails);
  const consolidateTimesheet = useAppSelector((state) => state.attendance.consolidateTimesheetList);
  let month = moment().month() > 8 ? moment().month() + 1 : 0 + moment().month() + 1;
  let currentMonth = monthList.find((m) => m.value == month);
  let year = moment().year();
  const [selectedMonth, setMonth] = useState(currentMonth);
  const [selectedYear, setYear] = useState({ title: year, value: year });

  const who = useAppSelector((state) => state?.login?.session?.who);
  const companyDetails = useAppSelector((state) => state.company.companyDetails);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getConsolidateViewApi({
      month: selectedMonth?.value,
      year: selectedYear?.value,
      companyId: who === constants.COMPANY.toLowerCase() ? companyDetails.id : memberDetail?.companyId
    }))
  }, [selectedMonth, selectedYear]);

  // const exportToExcel = () => {

  //   const ws = XLSX.utils.json_to_sheet(consolidateTimesheet);

  //   // Customizing the header row (making the first row bold)
  //   const range = XLSX.utils.decode_range(ws['!ref']); // Get the range of the sheet
  //   const headerRow = range.s.r; // Get the start row of the range (usually 0)

  //   for (let col = range.s.c; col <= range.e.c; col++) {
  //     console.log(col, "colcolcol");
  //     const cell = ws[XLSX.utils.encode_cell({ r: headerRow, c: col })]; // Access each header cell
  //     console.log(cell, "celllllll");
  //     if (cell) {
  //       console.log("you are in if condition");
  //       cell.s = { font: { bold: true } }; // Set font to bold
  //     }
  //   }

  //   //Create a new workbook
  //   const wb = XLSX.utils.book_new();

  //   // Append the worksheet to the workbook
  //   XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

  //   // Write the workbook to a file
  //   XLSX.writeFile(wb, `exported_sheet.xlsx`);
  // };

  const exportToExcel = async () => {
    // Create a new workbook with ExcelJS
    const workbook = new ExcelJS.Workbook();

    // Add a worksheet
    const worksheet = workbook.addWorksheet('Sheet1');

    worksheet.columns = [
      { header: 'EmployeeId', key: 'employeeCode', width: 20 },
      { header: 'Name', key: 'name', width: 20 },
      { header: 'DOJ', key: 'doj', width: 20 },
      { header: 'Team', key: 'team', width: 20 },
      { header: 'Company Name', key: 'companyName', width: 20 },
      { header: 'No Of Days In Month', key: 'noOfDaysInMonth', width: 20 },
      { header: 'No Of Working Days', key: 'noOfWorkingDays', width: 20 },
      { header: 'No Of Days Worked', key: 'noOfDaysWorked', width: 20 },
      { header: 'Leave Availed', key: 'leaveAvailed', width: 20 },
      { header: 'Leave Carry Forward', key: 'leaveCarryForward', width: 20 },
      { header: 'Leave Earned', key: 'leaveEarned', width: 20 },
      { header: 'Earned Sick Leave', key: 'earnedSickLeave', width: 20 },
      { header: 'Leave Balance', key: 'leaveBalance', width: 20 },
      { header: 'Payroll Amount', key: 'payrollAmount', width: 20 },
      { header: 'Loss Of Pay', key: 'lossOfPay', width: 20 },
    ];

    // Apply bold to the header row
    worksheet.getRow(1).font = { bold: true, size: 12, };

    // Add data to the worksheet
    consolidateTimesheet.forEach((item) => {
      worksheet.addRow(item);
    });

    // Apply borders to all cells in the worksheet
    worksheet.eachRow({ includeEmpty: true }, function (row, rowNumber) {
      row.eachCell({ includeEmpty: true }, function (cell, colNumber) {
        // cell.border = {
        //   top: { style: 'thin' },
        //   left: { style: 'thin' },
        //   bottom: { style: 'thin' },
        //   right: { style: 'thin' },
        // };
        cell.alignment = { horizontal: 'left' }
      });
    });

    // Save the file as a blob and download it
    workbook.xlsx.writeBuffer().then((buffer) => {
      const data = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(data);
      link.download = 'exported_data.xlsx';
      link.click();
    });
  };

  return (
    <>
      <div className='flex justify-between items-center mb-3'>
        <div>
          <h1 className='font-medium text-xl mr-5 mb-1'>Monthly Timesheet</h1>
          <button disabled={!consolidateTimesheet} onClick={() => exportToExcel()} className='bg-secondary-500 text-exwhite-100 text-base px-4 py-1 rounded-lg hover:bg-secondary-800'>
            Export Sheet
          </button>
        </div>
        <div className='flex items-center w-6/12'>
          <div className='w-6/12 mr-3'>
            <ExDropdown
              title="Select Month"
              placeholder="Select"
              onChange={(item) => setMonth(item)}
              value={selectedMonth}
              optionValue="Id"
              optionLabel="title"
              options={monthList}
              dropDownHeight="40px"
              dropDownBorderWidth="1px"
              dropDownBorderColor="#8C8C8C"
              error={''}
              isMandatory={true}
            />
          </div>
          <div className='w-6/12'>
            <ExDropdown
              title="Select Year"
              placeholder="Select"
              onChange={(item) => setYear(item)}
              value={selectedYear}
              optionValue="value"
              optionLabel="title"
              options={yearList}
              dropDownHeight="40px"
              dropDownBorderWidth="1px"
              dropDownBorderColor="#8C8C8C"
              error={''}
              isMandatory={true}
            />
          </div>
        </div>
      </div>
      <div>
        <InfiniteScroll
          dataLength={consolidateTimesheet ? consolidateTimesheet.length : 0} // Length of current data
          next={undefined} // Function to load more data
          hasMore={undefined} // Whether more data is available
          loader={<h4>Loading...</h4>} // Loader while fetching data
        >
          <table className="min-w-full rounded-sm border-collapse border border-gray-400 " cellSpacing="10">
            <thead className="bg-secondary-800">
              <tr className="border border-exgray-100">
                <th className="text-nowrap text-sm text-exwhite-100 font-medium py-3 px-4">Employee ID</th>
                <th className="text-nowrap text-sm text-exwhite-100 font-medium py-3 px-4 ">Name</th>
                <th className="text-nowrap text-sm text-exwhite-100 font-medium py-3 px-4 ">DOJ</th>
                <th className="text-nowrap text-sm text-exwhite-100 font-medium py-3 px-4">DOL</th>
                <th className="text-nowrap text-sm text-exwhite-100 font-medium py-3 px-4 ">Team</th>
                <th className="text-nowrap text-nowrap text-sm text-exwhite-100 font-medium py-3 px-4 ">Company Name</th>
                <th className="text-nowrap text-sm text-exwhite-100 font-medium py-3 px-4 ">Location</th>
                <th className="text-nowrap text-sm text-exwhite-100 font-medium py-3 px-4 ">No of days in Month</th>
                <th className="text-nowrap text-sm text-exwhite-100 font-medium py-3 px-4 ">No of Working Days</th>
                <th className="text-nowrap text-sm text-exwhite-100 font-medium py-3 px-4 ">No of Days worked</th>
                <th className="text-nowrap text-sm text-exwhite-100 font-medium py-3 px-4 ">Leave Availed</th>
                <th className="text-nowrap text-sm text-exwhite-100 font-medium py-3 px-4 ">Leave Carry forward</th>
                <th className="text-nowrap text-sm text-exwhite-100 font-medium py-3 px-4 ">Leave Earned (EL)+Comp Off</th>
                <th className="text-nowrap text-sm text-exwhite-100 font-medium py-3 px-4 ">Earned Sick Leave (SL)</th>
                <th className="text-nowrap text-sm text-exwhite-100 font-medium py-3 px-4 ">Leave Bal</th>
                <th className="text-nowrap text-sm text-exwhite-100 font-medium py-3 px-4 ">Payroll</th>
                <th className="text-nowrap text-sm text-exwhite-100 font-medium py-3 px-4 ">Loss of Pay</th>
                <th className="text-nowrap text-sm text-exwhite-100 font-medium py-3 px-4 ">Remark</th>
              </tr>
            </thead>
            <tbody className="items-center justify-center ">
              {
                consolidateTimesheet?.map((t) => {
                  return (
                    <tr key={t.id} className="items-center bg-exwhite-100 border-2 border-bg-primary-100/50 hover:bg-exgray-100/50">
                      <td className="items-center py-1 px-4 text-sm text-secondary-500 font-medium">
                        <Link to={`/company-admin/employee-timesheet/${t.employeeId}`}>
                          {t.employeeCode}
                        </Link>
                      </td>
                      <td className="text-nowrap text-center px-4 py-1 font-medium text-sm">
                        {t.name}
                      </td>
                      <td className="px-4 py-1 font-medium text-sm">
                        {moment(t.doj).format("DD/MM/YYYY")}
                      </td>
                      <td className="px-4 py-1 font-medium text-sm">

                      </td>
                      <td className="px-4  py-1 ont-medium text-sm">

                      </td>
                      <td className="text-center px-4 py-1 font-medium text-sm">
                        {t.companyName}
                      </td>
                      <td className="text-center px-4 py-1 font-medium text-sm">
                        {t.location}
                      </td>
                      <td className="text-center px-4 py-1 font-medium text-sm">
                        {t.noOfDaysInMonth}
                      </td>
                      <td className="text-center px-4 py-1 font-medium text-sm">
                        {t.noOfWorkingDays}
                      </td>
                      <td className="text-center text-sm text-secondary-700 font-bold px-4 py-1 font-medium">
                        {t.noOfDaysWorked}
                      </td>
                      <td className="text-center text-sm px-4 py-1 font-medium">
                        {t.leaveAvailed}
                      </td>
                      <td className="text-center text-sm px-4 py-1  font-medium">
                        {t.leaveCarryForward}
                      </td>
                      <td className="text-center text-sm px-4  py-1 font-medium">
                        {t.leaveEarned}
                      </td>
                      <td className="text-center text-sm px-4 py-1 font-medium">
                        {t.earnedSickLeave}
                      </td>
                      <td className="text-center text-sm px-4 py-1 font-medium">
                        {t.leaveBalance}
                      </td>
                      <td className="text-center text-sm px-4  py-1 font-medium">
                        {t.payrollAmount}
                      </td>
                      <td className="text-center text-sm px-4 py-1 font-medium">
                        {t.lossOfPay}
                      </td>
                      <td className="text-center text-sm px-4 py-1 font-medium">

                      </td>


                    </tr>
                  );
                })
              }
            </tbody>
          </table>
        </InfiniteScroll>

      </div>
    </>
  )
}

export default AllEmployeeTimesheet
