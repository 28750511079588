import { Outlet, useLocation, useNavigate } from "react-router-dom";
import * as constants from "../../../../../constant";
import { useAppSelector } from "../../../../../config/hooks";

const JobBoard = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const loginSelector = useAppSelector((state) => state?.login?.session);
  const memberSelector = useAppSelector(
    (state) => state?.member?.memberDetails
  );

  const navigateHandler = (path) => {
    navigate(path);
  };

  return (
    <div>
      {loginSelector?.who !== constants.COMPANY.toLowerCase() &&
        loginSelector?.who === constants.COMPANY_MEMBER.toLowerCase() &&
        memberSelector?.designation !== constants.ADVISER && (
          <div className="flex items-center justify-start">
            <button
              className={`px-6 py-2 border-b-2 text-base font-semibold ${
                pathname === "/company-admin/jobs"
                  ? "border-secondary-400 text-secondary-400 bg-secondary-100"
                  : "border-exgray-100 text-exgray-200"
              } `}
              onClick={() => navigateHandler(`/company-admin/jobs`)}
            >
              Assigned Jobs
            </button>
            <button
              className={`px-6 py-2 border-b-2 text-base font-semibold ${
                pathname === "/company-admin/jobs/approvals"
                  ? "border-secondary-400 text-secondary-400 bg-secondary-100"
                  : "border-exgray-100 text-exgray-200"
              } `}
              onClick={() => navigateHandler(`/company-admin/jobs/approvals`)}
            >
              Pending Approval
            </button>
            <div className="flex-1 py-5 border-b-2 border-exgray-100"></div>
          </div>
        )}

      <div>
        <Outlet />
      </div>
    </div>
  );
};

export default JobBoard;
