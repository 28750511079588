import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import companyMemberService from "../services/companyMember.service";
import { logout } from "./loginSlice";
import {
  AdditionalDetails,
  DocumentUpload,
  GetEmployeeDetailRequest,
  getMemberReq,
  member,
  MemberUpdate,
  removeMemberReq,
} from "../models/companyMember.model";
import { AdditionalDetail } from "../models/candidate.model";

export interface companyMemberReduxState {
  members: member;
  memberDetails: null;
  jobAuthorMemberDetails: null;
  documentList: [];
  advisers: member;
}

const initialState = {
  members: [],
  memberDetails: null,
  jobAuthorMemberDetails: null,
  documentList: [],
  advisers: [],
};

export const createMemberApi = createAsyncThunk(
  "companyMember/createMemberApi",
  async (data, { getState, rejectWithValue }) => {
    try {
      const response = await companyMemberService.createMember(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const updateMembersApi = createAsyncThunk(
  "companyMember/updateMembersApi",
  async (data: MemberUpdate, { getState, rejectWithValue }) => {
    try {
      const response = await companyMemberService.updateMember(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getMembersApi = createAsyncThunk(
  "companyMember/getMembersApi",
  async (data: getMemberReq, { getState, rejectWithValue }) => {
    try {
      const response = await companyMemberService.getMembers(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getMemberByIdApi = createAsyncThunk(
  "companyMember/getMemberByIdApi",
  async (data: GetEmployeeDetailRequest, { getState, rejectWithValue }) => {
    try {
      const response = await companyMemberService.getMemberById(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getJobAuthour_CompanyMemberApi = createAsyncThunk(
  "companyMember/getJobAuthourCompanyMemberApi",
  async (data: GetEmployeeDetailRequest, { getState, rejectWithValue }) => {
    try {
      const response = await companyMemberService.getMemberById(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const removeMemberApi = createAsyncThunk(
  "companyMember/removeMemberApi",
  async (data: removeMemberReq, { getState, rejectWithValue }) => {
    try {
      const response = await companyMemberService.removeMember(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const uploadDocumentsApi = createAsyncThunk(
  "companyMember/uploadDocumentsApi",
  async (data: DocumentUpload, { getState, rejectWithValue }) => {
    try {
      const response = await companyMemberService.uploadDocument(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);
export const updateDocumentStatusApi = createAsyncThunk(
  "companyMember/updateDocumentStatusApi",
  async (data: DocumentUpload, { getState, rejectWithValue }) => {
    try {
      const response = await companyMemberService.updateDocumentStatus(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);
export const getAllDocummentApi = createAsyncThunk(
  "companyMember/getAllDocummentApi",
  async (data: DocumentUpload, { getState, rejectWithValue }) => {
    try {
      const response = await companyMemberService.getAllDocuments(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const updateAdditionalDetailApi = createAsyncThunk(
  "companyMember/updateAdditionalDetailApi",
  async (data: AdditionalDetails, { getState, rejectWithValue }) => {
    try {
      const response = await companyMemberService.updateAdditionalDetail(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const editAdditionalDetailApi = createAsyncThunk(
  "companyMember/editAdditionalDetailApi",
  async (data: AdditionalDetails, { getState, rejectWithValue }) => {
    try {
      const response = await companyMemberService.editAdditionalDetail(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const employeeSearchApi = createAsyncThunk(
  "companyMember/employeeSearchApi",
  async (data: getMemberReq, { getState, rejectWithValue }) => {
    try {
      const response = await companyMemberService.getMemberSearch(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const companyMemberSlice = createSlice({
  name: "companyMember",
  initialState,
  reducers: {
    logoutMember: (state, action) => {
      state.members = [];
      state.memberDetails = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updateAdditionalDetailApi.fulfilled, (state, action) => {
      state.memberDetails.emergencyDetails = action.payload.data.data;
    });
    builder.addCase(employeeSearchApi.fulfilled, (state, action) => {
      const response = action.payload.data.data;
      state.members = response.filter((mem) => mem.designation !== "Adviser");
      state.advisers = response.filter((mem) => mem.designation === "Adviser");
      //***************
    });
    builder.addCase(editAdditionalDetailApi.fulfilled, (state, action) => {
      state.memberDetails.emergencyDetails = action.payload.data.data;
    });
    builder.addCase(createMemberApi.fulfilled, (state, action) => {
      const response = action.payload.data.data;
      state.members.push(response);
    });
    builder.addCase(getAllDocummentApi.fulfilled, (state, action) => {
      state.documentList = action.payload?.data;
    });
    builder.addCase(uploadDocumentsApi.fulfilled, (state, action) => {
      state.documentList = [...state.documentList, action.payload?.data];
    });

    builder.addCase(getMembersApi.fulfilled, (state, action) => {
      const response = action.payload.data.data;
      state.members = response.filter((mem) => mem.designation !== "Adviser");
      state.advisers = response.filter((mem) => mem.designation === "Adviser");
    });

    builder.addCase(removeMemberApi.fulfilled, (state, action) => {
      const response = action.meta.arg;
      state.members = state.members.filter(
        (member) => member.id !== response.memberId
      );
    });

    builder.addCase(getMemberByIdApi.fulfilled, (state, action) => {
      const response = action.payload.data.data;
      state.memberDetails = response;
    });
    builder.addCase(
      getJobAuthour_CompanyMemberApi.fulfilled,
      (state, action) => {
        const response = action.payload.data.data;
        state.jobAuthorMemberDetails = response;
      }
    );

    builder.addCase(logout, (state) => {
      // Reset this slice's state to initial state
      return initialState;
    });
  },
});

export const { logoutMember } = companyMemberSlice.actions;
export default companyMemberSlice.reducer;
