import React from "react";
import { Link } from "react-router-dom";
import { baseUrl } from "../../constant";
import { useAppSelector } from "../../config/hooks";

const ClientCard = ({ clientName,domain,profileImage }) => {
    const {userId,token}=useAppSelector((state)=>state.login.session)
  
  return (
   
    <div className="bg-white shadow-md rounded-lg p-4 flex flex-col items-center text-center border border-gray-200 h-full">
      {profileImage?
                    (<img
                      src={`${baseUrl}/${profileImage}?t=${token}`}
                      alt="Profile Preview"
                      className="w-16 h-16 rounded-full  border-2 border-secondary-400"
                    />)
                    :
                    (<img
                      src={"https://pic.onlinewebfonts.com/thumbnails/icons_148020.svg"}
                      alt="Profile Preview"
                      className="w-16 h-16 rounded-full  "
                    />)
                     }
      <h3 className="text-lg font-semibold">{clientName}</h3>
      <h3 className="text-sm text-exgray-500">{domain}</h3>
      {/* <p className="text-sm text-gray-600">{openings} Openings</p> */}
      {/* <p className="text-sm text-gray-600">{candidatesInProgress} Candidate in progress</p> */}
    </div>

  );
};

export default ClientCard;
