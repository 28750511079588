import { useEffect, useState } from "react";
import FileIcon from "../../assets/Icons/FileIcon";
import { baseUrl } from "../../constant";
import moment from "moment";
import { useAppSelector } from "../../config/hooks";
import useSalesMultipleDropdown from "../../utils/dropdown-options/useSalesMultipleDropdown";
import useSalesDropdown from "../../utils/dropdown-options/useSalesDropdown";
import ClockIcon from "../../assets/Icons/ClockIcon";


const OnboardingSummary = ({data}) => {
 const {session}=useAppSelector((state)=>state.login)
 const {defaultSalesSubStages}= useSalesDropdown()

  return (
    <div className="bg-exwhite-100 border border-gray-300 rounded-lg p-6  mx-auto my-2">
      
      <div className="flex items-center gap-8 ">
        {/* <h1 className="text-lg  font-semibold">Lead Created</h1> */}
        {data?.subStages && <h3 className="px-5 py-1 text-sm text-secondary-400 border border-secondary-400 rounded-lg bg-secondary-400/10">{defaultSalesSubStages(data?.subStages)}</h3>}
      </div>
      <div className="flex justify-between items-center mt-3">
              <div className="text-secondary-500  font-semibold flex gap-2 items-center"><ClockIcon color="#095bb8" width="14" height="16" />{moment(data?.createdAt).format('D MMM YYYY')}</div>
              
              <div className="flex gap-2"> 
               {/* <h3 className="text-red-500 border border-red-200 px-4 rounded-lg bg-red-100">{"Email"}</h3> */}
                    {/* <VerticleThreeDotsIcon/> */}
              </div>
        </div>
        
        <div>
        <span className="text-exgray-400 ">Created by :</span>{" "}
        <span className="text-exgray-800 ">{data?.authorDetails?.firstName?`${data?.authorDetails?.firstName} ${data?.authorDetails?.lastName}`:`Super admin`}</span>
        </div>

        <div className="">
        <span className="text-exgray-400 ">Remarks :</span>{" "}
        <span className="text-exgray-800  "> <span className="">{data?.remarks}</span> </span>      
        </div>

        {/* Attached Document */}
      <div className="flex gap-1 flex-wrap mt-2">
       {data?.documents?.map((ele)=>{
        return (
          <a href={`${baseUrl}/${ele.documentURL}?t=${session.token}`}>
          <div className="flex  items-center  gap-4  w-fit bg-secondary-200 py-1 px-3 rounded-lg mb-2">
          <FileIcon/>
          <div>
            <span className="font-semibold text-primary-700">{ele.documentName}</span>
            <div className="text-gray-500 text-xs">{moment(data?.uploadedAt).format('D MMM YYYY')}</div>
          </div>
          {/* <div>
              <CrossIcon color="#095BB8" width="12"/>
              </div> */}
        </div>
        </a>
        )
      })     
     }
    </div>
        
    </div>
  );
};

export default OnboardingSummary;
