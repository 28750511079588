import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import companyService from "../services/company.service";
import jobService from "../services/job.service";
import { logout } from "./loginSlice";
import companyMemberService from "../services/companyMember.service";
import { act } from "react";
import { GetEmployeeDetailRequest } from "../models/companyMember.model";

const initialState = {
  companyDetails: null,
  companyDomain: "",
  companyEmail: "",
  jobAuthorCompanyDetails: null,
  createdJobs: {
    jobCards: null,
    isLoading: true,
    error: false,
  },
  memberDetails: null,
};

export const getCompanyDetail = createAsyncThunk(
  "company/getCompanyDetail",
  async (data, { getState, rejectWithValue, dispatch }) => {
    try {
      const response = await companyService.getCompanyDetail(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getJobAuthour_CompanyDetailsApi = createAsyncThunk(
  "company/getJobAuthourCompanyDetailsApi",
  async (data, { getState, rejectWithValue, dispatch }) => {
    try {
      const response = await companyService.getCompanyDetail(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const updateCompany = createAsyncThunk(
  "company/updateCompany",
  async (data, { getState, rejectWithValue }) => {
    try {
      const response = await companyService.updateCompany(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getCreatedJobsApi = createAsyncThunk(
  "company/getCreatedJobsApi",
  async (_data, { getState, rejectWithValue, dispatch }) => {
    try {
      const response = await jobService.getRecommendedJobs(_data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getNextCreatedCompanyJobsApi = createAsyncThunk(
  "company/getNextCreatedCompanyJobsApi",
  async (a, { getState, rejectWithValue, dispatch }) => {
    try {
      const state: any = getState();
      const response = await jobService.getJobByUrl(
        state?.company?.createdJobs?.jobCards?.metadata?.nextResultURL
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getMemberDetailsByIdApi = createAsyncThunk(
  "company/getMemberDetailsByIdApi",
  async (data: GetEmployeeDetailRequest, { getState, rejectWithValue }) => {
    try {
      const response = await companyMemberService.getMemberById(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const companySlice = createSlice({
  name: "company",
  initialState,
  reducers: {
    setComapnyDomain: (state, action) => {
      state.companyDomain = action.payload.companyDomain;
      state.companyEmail = action.payload.companyEmail;
    },
    logoutComapany: (state, action) => {
      state.companyDetails = null;
      state.companyDomain = "";
      state.companyEmail = "";
      state.createdJobs = {
        jobCards: null,
        isLoading: true,
        error: false,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCompanyDetail.fulfilled, (state, action) => {
      const response = action.payload.data.data;
      state.companyDetails = response;
    });

    builder.addCase(
      getJobAuthour_CompanyDetailsApi.fulfilled,
      (state, action) => {
        const response = action.payload.data.data;
        state.jobAuthorCompanyDetails = response;
      }
    );
    builder.addCase(getMemberDetailsByIdApi.fulfilled, (state, action) => {
      const response = action.payload.data.data;
      state.memberDetails = response;
    });

    builder.addCase(updateCompany.fulfilled, (state, action) => {
      const response = action.payload.data.data;
      state.companyDetails = response;
    });

    builder.addCase(getCreatedJobsApi.pending, (state, action) => {
      state.createdJobs.isLoading = true;
      state.createdJobs.error = false;
    });

    builder.addCase(getCreatedJobsApi.fulfilled, (state, action) => {
      const response = action.payload.data.data;
      state.createdJobs.jobCards = response;
      state.createdJobs.isLoading = false;
      state.createdJobs.error = false;
    });

    builder.addCase(getNextCreatedCompanyJobsApi.fulfilled, (state, action) => {
      const response = action.payload.data.data;
      if (response?.jobs && response?.jobs.length > 0) {
        state.createdJobs.jobCards.jobs =
          state.createdJobs.jobCards.jobs.concat(response.jobs);
      }
      state.createdJobs.jobCards.metadata = response.metadata;
    });

    builder.addCase(getCreatedJobsApi.rejected, (state, action) => {
      state.createdJobs.isLoading = false;
      state.createdJobs.error = true;
    });

    builder.addCase(logout, (state) => {
      // Reset this slice's state to initial state
      return initialState;
    });
  },
});

export const { setComapnyDomain, logoutComapany } = companySlice.actions;
export default companySlice.reducer;
