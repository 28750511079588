import React, { useEffect, useState } from "react";
import EditIcon from "../../assets/Icons/EditIcon";
import { useAppDispatch, useAppSelector } from "../../config/hooks";
import { getClientByIdApi, updateClientApi } from "../../slices/salesSlice";
import { useParams } from "react-router-dom";
import moment from "moment";
import Popup from "../../components/popup/Popup";
import EditClientDetailForm from "./EditClientDetailForm";
import useSalesDropdown from "../../utils/dropdown-options/useSalesDropdown";
import useSalesMultipleDropdown from "../../utils/dropdown-options/useSalesMultipleDropdown";
import { baseUrl } from "../../constant";

const LeadCard = () => {
  const dispatch = useAppDispatch();
  const { clientDetail } = useAppSelector((state) => state.sales);
  const [togglePopup, setTogglePopup] = useState(false);
  const [selectedStage, setSelectedStage] = useState<string>("");
  const { salesStages } = useAppSelector((state) => state.appConfig);
  const [defaultStage, setDefaultStage] = useState(clientDetail?.stages);
  const { userId, token } = useAppSelector((state) => state.login.session);

  const clientId = useParams();

  useEffect(() => {
    if (clientDetail) {
      setSelectedStage(clientDetail.stages || "");
    }
  }, [clientDetail]);

  useEffect(() => {
    dispatch(getClientByIdApi(clientId.clientId));
  }, [clientId, dispatch]);

  const { defaultSalesIndustry, defaultSalesSubIndustry } = useSalesDropdown();
  const { defaultSalesEngagementType } = useSalesMultipleDropdown();

  const togglePopupHandler = () => {
    setTogglePopup(!togglePopup);
  };

  const handleChange = (e) => {
    const newStage = e.target.value;
    setSelectedStage(newStage);
    // Dispatch API to update client details when stage changes
    dispatch(updateClientApi({ id: clientDetail.id, stages: newStage }));
  };
  
  
  return (
    <div className="flex flex-col">
      <div className="flex justify-between items-start">
        <div className="flex gap-4 items-start pb-4">
          {clientDetail?.profileImage ? (
            <img
              src={`${baseUrl}/${clientDetail?.profileImage}?t=${token}`}
              alt="Profile Preview"
              className="w-16 h-16 rounded-full  border-2 border-secondary-400"
            />
          ) : (
            <img
              src={"https://pic.onlinewebfonts.com/thumbnails/icons_148020.svg"}
              alt="Profile Preview"
              className="w-16 h-16 rounded-full  "
            />
          )}
          {/* <img src={"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRwm2crnRitxC5fh95sHn-NIvLHH1CyORvr9g&s"} alt="Image" className="w-16 h-16 rounded-full  border-2 border-secondary-400" /> */}
          <div>
            <h2 className="text-xl font-semibold text-secondary-600 ">
              {clientDetail?.clientName}
            </h2>
            <h3 className="text-exgray-300 ">{clientDetail?.domain}</h3>
          </div>
          <div
            onClick={togglePopupHandler}
            className="hover:scale-110 cursor-pointer"
          >
            <EditIcon />
          </div>
        </div>
        <div className="flex justify-center  items-center  bg-green-400/30 border border-green-600  rounded-full px-8 py-1 ">
            <label className="text-green-600 " htmlFor="stage-select">
              Stage:
            </label>
            <select className="py-2 rounded bg-green-300/10 text-green-600 font-semibold" value={selectedStage} onChange={handleChange}>
              <option value="" className="text-center border  ">Select Stage</option>
              
              {salesStages.map((stage, index) => {
                // console.log("Stage ID",stage.Id,clientDetail?.stages)
                return (
                <option 
                disabled={stage.Id<parseInt(clientDetail?.stages)} 
                className="text-center" key={index} value={stage.Id}  >
                  {stage.Title}
                </option>
              )})}
            </select>
        </div> 
      </div>

      <div className="  bg-white     min-w-96 py-4 px-3">
        <h1 className="text-exgray-800 mb-2">Basic Details : </h1>
        <div className="grid grid-cols-2 gap-3">
          <div>
            <span className="text-exgray-400 ">Location :</span>{" "}
            <span className="text-exgray-800 ">{clientDetail?.location}</span>
          </div>
          <div>
            <span className="text-exgray-400 ">Lead Created Date :</span>{" "}
            <span className="text-exgray-800 ">
              {moment(clientDetail?.createdAt).format(" Do, MMMM YYYY")}
            </span>
          </div>
          <div>
            <span className="text-exgray-400 ">Lead Owner :</span>{" "}
           {  (clientDetail?.leadOwner && clientDetail?.leadOwner.length>0) ? <span className="text-exgray-800 ">{clientDetail?.leadOwner[0]?.firstName} {clientDetail?.leadOwner[0]?.lastName}</span>:<span>N/A</span>}
          </div>
          <div>
            <span className="text-exgray-400 ">Industry :</span>{" "}
            <span className="text-exgray-800 ">
              {defaultSalesIndustry(clientDetail?.industry)}
            </span>
          </div>
          {/* <div>
                <span className="text-exgray-400 ">Sub Industry :</span>{" "}
                <span className="text-exgray-800 ">{defaultSalesSubIndustry(clientDetail?.subIndustry)}</span>
            </div> */}
          <div>
            <span className="text-exgray-400 ">Engagement Type:</span>{" "}
            <span className="text-exgray-800 ">
              {defaultSalesEngagementType(clientDetail?.engagementType)}
            </span>
          </div>
          <div>
            <span className="text-exgray-400 ">Location :</span>{" "}
            <span className="text-exgray-800 ">{clientDetail?.location}</span>
          </div>
        </div>

        <Popup
          visible={togglePopup}
          onClose={togglePopupHandler}
          children={
            <EditClientDetailForm togglePopupHandler={togglePopupHandler} />
          }
        />
      </div>
    </div>
  );
};
export default LeadCard;
