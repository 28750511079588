const ExTextarea = ({
  isMandatory = false,
  title,
  name,
  error,
  reference,
  borderWidth = "border",
  borderColor = "border-exgray-200",
  ...props
}) => {
  return (
    <div className="space-y-1">
      <label
        htmlFor={name}
        className={`text-exgray-200/70 text-base font-semibold`}
      >
        {title}
        {isMandatory && " *"}
      </label>
      <textarea
        className={`w-full h-auto p-3 bg-exwhite-100 rounded-md outline-none ${borderWidth} ${
          error ? "border-[#dc2623]" : borderColor
        } text-exgray-800 placeholder:text-gray-400 text-base no-scrollbar`}
        style={{ resize: "none" }}
        id={name}
        ref={reference}
        {...props}
      />
      {error && <p className="text-red-400">{error}</p>}
    </div>
  );
};

export default ExTextarea;
