import { useMemo } from "react";
import { Controller, useForm } from "react-hook-form";

import { unwrapResult } from "@reduxjs/toolkit";
import { toast } from "sonner";
import CrossIcon from "../../../assets/Icons/CrossIcon";
import CustomDatePicker from "../../../components/basic-components/CustomDatePicker";
import ExDropdown from "../../../components/basic-components/ExDropdown";
import ExInput from "../../../components/basic-components/ExInput";
import ExMultiDropdown from "../../../components/basic-components/ExMultiDropdown";
import { useAppDispatch, useAppSelector } from "../../../config/hooks";
import * as constants from "../../../constant";
import { member } from "../../../models/companyMember.model";
import { updateMembersApi } from "../../../slices/companyMemberSlice";
import useAccessRole from "../../../utils/dropdown-options/useAccessRole";
import useBand from "../../../utils/dropdown-options/useBand";
import useDepartment from "../../../utils/dropdown-options/useDepartment";
import useDesignation from "../../../utils/dropdown-options/useDesignation";
import useJobTypes from "../../../utils/dropdown-options/useJobTypes";
import useWorkPlaceTypes from "../../../utils/dropdown-options/useWorkPlaceTypes";
import useWorkLocation from "../../../utils/dropdown-options/useWorkLocation";
import { updateLeaveBalanceApi } from "../../../slices/leaveSlice";
import { EARNED_LEAVE } from "../../../constant";
//import ExMultiDropdown from "../../../components/basic-components/ExMultiDropdown";

const marriageStatus = [
  {
    id: 1,
    Title: "Married",
  },
  {
    id: 2,
    Title: "UnMarried",
  },
];

const nationalityArray = [
  {
    id: 1,
    Title: "Indian",
  },
  {
    id: 2,
    Title: "NRI",
  },
];

export interface UpdateEmployeeDetalProps {
  employeeDetail: member;
  onClose: Function;
}

const UpdateProfessionalDetail = ({
  onClose,
  employeeDetail,
}: UpdateEmployeeDetalProps) => {
  const dispatch = useAppDispatch();
  const userId = useAppSelector((state) => state?.login?.session?.userId);
  const loginSelector = useAppSelector((state) => state?.login?.session);

  const companySelector = useAppSelector(
    (state) => state.company.companyDetails
  );
  const memberSelector = useAppSelector(
    (state) => state?.member?.memberDetails
  );

  const gender = useAppSelector((state) => state?.appConfig?.genderList);

  const employementType = useAppSelector((state) => state?.appConfig?.jobTypes);

  const workplaceTypes = useAppSelector(
    (state) => state?.appConfig?.workplaceTypes
  );

  const { designationList, defaultDesignation } = useDesignation();
  const { departmentList, defaultDepertments, getDepartment } = useDepartment();
  const { roleList, defaultRole } = useAccessRole();
  const { bandList, defaultBand } = useBand();
  const { workplaceTypesArray, defaultWorkPlaceType } = useWorkPlaceTypes();
  const { jobTypesArray, defaultJobType } = useJobTypes();
  const { workLocationArray, defaultWorkLocation } = useWorkLocation();

  const members = useAppSelector((state) => state.member.members);
  const list = members.map((mem) => {
    return { Name: `${mem.firstName} ${mem.lastName}`, Id: mem.id };
  });

  const domain = useMemo(() => {
    if (
      loginSelector.who === constants.COMPANY_MEMBER.toLowerCase() &&
      memberSelector.emailId
    ) {
      const email = memberSelector.emailId.match(
        /@[A-Za-z0-9.-]+\.[A-Za-z]{2,}/
      );
      if (email.length > 0) {
        return email[0] ? email[0] : "";
      }
      return "";
    } else if (
      loginSelector.who === constants.COMPANY.toLowerCase() &&
      companySelector?.domain
    ) {
      return `@${companySelector.domain}`;
    }
    return "";
  }, [companySelector?.domain, loginSelector?.who, memberSelector?.emailId]);

  // const defaultReportingManager = (Id: string) => {
  //   const reportingManager = members.find((mem) => {
  //     mem.id == Id
  //   });
  //   return reportingManager;
  // }

  const form = useForm({
    defaultValues: {
      departments: defaultDepertments(employeeDetail.departments),
      designation: defaultRole(employeeDetail?.designation),
      jobProfile: defaultDesignation(employeeDetail.jobProfile),
      workLocation: defaultWorkLocation(employeeDetail.workLocation),
      workMode: defaultWorkPlaceType(employeeDetail.workMode),
      employmentType: defaultJobType(employeeDetail.employmentType),
      joiningDate: employeeDetail.joiningDate,
      band: defaultBand(employeeDetail.band),
      reportingManager: list.find(
        (l) => l.Id == employeeDetail.reportingManager
      ),
      employeeCode: employeeDetail.employeeCode,
      leaveCarryForward: null
    },
  });
  const { handleSubmit, control, formState, setValue } = form;
  const { errors } = formState;

  const onSubmit = async (formData) => {
    try {
      await dispatch(
        updateMembersApi({
          departments: getDepartment(formData.departments),
          designation: formData.designation?.Title,
          jobProfile: formData.jobProfile.Title,
          joiningDate: formData.joiningDate,
          band: formData.band.Title,
          workLocation: formData.workLocation.Title,
          workMode: formData.workMode.Title,
          employmentType: formData.employmentType?.Title,
          reportingManager: formData.reportingManager?.Id,
          memberId: employeeDetail.id,
          employeeCode: formData.employeeCode,
        })
      ).then(unwrapResult);
      toast.success("Professional details updated successfully");
      dispatch(updateLeaveBalanceApi({
        employeeId: employeeDetail.id,
        companyId: employeeDetail.companyId,
        leaveType: EARNED_LEAVE,
        totalLeaves: parseFloat(formData.leaveCarryForward),
        usedLeaves: 0,
        remainingLeaves: parseFloat(formData.leaveCarryForward)
      }))
    } catch (error) {
      toast.error("Something went wrong,please try again");
    }
    onClose();
  };

  return (
    <div className="px-10 py-6 w-[70%] mx-auto bg-white shadow-md rounded-lg overflow-y-auto no-scrollbar">
      <div className="mb-5">
        <div className="flex flex-row items-center justify-between mb-2">
          <h1 className="text-xl text-primary-400 font-medium mb-1">
            Update Employement Detail
          </h1>
          <div onClick={() => onClose()}>
            <CrossIcon />
          </div>
        </div>
      </div>

      <form onSubmit={handleSubmit(onSubmit)} className="space-y-2">
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
          <Controller
            name="departments"
            control={control}
            rules={{
              required: {
                value: true,
                message: "Gender is required",
              },
            }}
            render={({ field: { onChange, value } }) => (
              <ExMultiDropdown
                title="Department"
                placeholder="Select"
                onChange={onChange}
                value={value || null}
                optionValue="Id"
                optionLabel="Title"
                options={departmentList}
                dropDownHeight="40px"
                dropDownBorderWidth="1px"
                dropDownBorderColor="#8C8C8C"
                error={errors?.departments?.message}
                isMandatory={true}
              />
            )}
          />

          <Controller
            name="designation" //that is used for access role of employee recreuter
            control={control}
            rules={{
              required: {
                value: false,
                message: "Designation is required",
              },
            }}
            render={({ field: { onChange, value } }) => (
              <ExDropdown
                title="Access Role"
                placeholder="Select"
                onChange={onChange}
                value={value || null}
                optionValue="Id"
                optionLabel="Title"
                options={roleList}
                dropDownHeight="40px"
                dropDownBorderWidth="1px"
                dropDownBorderColor="#8C8C8C"
                error={errors?.designation?.message}
                isMandatory={true}
              />
            )}
          />
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
          <Controller
            name="jobProfile"
            control={control}
            rules={{
              required: {
                value: true,
                message: "Job Profile is required",
              },
            }}
            render={({ field: { onChange, value } }) => (
              <ExDropdown
                title="Designation" //this is used attribute for designatiion
                placeholder="Select"
                onChange={onChange}
                value={value || null}
                optionValue="Id"
                optionLabel="Title"
                options={designationList}
                dropDownHeight="40px"
                dropDownBorderWidth="1px"
                dropDownBorderColor="#8C8C8C"
                error={errors?.jobProfile?.message}
                isMandatory={true}
              />
            )}
          />
          <Controller
            name="workMode"
            control={control}
            rules={{
              required: {
                value: true,
                message: "Work Mode is required",
              },
            }}
            render={({ field: { onChange, value } }) => (
              <ExDropdown
                title="Work Mode"
                placeholder="Select"
                onChange={onChange}
                value={value || null}
                optionValue="Id"
                optionLabel="Title"
                options={workplaceTypes}
                dropDownHeight="40px"
                dropDownBorderWidth="1px"
                dropDownBorderColor="#8C8C8C"
                error={errors?.workMode?.message}
                isMandatory={true}
              />
            )}
          />
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
          <Controller
            name="joiningDate"
            control={control}
            rules={{
              required: {
                value: true,
                message: "Joining date is required",
              },
            }}
            render={({ field: { onChange, value } }) => (
              <CustomDatePicker
                title="Joining Date"
                name="joiningDate"
                onChange={onChange}
                selected={value ? value : null}
                //maxDate={new Date()}
                //minDate={new Date()}
                showYearDropdown={true}
                dropdownMode="select"
                placeholderText="Ex. mm/dd/yyyy"
                popperPlacement="bottom-end"
                dateFormat="dd/MM/yyyy"
                showMonthYearPicker={false}
                error={errors?.joiningDate?.message}
                isMandatory={true}
              />
            )}
          />
          <Controller
            name="employmentType"
            control={control}
            rules={{
              required: {
                value: true,
                message: "Employement type is required",
              },
            }}
            render={({ field: { onChange, value } }) => (
              <ExDropdown
                title="Employement Type"
                placeholder="Select"
                onChange={onChange}
                value={value || null}
                optionValue="Id"
                optionLabel="Title"
                options={employementType}
                dropDownHeight="40px"
                dropDownBorderWidth="1px"
                dropDownBorderColor="#8C8C8C"
                error={errors?.employmentType?.message}
                isMandatory={true}
              />
            )}
          />
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
          <Controller
            name="band"
            control={control}
            rules={{
              required: {
                value: true,
                message: "Employee Band is required",
              },
            }}
            render={({ field: { onChange, value } }) => (
              <ExDropdown
                title="Employee Band"
                placeholder="Select"
                onChange={onChange}
                value={value || null}
                optionValue="Id"
                optionLabel="Title"
                options={bandList}
                dropDownHeight="40px"
                dropDownBorderWidth="1px"
                dropDownBorderColor="#8C8C8C"
                error={errors?.band?.message}
                isMandatory={true}
              />
            )}
          />
          <Controller
            name="reportingManager"
            control={control}
            rules={{
              required: {
                value: true,
                message: "Reporting Manager is required",
              },
            }}
            render={({ field: { onChange, value } }) => (
              <ExDropdown
                title="Reporting Manager"
                placeholder="Select"
                onChange={onChange}
                value={value || null}
                optionValue="Id"
                optionLabel="Name"
                options={list.filter((l) => l.Id !== userId)}
                dropDownHeight="40px"
                dropDownBorderWidth="1px"
                dropDownBorderColor="#8C8C8C"
                error={errors?.band?.message}
                isMandatory={true}
              />
            )}
          />
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
          <Controller
            name="employeeCode"
            control={control}
            rules={{
              required: {
                value: true,
                message: "Employee Code is required",
              },
            }}
            render={({ field: { onChange, value } }) => (
              <ExInput
                title="Employee Code"
                placeholder="Type here..."
                name="employeeCode"
                onChange={onChange}
                value={value}
                error={errors?.employeeCode?.message}
                isMandatory={true}
              />
            )}
          />
          <Controller
            name="workLocation"
            control={control}
            rules={{
              required: {
                value: true,
                message: "Work location is required",
              },
            }}
            render={({ field: { onChange, value } }) => (
              <ExDropdown
                title="Work Location"
                placeholder="Select"
                onChange={onChange}
                value={value || null}
                optionValue="Id"
                optionLabel="Title"
                options={workLocationArray}
                dropDownHeight="40px"
                dropDownBorderWidth="1px"
                dropDownBorderColor="#8C8C8C"
                error={errors?.band?.message}
                isMandatory={true}
              />
            )}
          />
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
          <Controller
            name="leaveCarryForward"
            control={control}
            rules={{
              required: {
                value: false,
                message: "",
              },
              pattern: {
                value: /^[+-]?\d+(\.\d+)?$/,  // Regex to allow only numbers
                message: "Only numbers are allowed",
              },
            }}
            render={({ field: { onChange, value } }) => (
              <ExInput
                title="Leave Balance"
                placeholder="Type here..."
                name="leaveCarryForward"
                onChange={onChange}
                value={value}
                error={errors?.leaveCarryForward?.message}
                isMandatory={false}
                keyBoard
              />
            )}
          />
        </div>

        <div className="w-fit ml-auto mr-0 pt-3">
          <button
            type="submit"
            className="px-8 py-2 text-exwhite-100 text-base font-semibold text-center bg-expurple-800 rounded-md"
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default UpdateProfessionalDetail;
