import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../config/hooks";
import {
  addFilters,
  getAllClientsApi,
  getfilteredClientsApi,
  getNextClientsApi,
  resetFilters,
} from "../../slices/salesSlice";
import Popup from "../../components/popup/Popup";
import ClientForm from "./ClientForm";
import InfiniteScroll from "react-infinite-scroll-component";
import { toast } from "sonner";
import FilterClientForm from "./FilterClientForm";
import SalesBulkUpload from "./SalesBulkUpload";
import { Link, useLocation } from "react-router-dom";
import useSalesMultipleDropdown from "../../utils/dropdown-options/useSalesMultipleDropdown";
import SearchIcon from "../../assets/Icons/SearchIcon";
import FilterIcon from "../../assets/Icons/FilterIcon";
import moment from "moment";
import useSalesDropdown from "../../utils/dropdown-options/useSalesDropdown";
import CrossIcon from "../../assets/Icons/CrossIcon";
import useLocations from "../../utils/dropdown-options/useLocations";
import Tooltip from "../../components/tool-tip/Tooltip";
import ArrowIcon from "../../assets/Icons/ArrowIcon";


const statusColors: Record<string, string> = {
  Warm: "bg-yellow-100 text-yellow-600",
  Hot: "bg-red-100 text-red-600",
  Cold: "bg-blue-100 text-blue-600",
};
const LeadManagement = () => {
  const dispatch = useAppDispatch();
  const allClients = useAppSelector((state) => state.sales.clients);
  const { leadFilters } = useAppSelector((state) => state.sales);
  const {
    salesEngangementType,
    industries,
    salesStages,
    salesStatus,
    salesEngageWith,
  } = useAppSelector((state) => state.appConfig);
  const { clients } = allClients || {};
  const [search, setSearch] = useState("");
  const [togglePopup, setTogglePopup] = useState(false);
  const [toggleUploadPopup, setToggleUploadPopup] = useState(false);
  const [toggleFilterPopup, setToggleFilterPopup] = useState(false);
  const [filterStatus, setFilterStatus] = useState("");
  const [filteredClients, setFilteredClients] = useState(clients || []);
  const [currEng, setCurrEng] = useState("");
  const [currStage, setCurrStage] = useState("");
  const [visibleData,setVisibleData]=useState(false)
  const [visibleColumns, setVisibleColumns] = useState({
    // leadName: true,
    All:true,
    lead_Created: true,
    lead_Owner: true,
    engageWith: true,
    status: true,
    stages: true,
    contact: true,
    lead_Email: true,
    company_Website: true,
    revenue_Expectation: true,
    engagement_Type: true,
    industry: true,
    positions: true,
    location: true,
    remarks: true,
    
  });

  const { defaultSalesEngagementType } = useSalesMultipleDropdown();
  const {
    defaultRevenue,
    defaultEngageWith,
    defaultSalesIndustry,
    defaultStatus,
    defaultSalesStages,
    defaultSalesSubStages,
    defaultEngagementType,
  } = useSalesDropdown();

  const {defaultLocation,defaultLocations}=useLocations();

  useEffect(() => {
    dispatch(getAllClientsApi());
  }, []);

  useEffect(() => {
    setFilteredClients(clients); // Sync filtered clients when clients change in Redux
  }, [clients]);
  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value.toLowerCase();
    setSearch(value);

    const updatedClients = clients?.filter(
      (client) =>
        client.clientName.toLowerCase().includes(value) ||
        client.domain.toLowerCase().includes(value) ||
        client.location.toLowerCase().includes(value)
    );
    setFilteredClients(updatedClients);
  };

  useEffect(() => {
    dispatch(getfilteredClientsApi(leadFilters));
  }, [leadFilters]);
  // const handleFilter = (status: string) => {
  //   setFilterStatus(status);

  //   if (status) {
  //     const updatedClients = clients.filter(
  //       (client) => client.status.toLowerCase() === status.toLowerCase()
  //     );
  //     setFilteredClients(updatedClients);
  //   } else {
  //     setFilteredClients(clients);
  //   }
  // };

  const togglePopupHandler = () => {
    setTogglePopup(!togglePopup);
  };

  const toggleFIlterPopupHandler = () => {
    setToggleFilterPopup(!toggleFilterPopup);
  };

  const toggleUploadPopupHandler = () => {
    setToggleUploadPopup(!toggleUploadPopup);
  };

  const getMoreDataHandler = () => {
    try {
      dispatch(getNextClientsApi());
    } catch (error) {
      toast.error("Unable to fetch data");
    }
  };

  // const toggleColumn = (column) => {
  //   setVisibleColumns((prev) => ({
  //     ...prev,
  //     [column]: !prev[column],
  //   }));
  // };

  const toggleColumn = (col) => {
    if (col === "All") {
      const allSelected = Object.values(visibleColumns).every(Boolean);
      setVisibleColumns(Object.fromEntries(Object.keys(visibleColumns).map(key => [key, !allSelected])));
    } else {
      setVisibleColumns((prev) => ({
        ...prev,
        [col]: !prev[col],
      }));
    }
  };

  const nonNullValues = leadFilters
    ? Object.entries(leadFilters)
        .filter(([key, value]) => value !== "")
        .map(([key, value]) => {
          if (key === "industry") {
            return {
              key,
              value: `${defaultSalesIndustry(value)}`,
            };
          }
          if (key === "status") {
            return {
              key,
              value: `${defaultStatus(value)}`,
            };
          }
          if (key === "stages") {
            return {
              key,
              value: `${defaultSalesStages(value)}`,
            };
          }
          if (key === "subStages") {
            return {
              key,
              value: `${defaultSalesSubStages(value)}`,
            };
          }
          if (key === "engageWith") {
            return {
              key,
              value: `${defaultEngageWith(value)}`,
            };
          }
          if (key === "engagementType") {
            return {
              key,
              value: `${defaultEngagementType(value)}`,
            };
          }
          return { key, value };
        })
    : [];
  const handleRemove = (valueToRemove, key) => {
    const updatedObj = { ...leadFilters };
    updatedObj[key] = "";
    dispatch(addFilters(updatedObj));
  };

  const handleClearAll = () => {
    dispatch(resetFilters());
  };

  const handeSelectChange = (e) => {
    const selectedValue = e.target.value;
    setCurrEng(selectedValue);
    dispatch(getfilteredClientsApi({ engageWith: selectedValue }));
  };

  const handeCurrStage = (e) => {
    const selectedValue = e.target.value;
    setCurrStage(selectedValue);
    dispatch(getfilteredClientsApi({ stages: selectedValue }));
  };
  return (
    <div className="px-6 py-2 mx-auto  shadow-lg rounded-xl bg-white  ">
      <div className="py-2 flex justify-between items-center">
        <h1 className="text-xl font-semibold text-exgray-500">
          Lead Management
        </h1>
        <div className="flex gap-4">
          <button
            onClick={toggleUploadPopupHandler}
            className="border  border-primary-400 text-primary-400 px-4 py-2 font-semibold rounded-md shadow hover:scale-95"
          >
            Upload
          </button>
          <button
            onClick={togglePopupHandler}
            className=" bg-primary-400 text-white px-4 py-2 font-semibold rounded-md shadow hover:bg-primary-600 hover:scale-95"
          >
            <span className="">+</span> Add Lead
          </button>
        </div>
      </div>
      <div className="flex items-center justify-between mb-4 relative">
        <div className="flex gap-2">
          <input
            type="text"
            placeholder="Search Leads..."
            value={search}
            onChange={handleSearch}
            className="border border-gray-300 rounded-md pl-8 pr-4 py-2 w-72 focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          <div className="absolute top-2 pl-1">
            <SearchIcon />
          </div>
          {/* <select
                value={filterStatus}
                onChange={(e) => handleFilter(e.target.value)}
                className="border border-gray-300 rounded-md px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
              >
                <option value="">All Status</option>
                <option value="Hot">Hot</option>
                <option value="Warm">Warm</option>
                <option value="Cold">Cold</option>
              </select> */}
            
            <select
              name="stages"
              value={currStage}
              onChange={(e)=>handeCurrStage(e)}
              className="border border-gray-300 rounded-md p-2 w-24"
            >
              <option disabled value="">Stages</option>
              <option  value="">All Stages</option>
              {salesStages.map((stage, index) => (
                <option key={index} value={stage.Id}>
                  {stage.Title}
                </option>
              ))}
             
            </select>
            <select
              name="engageWith"
              value={currEng}
              onChange={(e)=>handeSelectChange(e)}
              className="border border-gray-300 rounded-md p-2 w-fit"
            >
              <option disabled value="">Engage With</option>
              <option  value="">All</option>
              {salesEngageWith.map((eng, index) => (
                <option key={index} value={eng.value}>
                  {eng.Title}
                </option>
              ))}
              
            </select>
        </div>

        <div className="flex gap-4">
         
         <button className="bg-gray-100 flex items-center gap-1 hover:bg-gray-200 rounded px-4" onClick={()=>setVisibleData(!visibleData)}>
            Section <span className={`${visibleData?"rotate-180 transition 0.5s ":""}`}><ArrowIcon/></span>
          </button>
          <button
            onClick={toggleFIlterPopupHandler}
            className="bg-gray-100 text-gray-600 px-4 py-2 rounded-md shadow hover:bg-gray-200 flex gap-2"
          >
            <FilterIcon />
            Filter
          </button>
          
        </div>
      </div>
     
     
      {visibleData && <div className="  grid grid-cols-5 items-center  bg-white shadow-md p-4">
        {/* <h3 className="text-lg font-semibold">Select Columns</h3> */}
        {Object.keys(visibleColumns).map((col) => (
          <label key={col} className="flex items-center gap-2 pr-2">
            <input
              type="checkbox"
              checked={visibleColumns[col]}
              onChange={() => toggleColumn(col)}
            />
            {col}
          </label>
        ))}
      </div>}


      {
        <div className="flex items-center gap-2 overflow-x-scroll no-scrollbar  px-2  pb-2 ">
          {nonNullValues.length > 1 && (
            <div>
              <h1
                onClick={handleClearAll}
                className="text-sm text-lxgray-200 hover:text-lxgray-100 hover:underline cursor-pointer"
              >
                ClearAll
              </h1>
            </div>
          )}
          {nonNullValues.map((item, index) => (
            <div
              key={index}
              className="flex items-center gap-2 border px-2 border-exgray-300 bg-secondary-300/30 rounded  "
            >
              <p className="flex-1  text-sm text-nowrap ">{item.value}</p>
              <button
                onClick={() => handleRemove(item.value, item.key)}
                className=" text-white rounded-full p-1   cursor-pointer hover:bg-primary-400/50"
              >
                <CrossIcon width="8" height="8" color="black" />
              </button>
            </div>
          ))}
        </div>
      }

      <div className=" overflow-auto">
        <InfiniteScroll
          className=""
          dataLength={clients?.length || 0}
          next={() => getMoreDataHandler()}
          hasMore={allClients?.metadata?.nextResultURL ? true : false}
          height={"calc(100vh - 155px)"}
          loader={""}
        >
          <div className="relative ">
            <table className="min-w-full border border-gray-200 text-sm text-left text-gray-500 ">
              <thead className="bg-primary-400 text-center   text-white sticky top-0 z-10">
                <tr>
                 <th className="px-6 py-2 border-r border-gray-400 text-nowrap">
                    S. no.
                  </th>
                   <th className="px-6 py-2 border-r border-gray-400 text-nowrap">
                    Lead Name
                  </th>
                  {visibleColumns.lead_Created && <th className="px-6 py-2 border-r border-gray-400 text-nowrap">
                    Lead Created
                  </th>}
                  {visibleColumns.lead_Owner && <th className="px-6 py-2 border-r border-gray-400 text-nowrap">
                    Lead Owner
                  </th>}
                  {visibleColumns.engageWith && <th className="px-6 py-2 border-r border-gray-400 text-nowrap">
                    Engage With
                  </th>}
                  {visibleColumns.status && <th className="px-6 py-2 border-r border-gray-400 text-nowrap">
                    Status
                  </th>}
                  {visibleColumns.stages && <th className="px-6 py-2 border-r border-gray-400 text-nowrap">
                    Stages
                  </th>}
                  {visibleColumns.contact && <th
                    colSpan={5}
                    className="px-6 py-2 border-r border-gray-400 text-nowrap"
                  >
                    Contact
                  </th>}
                  {visibleColumns.lead_Email && <th className="px-6 py-2 border-r border-gray-400 text-nowrap">
                    Lead Email
                  </th>}
                  {visibleColumns.company_Website && <th className="px-6 py-2 border-r border-gray-400 text-nowrap">
                    Company Website
                  </th>}
                  {visibleColumns.revenue_Expectation && <th colSpan={2} className="px-6 py-2 border-r border-gray-400 text-nowrap">
                    Revenue Expectation
                  </th>}
                  {visibleColumns.engagement_Type && <th className="px-6 py-2 border-r border-gray-400 text-nowrap">
                    Engangement Type
                  </th>}
                  {/* <th className="px-6 py-3 border-b">subStages</th> */}
                 { visibleColumns.industry && <th className="px-6 py-2 border-r border-gray-400 text-nowrap">
                    Industry
                  </th>}
                  { visibleColumns.positions && <th className="px-6 py-2 border-r border-gray-400 text-nowrap">
                    No. of Positions
                  </th>}

                  { visibleColumns.location && <th className="px-6 py-2 border-r border-gray-400 text-nowrap">
                    Location
                  </th>}
                 { visibleColumns.remarks && <th className="px-6 py-2  ">Remarks</th>}
                </tr>
                <tr className="">
                 { <th className="px-6  border-r border-gray-400"></th>}
                 { <th className="px-6  border-r border-gray-400"></th>}
                 { visibleColumns.lead_Created && <th className="px-6  border-r border-gray-400"></th>}
                 { visibleColumns.lead_Owner && <th className="px-6  border-r border-gray-400"></th>}
                 { visibleColumns.engageWith && <th className="px-6  border-r border-gray-400"></th>}
                 { visibleColumns.status && <th className="px-6  border-r border-gray-400"></th>}
                 { visibleColumns.stages && <th className="px-6  border-r border-gray-400"></th>}
                { visibleColumns.contact && <th className="px-6  pb-1 border border-gray-400">Person</th>}
                { visibleColumns.contact && <th className="px-6  border border-gray-400">Email</th>}
                 {visibleColumns.contact && <th className="px-6  border border-gray-400">Phone</th>}
                { visibleColumns.contact && <th className="px-6  border border-gray-400">Designation</th>}
                { visibleColumns.contact && <th className="px-6  border border-gray-400">Location</th>}
                  {visibleColumns.lead_Email && <th className="px-6  border-r border-gray-400"></th>}
                  {visibleColumns.company_Website && <th className="px-6  border-r border-gray-400"></th>}
                  {visibleColumns.revenue_Expectation && <th className="px-6  border border-gray-400">min</th>}
                  {visibleColumns.revenue_Expectation && <th className="px-6  border border-gray-400">max</th>}
                  {visibleColumns.engagement_Type && <th className="px-6  border-r border-gray-400"></th>}
                  {visibleColumns.industry && <th className="px-6  border-r border-gray-400"></th>}
                  {visibleColumns.positions && <th className="px-6  border-r border-gray-400"></th>}
                  {visibleColumns.location && <th className="px-6  border-r border-gray-400"></th>}
                  {visibleColumns.remarks && <th className="px-6  border-r border-gray-400"></th>}
                </tr>
              </thead>
              <tbody>
                {filteredClients?.map((client, index) => (
                  <tr
                    key={index}
                    className="bg-white hover:bg-gray-50 border-b text-gray-600 text-center "
                  >
                    <td className="px-6 py-3 border border-gray-200">
                      {index + 1}
                    </td>
                    <td className="px-6 py-3 border border-gray-200 hover:text-primary-500 hover:font-semibold">
                      <Link
                        className="w-full block"
                        to={`/company-admin/sales/detail/${client.id}`}
                      >
                        {client?.clientName}
                      </Link>
                    </td>
                    {visibleColumns.lead_Created && <td className="px-6 py-3 border border-gray-200">
                      {moment(client?.createdAt).format(" DD/MM/YYYY")}
                    </td>}
                    {visibleColumns.lead_Owner && <td className="px-6 py-3 border border-gray-200">{`${
                      client?.leadOwner && client?.leadOwner.length>0 ? client?.leadOwner[0]?.firstName : ""
                    }`}</td>}
                    {visibleColumns.engageWith && <td className="px-6 py-3 border border-gray-200">
                      {defaultEngageWith(client?.engageWith)}
                    </td>}
                   {visibleColumns.status &&  <td className="px-6 py-3 border border-gray-200 text-nowrap">
                      <span
                        className={`px-3 py-1 rounded-md font-semibold  ${
                          statusColors[client.status]
                        }`}
                      >
                        {
                          salesStatus.find(
                            (ele) => ele.Id.toString() === client?.status
                          )?.Title
                        }
                      </span>
                    </td>}

                    {visibleColumns.stages && <td className="px-6 py-3 border border-gray-200">
                      {
                        salesStages.find(
                          (ele) => ele.Id.toString() === client?.stages
                        )?.Title
                      }
                    </td>}
                    {/* <td className="px-6 py-3">{salesStages.find((ele)=>ele.Id.toString()===client?.subStages)?.Title}</td> */}

                    {visibleColumns.contact && <td className="px-6 py-3 border border-gray-200 text-nowrap">
                      {client?.primaryContact?.firstName}{" "}
                      {client?.primaryContact?.lastName}
                    </td>}
                    {visibleColumns.contact && <td className="px-6 py-3 border border-gray-200">
                      {client?.primaryContact?.email}
                    </td>}
                    {visibleColumns.contact && <td className="px-6 py-3 border border-gray-200">
                      {client?.primaryContact?.phoneNumber}
                    </td>}
                    {visibleColumns.contact && <td className="px-6 py-3 border border-gray-200 text-nowrap">
                      {client?.primaryContact?.designation}
                    </td>}
                    {visibleColumns.contact && <td className="px-6 py-3 border border-gray-200 text-nowrap">
                      {(client?.primaryContact?.location)}
                    </td>}

                    {visibleColumns.lead_Email && <td className="px-6 py-3 border border-gray-200">
                      {client?.clientEmail}
                    </td>}
                    {visibleColumns.company_Website && <td className="px-6 py-3 border border-gray-200">
                      {client?.domain}
                    </td>}
                    {visibleColumns.revenue_Expectation && <td className="px-6 py-3 border border-gray-200">
                      {defaultRevenue(client?.revenueExpectations.min)}
                    </td>}
                    {visibleColumns.revenue_Expectation && <td className="px-6 py-3 border border-gray-200">
                      {defaultRevenue(client?.revenueExpectations.max)}
                    </td>}
                   { visibleColumns.engagement_Type && <td className="px-6 py-3 border border-gray-200">
                      {defaultSalesEngagementType(client?.engagementType)}
                    </td>}

                   { visibleColumns.industry && <td className="px-6 py-3 border border-gray-200">
                      {
                        industries.find(
                          (ele) => ele.Id.toString() === client?.industry
                        )?.Name
                      }
                    </td>}

                    { visibleColumns.positions && <td className="px-6 py-3 border border-gray-200">
                      {client?.noOfPositions > 0 ? client?.noOfPositions : ""}
                    </td>}
                    {  visibleColumns.location && <td className="px-6 py-3 border border-gray-200">
                      {defaultLocation(client?.location)?.Name}
                    </td>}
                   { visibleColumns.remarks && <td className="px-6 py-3 border border-gray-200">
                    <Tooltip text={client?.remarks}>
                      <div className="max-w-36 truncate">{client?.remarks}</div>
                    </Tooltip>
                     
                    </td>}
                  </tr>
                ))}
              </tbody>
            </table>
            {(filteredClients?.length === 0 || clients == null) && (
              <div className="text-center py-4 text-gray-500">
                No Leads found.
              </div>
            )}
          </div>
        </InfiniteScroll>
      </div>
      <Popup
        visible={togglePopup}
        onClose={togglePopupHandler}
        children={<ClientForm togglePopupHandler={togglePopupHandler} />}
      />
      <Popup
        visible={toggleFilterPopup}
        onClose={toggleFIlterPopupHandler}
        children={
          <FilterClientForm togglePopupHandler={toggleFIlterPopupHandler} />
        }
      />
      <Popup
        visible={toggleUploadPopup}
        onClose={toggleUploadPopupHandler}
        children={
          <SalesBulkUpload togglePopupHandler={toggleUploadPopupHandler} />
        }
      />
    </div>
  );
};

export default LeadManagement;
