import React, { useRef, useState } from "react";
import { FilterClientData } from "../../models/sales.model"; // Import the interface
import { useAppDispatch, useAppSelector } from "../../config/hooks";
import Select from "react-select";
import { updateClientApi } from "../../slices/salesSlice";
import { toast } from "sonner";
import DatePicker from "react-datepicker";
import EditIcon from "../../assets/Icons/EditIcon";
import uploadContent from "../../utils/upload-content/uploadContent";
import { baseUrl } from "../../constant";

const EditClientDetailForm = ({ togglePopupHandler }) => {
  const dispatch = useAppDispatch();
  const { clientDetail } = useAppSelector((state) => state.sales);
  const {
    salesEngangementType,
    industries,
    salesStages,
    salesEngageWith,
    salesSubStages,
    salesStatus,
    countries,
    minSalaries,
    AgCTCRange
  } = useAppSelector((state) => state.appConfig);
  const { userId, token } = useAppSelector((state) => state.login.session);
  const imageInputRef = useRef(null);

  const [formData, setFormData] = useState<FilterClientData>({
    industry: clientDetail.industry || "",
    location: clientDetail.location || "",
    status: clientDetail.status || "",
    stages: clientDetail.stages || "",
    subStages: clientDetail.subStages || "",
    engageWith: clientDetail.engageWith || "",
    revenueExpectations: {
      min: clientDetail.revenueExpectations.min||0,
      max:  clientDetail.revenueExpectations.max||0,
    },
    engagementType: clientDetail.engagementType || "",
    noOfPositions: clientDetail.noOfPositions || 0,
    firstPositionReceivedDate: clientDetail.firstPositionReceivedDate || "",
    address: clientDetail.address.street || "",
    profileImage: clientDetail.profileImage || "",
  });

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    if (name === 'revenueExpectationsMin' || name === 'revenueExpectationsMax') {
      setFormData({
        ...formData,
        revenueExpectations: {
          ...formData.revenueExpectations,
          [name === 'revenueExpectationsMin' ? 'min' : 'max']:parseInt(value),
        }
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleSubmit = () => {
    formData.noOfPositions = formData.noOfPositions
      ? Number(formData.noOfPositions)
      : 0;

    formData.address = {
      street: formData.address.length > 1 ? formData.address : "",
      city: "",
      state: "",
      country: "",
      postalCode: "",
    };

    dispatch(updateClientApi({ id: clientDetail.id, ...formData }));
    togglePopupHandler();
    toast.success("Updated Successfully");
  };

  const handleIndustryChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.target;
    setFormData({
      ...formData,
      industry: value,
      // subIndustry: "", // Reset sub-industry when industry changes
    });
  };

  const handleMultiSelectChange = (selectedOptions) => {
    const engagementType = selectedOptions
      ? selectedOptions.map((option) => option.value)
      : [];
    setFormData({ ...formData, engagementType });
  };

  const handleDateChange = (date: Date) => {
    setFormData({
      ...formData,
      firstPositionReceivedDate: date,
    });
  };

  const handleProfileImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];

    if (file) {
      // Check if the file is an image
      if (file.type.startsWith("image/")) {
        uploadContent.uploadCRMSContent(userId, e.target.files, (response) => {
          setFormData({ ...formData, profileImage: response[0].url });
        });
      } else {
        alert("Please upload a valid image file.");
      }
    }
  };
  return (
    <div className="p-6 w-[70%] bg-white rounded-lg shadow-md  max-h-screenheight overflow-y-auto  ">
      <h2 className="text-lg font-semibold mb-4">Edit lead</h2>

      <div className="flex items-center justify-center">
        <input
          ref={imageInputRef}
          type="file"
          name="profileImage"
          accept="image/*"
          onChange={handleProfileImageChange}
          className="hidden border border-gray-300 rounded-md p-2 w-full"
        />
        {
          <div className="mt-2 relative">
            {formData.profileImage ? (
              <img
                src={`${baseUrl}/${formData?.profileImage}?t=${token}`}
                alt="Profile Preview"
                className="w-20 h-20 object-cover rounded-full"
              />
            ) : (
              <img
                src={
                  "https://pic.onlinewebfonts.com/thumbnails/icons_148020.svg"
                }
                alt="Profile Preview"
                className="w-20 h-20 object-cover rounded-full"
              />
            )}
            <div
              onClick={() => imageInputRef.current.click()}
              className=" absolute top-0 left-20"
            >
              <EditIcon />
            </div>
          </div>
        }
      </div>
      <div>
        <div className="grid grid-cols-1  gap-4">
          <div>
            <label htmlFor="">Lead Name</label>
            <input
              type="text"
              name="clientName"
              placeholder=""
              defaultValue={clientDetail?.clientName}
              // value={formData.clientName}
              disabled
              // onChange={handleChange}
              className="font-semibold border-gray-300 rounded-md p-2 w-full"
            />
          </div>
          <div>
            <label htmlFor="">Domain</label>
            <input
              type="text"
              name="domain"
              placeholder=""
              defaultValue={clientDetail?.domain}
              // value={formData.clientName}
              disabled
              // onChange={handleChange}
              className="font-semibold border-gray-300 rounded-md p-2 w-full"
            />
          </div>

          <div>
            <label htmlFor="">Stage</label>
            <select
              name="stages"
              disabled
              value={formData.stages}
              onChange={handleChange}
              className="font-semibold bg-secondary-200/30 border-gray-300 rounded-md p-2 w-full"
            >
              <option value="">Select Stage</option>
              {salesStages.map((stage, index) => (
                <option key={index} value={stage.Id}>
                  {stage.Title}
                </option>
              ))}
            </select>
            </div>

            <div>
            <label htmlFor="">Sub Stages</label>
            <select
              name="subStages"
              disabled
              value={formData.subStages}
              onChange={handleChange}
              className="font-semibold bg-secondary-200/30 border-gray-300 rounded-md p-2 w-full"
            >
              <option value="">Select Sub-Stage</option>
              {salesSubStages.filter((ele)=>ele.value==formData.stages).map((stage, index) => (
                <option key={index} value={stage.Id}>
                  {stage.Title}
                </option>
              ))}
            </select>
            </div>


            <div>
            <label htmlFor="">Status</label>
            <select
              name="status"
              disabled
              value={formData.status}
              onChange={handleChange}
              className="font-semibold bg-secondary-200/30 border-gray-300 rounded-md p-2 w-full"
            >
              <option value="">Select Status</option>
              {salesStatus.filter((ele)=>ele.value==formData.stages).map((status, index) => (
                <option key={index} value={status.Id}>
                  {status.Title}
                </option>
              ))}
            </select>
            </div>
    
            <div>
              <label htmlFor="">Industry</label>
              <select
                name="industry"
                value={formData.industry}
                onChange={handleIndustryChange}
                className="border border-gray-300 rounded-md p-2 w-full"
              >
                <option value="">Select Industry</option>
                {industries.map((industry, index) => (
                  <option key={index} value={industry.Id}>
                    {industry.Name}
                  </option>
                ))}
              </select>
            </div>
            {/* <div>
             <label htmlFor="">Sub Industry</label>
            <select
              name="subIndustry"
              value={formData.subIndustry}
              onChange={handleChange}
              className="border border-gray-300 rounded-md p-2 w-full"
              disabled={!formData.industry} // Disable if no industry selected
            >
              <option value="">Select Sub-Industry</option>
              {formData.industry &&
                industries.map((sub, index) => (
                  <option key={index} value={sub.Id}>
                    {sub.Title}
                  </option>
                ))}
            </select> 
            </div> */}

          <div>
            <label htmlFor="">Engagement With</label>
            <select
              name="engageWith"
              value={formData.engageWith}
              onChange={handleChange}
              className="border border-gray-300 rounded-md p-2 w-full"
            >
              <option value="">Engagement With</option>
              {salesEngageWith.map((eng, index) => (
                <option key={index} value={eng.value}>
                  {eng.Title}
                </option>
              ))}
            </select>
          </div>
          {/* <select
              name="engagementType"
              value={formData.engagementType}
              onChange={handleChange}
              className="border border-gray-300 rounded-md p-2 w-full"
            >
              <option value="">Select Engagement Type</option>
              {salesEngangementType.map((eng, index) => (
                <option key={index} value={eng.Title}>
                  {eng.Title}
                </option>
              ))}
            </select> */}
          <div>
            <label htmlFor="">Engagement Type</label>
            <select
              disabled={!formData.engageWith}
              name="engagementType"
              value={formData.engagementType}
              onChange={handleChange}
              className="border border-gray-300 rounded-md p-2 w-full"
            >
              <option value="">Select Engagement Type</option>
              {salesEngangementType
                .filter((ele) => ele.value == formData.engageWith)
                .map((eng, index) => (
                  <option key={index} value={eng.Id}>
                    {eng.Title}
                  </option>
                ))}
            </select>
            {/* <Select
              isMulti
              name="engagementType"
              options={salesEngangementType?.map((eng) => ({
                label: eng.Title,
                value: eng.Title,
              }))}
              className="basic-multi-select"
              placeholder="Select Engagement Type"
              classNamePrefix="select"
              value={formData.engagementType.map((id) => ({
                label:  salesEngangementType.find((eng) => eng.Id.toString() === id)?.Title,
                value: id,
              }))}
              onChange={handleMultiSelectChange}
            /> */}
          </div>

            
            

            <div>
            <label htmlFor="">Location</label>
            <input
              type="text"
              name="location"
              placeholder="Location"
              value={formData.location}
              onChange={handleChange}
              className="border border-gray-300 rounded-md p-2 w-full"
            />
          </div>

          {/* <div>
            <label htmlFor="">Revenue Expectations</label>
            <select
              name="revenueExpectations"
              value={formData.revenueExpectations}
              onChange={handleChange}
              className="border border-gray-300 rounded-md p-2 w-full"
            >
              <option value="">Select Revenue Expectation</option>
              {minSalaries.map((stage, index) => (
                <option key={index} value={stage.Id}>
                  {stage.Title}
                </option>
              ))}
            </select>
          </div> */}
          <div className="flex gap-2 items-center">
              <label className="text-nowrap " >Revenue Expectation :</label>
              <select
                name="revenueExpectationsMin"
                value={parseInt(formData.revenueExpectations.min)}
                onChange={handleChange}
                className="border border-gray-300 rounded-md p-2 w-fit"
              >
                <option value="">Min</option>
                {AgCTCRange.map((stage, index) => (
                  <option key={index} value={stage.value}>
                    {stage.Title}
                  </option>
                ))}
              </select>
                <select
                  name="revenueExpectationsMax"
                  value={formData.revenueExpectations.max}
                  onChange={handleChange}
                  className="border border-gray-300 rounded-md p-2 w-fit"
                >
                  <option value="">Max</option>
                  {AgCTCRange.map((stage, index) => (
                    <option key={index} value={stage.value}>
                      {stage.Title}
                    </option>
                  ))}
                </select>

            </div>

          <div>
            <label htmlFor="">No. of Positions</label>
            <input
              type="number"
              name="noOfPositions"
              placeholder="No. of Positions"
              value={formData.noOfPositions}
              onChange={handleChange}
              onWheel={(e) => e.target.blur()}
              className="border border-gray-300 rounded-md p-2 w-full"
            />
          </div>

          <div className="">
            <label className="">First Position Received Date </label>
            <DatePicker
              selected={formData.firstPositionReceivedDate}
              onChange={(date) => handleDateChange(date)}
              dateFormat="dd/MM/yyyy"
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-300"
            />
          </div>

          <div>
            <label htmlFor="">Address</label>
            <input
              type="text"
              name="address"
              placeholder="Address"
              value={formData.address}
              onChange={handleChange}
              className="border border-gray-300 rounded-md p-2 w-full"
            />
          </div>
        </div>
      </div>

      {/* Buttons */}
      <div className="flex justify-end gap-4 mt-4">
        <button
          onClick={handleSubmit}
          className="bg-primary-500 text-white px-8 py-2 rounded-md shadow hover:bg-primary-400"
        >
          Save
        </button>
      </div>
    </div>
  );
};

export default EditClientDetailForm;
