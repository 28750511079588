import React, { useRef, useState } from "react";
import CrossIcon from "../../assets/Icons/CrossIcon";
import { useAppDispatch, useAppSelector } from "../../config/hooks";
import { createClientApi } from "../../slices/salesSlice";
import Select from "react-select";
import { formatDate } from "../../utils/date.util";
import EditIcon from "../../assets/Icons/EditIcon";
import uploadContent from "../../utils/upload-content/uploadContent";
import { baseUrl } from "../../constant";
interface ClientFormData {
  clientName: string;
  clientEmail: string;
  domain: string;
  engageWith: string;
  engagementType: string;
  industry: string;
  location: string;
  stages: string;
  subStages: string;
  status: string;
  contactPersonFirstName: string;
  contactPersonLastName: string;
  contactPersonPhone: number;
  contactPersonEmail: string;
  contactPersonCountryCode: Number;
  contactPersondesignation: string;
  contactPersonLocation: string;
  revenueExpectations:  {
    min: Number,
    max: Number,
  },
  remarks: string;
  leadOwner: any[];
  noOfPositions: Number;
  profileImage: string;
  firstPositionReceivedDate: Date;
  createdBy: string;
}

const countryCodes = ["+1", "+91", "+44", "+61", "+81"];
const industries = ["IT", "Finance", "Healthcare", "Retail"]; // Example industries
const subIndustries = {
  IT: ["Software", "Hardware", "Networking"],
  Finance: ["Banking", "Insurance", "Investment"],
  Healthcare: ["Pharmaceuticals", "Hospitals", "Medical Devices"],
  Retail: ["E-commerce", "Supermarkets", "Luxury Goods"],
};

const ClientForm = ({ togglePopupHandler }) => {
  const dispatch = useAppDispatch();
  const {
    salesEngangementType,
    industries,
    salesStages,
    salesSubStages,
    salesStatus,
    countries,
    salesEngageWith,
    AgCTCRange
  } = useAppSelector((state) => state.appConfig);

  const { userId, token } = useAppSelector((state) => state.login.session);
  const imageInputRef = useRef(null);
  const [formData, setFormData] = useState<ClientFormData>({
    clientName: "",
    clientEmail: "",
    domain: "",
    location: "",
    contactPersonFirstName: "",
    contactPersonLastName: "",
    contactPersonPhone: 0,
    contactPersonEmail: "",
    contactPersonCountryCode: 91,
    contactPersondesignation: "",
    industry: "",
    engageWith: "",
    engagementType: "",
    revenueExpectations: {
      min: 0,
      max: 0
    },
    noOfPositions: "",
    createdBy: userId,
    profileImage: "",
  });

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    if (name === 'revenueExpectationsMin' || name === 'revenueExpectationsMax') {
      setFormData({
        ...formData,
        revenueExpectations: {
          ...formData.revenueExpectations,
          [name === 'revenueExpectationsMin' ? 'min' : 'max']: value,
        }
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleIndustryChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.target;
    setFormData({
      ...formData,
      industry: value,
      // subIndustry: "", // Reset sub-industry when industry changes
    });
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    //  formData.engagementType= []
    dispatch(
      createClientApi({
        clientName: formData.clientName,
        clientEmail: formData.clientEmail,
        domain: formData.domain,
        engageWith: formData.engageWith,
        engagementType: formData.engagementType,
        industry: formData.industry,
        location: formData.location,
        stages: salesStages[0].Id.toString(),
        subStages: salesSubStages[0].Id.toString(),
        status: salesStatus[0].Id.toString(),
        revenueExpectations:{
          min:parseInt(formData.revenueExpectations.min),
          max:parseInt(formData.revenueExpectations.max),
        } ,
        primaryContact: {
          firstName: formData.contactPersonFirstName,
          lastName: formData.contactPersonLastName,
          email: formData.contactPersonEmail,
          countryCode: formData.contactPersonCountryCode,
          phoneNumber: parseInt(formData.contactPersonPhone),
          designation: formData.contactPersondesignation,
          isPrimary: true,
          location: formData.contactPersonLocation,
        },
        noOfPositions: formData.noOfPositions
          ? parseInt(formData.noOfPositions)
          : 0,
        createdBy: userId,
      })
    );
    togglePopupHandler(false);
    // alert("Client created successfully!");
  };

  const handleMultiSelectChange = (selectedOptions) => {
    const engagementType = selectedOptions
      ? selectedOptions.map((option) => option.value)
      : [];
    setFormData({ ...formData, engagementType });
  };

  const handleProfileImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];

    if (file) {
      // Check if the file is an image
      if (file.type.startsWith("image/")) {
        uploadContent.uploadCRMSContent(userId, e.target.files, (response) => {
          setFormData({ ...formData, profileImage: response[0].url });
        });
      } else {
        alert("Please upload a valid image file.");
      }
    }
  };

  return (
    <div className="p-6 w-[70%]  mx-auto bg-white shadow-md rounded-lg max-h-screenheight  overflow-y-auto no-scrollbar">
      <div className="flex justify-between ">
        <h1 className="text-2xl font-bold mb-6">Create Lead</h1>
        <div
          onClick={() => togglePopupHandler(false)}
          className="hover:scale-90 hover:font-bold"
        >
          <CrossIcon color="black" />
        </div>
      </div>
      <form onSubmit={handleSubmit} className="space-y-4">
        {/* Profile Image Upload */}
        <div className="flex items-center justify-center">
          <input
            ref={imageInputRef}
            type="file"
            name="profileImage"
            accept="image/*"
            onChange={handleProfileImageChange}
            className="hidden border border-gray-300 rounded-md p-2 w-full"
          />
          {
            <div className="mt-2 relative">
              {formData.profileImage ? (
                <img
                  src={`${baseUrl}/${formData?.profileImage}?t=${token}`}
                  alt="Profile Preview"
                  className="w-20 h-20 object-cover rounded-full"
                />
              ) : (
                <img
                  src={
                    "https://pic.onlinewebfonts.com/thumbnails/icons_148020.svg"
                  }
                  alt="Profile Preview"
                  className="w-20 h-20 object-cover rounded-full"
                />
              )}
              <div
                onClick={() => imageInputRef.current.click()}
                className=" absolute top-0 left-20"
              >
                <EditIcon />
              </div>
            </div>
          }
        </div>

        {/* Client Details */}
        <div>
          <h2 className="text-lg font-semibold mb-2">Lead Details</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            <input
              type="text"
              name="clientName"
              placeholder="Lead Name *"
              value={formData.clientName}
              onChange={handleChange}
              className="border border-gray-300 rounded-md p-2 w-full"
              required
            />
            <input
              type="text"
              name="domain"
              placeholder="Lead Domain (Ex:- google.com) *"
              value={formData.domain}
              onChange={handleChange}
              className="border border-gray-300 rounded-md p-2 w-full"
              required
            />
            <input
              type="email"
              name="clientEmail"
              placeholder="Lead Email "
              value={formData.clientEmail}
              onChange={handleChange}
              className="border border-gray-300 rounded-md p-2 w-full"
            />
            <input
              type="text"
              name="location"
              placeholder="Location"
              value={formData.location}
              onChange={handleChange}
              className="border border-gray-300 rounded-md p-2 w-full"
            />
          </div>
        </div>

        {/* Contact Person Details */}
        <div>
          <h2 className="text-lg font-semibold mb-2">Contact Person Details</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            <input
              type="text"
              name="contactPersonFirstName"
              placeholder="First Name *"
              value={formData.contactPersonFirstName}
              onChange={handleChange}
              className="border border-gray-300 rounded-md p-2 w-full"
              required
            />
            <input
              type="text"
              name="contactPersonLastName"
              placeholder="Last Name *"
              value={formData.contactPersonLastName}
              onChange={handleChange}
              className="border border-gray-300 rounded-md p-2 w-full"
              required
            />

            {/* Country Code and Phone */}
            <div className="flex gap-2">
              <select
                name="contactPersonCountryCode"
                value={formData.contactPersonCountryCode}
                onChange={handleChange}
                className="border border-gray-300 rounded-md p-2 w-1/3"
              >
                <option value="">Country code</option>
                {countries.map((code, index) => (
                  <option key={index} value={Math.floor(code.PhoneCode)}>
                    {code.Iso2} +{code.PhoneCode}
                  </option>
                ))}
              </select>
              <input
                type="text"
                name="contactPersonPhone"
                placeholder="Phone"
                value={Math.floor(formData.contactPersonPhone)}
                minLength={10}
                onChange={(e) => {
                  const value = e.target.value;
                  // Allow only numeric values and ensure length is <= 10
                  if (/^\d*$/.test(value) && value.length <= 10) {
                    handleChange(e); // Update the state only if validation passes
                  }
                }}
                className="border border-gray-300 rounded-md p-2 w-2/3"
              />
            </div>

            <input
              type="email"
              name="contactPersonEmail"
              placeholder="Email *"
              value={formData.contactPersonEmail}
              onChange={handleChange}
              className="border border-gray-300 rounded-md p-2 w-full"
              required
            />

            <input
              type="text"
              name="contactPersondesignation"
              placeholder="Designation "
              value={formData.contactPersondesignation}
              onChange={handleChange}
              className="border border-gray-300 rounded-md p-2 w-full"
              
            />

            <input
              type="text"
              name="contactPersonLocation"
              placeholder="Location "
              value={formData.contactPersonLocation}
              onChange={handleChange}
              className="border border-gray-300 rounded-md p-2 w-full"
              
            />
          </div>
        </div>

        {/* Additional Details */}
        <div>
          <h2 className="text-lg font-semibold mb-2">Additional Details</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            {/* <select
              name="subIndustry"
              value={formData.subIndustry}
              onChange={handleChange}
              className="border border-gray-300 rounded-md p-2 w-full"
              disabled={!formData.industry} // Disable if no industry selected
            >
              <option value="">Select Sub-Industry</option>
              {formData.industry &&
                salesSubIndustry.map((sub, index) => (
                  <option key={index} value={sub.Id}>
                    {sub.Name}
                  </option>
                ))}
            </select> */}
            <select
              name="engageWith"
              value={formData.engageWith}
              onChange={handleChange}
              className="border border-gray-300 rounded-md p-2 w-full"
            >
              <option value="">Engagement With</option>
              {salesEngageWith.map((eng, index) => (
                <option key={index} value={eng.value}>
                  {eng.Title}
                </option>
              ))}
            </select>

            <select
              disabled={!formData.engageWith}
              name="engagementType"
              value={formData.engagementType}
              onChange={handleChange}
              className="border border-gray-300 rounded-md p-2 w-full"
            >
              <option value="">Select Engagement Type</option>
              {salesEngangementType
                .filter((ele) => ele.value == formData.engageWith)
                .map((eng, index) => (
                  <option key={index} value={eng.Id}>
                    {eng.Title}
                  </option>
                ))}
            </select>

            <select
              name="industry"
              value={formData.industry}
              onChange={handleIndustryChange}
              className="border border-gray-300 rounded-md p-2 w-full"
            >
              <option value="">Select Industry</option>
              {industries.map((industry, index) => (
                <option key={index} value={industry.Id}>
                  {industry.Name}
                </option>
              ))}
            </select>
            {/* <Select
              isMulti
              name="engagementType"
              options={salesEngangementType?.map((eng) => ({
                label: eng.Title,
                value: eng.Id.toString(),
              }))}
              className="basic-multi-select"
              placeholder="Select Engagement Type"
              classNamePrefix="select"
              value={formData.engagementType.map((id) => ({
                label: salesEngangementType.find((eng) => eng.Id.toString() === id).Title,
                value: id,
              }))}
              onChange={handleMultiSelectChange}
            /> */}

            {/* <select
              name="status"
              value={formData.status}
              onChange={handleChange}
              className="border border-gray-300 rounded-md p-2 w-full"
            >
              <option value="">Select Status</option>
              {salesStatus.map((status, index) => (
                <option key={index} value={status.Id}>
                  {status.Title}
                </option>
              ))}
            </select> */}
            {/* <select
              name="stages"
              value={formData.stages}
              onChange={handleChange}
              className="border border-gray-300 rounded-md p-2 w-full"
            >
              <option value="">Select Stage</option>
              {salesSubStages.map((stage, index) => (
                <option key={index} value={stage.Id}>
                  {stage.Title}
                </option>
              ))}
            </select> */}


            <input
              type="number"
              name="noOfPositions"
              placeholder="No. of Positions"
              value={formData.noOfPositions}
              onChange={handleChange}
              onWheel={(e) => e.target.blur()}
              className="border border-gray-300 rounded-md p-2 w-full"
            />
            <div className="flex gap-2 items-center">
              <label className="text-nowrap " >Revenue Expectation :</label>
              <select
                name="revenueExpectationsMin"
                value={formData.revenueExpectations.min}
                onChange={handleChange}
                className="border border-gray-300 rounded-md p-2 w-fit"
              >
                <option value="">Min</option>
                {AgCTCRange.map((stage, index) => (
                  <option key={index} value={stage.value}>
                    {stage.Title}
                  </option>
                ))}
              </select>
                <select
                  name="revenueExpectationsMax"
                  value={formData.revenueExpectations.max}
                  onChange={handleChange}
                  className="border border-gray-300 rounded-md p-2 w-fit"
                >
                  <option value="">Max</option>
                  {AgCTCRange.map((stage, index) => (
                    <option key={index} value={stage.value}>
                      {stage.Title}
                    </option>
                  ))}
                </select>

            </div>
           
            {/* <input
              type="text"
              name="revenueExpectations"
              placeholder="Revenue Expectation"
              value={formData.revenueExpectations}
              onChange={handleChange}
              className="border border-gray-300 rounded-md p-2 w-full"
            /> */}
           
          </div>
        </div>

        {/* Submit Button */}
        <div className="text-right">
          <button
            type="submit"
            className="bg-primary-500 text-white px-6 py-2 rounded-md shadow hover:bg-primary-400"
          >
            Create Lead
          </button>
        </div>
      </form>
    </div>
  );
};

export default ClientForm;
