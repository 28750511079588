import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as constants from "../../../constant";
import moment from "moment";
import { useAppDispatch, useAppSelector } from "../../../config/hooks";
import { getAllRegularizeRequestApi, updateRegularizationApi } from "../../../slices/attendanceSlice";
import { leaveRequestListApi, updateLeaveRequestApi } from "../../../slices/leaveSlice";
import Popup from "../../../components/popup/Popup";
import LeaveRequestForm from "../components/LeaveRequestForm";



const MyLeavequest = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [togglePopup, setTogglePopup] = useState(false);
  const userId = useAppSelector((state) => state?.login?.session?.userId);
  //const attendanceList = useAppSelector((state) => state?.attendance?);
  const [startDateSelected, setStartDateSelected] = useState(null);
  const loginSelector = useAppSelector((state) => state?.login?.session);
  const memberDetail = useAppSelector((state) => state.company.memberDetails); //that is login user details
  const leaveRequestList = useAppSelector((state) => state.leave.leaveRequestList);

  useEffect(() => {
    getMyAllLeaveReaquestList();
  }, []);

  const getMyAllLeaveReaquestList = () => {
    dispatch(
      leaveRequestListApi(
        {
          size: 100,
          employeeId: memberDetail?.id,
          // companyId: loginSelector.who === constants.COMPANY.toLowerCase()
          //   ? userId
          //   : memberDetail?.companyId,
        }
      )
    )
  }

  const togglePopupHandler = () => {
    setTogglePopup(!togglePopup);
  };

  const withDraLeaveRequest = (leaveId) => {
    dispatch(updateLeaveRequestApi({
      id: leaveId,
      status: "Withdraw",
    }))
  }

  return (
    <>
      <div className="mt-3">
        <div className="flex items-center justify-between">
          <h1></h1>
          <button onClick={() => togglePopupHandler()} className="text-base bg-secondary-500 text-exwhite-100 rounded-lg py-2 px-3 hover:bg-secondary-700">
            Apply Leave
          </button>
        </div>
        <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
          <table className="w-full mt-2 text-center rounded-sm">
            <thead className="py-3">
              <tr className="bg-secondary-800 rounded-lg">
                <th className="text-sm text-exwhite-100 border border-exgray-100 font-medium py-3">Application Date</th>
                <th className="text-sm text-exwhite-100 border border-exgray-100 font-medium py-3">Leave Type</th>
                <th className="text-sm text-exwhite-100 border border-exgray-100 font-medium py-3">From date</th>
                <th className="text-sm text-exwhite-100 border border-exgray-100 font-medium py-3">To date</th>
                <th className="text-sm text-exwhite-100 border border-exgray-100 font-medium py-3">Leave(days)</th>
                {/* <th className="text-sm text-exwhite-100 border border-exgray-100 font-medium py-3">Status</th> */}
                <th className="text-sm text-exwhite-100 border border-exgray-100 font-medium py-3">Action</th>
              </tr>
            </thead>
            <tbody className="items-center justify-center ">
              {
                leaveRequestList?.map((leave, index) => {
                  return (
                    <tr key={leave.id} className="items-center border border-exgray-100">
                      <td className="text-sm items-center border border-exgray-100 py-3">{moment(leave.createdAt).format('DD/MM/YYYY')}</td>
                      <td className="text-sm items-center border border-exgray-100 py-3">{leave.leaveType}</td>
                      {/* <td className="text-sm items-center py-3 text-secondary-500 font-medium">{attendance?.originalCheckIn}</td> */}
                      <td className="text-sm items-center border border-exgray-100 py-3">{moment(leave.startDate).format('DD/MM/YYYY')}</td>
                      <td className="text-sm items-center border border-exgray-100 py-3">{moment(leave.endDate).format('DD/MM/YYYY')}</td>
                      <td className="text-sm items-center border border-exgray-100 py-3">{leave.totalDays}</td>
                      {/* <td className="text-sm items-center border border-exgray-100 py-3">{leave.status}</td> */}
                      <td className="items-center">
                        {
                          leave.status == "Pending" ?
                            <button onClick={() => withDraLeaveRequest(leave.id)} className="bg-orange-400 px-2 py-1 mr-2 text-exwhite-100 rounded-lg text-xs">
                              Withdrawn
                            </button>
                            :
                            leave.status === "Approved" ?
                              <div className="flex items-center justify-center">
                                <h1 className={`${leave.status == "Approved" ? "text-green-600" : leave.status == "Rejected" ? "text-red-700" : "text-orange-500"} font-medium text-sm`}>
                                  {leave.status}
                                </h1>
                                <button onClick={() => withDraLeaveRequest(leave.id)} className="bg-orange-400 px-2 ml-2 py-1 text-exwhite-100 rounded-lg text-xs">
                                  Withdrawn
                                </button>
                              </div>

                              :
                              <h1 className={`${leave.status == "Approved" ? "text-green-600" : leave.status == "Rejected" ? "text-red-700" : "text-orange-500"} font-medium text-sm`}>
                                {leave.status}
                              </h1>

                          // <h1 className={`${leave.status == "Approved" ? "text-green-600" : leave.status == "Rejected" ? "text-red-700" : "text-orange-500"} font-medium text-sm`}>
                          //   {leave.status}
                          // </h1>
                        }

                        {
                          leave.status == "Rejected" &&
                          <p style={{ fontSize: 10 }} >{leave.rejectedReason}</p>
                        }

                      </td>
                    </tr>
                  )
                })
              }
            </tbody>
          </table>
        </div>

        <Popup onClose={togglePopupHandler} visible={togglePopup}>
          <LeaveRequestForm
            onClose={togglePopupHandler}
          />
        </Popup>

      </div >
    </>
  );
};

export default MyLeavequest;
