import { useForm } from "react-hook-form";
import { v4 as uuidv4 } from "uuid";

import useCurrencies from "../../../../utils/dropdown-options/useCurrencies";

import { useAppDispatch, useAppSelector } from "../../../../config/hooks";

import CandidateSearchCard from "../../../../components/candidate-card/CandidateSearchCard";
import CandidateSearchForm from "./CandidateSearchForm";
import FilterIcon from "../../../../assets/Icons/FilterIcon";
import { useEffect, useRef, useState } from "react";
import {
  clearSearchResultJobLevelReducer,
  clearSearchResultReducer,
  dataBaseSearchCandidatesApi,
  dataBaseSearchCandidatesJobLevelApi,
  dataBaseSearchRelevantCandidatesJobLevelApi,
  toggleSearchCriteriaJobLevel,
  toggleSearchCriteria,
  dataBaseSearchRelevantCandidatesApi,
} from "../../../../slices/aiSlice";
import { toast } from "sonner";
import InfiniteScroll from "react-infinite-scroll-component";
import NoDataFoundIcon from "../../../../assets/Icons/NoDataFoundIcon";
import { useParams } from "react-router-dom";
import CandidateSearchCardJobLevel from "../../../../components/candidate-card/CandidateSearchCardJobLevel";

const CandidateSearch = () => {
  const dispatch = useAppDispatch();

  const { jobId } = useParams();

  const menuRef = useRef(null);
  const menuParentRef = useRef(null);

  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const databaseSearchSelector = useAppSelector(
    (state) => state.ai.databaseSearch
  );
  const databaseSearchJobLevelSelector = useAppSelector(
    (state) => state.ai.databaseSearchJobLevel
  );
  const databaseRelevantSearchJobLevel = useAppSelector(
    (state) => state.ai.databaseRelevantSearchJobLevel
  );
  const databaseRelevantSearch = useAppSelector(
    (state) => state.ai.databaseRelevantSearch
  );

  const { mappedCandidates, metadata, searchedFields, isRelaventSearchOn } =
    databaseSearchSelector || {};

  const {
    mappedCandidatesJobLevel,
    metadataJobLevel,
    searchedFieldsJobLevel,
    isRelaventSearchOnJobLevel,
  } = databaseSearchJobLevelSelector || {};

  const { mappedRelevantCandidatesJobLevel, metadataRelevantJobLevel } =
    databaseRelevantSearchJobLevel || {};

  const { mappedRelevantCandidates, metadataRelevant } =
    databaseRelevantSearch || {};

  const { currenciesArray } = useCurrencies();

  const form = useForm({
    defaultValues: jobId
      ? {
          keywords: searchedFieldsJobLevel
            ? searchedFieldsJobLevel?.keywords
            : [],
          mandatoryKeywords: searchedFieldsJobLevel
            ? searchedFieldsJobLevel?.mandatoryKeywords
            : [],
          minExperience: searchedFieldsJobLevel
            ? searchedFieldsJobLevel?.minExperience
            : null,
          maxExperience: searchedFieldsJobLevel
            ? searchedFieldsJobLevel?.maxExperience
            : null,
          minSalary: searchedFieldsJobLevel
            ? searchedFieldsJobLevel?.minSalary
            : null,
          maxSalary: searchedFieldsJobLevel
            ? searchedFieldsJobLevel?.maxSalary
            : null,
          currency: searchedFieldsJobLevel
            ? searchedFieldsJobLevel?.currency
            : {
                Id: currenciesArray[0]?.Id,
                Title: currenciesArray[0]?.Title,
              },

          //employment details
          industry: searchedFieldsJobLevel
            ? searchedFieldsJobLevel?.industry
            : [],
          company: searchedFieldsJobLevel
            ? searchedFieldsJobLevel?.company
            : [],
          excludecompany: searchedFieldsJobLevel
            ? searchedFieldsJobLevel?.excludecompany
            : [],
          designation: searchedFieldsJobLevel
            ? searchedFieldsJobLevel?.designation
            : "",
          location: searchedFieldsJobLevel
            ? searchedFieldsJobLevel?.location
            : [],
          noticePeriodDuration: searchedFieldsJobLevel
            ? searchedFieldsJobLevel?.noticePeriodDuration
            : "",
          openForRelocation: searchedFieldsJobLevel
            ? searchedFieldsJobLevel?.openForRelocation
            : "No",

          //Qualification details
          qualification: searchedFieldsJobLevel
            ? searchedFieldsJobLevel?.qualification
            : [],
        }
      : {
          keywords: searchedFields ? searchedFields?.keywords : [],
          mandatoryKeywords: searchedFields
            ? searchedFields?.mandatoryKeywords
            : [],
          minExperience: searchedFields ? searchedFields?.minExperience : null,
          maxExperience: searchedFields ? searchedFields?.maxExperience : null,
          minSalary: searchedFields ? searchedFields?.minSalary : null,
          maxSalary: searchedFields ? searchedFields?.maxSalary : null,
          currency: searchedFields
            ? searchedFields?.currency
            : {
                Id: currenciesArray[0]?.Id,
                Title: currenciesArray[0]?.Title,
              },

          //employment details
          industry: searchedFields ? searchedFields?.industry : [],
          company: searchedFields ? searchedFields?.company : [],
          excludecompany: searchedFields ? searchedFields?.excludecompany : [],
          designation: searchedFields ? searchedFields?.designation : "",
          location: searchedFields ? searchedFields?.location : [],
          noticePeriodDuration: searchedFields
            ? searchedFields?.noticePeriodDuration
            : "",
          openForRelocation: searchedFields
            ? searchedFields?.openForRelocation
            : "No",

          //Qualification details
          qualification: searchedFields ? searchedFields?.qualification : [],
        },
  });

  const { reset } = form;

  useEffect(() => {
    const toggleHandler = (e) => {
      if (
        menuRef.current &&
        menuParentRef &&
        !menuRef.current.contains(e.target) &&
        !menuParentRef.current.contains(e.target)
      ) {
        // close popup
        setIsPopupOpen(false);
      }
    };
    document.addEventListener("mousedown", toggleHandler);

    return () => {
      document.removeEventListener("mousedown", toggleHandler);
    };
  });

  const getMoreResult = async () => {
    const formData = searchedFields;

    const toastId = toast.loading("Fetching Data . . .");
    try {
      await dispatch(
        dataBaseSearchCandidatesApi({
          keywords: formData.keywords,
          requiredKeywords: formData.mandatoryKeywords,
          minExperience: formData?.minExperience
            ? formData?.minExperience?.value
            : 0,
          maxExperience: formData?.maxExperience
            ? formData?.maxExperience?.value
            : 60,
          minSalary: formData?.minSalary ? formData?.minSalary?.value : 0,
          maxSalary: formData?.maxSalary ? formData?.maxSalary?.value : 300,
          candidateLocation:
            formData.location && formData.location.length > 0
              ? formData.location.map((loc) => loc?.Id.toString())
              : [],
          page:
            metadata?.next_page &&
            metadata?.current_page !== metadata?.total_pages
              ? metadata?.next_page
              : "",
          // per_page: 10,
          industry:
            formData?.industry && formData?.industry?.length > 0
              ? formData?.industry.map((ind) => ind.Id.toString())
              : [],
          designation: formData?.designation,
          preferredLocation: [],
          company:
            formData?.company && formData?.company?.length > 0
              ? formData?.company
              : [],
          excludeCompany:
            formData?.excludecompany && formData?.excludecompany?.length > 0
              ? formData?.excludecompany
              : [],
          relocation: formData?.openForRelocation === "Yes" ? true : false,
          noticePeriod: formData?.noticePeriodDuration
            ? formData?.noticePeriodDuration
            : "",
        })
      );
      toast.dismiss(toastId);
    } catch (error) {
      toast.dismiss(toastId);
      toast.error("Something went wrong!");
    }
  };

  const getMoreRelevantResult = async () => {
    const formData = searchedFields;

    const toastId = toast.loading("Fetching Data . . .");
    try {
      await dispatch(
        dataBaseSearchRelevantCandidatesApi({
          keywords: formData.keywords,
          requiredKeywords: formData.mandatoryKeywords,
          minExperience: formData?.minExperience
            ? formData?.minExperience?.value
            : 0,
          maxExperience: formData?.maxExperience
            ? formData?.maxExperience?.value
            : 60,
          minSalary: formData?.minSalary ? formData?.minSalary?.value : 0,
          maxSalary: formData?.maxSalary ? formData?.maxSalary?.value : 300,
          candidateLocation:
            formData.location && formData.location.length > 0
              ? formData.location.map((loc) => loc?.Id.toString())
              : [],
          page:
            metadataRelevant?.next_page &&
            metadataRelevant?.current_page !== metadataRelevant?.total_pages
              ? metadataRelevant?.next_page
              : "",
          // per_page: 10,
          industry:
            formData?.industry && formData?.industry?.length > 0
              ? formData?.industry.map((ind) => ind.Id.toString())
              : [],
          designation: formData?.designation,
          preferredLocation: [],
          company:
            formData?.company && formData?.company?.length > 0
              ? formData?.company
              : [],
          excludeCompany:
            formData?.excludecompany && formData?.excludecompany?.length > 0
              ? formData?.excludecompany
              : [],
          relocation: formData?.openForRelocation === "Yes" ? true : false,
          noticePeriod: formData?.noticePeriodDuration
            ? formData?.noticePeriodDuration
            : "",
        })
      );
      toast.dismiss(toastId);
    } catch (error) {
      toast.dismiss(toastId);
      toast.error("Something went wrong!");
    }
  };

  const getMoreResultJobLevel = async () => {
    const formData = searchedFieldsJobLevel;

    const toastId = toast.loading("Fetching Data . . .");
    try {
      await dispatch(
        dataBaseSearchCandidatesJobLevelApi({
          keywords: formData.keywords,
          requiredKeywords: formData.mandatoryKeywords,
          minExperience: formData?.minExperience
            ? formData?.minExperience?.value
            : 0,
          maxExperience: formData?.maxExperience
            ? formData?.maxExperience?.value
            : 60,
          minSalary: formData?.minSalary ? formData?.minSalary?.value : 0,
          maxSalary: formData?.maxSalary ? formData?.maxSalary?.value : 300,
          candidateLocation:
            formData.location && formData.location.length > 0
              ? formData.location.map((loc) => loc?.Id.toString())
              : [],
          page:
            metadataJobLevel?.next_page &&
            metadataJobLevel?.current_page !== metadataJobLevel?.total_pages
              ? metadataJobLevel?.next_page
              : "",
          // per_page: 10,
          industry:
            formData?.industry && formData?.industry?.length > 0
              ? formData?.industry.map((ind) => ind.Id.toString())
              : [],
          designation: formData?.designation,
          preferredLocation: [],
          company:
            formData?.company && formData?.company?.length > 0
              ? formData?.company
              : [],
          excludeCompany:
            formData?.excludecompany && formData?.excludecompany?.length > 0
              ? formData?.excludecompany
              : [],
          relocation: formData?.openForRelocation === "Yes" ? true : false,
          noticePeriod: formData?.noticePeriodDuration
            ? formData?.noticePeriodDuration
            : "",
        })
      );
      toast.dismiss(toastId);
    } catch (error) {
      toast.dismiss(toastId);
      toast.error("Something went wrong!");
    }
  };

  const getMoreRelevantResultJobLevel = async () => {
    const formData = searchedFieldsJobLevel;

    const toastId = toast.loading("Fetching Data . . .");
    try {
      await dispatch(
        dataBaseSearchRelevantCandidatesJobLevelApi({
          keywords: formData.keywords,
          requiredKeywords: formData.mandatoryKeywords,
          minExperience: formData?.minExperience
            ? formData?.minExperience?.value
            : 0,
          maxExperience: formData?.maxExperience
            ? formData?.maxExperience?.value
            : 60,
          minSalary: formData?.minSalary ? formData?.minSalary?.value : 0,
          maxSalary: formData?.maxSalary ? formData?.maxSalary?.value : 300,
          candidateLocation:
            formData.location && formData.location.length > 0
              ? formData.location.map((loc) => loc?.Id.toString())
              : [],
          page:
            metadataRelevantJobLevel?.next_page &&
            metadataRelevantJobLevel?.current_page !==
              metadataRelevantJobLevel?.total_pages
              ? metadataRelevantJobLevel?.next_page
              : "",
          // per_page: 10,
          industry:
            formData?.industry && formData?.industry?.length > 0
              ? formData?.industry.map((ind) => ind.Id.toString())
              : [],
          designation: formData?.designation,
          preferredLocation: [],
          company:
            formData?.company && formData?.company?.length > 0
              ? formData?.company
              : [],
          excludeCompany:
            formData?.excludecompany && formData?.excludecompany?.length > 0
              ? formData?.excludecompany
              : [],
          relocation: formData?.openForRelocation === "Yes" ? true : false,
          noticePeriod: formData?.noticePeriodDuration
            ? formData?.noticePeriodDuration
            : "",
        })
      );
      toast.dismiss(toastId);
    } catch (error) {
      toast.dismiss(toastId);
      toast.error("Something went wrong!");
    }
  };

  const toggleCandidateSearchFormHandler = () => {
    setIsPopupOpen(!isPopupOpen);
  };

  const clearResultHandler = () => {
    if (jobId) dispatch(clearSearchResultJobLevelReducer());
    else dispatch(clearSearchResultReducer());

    reset({
      keywords: [],
      mandatoryKeywords: [],
      minExperience: null,
      maxExperience: null,
      minSalary: null,
      maxSalary: null,
      currency: {
        Id: currenciesArray[0]?.Id,
        Title: currenciesArray[0]?.Title,
      },

      industry: [],
      company: [],
      excludecompany: [],
      designation: "",
      location: [],
      noticePeriodDuration: "",
      openForRelocation: "No",

      //Qualification details
      qualification: [],
    });
  };

  const toggleSwitchHandlerJobLevel = () => {
    dispatch(toggleSearchCriteriaJobLevel());
  };

  const toggleSwitchHandler = () => {
    dispatch(toggleSearchCriteria());
  };

  return jobId ? (
    <div
      className={` ${
        mappedCandidatesJobLevel && mappedCandidatesJobLevel.length > 0
          ? "bg-transparent"
          : "p-4 bg-exwhite-100"
      } min-h-screenheight rounded-sm space-y-2`}
    >
      <div className="flex items-center justify-between pr-4 relative">
        <div className="flex items-center justify-start gap-2">
          <h1 className="text-lg text-exgray-700 font-semibold px-2">
            Search Candidates
          </h1>
          <div>
            {searchedFieldsJobLevel && (
              <div>
                <div className="flex items-center justify-center gap-1">
                  <div
                    className={`h-5 w-10 p-1 ${
                      isRelaventSearchOnJobLevel
                        ? "bg-expurple-700"
                        : "bg-exgray-100"
                    } rounded-full flex items-center transition-all cursor-pointer ${
                      isRelaventSearchOnJobLevel
                        ? "justify-end"
                        : "justify-start"
                    }`}
                    onClick={toggleSwitchHandlerJobLevel}
                  >
                    <div className="h-[12px] w-[12px] rounded-full bg-exwhite-100 shadow-xl shadow-black"></div>
                  </div>
                  <h1 className="text-base font-semibold text-exgray-200/60 flex-1">
                    {isRelaventSearchOnJobLevel
                      ? "Relevant Match"
                      : "Exact Match"}
                  </h1>
                </div>
              </div>
            )}
          </div>
        </div>
        {((mappedCandidatesJobLevel && mappedCandidatesJobLevel.length > 0) ||
          metadataJobLevel?.current_page !== null) && (
          <div className="flex items-center justify-end gap-2">
            <div
              className="active:scale-95 cursor-pointer"
              onClick={toggleCandidateSearchFormHandler}
              ref={menuParentRef}
            >
              <FilterIcon />
            </div>
            <button
              className="py-1 px-6 border-2 border-primary-400 rounded-sm text-center text-primary-400 font-semibold text-base hover:bg-primary-400 hover:text-exwhite-100 active:scale-95"
              onClick={clearResultHandler}
            >
              Clear All
            </button>
          </div>
        )}

        {isPopupOpen && (
          <div
            className="absolute top-8 right-36 bg-exwhite-100 p-4 rounded-sm w-[80%]"
            style={{
              boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
            }}
            ref={menuRef}
          >
            <div className="max-h-popupScreenheight overflow-y-auto no-scrollbar">
              <CandidateSearchForm form={form} />
            </div>
          </div>
        )}
      </div>
      {!isRelaventSearchOnJobLevel ? (
        <div>
          {mappedCandidatesJobLevel && mappedCandidatesJobLevel.length > 0 ? (
            <InfiniteScroll
              className=""
              dataLength={
                mappedCandidatesJobLevel.length
                  ? mappedCandidatesJobLevel.length
                  : 0
              }
              next={getMoreResultJobLevel}
              hasMore={
                metadataJobLevel?.next_page &&
                metadataJobLevel?.current_page !== metadataJobLevel?.total_pages
                  ? metadataJobLevel?.next_page
                  : ""
              }
              height={"calc(100vh - 100px)"}
              loader={"Loading..."}
            >
              <div className="px-2 space-y-2">
                {mappedCandidatesJobLevel.map((candidate) => (
                  <CandidateSearchCardJobLevel
                    key={uuidv4()}
                    candidateDetails={candidate}
                  />
                ))}
              </div>
            </InfiniteScroll>
          ) : metadataJobLevel?.current_page === null ? (
            <CandidateSearchForm form={form} />
          ) : (
            <div className="w-full">
              <div className="mx-auto w-fit py-5">
                <div className="flex items-center justify-center">
                  <NoDataFoundIcon />
                </div>
                <div>
                  <h1 className="text-3xl text-expurple-800/50 font-semibold text-center">
                    No Data Found
                  </h1>
                  <p className="text-base text-exgray-200 font-medium text-center max-w-sm mx-auto">
                    Could not find any data in source list. Please try again.
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>
      ) : (
        // <div>Null</div>
        <div>
          {mappedRelevantCandidatesJobLevel &&
          mappedRelevantCandidatesJobLevel.length > 0 ? (
            <InfiniteScroll
              className=""
              dataLength={
                mappedRelevantCandidatesJobLevel.length
                  ? mappedRelevantCandidatesJobLevel.length
                  : 0
              }
              next={getMoreRelevantResultJobLevel}
              hasMore={
                metadataRelevantJobLevel?.next_page &&
                metadataRelevantJobLevel?.current_page !==
                  metadataRelevantJobLevel?.total_pages
                  ? metadataRelevantJobLevel?.next_page
                  : ""
              }
              height={"calc(100vh - 100px)"}
              loader={"Loading..."}
            >
              <div className="px-2 space-y-2">
                {mappedRelevantCandidatesJobLevel.map((candidate) => (
                  <CandidateSearchCardJobLevel
                    key={uuidv4()}
                    candidateDetails={candidate}
                  />
                ))}
              </div>
            </InfiniteScroll>
          ) : (
            <div className="w-full">
              <div className="mx-auto w-fit py-5">
                <div className="flex items-center justify-center">
                  <NoDataFoundIcon />
                </div>
                <div>
                  <h1 className="text-3xl text-expurple-800/50 font-semibold text-center">
                    No Data Found
                  </h1>
                  <p className="text-base text-exgray-200 font-medium text-center max-w-sm mx-auto">
                    Could not find any data in source list. Please try again.
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  ) : (
    <div
      className={` ${
        mappedCandidates && mappedCandidates.length > 0
          ? "bg-transparent"
          : "p-4 bg-exwhite-100"
      } min-h-screenheight rounded-sm space-y-2`}
    >
      <div className="flex items-center justify-between pr-4 relative">
        <div className="flex items-center justify-start gap-2">
          <h1 className="text-lg text-exgray-700 font-semibold px-2">
            Search Candidates
          </h1>
          <div>
            {searchedFields && (
              <div>
                <div className="flex items-center justify-center gap-1">
                  <div
                    className={`h-5 w-10 p-1 ${
                      isRelaventSearchOn ? "bg-expurple-700" : "bg-exgray-100"
                    } rounded-full flex items-center transition-all cursor-pointer ${
                      isRelaventSearchOn ? "justify-end" : "justify-start"
                    }`}
                    onClick={toggleSwitchHandler}
                  >
                    <div className="h-[12px] w-[12px] rounded-full bg-exwhite-100 shadow-xl shadow-black"></div>
                  </div>
                  <h1 className="text-base font-semibold text-exgray-200/60 flex-1">
                    {isRelaventSearchOn ? "Relevant Match" : "Exact Match"}
                  </h1>
                </div>
              </div>
            )}
          </div>
        </div>
        {((mappedCandidates && mappedCandidates.length > 0) ||
          metadata?.current_page !== null) && (
          <div className="flex items-center justify-end gap-2">
            <div
              className="active:scale-95 cursor-pointer"
              onClick={toggleCandidateSearchFormHandler}
              ref={menuParentRef}
            >
              <FilterIcon />
            </div>
            <button
              className="py-1 px-6 border-2 border-primary-400 rounded-sm text-center text-primary-400 font-semibold text-base hover:bg-primary-400 hover:text-exwhite-100 active:scale-95"
              onClick={clearResultHandler}
            >
              Clear All
            </button>
          </div>
        )}

        {isPopupOpen && (
          <div
            className="absolute top-8 right-36 bg-exwhite-100 p-4 rounded-sm w-[80%]"
            style={{
              boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
            }}
            ref={menuRef}
          >
            <div className="max-h-popupScreenheight overflow-y-auto no-scrollbar">
              <CandidateSearchForm form={form} />
            </div>
          </div>
        )}
      </div>
      {!isRelaventSearchOn ? (
        <div>
          {mappedCandidates && mappedCandidates.length > 0 ? (
            <InfiniteScroll
              className=""
              dataLength={mappedCandidates.length ? mappedCandidates.length : 0}
              next={getMoreResult}
              hasMore={
                metadata?.next_page &&
                metadata?.current_page !== metadata?.total_pages
                  ? metadata?.next_page
                  : ""
              }
              height={"calc(100vh - 125px)"}
              loader={"Loading..."}
            >
              <div className="px-2 space-y-2">
                {mappedCandidates.map((candidate) => (
                  <CandidateSearchCard
                    key={uuidv4()}
                    candidateDetails={candidate}
                  />
                ))}
              </div>
            </InfiniteScroll>
          ) : metadata?.current_page === null ? (
            <CandidateSearchForm form={form} />
          ) : (
            <div className="w-full">
              <div className="mx-auto w-fit py-5">
                <div className="flex items-center justify-center">
                  <NoDataFoundIcon />
                </div>
                <div>
                  <h1 className="text-3xl text-expurple-800/50 font-semibold text-center">
                    No Data Found
                  </h1>
                  <p className="text-base text-exgray-200 font-medium text-center max-w-sm mx-auto">
                    Could not find any data in source list. Please try again.
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div>
          {mappedRelevantCandidates && mappedRelevantCandidates.length > 0 ? (
            <InfiniteScroll
              className=""
              dataLength={
                mappedRelevantCandidates.length
                  ? mappedRelevantCandidates.length
                  : 0
              }
              next={getMoreRelevantResult}
              hasMore={
                metadataRelevant?.next_page &&
                metadataRelevant?.current_page !== metadataRelevant?.total_pages
                  ? metadataRelevant?.next_page
                  : ""
              }
              height={"calc(100vh - 125px)"}
              loader={"Loading..."}
            >
              <div className="px-2 space-y-2">
                {mappedRelevantCandidates.map((candidate) => (
                  <CandidateSearchCard
                    key={uuidv4()}
                    candidateDetails={candidate}
                  />
                ))}
              </div>
            </InfiniteScroll>
          ) : (
            <div className="w-full">
              <div className="mx-auto w-fit py-5">
                <div className="flex items-center justify-center">
                  <NoDataFoundIcon />
                </div>
                <div>
                  <h1 className="text-3xl text-expurple-800/50 font-semibold text-center">
                    No Data Found
                  </h1>
                  <p className="text-base text-exgray-200 font-medium text-center max-w-sm mx-auto">
                    Could not find any data in source list. Please try again.
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default CandidateSearch;
