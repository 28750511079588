import apiRecruiterClient from "../config/local-axios-config/recruiterApiConfig";
import apiClient from "../config/axiosConfig";
import { Attendance, AttendanceFilter, FilterDataForConsolidateView, GetDailyReport, Regularization, RegularizeFilter } from "../slices/attendanceSlice";
import { json } from "react-router-dom";
import { PostDailyReport } from "../models/employee.model";
import { ApplyLeaveRequest, GetLeaveBalanceApi, GetLeaveRequestFilter, UpdateLeaveBalance } from "../slices/leaveSlice";

class AttendanceService {
  postAttedance(req: Attendance) {
    return apiClient.post(`/v1/exo/attendance`, req);
  }

  getAllAttendance(req: AttendanceFilter) {
    return apiClient.get(`/v1/exo/attendance?`, {
      params: req
    });
  }

  getAllEmployeeAttendance(req: AttendanceFilter) {
    return apiClient.get(`/v1/exo/attendance?`, {
      params: req
    });
  }

  getAllRegularizeRequest(req: RegularizeFilter) {
    return apiClient.get(`/v1/exo/regularization?`, {
      params: req
    });
  }

  updateRegularization(req: RegularizeFilter) {
    return apiClient.put(`/v1/exo/regularization/${req.regularizationId}`,
      req
    );
  }

  updateOutTime(req: Attendance) {
    return apiClient.put(`/v1/exo/attendance/${req.attendanceId}`, req);
  }

  postDailyReport(req: PostDailyReport) {
    return apiClient.post(`/v1/exo/daily-reports`, req);
  }

  getDailyReport(
    data: GetDailyReport
  ) {
    return apiClient.get(`/v1/exo/daily-reports?employeeId=${data.employeeId}`);
  }

  regularizeRequest(
    data: Regularization
  ) {
    return apiClient.post(`/v1/exo/regularization`, data);
  }

  getTimesheet(
    data: AttendanceFilter
  ) {
    return apiClient.get(`/v1/exo/timesheets?`, {
      params: data
    });
  }

  getConsolidateView(
    data: FilterDataForConsolidateView
  ) {
    return apiClient.get(`/v1/exo/consolidated-view?`, {
      params: data
    });
  }

  getLeaveRequest(data: GetLeaveRequestFilter) {
    return apiClient.get(`/v1/exo/leaves?`, {
      params: data
    });
  }

  applyLeave(data: ApplyLeaveRequest) {
    return apiClient.post(`/v1/exo/leaves`,
      data
    );
  }

  updateLeaveRequest(data: ApplyLeaveRequest) {
    return apiClient.put(`/v1/exo/leaves/${data.id}`,
      data
    );
  }

  updateLeaveBalance(data: UpdateLeaveBalance) {
    return apiClient.post(`/v1/exo/leaves/balance`,
      data
    );
  }

  getLeaveBalance(data: GetLeaveBalanceApi) {
    return apiClient.get(`/v1/exo/leaves/balance?`, {
      params: data
    });
  }



}

const attendanceService = new AttendanceService();
export default attendanceService;
