import { Controller, useFieldArray, useForm } from "react-hook-form";
import CustomDatePicker from "../../../components/basic-components/CustomDatePicker";
import ExDropdown from "../../../components/basic-components/ExDropdown";
import useIndustries from "../../../utils/dropdown-options/useIndustries";
import ExInput from "../../../components/basic-components/ExInput";
import PlusCircleIcon from "../../../assets/Icons/PlusCircleIcon";
import ExTextarea from "../../../components/basic-components/ExTextarea";
import DeleteIcon from "../../../assets/Icons/DeleteIcon";
import { useRef, useState } from "react";
import uploadContent from "../../../utils/upload-content/uploadContent";
import AttachmentIcon from "../../../assets/Icons/AttachmentIcon";
import { toast } from "sonner";
import Image from "../../../components/Image";
import CrossIcon from "../../../assets/Icons/CrossIcon";

type Riembersment = {
  type: any;
  amount: string;
  date: Date;
  description: string;
  attachment: any;
};

type FormValues = {
  riembersment: Array<Riembersment>;
  assignTo: any;
};

const ReimbursementForm = () => {
  const inputRef = useRef(null);
  const btnRef = useRef(null);

  const { industriesArray, defaultIndustry } = useIndustries();

  const [isLoading, setIsLoading] = useState(false);

  const form = useForm<FormValues>({
    defaultValues: {
      riembersment: [
        {
          type: null,
          amount: "",
          date: null,
          description: "",
          attachment: null,
        },
      ],
      assignTo: null,
    },
  });

  const { handleSubmit, control, formState, setValue } = form;
  const { errors } = formState;

  const { fields, append, remove } = useFieldArray({
    name: "riembersment",
    control,
  });

  const addMoreReuiembersmentHandler = () => {
    append({
      type: null,
      amount: "",
      date: null,
      description: "",
      attachment: null,
    });
  };

  const removeMoreReuiembersmentHandler = (index: number) => {
    remove(index);
  };

  const triggerInputfileHanlder = () => {
    if (inputRef.current) inputRef.current.click();
  };

  const submitButtonHandler = () => {
    if (btnRef?.current) btnRef.current.click();
  };

  const removeAttachment = (index: number) => {
    setValue(`riembersment.${index}.attachment`, null, {
      shouldValidate: true,
    });
  };

  const onSubmit = (formData) => {
    console.log(formData);
  };

  return (
    <div className="bg-exwhite-100 rounded-md p-4 w-[45%]">
      <h1 className="text-xl text-primary-700 font-semibold">
        Reimbursement Form
      </h1>
      <form
        className="max-h-popupScreenheight overflow-y-auto no-scrollbar"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="mt-4 flex items-center justify-start gap-4">
          <div className={`${errors?.assignTo?.message ? "pb-7" : ""}`}>
            <h1 className="text-exgray-200/70 text-base font-semibold flex-1">
              Approver Name
            </h1>
          </div>

          <div className="w-[40%]">
            <Controller
              name={`assignTo`}
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "Required",
                },
              }}
              render={({ field: { onChange, value } }) => (
                <ExDropdown
                  title=""
                  onChange={onChange}
                  placeholder="Select"
                  value={value || null}
                  optionValue="Id"
                  optionLabel="Name"
                  options={industriesArray}
                  dropDownHeight="40px"
                  isMandatory={true}
                  dropDownBorderWidth="2px"
                  dropDownBorderColor="#CDCED2"
                  error={errors?.assignTo?.message}
                />
              )}
            />
          </div>
        </div>
        <div className="space-y-2">
          <h1 className="text-base text-primary-400 font-semibold">
            Expense Details
          </h1>

          <div className="space-y-2">
            {fields?.map((field, index) => {
              return (
                <div key={field.id}>
                  <div className="border-2 border-secondary-300/50 rounded-t-md p-2">
                    <div className="flex items-start justify-center gap-2">
                      <div className="flex-1" key={field.id}>
                        <Controller
                          name={`riembersment.${index}.date`}
                          control={control}
                          rules={{
                            required: {
                              value: true,
                              message: "Date is required",
                            },
                          }}
                          render={({ field: { onChange, value } }) => (
                            <CustomDatePicker
                              title="Date"
                              name="startDate"
                              onChange={onChange}
                              selected={value ? new Date(value) : null}
                              maxDate={new Date()}
                              placeholderText="Ex. dd/mm/yy"
                              isMandatory={true}
                              popperPlacement="bottom-end"
                              dateFormat="dd/MM/yyyy"
                              borderWidth="border-2"
                              borderColor="border-exgray-100"
                              error={
                                errors?.riembersment?.[index]?.date?.message
                              }
                            />
                          )}
                        />
                      </div>
                      <div className="flex-1">
                        <Controller
                          name={`riembersment.${index}.type`}
                          control={control}
                          rules={{
                            required: {
                              value: true,
                              message: "Required",
                            },
                          }}
                          render={({ field: { onChange, value } }) => (
                            <ExDropdown
                              title="Expense Type"
                              onChange={onChange}
                              placeholder="Select"
                              value={value || null}
                              optionValue="Id"
                              optionLabel="Name"
                              options={industriesArray}
                              dropDownHeight="40px"
                              isMandatory={true}
                              dropDownBorderWidth="2px"
                              dropDownBorderColor="#CDCED2"
                              menuListHeight="150px"
                              error={
                                errors?.riembersment?.[index]?.date?.message
                              }
                            />
                          )}
                        />
                      </div>
                      <div className="flex-1">
                        <Controller
                          name={`riembersment.${index}.amount`}
                          control={control}
                          rules={{
                            required: {
                              value: true,
                              message: "Required",
                            },
                          }}
                          render={({ field: { onChange, value } }) => (
                            <ExInput
                              title="Amount (₹)"
                              placeholder="Ex. 5"
                              type="number"
                              onWheel={(e) => e.target.blur()}
                              isMandatory={true}
                              name={`riembersment.${index}.amount`}
                              onChange={onChange}
                              value={value || ""}
                              borderWidth="border-2"
                              borderColor="border-exgray-100"
                              error={
                                errors?.riembersment?.[index]?.amount?.message
                              }
                            />
                          )}
                        />
                      </div>
                    </div>

                    <div>
                      <Controller
                        name={`riembersment.${index}.description`}
                        control={control}
                        rules={{
                          required: {
                            value: true,
                            message: "Description is required",
                          },
                          maxLength: {
                            value: 2600,
                            message: "Only 2600 characters are allowed",
                          },
                        }}
                        render={({ field: { onChange, value } }) => (
                          <ExTextarea
                            rows={4}
                            name={`riembersment.${index}.description`}
                            value={value || ""}
                            title="Description *"
                            maxLength={2600}
                            onChange={onChange}
                            reference={null}
                            borderWidth="border-2"
                            borderColor="border-exgray-100"
                            placeholder="This information will be seen by the candidate in job discription."
                            error={
                              errors?.riembersment?.[index]?.description
                                ?.message
                            }
                          />
                        )}
                      />
                    </div>

                    <div>
                      <Controller
                        name={`riembersment.${index}.attachment`}
                        control={control}
                        rules={{
                          required: {
                            value: true,
                            message: "Required",
                          },
                        }}
                        render={({ field: { onChange, value } }) => (
                          <div>
                            <div className="hidden">
                              <input
                                placeholder="Browse file"
                                name={`riembersment.${index}.attachment`}
                                id={`riembersment.${index}.attachment`}
                                type="file"
                                ref={inputRef}
                                accept="image/*"
                                onChange={(e) => {
                                  setIsLoading(true);
                                  const toasId = toast.loading("Loading...");
                                  uploadContent.uploadPandCard(
                                    e.target.files,
                                    (response) => {
                                      setIsLoading(false);
                                      toast.dismiss(toasId);
                                      if (!response[0].error) {
                                        onChange({
                                          name: response[0].orginalFileName,
                                          url: response[0].url,
                                          contentType: response[0].contentType,
                                          size: response[0].size,
                                        });
                                        toast.success(
                                          `${response[0].orginalFileName} Uploaded successfully`
                                        );
                                      } else {
                                        toast.error("Something Went Wrong");
                                      }
                                    }
                                  );
                                }}
                                // value={value}
                              />
                            </div>
                            {!value && (
                              <div className="flex items-center justify-end gap-2">
                                <div
                                  className="cursor-pointer"
                                  onClick={triggerInputfileHanlder}
                                >
                                  <AttachmentIcon
                                    width="20"
                                    height="20"
                                    color={
                                      errors?.riembersment?.[index]?.attachment
                                        ?.message
                                        ? "#e7000b"
                                        : "#8C8C8C"
                                    }
                                  />
                                </div>
                              </div>
                            )}

                            {value?.url && (
                              <div className="flex items-start justify-start gap-2">
                                <div className="w-20 h-20 relative">
                                  <Image
                                    url={value?.url}
                                    alt="recipt"
                                    borderRadius="2px"
                                  />
                                  <div
                                    className="absolute top-1 left-1 flex items-center justify-center bg-exwhite-100 rounded-full p-1 cursor-pointer"
                                    onClick={() => removeAttachment(index)}
                                  >
                                    <CrossIcon width="10" height="10" />
                                  </div>
                                </div>
                                <h1 className="text-base text-primary-400 font-semibold">
                                  {value?.name}
                                </h1>
                              </div>
                            )}
                          </div>
                        )}
                      />
                    </div>
                  </div>

                  <div className="bg-secondary-300/50 rounded-b-md">
                    <div className="w-fit ml-auto mr-2 py-2">
                      {fields?.length === index + 1 ? (
                        <div className="flex items-center justify-center gap-2">
                          <button
                            className="text-base font-semibold text-secondary-400 flex items-center justify-center gap-1"
                            type="button"
                            onClick={addMoreReuiembersmentHandler}
                          >
                            Add <PlusCircleIcon width="20" height="20" />
                          </button>

                          {index !== 0 && (
                            <button
                              className="text-base font-semibold text-secondary-400 flex items-center justify-center gap-1 group"
                              type="button"
                              onClick={() =>
                                removeMoreReuiembersmentHandler(index)
                              }
                            >
                              <DeleteIcon
                                width="18"
                                height="22"
                                className="group-hover:fill-red-400"
                              />
                            </button>
                          )}
                        </div>
                      ) : (
                        <button
                          className="text-base font-semibold text-secondary-400 flex items-center justify-center gap-1 group"
                          type="button"
                          onClick={() => removeMoreReuiembersmentHandler(index)}
                        >
                          <DeleteIcon
                            width="18"
                            height="22"
                            className="group-hover:fill-red-400"
                          />
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        <button className="hidden" ref={btnRef}>
          Apply
        </button>
      </form>

      <div>
        <div className="w-fit ml-auto mr-0 pt-2">
          <button
            className="py-2 px-8 bg-secondary-400 text-exwhite-100 text-center font-semibold rounded-md"
            onClick={submitButtonHandler}
          >
            Request Reimbursement
          </button>
        </div>
      </div>
    </div>
  );
};

export default ReimbursementForm;
