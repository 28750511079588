import React, { useEffect, useMemo, useRef, useState } from "react";
import RightArrowIcon from "../../assets/Icons/RightArrowIcon";
import VerticleThreeDotsIcon from "../../assets/Icons/VerticleThreeDotsIcon";
import LocationIcon from "../../assets/Icons/LocationIcon";
import * as constants from "../../constant";
import { useAppDispatch, useAppSelector } from "../../config/hooks";
import {
  rejectApplicantApi,
  updateApplicantStatusApi,
} from "../../slices/jobSlice";
import useLocations from "../../utils/dropdown-options/useLocations";
import axios from "axios";
import useIndustries from "../../utils/dropdown-options/useIndustries";
import {
  updateApplicantStatusByMemberApi,
  updateReferalStatusApi,
} from "../../slices/referSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { Link, useNavigate } from "react-router-dom";
import { v4 as uuidV4 } from "uuid";
import Candidate from "../../modules/home/logins/candidate/Candidate";
import useSalaries from "../../utils/dropdown-options/useSalaries";
import ExCheckBox from "../basic-components/ExCheckBox";
import CheckedIcon from "../../assets/Icons/CheckedIcon";
import Tooltip from "../tool-tip/Tooltip";
import { toast } from "sonner";
import moment from "moment";

const CandidateCard = ({
  showStatus,
  candidate,
  candidateDetails,
  setAppliedCadidateViaRefer,
  appliedCadidateViaRefer,
  setSelectedCandidates = () => {},
  selectedCandidates = [],
}) => {
  const dispatch = useAppDispatch();

  const menuRef = useRef(null);
  const menuParentRef = useRef(null);
  const inpuRef = useRef(null);

  const loginSelector = useAppSelector((state) => state?.login?.session);
  const memberSelector = useAppSelector((state) => state.member.memberDetails);

  const [isChecked, setIsChecked] = useState(
    selectedCandidates.some((c) => c.id === candidate.id)
    // false
  );

  const { defaultIndustry } = useIndustries();

  const preferdIndustry = useMemo(() => {
    if (candidate?.preferredIndustry) {
      return candidate?.preferredIndustry[0]
        ? defaultIndustry(candidate.preferredIndustry[0])
        : null;
    } else if (
      candidate?.applications &&
      candidate?.applications[0]?.preferredIndustry[0]
    ) {
      return defaultIndustry(candidate?.applications[0]?.preferredIndustry[0]);
    }
    return null;
  }, [candidate?.applications, candidate.preferredIndustry, defaultIndustry]);

  const token = useAppSelector((state) => state?.login?.session?.token);

  const [toggle, setToggle] = useState(false);

  const { defaultLocation } = useLocations();
  const { defualtSalary } = useSalaries();

  const data = {
    comments: candidate.comments,
    reasonForjobChange: candidate.reasonForJobChange,
    expectation: candidate.expectation,
    previousCompany: candidate.previousCompany,
    workMode: candidate.workMode,
    teamSize: candidate.teamSize,
    reportingTo: candidate.reportingTo,
    qualification: candidate.qualification,
    skills: candidate.skills,
  };

  useEffect(() => {
    const toggleHandler = (e) => {
      if (
        menuRef.current &&
        menuParentRef &&
        !menuRef.current.contains(e.target) &&
        !menuParentRef.current.contains(e.target)
      ) {
        // close popup
        setToggle(false);
      }
    };
    document.addEventListener("mousedown", toggleHandler);

    return () => {
      document.removeEventListener("mousedown", toggleHandler);
    };
  });

  const toggleStateModal = () => {
    setToggle(!toggle);
  };

  const handleDownload = async () => {
    if (candidate?.attachment?.url) {
      axios
        .get(`${constants.baseUrl}/${candidate?.attachment?.url}?t=${token}`, {
          headers: {
            "Content-Type": "application/octet-stream",
          },
          responseType: "blob",
        })
        .then((response) => {
          const a = document.createElement("a");
          const url = window.URL.createObjectURL(response.data);
          a.href = url;
          a.download = candidate?.attachment?.name;
          a.click();
        })
        .catch((err) => {});
    } else if (
      (loginSelector?.who === constants.COMPANY_MEMBER.toLowerCase() &&
        memberSelector.designation === constants.CANDIDATE_MANEGMENT) ||
      loginSelector?.who === constants.RECRUITER.toLowerCase()
    ) {
      axios
        .get(
          `${constants.baseUrl}/${candidate?.applications[0]?.attachment?.url}?t=${token}`,
          {
            headers: {
              "Content-Type": "application/octet-stream",
            },
            responseType: "blob",
          }
        )
        .then((response) => {
          const a = document.createElement("a");
          const url = window.URL.createObjectURL(response.data);
          a.href = url;
          a.download = candidate?.applications[0]?.attachment?.name;
          a.click();
        })
        .catch((err) => {});
    }
  };

  const updateStateHandler = async (applicantStatus) => {
    if (
      loginSelector?.who === constants.COMPANY.toLowerCase() ||
      (loginSelector?.who === constants.COMPANY_MEMBER.toLowerCase() &&
        memberSelector.designation === constants.ADMIN)
    ) {
      dispatch(
        updateApplicantStatusApi({
          jobId: candidate.jobId,
          applicationId: candidate.id,
          status: applicantStatus,
          subStatus: "APPLICATION_VIEWED", //APPLICATION_CONTACT_VIEWED
        })
      );
    } else if (
      (loginSelector?.who === constants.COMPANY_MEMBER.toLowerCase() &&
        memberSelector.designation === constants.CANDIDATE_MANEGMENT) ||
      loginSelector?.who === constants.RECRUITER.toLowerCase()
    ) {
      const resp = await dispatch(
        updateApplicantStatusByMemberApi({
          jobId: candidate.jobId,
          applicationId: candidate.applications[0].id,
          status: applicantStatus,
          subStatus: "APPLICATION_VIEWED", //APPLICATION_CONTACT_VIEWED
        })
      ).then(unwrapResult);
      const newArray = appliedCadidateViaRefer.referrals.map((ref) => {
        return {
          ...ref,
          applications: ref.applications.map((apps) => {
            if (apps.id === resp.data.data.id) {
              return { ...apps, status: resp.data.data.status };
            }
          }),
        };
      });

      setAppliedCadidateViaRefer({
        ...appliedCadidateViaRefer,
        referrals: newArray,
      });
      dispatch(
        updateReferalStatusApi({
          referalId: candidate.id,
          status: applicantStatus,
          subStatus: "APPLICATION_VIEWED",
        })
      ).then(unwrapResult);
    }
    toggleStateModal();
  };

  const candidateLocation = useMemo(() => {
    return candidate?.location && candidate?.location.length > 0
      ? defaultLocation(candidate?.location[0])
      : "";
  }, [candidate?.location, defaultLocation]);

  const candidateLastsal = useMemo(() => {
    return defualtSalary(candidate?.lastSalary);
  }, [candidate?.lastSalary, defualtSalary]);

  const expectedSal = useMemo(() => {
    return defualtSalary(candidate?.expectation);
  }, [candidate?.expectation, defualtSalary]);

  const assignedDates = useMemo(() => {
    return candidate?.assignedToDetails &&
      candidate?.assignedToDetails.length > 0
      ? (loginSelector?.who === constants.COMPANY_MEMBER.toLowerCase() &&
          memberSelector?.designation !== constants.ADVISER) ||
        loginSelector?.who === constants.COMPANY.toLowerCase()
        ? candidate?.assignedToDetails.map((details) =>
            moment(details.createdAt).format(`DD MMMM YYYY hh:mm A`)
          )
        : candidate?.assignedToDetails.map((details) => {
            if (details?.memberId === loginSelector?.userId) {
              return moment(details.createdAt).format(`DD MMMM YYYY hh:mm A`);
            }
            return null;
          })
      : [];
  }, [
    candidate?.assignedToDetails,
    loginSelector?.userId,
    loginSelector?.who,
    memberSelector?.designation,
  ]);

  const updatedDates = useMemo(() => {
    return candidate?.assignedToDetails &&
      candidate?.assignedToDetails.length > 0
      ? candidate?.assignedToDetails.map((details) =>
          details.status === "Pending" || details.status === ""
            ? "_ _ _ _ _ _ _"
            : moment(details.updatedAt).format(`DD MMMM YYYY hh:mm A`)
        )
      : [];
  }, [candidate?.assignedToDetails]);

  // const navigateHandler = () => {
  //   if (candidateDetails.userId)
  //     navigate(`/company-admin/applicant/${candidateDetails.userId}`, {
  //       state: candidate,
  //     });
  // };

  const onChange = (e) => {
    if (!selectedCandidates.some((c) => c.id === candidate.id)) {
      setSelectedCandidates((prev) => [...prev, candidate]);
    } else {
      const filteredArray = selectedCandidates.filter(
        (c) => c.id !== candidate.id
      );
      setSelectedCandidates(filteredArray);
    }
  };

  const toggleInputHandler = () => {
    if (inpuRef.current) {
      inpuRef.current.click();
      setIsChecked(!isChecked);
    }
  };

  const rejectCandidatesHandler = async () => {
    try {
      await dispatch(
        rejectApplicantApi({
          applicantId: candidate.id,
          isRejected: false,
          rejectedBy: loginSelector?.userId,
          firstName: "",
          lastName: "",
        })
      ).then(unwrapResult);
    } catch (error) {
      toast.error(`Something went wrong - try again !`);
    }
  };

  return (
    // first:border-none border-t-8 border-primary-100/50
    <tr
    // className={`${candidate.isRejected ? "bg-red-200" : "bg-exwhite-100"}`}
    >
      <td
        className={`px-3 py-4 ${
          candidate.isRejected ? "bg-exwhite-100" : "bg-exwhite-100"
        } rounded-l-lg`}
      >
        <div className="flex items-center justify-start">
          {!showStatus && (
            <div className="flex items-center gap-2 mr-2">
              <input
                type="checkbox"
                id={candidate.id}
                className="hidden"
                ref={inpuRef}
                onChange={onChange}
              />
              <div
                className={`h-5 w-5 border-2 rounded-sm border-exgray-200 ${
                  candidate.isTagged || candidate.isRejected
                    ? "bg-exgray-100 border-none"
                    : isChecked && "bg-secondary-400 border-none"
                } cursor-pointer flex items-center justify-center`}
                onClick={() => {
                  if (!candidate.isTagged && !candidate.isRejected)
                    toggleInputHandler();
                }}
              >
                {(isChecked || candidate.isTagged || candidate.isRejected) && (
                  <CheckedIcon />
                )}
              </div>
            </div>
          )}

          <Link
            className={`flex-1 text-base ${
              candidate.isRejected ? "text-red-400" : "text-primary-400"
            } font-semibold cursor-pointer text-nowrap`}
            // target="_blank"
            to={`/company-admin/job/${candidate?.jobId}/applicant/${candidate?.id}/user/${candidateDetails.userId}`}
            state={{ candidate }}
          >
            {`${candidate?.author?.firstName} ${candidate?.author?.lastName}`}
          </Link>
        </div>
      </td>

      <td
        className={`text-base text-start ${
          candidate.isRejected
            ? "text-red-400 bg-exwhite-100 "
            : "text-exgray-500 bg-exwhite-100 "
        } px-3 py-4`}
      >
        <Tooltip text={candidate.emailId}>
          <div className="max-w-36 truncate">{candidate.emailId}</div>
        </Tooltip>
      </td>
      <td
        className={`text-base text-start ${
          candidate.isRejected
            ? "text-red-400 bg-exwhite-100"
            : "text-exgray-500 bg-exwhite-100"
        } px-3 py-4 text-nowrap`}
      >
        {candidateLocation?.Name && candidateLocation?.Name}
      </td>
      <td
        className={`text-base text-center ${
          candidate.isRejected
            ? "text-red-400 bg-exwhite-100 "
            : "text-exgray-500 bg-exwhite-100 "
        } px-3 py-4`}
      >
        {`${candidate?.yearsOfExperience} ${
          candidate?.yearsOfExperience === 1 ? "Yr" : "Yrs"
        }`}
      </td>
      <td
        className={`text-base text-center ${
          candidate.isRejected
            ? "text-red-400 bg-exwhite-100"
            : "text-exgray-500 bg-exwhite-100"
        } px-3 py-4`}
      >
        {candidate.noOfServingDays}
      </td>
      <td
        className={`text-base text-center ${
          candidate.isRejected
            ? "text-red-400 bg-exwhite-100"
            : "text-exgray-500 bg-exwhite-100"
        } px-3 py-4`}
      >
        {`${candidateLastsal?.Title}${
          candidate?.lastSalaryThousand && candidate?.lastSalaryThousand !== 0
            ? `.${candidate?.lastSalaryThousand}`
            : ""
        } ${
          candidateLastsal?.Title || candidate?.lastSalaryThousand
            ? `${candidateLastsal?.Title === "0" ? "Lac" : "Lacs"}`
            : ""
        }`}
      </td>
      <td
        className={`text-base text-center self-start ${
          candidate.isRejected
            ? "text-red-400 bg-exwhite-100"
            : "text-exgray-500 bg-exwhite-100"
        } px-3 py-4`}
      >
        {`${expectedSal?.Title}${
          candidate?.expectationThousand && candidate?.expectationThousand !== 0
            ? `.${candidate?.expectationThousand}`
            : ""
        } ${
          expectedSal?.Title || candidate?.expectationThousand
            ? `${expectedSal?.Title === "0" ? "Lac" : "Lacs"}`
            : ""
        }`}
      </td>

      {((loginSelector?.who === constants.COMPANY_MEMBER.toLowerCase() &&
        memberSelector?.designation !== constants.ADVISER) ||
        loginSelector?.who === constants.COMPANY.toLowerCase()) && (
        <td
          className={`text-base text-start ${
            candidate.isRejected
              ? "text-red-400 bg-exwhite-100"
              : "text-exgray-500 bg-exwhite-100"
          } px-3 py-4`}
        >
          <div className="">
            {candidate?.assignedToDetails &&
              candidate?.assignedToDetails?.length > 0 &&
              candidate?.assignedToDetails?.map((detail, index) => (
                <h1 key={uuidV4()} className="text-nowrap">
                  {index + 1}) {detail?.adviser?.firstName}{" "}
                  {detail?.adviser?.lastName}
                </h1>
              ))}
          </div>
        </td>
      )}

      <td
        className={`text-base text-start ${
          candidate.isRejected
            ? "text-red-400 bg-exwhite-100"
            : "text-exgray-500 bg-exwhite-100"
        } px-3 py-4`}
      >
        <div className="">
          {assignedDates &&
            assignedDates?.length > 0 &&
            assignedDates?.map(
              (detail, index) =>
                detail && (
                  <h1 key={uuidV4()} className="text-nowrap">
                    {detail}
                  </h1>
                )
            )}
        </div>
      </td>

      <td
        className={`text-base text-start ${
          candidate.isRejected
            ? "text-red-400 bg-exwhite-100"
            : "text-exgray-500 bg-exwhite-100"
        } px-3 py-4`}
      >
        {(loginSelector?.who === constants.COMPANY_MEMBER.toLowerCase() &&
          memberSelector?.designation !== constants.ADVISER) ||
        loginSelector?.who === constants.COMPANY.toLowerCase() ? (
          <div className="">
            {candidate?.assignedToDetails &&
              candidate?.assignedToDetails?.length > 0 &&
              candidate?.assignedToDetails?.map((detail, index) => (
                <h1
                  key={uuidV4()}
                  className={`text-nowrap text-center font-semibold ${
                    detail?.status === "Pending"
                      ? "text-warning"
                      : detail?.status === "Approved"
                      ? "text-[#0BA02C]"
                      : "text-red-400"
                  }`}
                >
                  {detail?.status}
                </h1>
              ))}
          </div>
        ) : (
          <div className="">
            {candidate?.assignedToDetails &&
              candidate?.assignedToDetails?.length > 0 &&
              candidate?.assignedToDetails?.map(
                (detail, index) =>
                  detail?.memberId === loginSelector?.userId && (
                    <h1
                      key={uuidV4()}
                      className={`text-nowrap text-center font-semibold ${
                        detail?.status === "Pending"
                          ? "text-warning"
                          : detail?.status === "Approved"
                          ? "text-[#0BA02C]"
                          : "text-red-400"
                      }`}
                    >
                      {detail?.status}
                    </h1>
                  )
              )}
          </div>
        )}
      </td>

      {((loginSelector?.who === constants.COMPANY_MEMBER.toLowerCase() &&
        memberSelector?.designation !== constants.ADVISER) ||
        loginSelector?.who === constants.COMPANY.toLowerCase()) && (
        <td
          className={`text-base text-start ${
            candidate.isRejected
              ? "text-red-400 bg-exwhite-100"
              : "text-exgray-500 bg-exwhite-100"
          } px-3 py-4`}
        >
          <div className="">
            {updatedDates &&
              updatedDates?.length > 0 &&
              updatedDates?.map((detail, index) => (
                <h1 key={uuidV4()} className="text-nowrap text-center">
                  {detail}
                </h1>
              ))}
          </div>
        </td>
      )}

      {((loginSelector?.who === constants.COMPANY_MEMBER.toLowerCase() &&
        memberSelector?.designation !== constants.ADVISER) ||
        loginSelector?.who === constants.COMPANY.toLowerCase()) && (
        <td
          className={`text-base text-center ${
            candidate.isRejected
              ? "text-red-400 bg-exwhite-100"
              : "text-exgray-500 bg-exwhite-100"
          } px-3 py-4`}
        >
          {candidate.isRejected ? (
            <h1 className="text-nowrap">{`${candidate?.rejectedByDetails?.firstName} ${candidate?.rejectedByDetails?.lastName}`}</h1>
          ) : candidate.isTagged ? (
            <h1>{`${candidate?.taggedByDetails?.firstName} ${candidate?.taggedByDetails?.lastName}`}</h1>
          ) : null}
        </td>
      )}

      <td
        className={`text-base text-center ${
          candidate.isRejected
            ? "text-red-400 bg-exwhite-100"
            : "text-exgray-500 bg-exwhite-100"
        } pr-3 py-4 rounded-r-lg`}
      >
        <button
          className={`text-base text-secondary-400 font-semibold ${
            candidate.isRejected &&
            candidate?.rejectedBy === loginSelector?.userId
              ? ""
              : "hidden"
          }`}
          onClick={rejectCandidatesHandler}
        >
          Undo
        </button>
      </td>
    </tr>
  );
};

export default CandidateCard;
