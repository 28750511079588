import { useEffect, useState } from "react";
import { useAppDispatch } from "../../../../../config/hooks";
import { getGlobalSearchCandidateAfterJobCreatedApi } from "../../../../../slices/aiSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { toast } from "sonner";
import NoDataFoundIcon from "../../../../../assets/Icons/NoDataFoundIcon";
import Tooltip from "../../../../../components/tool-tip/Tooltip";
import { useParams } from "react-router-dom";
import Popup from "../../../../../components/popup/Popup";
import GlobalSearchForm from "./GlobalSearchForm";
import LoadingIcon from "../../../../../assets/Icons/LoadingIcon";

const GlobalSearchJobLevelList = () => {
  const dipsatch = useAppDispatch();

  const { jobId } = useParams();

  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [usersData, setUsersData] = useState({
    users: [],
    metadata: null,
  });

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await dipsatch(
        getGlobalSearchCandidateAfterJobCreatedApi(jobId)
      ).then(unwrapResult);
      setUsersData({
        users:
          response?.data?.data?.users && response?.data?.data?.users?.length > 0
            ? response?.data?.data?.users
            : [],
        metadata: response?.data?.data?.metadata,
      });
      setIsLoading(false);
      setError(false);
    } catch (error) {
      setIsLoading(false);
      setError(true);
      toast.error("Something went wrong");
    }
  };

  const addMorePopupHandler = () => {
    setIsPopupOpen(!isPopupOpen);
  };

  if (error) {
    return (
      <div className="w-full">
        <div className="mx-auto w-fit py-5">
          <div className="flex items-center justify-center">
            <NoDataFoundIcon />
          </div>
          <div>
            <h1 className="text-3xl text-expurple-800/50 font-semibold text-center">
              Something Went Wrong
            </h1>
            <p className="text-base text-exgray-200 font-medium text-center max-w-sm mx-auto">
              Could not find any data from the global search. Please try again.
            </p>
          </div>
        </div>
      </div>
    );
  }

  if (isLoading) {
    return (
      <div className="py-20 flex items-center justify-center">
        <LoadingIcon />
      </div>
    );
  }

  return (
    <div className="">
      <div className="flex items-center justify-between my-4">
        <div className="w-[40%] flex items-center justify-start gap-2">
          <h1 className="text-base text-exgray-100 font-semibold pl-4">
            Global Search
          </h1>
          {/* <div className="flex-1 relative pl-2">
                      <input
                        type="text"
                        name="title"
                        onChange={onChange}
                        // value={value}
                        placeholder="Search"
                        className="outline outline-2 outline-exgray-100 bg-exwhite-100 py-2 w-full text-base pr-10 placeholder:text-exgray-100 rounded-full text-exgray-400 pl-5 focus:outline-secondary-300"
                      />
                      <div className="absolute top-0 right-2 h-full flex items-center justify-center">
                        <SearchIcon color="#042347" />
                      </div>
                    </div>
                    <div className="cursor-pointer">
                      <FilterIcon />
                    </div> */}
        </div>
        {/* <div className="">
          <button
            className={`text-base border-2 border-secondary-400 bg-secondary-400 text-exwhite-100 font-semibold text-center px-4 py-1 rounded-full active:scale-95`}
            onClick={addMorePopupHandler}
          >
            Add More
          </button>
        </div> */}
      </div>
      <div className="max-h-popupScreenheight overflow-x-auto rounded-t-md no-scrollbar">
        <table className="min-w-full bg-white">
          <thead className="sticky top-0 z-10">
            <tr className="text-left bg-secondary-800">
              <th className="px-4 py-2 text-sm font-semibold text-exwhite-100">
                ID
              </th>
              <th className="px-4 py-2 text-sm font-semibold text-exwhite-100">
                Name
              </th>
              <th className="px-4 py-2 text-sm font-semibold text-exwhite-100">
                Title
              </th>
              <th className="px-4 py-2 text-sm font-semibold text-exwhite-100">
                Location
              </th>
              <th className="px-4 py-2 text-sm font-semibold text-exwhite-100">
                TotalExperience
              </th>
              <th className="px-4 py-2 text-sm font-semibold text-exwhite-100">
                CurrentCompany
              </th>
              <th className="px-4 py-2 text-sm font-semibold text-exwhite-100">
                PreviousCompany
              </th>
              <th className="px-4 py-2 text-sm font-semibold text-exwhite-100">
                Skills
              </th>
              <th className="px-4 py-2 text-sm font-semibold text-exwhite-100">
                Education
              </th>
              <th className="px-4 py-2 text-sm font-semibold text-exwhite-100">
                Experience
              </th>
              <th className="px-4 py-2 text-sm font-semibold text-exwhite-100">
                Industry
              </th>
              <th className="px-4 py-2 text-sm font-semibold text-exwhite-100">
                LinkedInID
              </th>
              <th className="px-4 py-2 text-sm font-semibold text-exwhite-100">
                ProfessionalSummary
              </th>
            </tr>
          </thead>
          <tbody>
            {usersData?.users?.map((candidate, i) => (
              <tr key={i + "can"} className="hover:bg-gray-50">
                <td className="px-4 py-2 text-sm text-gray-600">{i + 1}</td>
                <td className="px-4 py-2 text-sm text-gray-600 text-nowrap font-semibold">
                  {candidate?.firstName} {candidate?.lastName}
                </td>
                <td className="px-4 py-2 text-sm text-gray-600">
                  <Tooltip text={candidate.title}>
                    <div className="line-clamp-2">{candidate.title}</div>
                  </Tooltip>
                </td>
                <td className="px-4 py-2 text-sm text-gray-600">
                  {candidate.location}
                </td>
                <td className="px-4 py-2 text-sm text-gray-600">
                  {candidate.totalExperience}
                </td>
                <td className="px-4 py-2 text-sm text-gray-600">
                  <Tooltip text={candidate?.currentCompany}>
                    <div className="line-clamp-2">
                      {candidate?.currentCompany}
                    </div>
                  </Tooltip>
                </td>
                <td className="px-4 py-2 text-sm text-gray-600">
                  <Tooltip text={candidate?.previousCompany}>
                    <div className="line-clamp-2">
                      {candidate?.previousCompany}
                    </div>
                  </Tooltip>
                </td>
                <td className="px-4 py-2 text-sm text-gray-600">
                  <Tooltip text={candidate.skills}>
                    <div className="line-clamp-2">{candidate.skills}</div>
                  </Tooltip>
                </td>
                <td className="px-4 py-2 text-sm text-gray-600">
                  <Tooltip text={candidate.education}>
                    <div className="line-clamp-2">{candidate.education}</div>
                  </Tooltip>
                </td>
                <td className="px-4 py-2 text-sm text-gray-600">
                  <Tooltip text={candidate.experience}>
                    <div className="line-clamp-2">{candidate.experience}</div>
                  </Tooltip>
                </td>
                <td className="px-4 py-2 text-sm text-gray-600">
                  <Tooltip text={candidate.industry}>
                    <div className="line-clamp-2">{candidate.industry}</div>
                  </Tooltip>
                </td>
                <td className="px-4 py-2 text-sm text-gray-600 max-w-40">
                  <a
                    href={candidate?.linkedinID}
                    target="_blank"
                    rel="noreferrer"
                    className="text-blue-600 underline break-words"
                  >
                    {candidate?.linkedinID}
                  </a>
                </td>
                <td className="px-4 py-2 text-sm text-gray-600">
                  <Tooltip text={candidate?.professionalSummary}>
                    <div className="line-clamp-2">
                      {candidate?.professionalSummary}
                    </div>
                  </Tooltip>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        {usersData?.users && usersData?.users?.length === 0 && (
          <div className="w-full">
            <div className="mx-auto w-fit py-5">
              <div className="flex items-center justify-center">
                <NoDataFoundIcon />
              </div>
              <div>
                <h1 className="text-3xl text-expurple-800/50 font-semibold text-center">
                  No Data Found
                </h1>
                <p className="text-base text-exgray-200 font-medium text-center max-w-sm mx-auto">
                  Could not find any data from the global search. Please try
                  again.
                </p>
              </div>
            </div>
          </div>
        )}
      </div>

      <Popup onClose={() => setIsPopupOpen(false)} visible={isPopupOpen}>
        <GlobalSearchForm />
      </Popup>
    </div>
  );
};

export default GlobalSearchJobLevelList;
