import React, { useEffect, useState } from "react";
import Interaction from "./Interaction";
import Contact from "./Contact";
import LeadCard from "./LeadCard";
import {
  getAllContactsApi,
  getAllInteractionsApi,
  getAllOnboardingInteractionsApi,
  getClientByIdApi,
} from "../../slices/salesSlice";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../config/hooks";
import Popup from "../../components/popup/Popup";
import InteractionForm from "./InteractionForm";
import PlusCircleIcon from "../../assets/Icons/PlusCircleIcon";
import ContactDetailForm from "./ContactDetailForm";
import ArrowIcon from "../../assets/Icons/ArrowIcon";
import OnboardingSummary from "./OnboardingSummary";

import OnboardingForm from "./OnboardingForm";
import ServiceAgreement from "./ServiceAgreement";

// type Interaction = {
//   date: string;
//   time: string;
//   description: string;
//   participants: string[];
//   document?: string;
//   followUpRequired?: boolean;
//   followUpDate?: string;
// };

const ClientDetail = () => {
  const dispatch = useAppDispatch();
  const clientId = useParams();
  const interactions = useAppSelector((state) => state.sales?.interactions);
  const { contacts, clientDetail ,onboardingInteractions} = useAppSelector((state) => state.sales);
  const [togglePopup, setTogglePopup] = useState(false);
  const [togglePopupContact, setTogglePopupContact] = useState(false);
  const [togglePopupOnboarding, setTogglePopupOnboarding] = useState(false);
  const [showContacts,setShowContacts]=useState(false)
  const [tab,setTab]=useState(0)
  
  // console.log(interactions, ":INTERACTIONS FROM");
  useEffect(() => {
    if (clientId.clientId) {
      dispatch(getAllInteractionsApi(clientId.clientId));
      dispatch(getAllOnboardingInteractionsApi(clientId.clientId));
      dispatch(getAllContactsApi(clientId.clientId));
      // dispatch(getClientByIdApi(clientId.clientId));
    }
  }, [clientId.clientId]);

  const togglePopupHandler = () => {
    setTogglePopup(!togglePopup);
  };

  const togglePopupContactHandler = () => {
    setTogglePopupContact(!togglePopupContact);
  };

  const togglePopupOnboardingHandler = () => {
    setTogglePopupOnboarding(!togglePopupOnboarding);
  };

  const Tabs = clientDetail?.stages == '1'
  ? ["Interaction Timeline"]
  : clientDetail?.stages == '2'
  ? ["Interaction Timeline", "Onboarding Summary"]
  : clientDetail?.stages == '3'
  ? ["Interaction Timeline", "Onboarding Summary", "Service Agreement"]
  : [];

  return (
    <div className="flex flex-col gap-2 bg-gray-100 rounded-md shadow-md">
      <div className="">
        <LeadCard />

        <div className=" px-3 flex items-center justify-between bg-white py-2 mt-2   ">
          <h1 className="text-exgray-500 font-semibold">Contact Details</h1>

          <div className="flex items-center gap-4">
            <button
              onClick={togglePopupContactHandler}
              className="px-4 p-1 bg-primary-500 text-white rounded-md hover:bg-primary-700"
            >
              +
            </button>
            <div
              className={`${showContacts ? "rotate-180" : ""} font-semibold`}
              onClick={() => setShowContacts(!showContacts)}
            >
              <ArrowIcon />
            </div>
          </div>
        </div>

        {showContacts && (
          <div className="flex flex-col px-3 pb-3 bg-white  overflow-hidden ">
            <div className="flex  items-center justify-end ">
              {/* <div className="text-gray-800 font-semibold text-xl">Contact Persons:</div>
              <div onClick={togglePopupContactHandler} className="hover:scale-110">
                <PlusCircleIcon />
              </div> */}
            </div>

            <div className="flex  gap-3 overflow-x-auto ">
              {contacts.length > 0 ? (
                contacts?.map((ele, i) => {
                  return <Contact key={ele.id} data={ele} />;
                })
              ) : (
                <h1 className="text-person text-sm text-exgray-400 text-center">
                  Please add a Contact Person...
                </h1>
              )}
            </div>
          </div>
        )}
      </div>

      <div>
        <div className="flex gap-6 p-3 bg-white text-exgray-200 border border-b-exgray-100/50 ">
          {Tabs.map((heading, index) => {
            return (
              <h1
                key={index}
                onClick={() => setTab(index)}
                className={`${
                  tab === index
                    ? "text-secondary-600 font-semibold underline underline-offset-2 "
                    : " "
                } cursor-pointer`}
              >
                {heading}
              </h1>
            );
          })}
        </div>

        {/* Interaction Timeline */}
        {tab == 0 && (
          <div className="p-3">
            <div className="flex justify-end">
              {/* <h3 className="text-lg text-exgray-200  font-semibold">
             Details
          </h3>      */}
              <button
                onClick={togglePopupHandler}
                className="px-6 py-2 bg-primary-500 text-white rounded-md hover:bg-primary-700"
              >
                + Add Interaction
              </button>
            </div>

            {interactions?.length > 0 ? (
              <div className="">
                {interactions?.map((ele) => {
                  return <Interaction key={ele.id} data={ele} />;
                })}
              </div>
            ) : (
              <div className="text-exgray-500 text-center mt-8">
                <h3>No Interaction yet ....</h3>
              </div>
            )}
          </div>
        )}
        {tab == 1 && (
          <div className="p-3">
            <div className="flex justify-end  ">
              {/* <h3 className="text-lg text-exgray-200  font-semibold">
           Details
          </h3>      */}
          <button
            onClick={togglePopupOnboardingHandler}
            className="px-6 py-2 bg-primary-500 text-white rounded-md hover:bg-primary-700"
          >
            + Add
          </button>
        </div>
        {onboardingInteractions?.length > 0 ? (
          <div className=""> 
           {onboardingInteractions?.map((ele) => {
              return  <OnboardingSummary key={ele.id} data={ele}/>
            })}
          </div>
        ) : (
          <div className="text-exgray-500 text-center mt-8">
            <h3>No Summary yet ....</h3>
          </div>
        )}  
        </div>
       )}
      {
        tab==2 && <div>
            <ServiceAgreement/>
        </div>
       }
</div>
      <Popup
        visible={togglePopup}
        onClose={togglePopupHandler}
        children={<InteractionForm togglePopupHandler={togglePopupHandler} />}
      />

      <Popup
        visible={togglePopupContact}
        onClose={togglePopupContactHandler}
        children={
          <ContactDetailForm
            togglePopupHandler={togglePopupContactHandler}
            initialData={null}
          />
        }
      />

      <Popup
        visible={togglePopupOnboarding}
        onClose={togglePopupOnboardingHandler}
        children={
          <OnboardingForm togglePopupHandler={togglePopupOnboardingHandler} />
        }
      />
    </div>
  );
};

export default ClientDetail;
