//import Logo from "../../assets/images/LogowithTagline.png";
import Logo from "../assets/images/LogowithTagline.png";
//import Logo from "../../assets/images/LogowithTagline.png";


const NotFoundPage = () => {
  return (
    <div style={{flex:1,alignItems:"center",justifyContent:"center"}}>
      
      <div className="flex-1 items-center justify-center">
        {/* <div className="flex-1 items-center">
          <img src={Logo} alt="Exotelent Logo" className="max-w-52" />
        </div> */}
        <h1 className="text-center">
          Page Not Found
        </h1>
        <h1 className="text-center font-medium">
          The page you are looking for cannot be found.
        </h1>
        <h1 className="text-center font-bold text-lg">
          Go to our exotalent <a className="text-secondary-500 text-xl underline" href="http://localhost:3000">Home</a> Page
        </h1>
      </div>
    </div>
  );
}

export default NotFoundPage;