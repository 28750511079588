import React, { useState } from "react";
import MemberCard from "../../../../../components/company-member-card/MemberCard";
import { useAppSelector } from "../../../../../config/hooks";
import { v4 as uuid4 } from "uuid";

const AdvisersList = () => {
  const members = useAppSelector((state) => state.member.advisers);

  const [toggle, setToggle] = useState(0);

  const toggleHandler = (index) => {
    setToggle(index);
  };

  return (
    <div className="space-y-4">
      {toggle === 0 && (
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4">
          {members &&
            members.length > 0 &&
            members.map((member) => (
              <MemberCard member={member} key={uuid4()} type={undefined} />
            ))}
        </div>
      )}
    </div>
  );
};

export default AdvisersList;
