import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import { PostDailyReport } from "../models/employee.model";
import attendanceService from "../services/attendance.service";
import { BasicUser } from "../models/candidate.model";
import ToastNotification from "../components/toast-notification/ToastNotification";
import { toast } from "sonner";

export interface ApplyLeaveRequest {
  id?:string;
  employeeId?:string;
  companyId?:string;
  leaveType?:string;
  startDate?:Date;
  endDate?:Date;
  totalDays?:number;
  reason?:string;
  status?:string;
  approverId?:string;
  rejectedReason?:string;
}

export interface GetLeaveRequestFilter{
  approverId?: string;
  size?: number;
  companyId?: string;
  status?: string;
  employeeId?:string;
}

export interface UpdateLeaveBalance{
  employeeId:string;
  companyId:string;
  leaveType:string;
  totalLeaves:number;
  usedLeaves:number;
  remainingLeaves:number;
}

export interface LeaveBalance{
  id:string;
  employeeId:string;
  companyId:string;
  leaveType:string;
  totalLeaves:number;
  usedLeaves:number;
  remainingLeaves:number;
  createdAt:Date;
  updatedAt:Date;
}

export interface GetLeaveBalance{
  employeeId:string;
  companyId:string;
}

export interface leaveReduxState {
  leaveRequestList:Array<ApplyLeaveRequest>;
  leaveBalanceData:Array<LeaveBalance>;
  totalLeaveBalance:number;
}

const initialState = {
  leaveRequestList:[],
  leaveBalanceData:[],
  totalLeaveBalance:null
};

export const applyLeaveApi = createAsyncThunk(
  "leave/applyLeaveApi",
  async (data: ApplyLeaveRequest, { getState, rejectWithValue }) => {
    try {
      const response = await attendanceService.applyLeave(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const leaveRequestListApi = createAsyncThunk(
  "leave/leaveRequestListApi",
  async (data: GetLeaveRequestFilter, { getState, rejectWithValue }) => {
    try {
      const response = await attendanceService.getLeaveRequest(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateLeaveRequestApi = createAsyncThunk(
  "leave/updateLeaveRequestApi",
  async (data: ApplyLeaveRequest, { getState, rejectWithValue }) => {
    try {
      const response = await attendanceService.updateLeaveRequest(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateLeaveBalanceApi = createAsyncThunk(
  "leave/updateLeaveBalanceApi",
  async (data: UpdateLeaveBalance, { getState, rejectWithValue }) => {
    try {
      const response = await attendanceService.updateLeaveBalance(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getLeaveBalanceApi = createAsyncThunk(
  "leave/getLeaveBalanceApi",
  async (data: GetLeaveBalance, { getState, rejectWithValue }) => {
    try {
      const response = await attendanceService.getLeaveBalance(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const leaveSlice = createSlice({
  name: "leave",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(leaveRequestListApi.fulfilled,(state,action)=>{
      state.leaveRequestList = action.payload.data.data;
    });
    builder.addCase(getLeaveBalanceApi.fulfilled,(state,action)=>{
      state.leaveBalanceData = action.payload.data.data;
      state.totalLeaveBalance = action.payload.data.data.leaveBalances;
    });
    
    builder.addCase(applyLeaveApi.fulfilled,(state,action)=>{
      if(state.leaveRequestList){
        state.leaveRequestList = [action.payload.data.data, ...state.leaveRequestList];
      }
      else{
        state.leaveRequestList = [action.payload.data.data];
      }
    });

    builder.addCase(updateLeaveRequestApi.fulfilled,(state,action)=>{
      let leaveRequestList = state.leaveRequestList;
      leaveRequestList.map((leave)=>{
        if(leave.id == action.meta.arg.id){
          leave.status = action.meta.arg.status;
        }
      })
      state.leaveRequestList = leaveRequestList;
    });
}
    
});

//export const { removeRecruiterDetails } = attendanceSlice.actions;
export default leaveSlice.reducer;
