import moment from 'moment';
import { useEffect } from 'react';
import ReactQuill from 'react-quill';
import { useAppDispatch, useAppSelector } from '../../config/hooks';
import { getTimesheetApi } from '../../slices/attendanceSlice';
import ExcelJS from 'exceljs';

const EmployeeTimesheet = () => {
  const dispatch = useAppDispatch();
  const userId = useAppSelector((state) => state.login.session.userId);
  const timesheet = useAppSelector((state) => state.attendance.timesheet);

  useEffect(() => {
    let month = moment().month() > 8 ? moment().month() + 1 : 0 + moment().month() + 1;
    let year = moment().year();
    dispatch(getTimesheetApi({
      dateFrom: moment(`${month}/01/${year}`).format('YYYY-MM-DD'),
      dateTo: moment().format('YYYY-MM-DD'),
      employeeId: userId,
    }))
  }, [])

  const exportToExcel = async () => {
    // Create a new workbook with ExcelJS
    const workbook = new ExcelJS.Workbook();

    // Add a worksheet
    const worksheet = workbook.addWorksheet('Sheet1');

    worksheet.columns = [
      { header: 'Day', key: 'date', width: 20 },
      { header: 'Date', key: 'date', width: 20 },
      { header: 'CheckIn', key: 'checkIn', width: 20 },
      { header: 'CheckOut', key: 'checkOut', width: 20 },
      { header: 'Working Hours', key: 'workingHours', width: 20 },
      { header: 'Task', key: 'dailyReport', width: 20 },

    ];

    // Apply bold to the header row
    worksheet.getRow(1).font = { bold: true, size: 12, };

    // Add data to the worksheet
    timesheet.forEach((item) => {
      var startTime = moment(item.checkIn);
      var endTime = moment(item.checkOut);
      var dif = moment.duration(endTime.diff(startTime));

      const htmlContent = item.dailyReport?.dailyReport[0];
      // Parse HTML content and get the text
      const parser = new DOMParser();
      const doc = parser.parseFromString(htmlContent, 'text/html');
      const text = doc.body.textContent || doc.body.innerText;

      const customizedData = [
        moment(item.date).format('dddd'),           // Example: Capitalize name
        moment(item.date).format('DD/MM/YYYY'),           // Example: Capitalize name
        moment(item.checkIn).format('HH:mm'),                          // No change, just add as is
        moment(item.checkOut).format('HH:mm'),              // Example: Double the hours worked
        item?.status ? `${dif.hours()}:${dif.minutes() < 10 ? "0" + dif.minutes() : dif.minutes()}` : '',
        text
      ];
      worksheet.addRow(customizedData);
    });

    // Apply borders to all cells in the worksheet
    worksheet.eachRow({ includeEmpty: true }, function (row, rowNumber) {
      row.eachCell({ includeEmpty: true }, function (cell, colNumber) {
        // cell.border = {
        //   top: { style: 'thin' },
        //   left: { style: 'thin' },
        //   bottom: { style: 'thin' },
        //   right: { style: 'thin' },
        // };
        cell.alignment = { horizontal: 'left' }
      });
    });

    // Save the file as a blob and download it
    workbook.xlsx.writeBuffer().then((buffer) => {
      const data = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(data);
      link.download = `Exported_timesheet.xlsx`;
      link.click();
    });
  };

  return (
    <>
      <div className='flex justify-between items-center'>
        <h1 className='font-bold'>TimeSheet</h1>
        <div className='flex items-center'>
          {/* <div className='flex items-center border border-exgray-300 rounded-md py-1 px-4'>
            <FilterIcon />
            <h1 className='text-exgray-300 ml-2'>Filter</h1>
          </div> */}
          <button onClick={() => exportToExcel()} className='bg-secondary-700 text-exwhite-100 py-1 px-5 rounded-md ml-4 border border-primary-500'>
            Export
          </button>
        </div>
      </div>
      <div>
        <table className="w-full mt-2 rounded-sm">
          <thead className="py-3 bg-secondary-800">
            <tr className="border-b border-exgray-100">
              <th className="text-sm text-exwhite-100 font-medium py-3 px-2 w-2/12 text-start">Date</th>
              <th className="text-sm text-exwhite-100 font-medium py-3 px-2 w-1.5/12 text-start">Start-Time</th>
              <th className="text-sm text-exwhite-100 font-medium py-3 px-2 w-1.5/12 text-start">End-Time</th>
              <th className="text-sm text-exwhite-100 font-medium py-3 px-2 w-2/12 text-center">Working Hrs</th>
              <th className="text-sm text-exwhite-100 font-medium py-3 px-2 w-6/12 text-start">Task List</th>
            </tr>
          </thead>
          <tbody className="items-center justify-center ">
            {
              timesheet?.map((t) => {
                var startTime = moment(t.checkIn);
                var endTime = moment(t.checkOut);
                var dif = moment.duration(endTime.diff(startTime));
                return (
                  <tr className="items-center border-b border-l border-r border-exgray-100">
                    <td className="items-center py-3 px-2">
                      {moment(t.date).format('DD/MM/YYYY')}
                    </td>
                    <td className="px-2 text-start py-3 text-secondary-500 font-medium">
                      {moment(t.checkIn).format('LT')}
                    </td>
                    <td className="px-2 text-start py-3 text-secondary-500 font-medium">
                      {t?.status ? moment(t.checkOut).format('LT') : ''}
                    </td>
                    <td className="px-2 text-center py-3 text-secondary-500 font-medium">
                      {t?.status ? `${dif.hours()}:${dif.minutes() < 10 ? "0" + dif.minutes() : dif.minutes()}` : ''}
                    </td>
                    <td className="px-2 items-center">
                      <ReactQuill
                        style={{ paddingBottom: -15, marginBottom: -35 }}
                        value={t?.dailyReport?.dailyReport[0]}
                        readOnly={true}
                        theme={"bubble"}
                      />
                    </td>
                  </tr>
                );
              })
            }
            {/* <tr className="items-center bg-exwhite-100 border-b-4 border-bg-primary-100/50">
              <td className="items-center py-3 px-2">
                22 January 2025
              </td>
              <td className="px-2 text-start py-3 text-secondary-500 font-medium">
                09:00 am
              </td>
              <td className="px-2 text-start py-3">
                09:00 pm
              </td>
              <td className="px-2 items-center py-3">
                Wireframe Design for New Project
                3 candidates have interviews scheduled for tomorrow. Ensure feedback forms are ready...
              </td>
            </tr>
            <tr className="items-center border-b-4 border-bg-primary-100/50 bg-exwhite-100 mt-3">
              <td className="items-center py-3 px-2">
                22 January 2025
              </td>
              <td className="px-2 text-start py-3 text-secondary-500 font-medium">
                09:00 am
              </td>
              <td className="px-2 text-start py-3">
                09:00 pm
              </td>
              <td className="px-2 items-center py-3">
                Wireframe Design for New Project
                3 candidates have interviews scheduled for tomorrow. Ensure feedback forms are ready...
              </td>
            </tr>
            <tr className="items-center border-b-4 border-bg-primary-100/50 bg-exwhite-100 mt-3">
              <td className="items-center py-3 px-2">
                22 January 2025
              </td>
              <td className="px-2 text-start py-3 text-secondary-500 font-medium">
                09:00 am
              </td>
              <td className="px-2 text-start py-3">
                09:00 pm
              </td>
              <td className="px-2 items-center py-3">
                Wireframe Design for New Project
                3 candidates have interviews scheduled for tomorrow. Ensure feedback forms are ready...
              </td>
            </tr> */}
          </tbody>
        </table>
      </div>
    </>
  )
}

export default EmployeeTimesheet
