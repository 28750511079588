import { useState } from "react";
import { useAppSelector } from "../config/hooks";
import * as constants from "../constant";

export interface ImageProps {
  url: string;
  alt: string;
  borderRadius: string;
}

const Image = ({ url, alt, borderRadius }: ImageProps) => {
  const token = useAppSelector((state) => state?.login?.session?.token);
  const [error, setError] = useState(false);

  const errorHandler = () => setError(true);

  return error ? (
    <div
      className={`w-full h-full bg-DefaultProfileImg bg-cover bg-center`}
      style={{
        borderRadius: borderRadius,
      }}
    ></div>
  ) : (
    <img
      src={`${constants.baseUrl}/${url}?t=${token}`}
      alt={alt}
      className={`w-full h-full object-cover`}
      style={{
        borderRadius: borderRadius,
      }}
      onError={errorHandler}
    />
  );
};

export default Image;
