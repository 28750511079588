import React, { useState } from "react";
import EditIcon from "../../assets/Icons/EditIcon";
import Popup from "../../components/popup/Popup";
import ContactDetailForm from "./ContactDetailForm";

const Contact = ({ data }) => {
  const [togglePopupContact, setTogglePopupContact] = useState(false);
  const contact = data;

  // const contact = {
  //   name: "Sarah Johnson",
  //   designation: "CEO",
  //   phone: "+1-234-567-8901",
  //   email: "sarah.johnson@abctech.com",
  //   isPrimary: true,
  // };

  //   const currentContact = contacts.find(
  //     (contact) => contact.name === selectedContact
  //   );

  const togglePopupContactHandler = () => {
    setTogglePopupContact(!togglePopupContact);
  };

  return (
    <>
      <div className=" min-w-64 border text-sm border-gray-300 rounded-lg px-3 pb-2  bg-white">
        {/* Header */}

        <div>
          <div
            onClick={togglePopupContactHandler}
            className="flex justify-end py-3 cursor-pointer"
          >
            <EditIcon />
          </div>
          <div className="mb-2 flex justify-between">
            <div>
              <span className="text-exgray-400">Name :</span>{" "}
              <span className="text-exgray-800 ">
                {contact.firstName} {contact.lastName}
              </span>
            </div>
            {contact.isPrimary && (
              <span className="bg-blue-100 text-blue-600 text-xs font-semibold ml-2 px-2 py-0.5 rounded-full ">
                Primary
              </span>
            )}
          </div>
          <div className="mb-2">
            <span className="text-exgray-400">Designation :</span>{" "}
            <span className="text-exgray-800 ">{contact.designation}</span>
          </div>
          <div className="mb-2">
            <span className="text-exgray-400">Phone Number :</span>{" "}
            <span className="text-exgray-800 ">{contact.phoneNumber}</span>
          </div>
          <div className="mb-2">
            <span className="text-exgray-400">Email ID :</span>{" "}
            <span className="text-exgray-800 ">{contact.email}</span>
          </div>
          <div className="mb-2">
            <span className="text-exgray-400">Location :</span>{" "}
            <span className="text-exgray-800 ">{contact.location}</span>
          </div>
        </div>
      </div>

      <Popup
        visible={togglePopupContact}
        onClose={togglePopupContactHandler}
        children={
          <ContactDetailForm
            togglePopupHandler={togglePopupContactHandler}
            isUpdate={true}
            initialData={data}
          />
        }
      />
    </>
  );
};

export default Contact;
