import React, { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "../../../../../config/hooks";
import { useParams } from "react-router-dom";
import { unwrapResult } from "@reduxjs/toolkit";
import { getMembersApi } from "../../../../../slices/companyMemberSlice";
import * as constants from "../../../../../constant";
import { assignApplicantApi } from "../../../../../slices/jobSlice";
import { toast } from "sonner";
import ExDropdown from "../../../../../components/basic-components/ExDropdown";

const AssignCandidateForm = ({
  selectedCandidates,
  onClose = () => {},
  setSelectedCandidates,
}) => {
  const dispatch = useAppDispatch();

  const { jobId } = useParams();

  const buttonRef = useRef();

  const [companyMembers, setCompanyMembers] = useState(null);

  const userId = useAppSelector((state) => state?.login?.session?.userId);
  const loginSelector = useAppSelector((state) => state?.login?.session);
  const memberSelector = useAppSelector(
    (state) => state?.member?.memberDetails
  );

  const form = useForm({
    defaultValues: {
      advisor: null,
    },
  });

  const { handleSubmit, control, formState } = form;
  const { errors } = formState;

  useEffect(() => {
    const fetchData = async () => {
      if (loginSelector?.who === constants.COMPANY.toLowerCase()) {
        try {
          const response = await dispatch(
            getMembersApi({ companyId: userId })
          ).then(unwrapResult);
          const data = response.data.data;
          const advisors =
            data && data.length > 0
              ? data
                  .map((adv) => {
                    if (
                      constants.BANDS_TO_ASSIGN_CANDIDATE.includes(adv.band)
                    ) {
                      return {
                        ...adv,
                        label: `${adv.firstName} ${adv.lastName} - ${adv.emailId}`,
                      };
                    }
                  })
                  .filter((record) => record !== undefined)
              : [];
          setCompanyMembers(advisors);
        } catch (error) {}
      } else if (
        loginSelector?.who === constants.COMPANY_MEMBER.toLowerCase()
      ) {
        try {
          const response = await dispatch(
            getMembersApi({ companyId: memberSelector?.companyId })
          ).then(unwrapResult);
          const data = response.data.data;
          const advisors =
            data && data.length > 0
              ? data
                  .map((adv) => {
                    if (
                      constants.BANDS_TO_ASSIGN_CANDIDATE.includes(adv.band)
                    ) {
                      return {
                        ...adv,
                        label: `${adv.firstName} ${adv.lastName} - ${adv.emailId}`,
                      };
                    }
                  })
                  .filter((record) => record !== undefined)
              : [];
          setCompanyMembers(advisors);
        } catch (error) {}
      }
    };

    fetchData();
  }, []);

  const submitButtonHandler = () => {
    if (buttonRef.current) {
      buttonRef.current.click();
    }
  };

  const onSubmit = async (formData) => {
    const requestData = selectedCandidates.map((data) => ({
      memberId: formData.advisor.id,
      applicationId: data?.id,
      jobId: jobId,
      authorId: userId,
    }));

    try {
      await dispatch(assignApplicantApi(requestData));
      toast.success(
        `Assigned to ${formData?.advisor?.firstName} ${formData?.advisor?.lastName}`
      );
      onClose();
      setSelectedCandidates([]);
    } catch (error) {
      toast.error("Something went wrong");
    }
  };

  return (
    <div className="w-[50%] bg-exwhite-100 p-6 rounded-md space-y-2 max-h-popupScreenheight">
      <h1 className="text-lg text-primary-600 font-semibold text-start border-b border-exgray-100 pb-1">
        Assign Candidates
      </h1>
      <form className="" onSubmit={handleSubmit(onSubmit)}>
        <div>
          <Controller
            name="advisor"
            control={control}
            rules={{
              required: {
                value: true,
                message: "required",
              },
            }}
            render={({ field: { onChange, value } }) => (
              <ExDropdown
                title="Assign Candidate to"
                onChange={onChange}
                placeholder="Select"
                value={value || null}
                optionValue="emailId"
                optionLabel="label"
                dropDownHeight="40px"
                dropDownBorderWidth="2px"
                dropDownBorderColor="#CDCED2"
                menuListHeight="200px"
                error={errors?.advisor?.message}
                options={
                  companyMembers
                    ? companyMembers
                    : [{ emailId: "Loading...", isDisabled: true }]
                }
              />
            )}
          />
        </div>

        <button className="hidden" type="submit" ref={buttonRef}>
          Assign
        </button>
      </form>

      <div className="flex items-center justify-end pt-2">
        <button
          className="px-7 py-1 bg-primary-400 rounded-sm text-exwhite-100 font-semibold"
          onClick={submitButtonHandler}
        >
          Assign
        </button>
      </div>
    </div>
  );
};

export default AssignCandidateForm;
