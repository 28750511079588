import { AxiosPromise } from "axios";
import { ContactDetail } from "../models/contact.model";
import { ApiResponse } from "../models/base.model";
import apiClient from "../config/axiosConfig";

class ContactService {
    createContact(
       contact:ContactDetail
      ): AxiosPromise<ApiResponse<ContactDetail>> {
        return apiClient.post(`/v1/exo/clients/${contact.clientId}/contacts`, contact);
    }

    updateContact(
       contact:ContactDetail
      ): AxiosPromise<ApiResponse<ContactDetail>> {
        return apiClient.put(`/v1/exo/clients/${contact.clientId}/contacts/${contact.id}`, contact);
    }


    getAllContacts(clientId:string): AxiosPromise<ApiResponse<Array<ContactDetail>>> {
         return apiClient.get(`/v1/exo/clients/${clientId}/contacts`);
    }


    // getClientById(clientId:string):AxiosPromise<ApiResponse<Client>>{
    //     return apiClient.get(`/v1/exo/clients/${clientId}`);
    // }

    // checkDomain(domain:string):AxiosPromise<ApiResponse<CompanyProfile>>{
    //     return apiClient.get(`/v1/exo/companies/domain?domain=${domain}`)
    // }


}

const contactService=new ContactService();
export default contactService;