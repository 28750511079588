
import moment from "moment";
import { useAppDispatch, useAppSelector } from "../../../config/hooks";
import { useEffect, useState } from "react";
import { getHolidayListApi } from "../../../slices/appSlice";
import ExDropdown from "../../../components/basic-components/ExDropdown";
import useWorkLocation from "../../../utils/dropdown-options/useWorkLocation";

const HolidayList = () => {
  const memberDetail = useAppSelector((state) => state.company.memberDetails);
  const dispatch = useAppDispatch();
  const holidayList = useAppSelector((state) => state?.appConfig.holidayList);
  const { workLocationArray, defaultWorkLocation } = useWorkLocation();
  const [location, setLocation] = useState(defaultWorkLocation(memberDetail?.workLocation));

  useEffect(() => {
    dispatch(getHolidayListApi(location?.Title))
  }, [location])

  return (
    <>
      <div className="mt-3">
        <div className="flex justify-between">
          <h1 className="text-xl text-exgray-700 font-medium">Holiday List</h1>
          <div className="w-4/12">
            <ExDropdown
              title="Select Location"
              placeholder="Select"
              onChange={(l) => setLocation(l)}
              value={location}
              optionValue="Id"
              optionLabel="Title"
              options={workLocationArray}
              dropDownHeight="40px"
              dropDownBorderWidth="1px"
              dropDownBorderColor="#8C8C8C"
              error={''}
              isMandatory={false}
            />
          </div>
        </div>
        <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
          <table className="w-full mt-2 text-center rounded-sm">
            <thead className="py-3">
              <tr className="bg-secondary-800 rounded-lg ">
                <th className="text-sm text-exwhite-100 font-medium py-3">Location</th>
                <th className="text-sm text-exwhite-100 font-medium py-3">Month</th>
                <th className="text-sm text-exwhite-100 font-medium py-3">Date</th>
                <th className="text-sm text-exwhite-100 font-medium py-3">Day</th>
                <th className="text-sm text-exwhite-100 font-medium py-3">Ocassion</th>
              </tr>
            </thead>
            <tbody className="items-center justify-center ">
              {
                holidayList?.map((holiday, index) => {

                  return (
                    <tr key={holiday.id} className="items-center border-b border-l border-r border-exgray-100">
                      <td className="items-center py-3">{holiday.Location}</td>
                      <td className="items-center py-3 text-secondary-500 font-medium">{holiday.Month}</td>
                      <td className="items-center py-3">{moment(holiday.Date).format('DD/MM/YYYY')}</td>
                      <td className="items-center py-3">{holiday.Day}</td>
                      <td className="items-center py-3 text-secondary-500 font-medium">{holiday.Ocassion}</td>
                    </tr>
                  )
                })
              }
            </tbody>
          </table>
        </div>

      </div>
    </>
  );
};

export default HolidayList;
