import React, { useEffect, useRef, useState } from "react";
import CandidateCard from "../../../../../components/candidate-card/CandidateCard";
import { v4 as uuid4 } from "uuid";
import { useAppDispatch, useAppSelector } from "../../../../../config/hooks";
import {
  assignApplicantApi,
  getApplicantsApi,
  getNextApplicantsApi,
  getNextApplicantsCMApi,
  rejectApplicantApi,
  tagApplicantApi,
  updateApplicantStatusApi,
} from "../../../../../slices/jobSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { getAllReferralsApi } from "../../../../../slices/referSlice";
import * as constants from "../../../../../constant";
import InfiniteScroll from "react-infinite-scroll-component";
import { toast } from "sonner";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import SearchIcon from "../../../../../assets/Icons/SearchIcon";
import FilterIcon from "../../../../../assets/Icons/FilterIcon";
import LoadingIcon from "../../../../../assets/Icons/LoadingIcon";
import NoDataFoundIcon from "../../../../../assets/Icons/NoDataFoundIcon";
import Popup from "../../../../../components/popup/Popup";
import AdvisorForm from "./AdvisorForm";
import AssignCandidateForm from "./AssignCandidateForm";

const JobApplicants = () => {
  const { jobId } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const menuRef = useRef();
  const menuParentRef = useRef(null);

  const jobSelector = useAppSelector((state) => state.jobs.jobApplicants);
  const loginSelector = useAppSelector((state) => state?.login?.session);
  const memberSelector = useAppSelector((state) => state.member.memberDetails);

  const [appliedCadidateViaRefer, setAppliedCadidateViaRefer] = useState(null);
  const [selectedCandidates, setSelectedCandidates] = useState([]);
  const [isForward, setIsForward] = useState(false);
  const [isAdvisorPopupOpen, setisAdvisorPopupOpen] = useState(false);
  const [isAssignPopupOpen, setIsAssignPopupOpen] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const toggleHandler = (e) => {
      if (
        menuRef.current &&
        menuParentRef &&
        !menuRef.current.contains(e.target) &&
        !menuParentRef.current.contains(e.target)
      ) {
        // close popup
        setIsForward(false);
      }
    };
    document.addEventListener("mousedown", toggleHandler);

    return () => {
      document.removeEventListener("mousedown", toggleHandler);
    };
  });

  const fetchData = async () => {
    try {
      await dispatch(
        getApplicantsApi({
          jobId,
          recruiterId:
            loginSelector?.who === constants.COMPANY.toLowerCase() ||
            (loginSelector?.who === constants.COMPANY_MEMBER.toLowerCase() &&
              memberSelector?.designation === "Admin") ||
            (loginSelector?.who === constants.COMPANY_MEMBER.toLowerCase() &&
              memberSelector?.designation === constants.SUPER_ADMIN) ||
            (loginSelector?.who === constants.COMPANY_MEMBER.toLowerCase() &&
              memberSelector?.designation === constants.ADVISER) ||
            pathname === `/company-admin/job/${jobId}/approvals`
              ? ""
              : loginSelector.userId,
          adviserId:
            pathname === `/company-admin/job/${jobId}/approvals` ||
            (loginSelector?.who === constants.COMPANY_MEMBER.toLowerCase() &&
              memberSelector?.designation === constants.ADVISER)
              ? loginSelector.userId
              : "",
        })
      ).then(unwrapResult);
    } catch (error) {
      toast.error("Something went wrong");
    }
  };

  const getMoreDataHandler = () => {
    try {
      dispatch(getNextApplicantsApi());
    } catch (error) {
      toast.error("Unable to fetch data");
    }
  };

  const tagCandidatesHandler = async () => {
    if (selectedCandidates && selectedCandidates.length > 0) {
      setIsForward(false);
      for (let index = 0; index < selectedCandidates.length; index++) {
        try {
          await dispatch(
            tagApplicantApi({
              applicantId: selectedCandidates[index].id,
              isTagged: true,
              taggedBy: loginSelector.userId,
            })
          ).then(unwrapResult);
          toast.success("Candidate tagged successfully");
          if (
            loginSelector?.who === constants.COMPANY_MEMBER.toLowerCase() &&
            memberSelector?.designation === constants.ADVISER
          ) {
            //change ststus to advisor seletecd
            dispatch(
              updateApplicantStatusApi({
                jobId: jobId,
                applicationId: selectedCandidates[index].id,
                status: "Advisor Approved",
                subStatus: "APPLICATION_VIEWED", //APPLICATION_CONTACT_VIEWED
              })
            ).then(unwrapResult);
          }
          if (pathname === `/company-admin/job/${jobId}/approvals`) {
            navigate(`/company-admin/job/${jobId}/tagged-list/approvals`);
          } else {
            navigate(`/company-admin/job/${jobId}/tagged-list`);
          }
          setSelectedCandidates([]);
        } catch (error) {
          toast.error(
            `${selectedCandidates[index].author.firstName} ${selectedCandidates[index].author.lastName} - try again !`
          );
        }
      }
    }
  };

  const rejectCandidatesHandler = async () => {
    if (selectedCandidates && selectedCandidates.length > 0) {
      setIsForward(false);
      for (let index = 0; index < selectedCandidates.length; index++) {
        try {
          await dispatch(
            rejectApplicantApi({
              applicantId: selectedCandidates[index].id,
              isRejected: true,
              rejectedBy: loginSelector.userId,
              firstName: memberSelector?.firstName,
              lastName: memberSelector?.lastName,
            })
          ).then(unwrapResult);
          if (
            loginSelector?.who === constants.COMPANY_MEMBER.toLowerCase() &&
            memberSelector?.designation === constants.ADVISER
          ) {
            //change status to advisor rejected
            dispatch(
              updateApplicantStatusApi({
                jobId: jobId,
                applicationId: selectedCandidates[index].id,
                status: "Advisor Rejected",
                subStatus: "APPLICATION_VIEWED", //APPLICATION_CONTACT_VIEWED
              })
            ).then(unwrapResult);
          }
          // navigate(`/company-admin/job/${jobId}/tagged-list`);
          setSelectedCandidates([]);
        } catch (error) {
          toast.error(
            `${selectedCandidates[index].author.firstName} ${selectedCandidates[index].author.lastName} - try again !`
          );
        }
      }
    }
  };

  const forwardButtonHandler = () => {
    setIsForward(!isForward);
  };

  const advisorPopupHandler = () => {
    setisAdvisorPopupOpen(true);
    setIsForward(false);
  };

  const assignCandidatePopupHandler = () => {
    setIsAssignPopupOpen(true);
    setIsForward(false);
  };

  const getMoreHandler = async () => {
    try {
      const response = await dispatch(
        getNextApplicantsCMApi({
          nextUrl: appliedCadidateViaRefer.metadata.nextResultURL,
          jobId: jobId,
          recruiterId: loginSelector?.userId,
        })
      ).then(unwrapResult);

      setAppliedCadidateViaRefer({
        referrals: [
          ...appliedCadidateViaRefer.referrals,
          ...response.data.data.referrals,
        ],
        metadata: response.data.data.metadata,
      });
    } catch (error) {
      toast.error("Unable to fetch data");
    }
  };

  if (jobSelector.error) {
    return <h1>Somthing went wrong</h1>;
  }

  if (
    loginSelector?.who === constants.COMPANY.toLowerCase() ||
    loginSelector?.who === constants.COMPANY_MEMBER.toLowerCase()
  ) {
    return !jobSelector.isLoading ? (
      <div>
        <div className="space-y-1 min-h-60">
          <div className="flex items-center justify-between my-3">
            <div className="w-[40%] flex items-center justify-start gap-2">
              <h1 className="text-base text-exgray-100 font-semibold pl-4">
                Source List
              </h1>
              {/* <div className="flex-1 relative pl-2">
                <input
                  type="text"
                  name="title"
                  onChange={onChange}
                  // value={value}
                  placeholder="Search"
                  className="outline outline-2 outline-exgray-100 bg-exwhite-100 py-2 w-full text-base pr-10 placeholder:text-exgray-100 rounded-full text-exgray-400 pl-5 focus:outline-secondary-300"
                />
                <div className="absolute top-0 right-2 h-full flex items-center justify-center">
                  <SearchIcon color="#042347" />
                </div>
              </div>
              <div className="cursor-pointer">
                <FilterIcon />
              </div> */}
            </div>
            <div className="relative">
              <button
                className={`text-base border-2 ${
                  selectedCandidates && selectedCandidates.length > 0
                    ? "border-secondary-400 bg-secondary-400 text-exwhite-100"
                    : "border-exgray-200 text-exgray-200"
                }  font-semibold text-center px-4 py-1 rounded-full active:scale-95`}
                // onClick={tagCandidatesHandler}
                onClick={() => {
                  if (selectedCandidates && selectedCandidates.length > 0)
                    forwardButtonHandler();
                }}
                ref={menuParentRef}
              >
                Select Action
              </button>
              {isForward &&
                selectedCandidates &&
                selectedCandidates.length > 0 && (
                  <div
                    className="absolute top-11 right-0 rounded-md w-56 z-50"
                    style={{
                      boxShadow: "0px 12px 32px 0px #18191C14",
                    }}
                    ref={menuRef}
                  >
                    {((loginSelector?.who ===
                      constants.COMPANY_MEMBER.toLowerCase() &&
                      memberSelector?.designation !== constants.ADVISER &&
                      !pathname.includes(`approvals`)) ||
                      loginSelector?.who ===
                        constants.COMPANY.toLowerCase()) && (
                      <button
                        className="py-2 px-4 bg-exwhite-100 text-start rounded-t-md hover:bg-secondary-100 w-full hover:text-secondary-400"
                        onClick={advisorPopupHandler}
                      >
                        Assign To Adviser
                      </button>
                    )}

                    {((loginSelector?.who ===
                      constants.COMPANY_MEMBER.toLowerCase() &&
                      memberSelector?.designation !== constants.ADVISER &&
                      !pathname.includes(`approvals`)) ||
                      loginSelector?.who ===
                        constants.COMPANY.toLowerCase()) && (
                      <button
                        className="py-2 px-4 bg-exwhite-100 text-start hover:bg-secondary-100 hover:text-secondary-400 w-full"
                        onClick={assignCandidatePopupHandler}
                      >
                        Assign Candidate To
                      </button>
                    )}

                    {(pathname === `/company-admin/job/${jobId}/approvals` ||
                      pathname ===
                        `/company-admin/job/${jobId}/tagged-list/approvals` ||
                      (loginSelector?.who ===
                        constants.COMPANY_MEMBER.toLowerCase() &&
                        memberSelector?.designation === constants.ADVISER)) && (
                      <button
                        className="py-2 px-4 bg-exwhite-100 text-start hover:bg-secondary-100 hover:text-secondary-400 w-full"
                        onClick={tagCandidatesHandler}
                      >
                        Approve
                      </button>
                    )}

                    <button
                      className="py-2 px-4 bg-exwhite-100 text-start hover:bg-secondary-100 hover:text-secondary-400 w-full"
                      onClick={rejectCandidatesHandler}
                    >
                      Reject
                    </button>
                  </div>
                )}
            </div>
          </div>
          {!jobSelector.isLoading && (
            <InfiniteScroll
              className="overflow-x-auto"
              dataLength={
                jobSelector?.applicants?.applications?.length
                  ? jobSelector?.applicants?.applications?.length
                  : 0
              }
              next={() => getMoreDataHandler()}
              hasMore={jobSelector?.applicants?.metadata?.nextResultURL}
              // height={"calc(100vh - 155px)"}
              style={{
                overflow: "auto",
                maxHeight: "calc(100vh - 155px)",
              }}
              loader={""}
            >
              <table
                className="min-w-full border-separate border-spacing-y-2"
                cellSpacing="10"
              >
                <thead className="sticky top-0 z-10">
                  <tr className="text-left">
                    <th className="text-nowrap px-4 py-2 text-base text-exwhite-100 bg-secondary-800 font-semibold">
                      Candidate Name
                    </th>
                    <th className="text-nowrap px-4 py-2 text-base text-exwhite-100 bg-secondary-800 font-semibold">
                      Email Id
                    </th>
                    <th className="text-nowrap px-4 py-2 text-base text-exwhite-100 bg-secondary-800 font-semibold">
                      Location
                    </th>
                    <th className="text-center text-nowrap px-4 py-2 text-base text-exwhite-100 bg-secondary-800 font-semibold">
                      Yrs of Experience
                    </th>
                    <th className="text-center text-nowrap px-4 py-2 text-base text-exwhite-100 bg-secondary-800 font-semibold">
                      Notice Period
                    </th>
                    <th className="text-center text-nowrap px-4 py-2 text-base text-exwhite-100 bg-secondary-800 font-semibold">
                      Current CTC
                    </th>
                    <th className="text-center text-nowrap px-4 py-2 text-base text-exwhite-100 bg-secondary-800 font-semibold">
                      Expected CTC
                    </th>
                    {((loginSelector?.who ===
                      constants.COMPANY_MEMBER.toLowerCase() &&
                      memberSelector?.designation !== constants.ADVISER) ||
                      loginSelector?.who ===
                        constants.COMPANY.toLowerCase()) && (
                      <th className="text-start text-nowrap px-4 py-2 text-base text-exwhite-100 bg-secondary-800 font-semibold">
                        Approver Name
                      </th>
                    )}
                    <th className="text-start text-nowrap px-4 py-2 text-base text-exwhite-100 bg-secondary-800 font-semibold">
                      Assigned Date & Time
                    </th>
                    <th className="text-center text-nowrap px-4 py-2 text-base text-exwhite-100 bg-secondary-800 font-semibold">
                      Approver Status
                    </th>
                    {((loginSelector?.who ===
                      constants.COMPANY_MEMBER.toLowerCase() &&
                      memberSelector?.designation !== constants.ADVISER) ||
                      loginSelector?.who ===
                        constants.COMPANY.toLowerCase()) && (
                      <th className="text-center text-nowrap px-4 py-2 text-base text-exwhite-100 bg-secondary-800 font-semibold">
                        Action Taken On
                      </th>
                    )}
                    {((loginSelector?.who ===
                      constants.COMPANY_MEMBER.toLowerCase() &&
                      memberSelector?.designation !== constants.ADVISER) ||
                      loginSelector?.who ===
                        constants.COMPANY.toLowerCase()) && (
                      <th className="text-center text-nowrap px-4 py-2 text-base text-exwhite-100 bg-secondary-800 font-semibold">
                        Action Taken By
                      </th>
                    )}
                    <th className="text-center text-nowrap  py-2 text-base text-exwhite-100 bg-secondary-800 font-semibold"></th>
                  </tr>
                </thead>
                <tbody className="space-y-4">
                  {jobSelector?.applicants?.applications?.map((candidate) => (
                    <CandidateCard
                      candidate={candidate}
                      setSelectedCandidates={setSelectedCandidates}
                      selectedCandidates={selectedCandidates}
                      candidateDetails={candidate.author}
                      key={uuid4()}
                    />
                  ))}
                </tbody>
              </table>
              {(!jobSelector?.applicants?.applications ||
                jobSelector?.applicants?.applications.length === 0) && (
                <div className="w-full">
                  <div className="mx-auto w-fit py-5">
                    <div className="flex items-center justify-center">
                      <NoDataFoundIcon />
                    </div>
                    <div>
                      <h1 className="text-3xl text-expurple-800/50 font-semibold text-center">
                        No Data Found
                      </h1>
                      <p className="text-base text-exgray-200 font-medium text-center max-w-sm mx-auto">
                        Could not find any data in source list. Please try
                        again.
                      </p>
                    </div>
                  </div>
                </div>
              )}
            </InfiniteScroll>
          )}
        </div>

        <Popup
          onClose={() => setisAdvisorPopupOpen(false)}
          visible={isAdvisorPopupOpen}
        >
          <AdvisorForm
            selectedCandidates={selectedCandidates}
            onClose={() => setisAdvisorPopupOpen(false)}
            setSelectedCandidates={setSelectedCandidates}
          />
        </Popup>

        <Popup
          onClose={() => setIsAssignPopupOpen(false)}
          visible={isAssignPopupOpen}
        >
          <AssignCandidateForm
            selectedCandidates={selectedCandidates}
            onClose={() => setIsAssignPopupOpen(false)}
            setSelectedCandidates={setSelectedCandidates}
          />
        </Popup>
      </div>
    ) : (
      <div className="flex items-center justify-center py-20">
        <LoadingIcon />
      </div>
    );
  } else {
    return (
      <div className="space-y-1">
        <div className="flex items-center justify-between my-3">
          <div className="w-[40%] flex items-center justify-start gap-2">
            <h1 className="text-base text-exgray-100 font-semibold">
              Source List
            </h1>

            {/* <div className="flex-1 relative pl-2">
              <input
                type="text"
                name="title"
                onChange={onChange}
                // value={value}
                placeholder="Search"
                className="outline outline-2 outline-exgray-100 bg-exwhite-100 py-2 w-full text-base pr-10 placeholder:text-exgray-100 rounded-full text-exgray-400 pl-5 focus:outline-secondary-300"
              />
              <div className="absolute top-0 right-2 h-full flex items-center justify-center">
                <SearchIcon color="#042347" />
              </div>
            </div>

            <div className="cursor-pointer">
              <FilterIcon />
            </div> */}
          </div>

          <div className="relative">
            <button
              className={`text-base border-2 ${
                selectedCandidates && selectedCandidates.length > 0
                  ? "border-secondary-400 bg-secondary-400 text-exwhite-100"
                  : "border-exgray-200 text-exgray-200"
              }  font-semibold text-center px-4 py-1 rounded-full active:scale-95`}
              onClick={() => {
                if (selectedCandidates && selectedCandidates.length > 0)
                  forwardButtonHandler();
              }}
              ref={menuParentRef}
            >
              Move Forward
            </button>

            {isForward &&
              selectedCandidates &&
              selectedCandidates.length > 0 && (
                <div
                  className="absolute top-11 right-0 rounded-md w-56 z-50"
                  style={{
                    boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 25px",
                  }}
                  ref={menuRef}
                >
                  <button className="py-2 px-4 bg-exwhite-100 text-start rounded-t-md hover:bg-secondary-100 w-full hover:text-secondary-400">
                    To Advisor
                  </button>

                  <button className="py-2 px-4 bg-exwhite-100 text-start rounded-b-md hover:bg-secondary-100 hover:text-secondary-400 w-full">
                    To Reporting Manager
                  </button>
                  <button
                    className="py-2 px-4 bg-exwhite-100 text-start rounded-b-md hover:bg-secondary-100 hover:text-secondary-400 w-full"
                    onClick={tagCandidatesHandler}
                  >
                    To Tagged List
                  </button>
                </div>
              )}
          </div>
        </div>
        {appliedCadidateViaRefer && (
          <InfiniteScroll
            className="overflow-x-auto"
            dataLength={
              appliedCadidateViaRefer?.referrals?.length
                ? appliedCadidateViaRefer?.referrals?.length
                : 0
            }
            next={() => getMoreHandler()}
            hasMore={appliedCadidateViaRefer?.metadata?.nextResultURL}
            // hasMore={true}
            height={"calc(100vh - 155px)"}
            style={{ overflow: "auto" }}
            loader={""}
          >
            <table className="min-w-full" cellSpacing="10">
              <thead>
                <tr className="text-left">
                  <th className="text-nowrap px-4 py-2 text-base text-exgray-200/70 font-semibold">
                    Candidate Name
                  </th>
                  <th className="text-nowrap px-4 py-2 text-base text-exgray-200/70 font-semibold">
                    Email Id
                  </th>
                  <th className="text-nowrap px-4 py-2 text-base text-exgray-200/70 font-semibold">
                    Location
                  </th>
                  <th className="text-center text-nowrap px-4 py-2 text-base text-exgray-200/70 font-semibold">
                    Yrs of Experience
                  </th>
                  <th className="text-center text-nowrap px-4 py-2 text-base text-exgray-200/70 font-semibold">
                    Notice Period
                  </th>
                  <th className="text-center text-nowrap px-4 py-2 text-base text-exgray-200/70 font-semibold">
                    Current CTC
                  </th>
                  <th className="text-center text-nowrap px-4 py-2 text-base text-exgray-200/70 font-semibold">
                    Expected CTC
                  </th>
                </tr>
              </thead>
              <tbody className="">
                {appliedCadidateViaRefer?.referrals?.map((candidate) => {
                  if (candidate.status !== "pending") {
                    return (
                      <CandidateCard
                        candidate={candidate}
                        setSelectedCandidates={setSelectedCandidates}
                        selectedCandidates={selectedCandidates}
                        // candidateDetails={candidate.author}
                        key={uuid4()}
                        // candidate={candidate}
                        // setAppliedCadidateViaRefer={setAppliedCadidateViaRefer}
                        // appliedCadidateViaRefer={appliedCadidateViaRefer}
                        candidateDetails={candidate.userDetails}
                        // key={uuid4()}
                      />
                    );
                  }
                })}
              </tbody>
            </table>
          </InfiniteScroll>
        )}
      </div>
    );
  }
};

export default JobApplicants;
