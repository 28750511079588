import { useAppSelector } from "../../config/hooks";

const useWorkLocation=()=>{

  const workLocationArray = useAppSelector((state)=>state.appConfig.workLocations);
  
  const defaultWorkLocation = (defaultId)=>{
    if(isNaN(defaultId)){
      return {Title:defaultId,Id:defaultId}
    }
    const selectedWorkLocation = workLocationArray.find((worklocation)=>
      worklocation.Id == defaultId
    )
    return selectedWorkLocation;
  }
  
  return { workLocationArray, defaultWorkLocation };

}

export default useWorkLocation;