import React, { useState } from "react";
import { FilterClientData } from "../../models/sales.model"; // Import the interface
import { useAppDispatch, useAppSelector } from "../../config/hooks";
import Select from "react-select";
import { addFilters, getfilteredClientsApi } from "../../slices/salesSlice";

const FilterClientForm = ({ togglePopupHandler }) => {
  const dispatch = useAppDispatch();
  const {
    salesEngangementType,
    industries,
    salesStages,
    salesEngageWith,
    salesSubStages,
    salesStatus,
    countries,
  } = useAppSelector((state) => state.appConfig);

  const { leadFilters } = useAppSelector((state) => state.sales);
  const [formData, setFormData] = useState<FilterClientData>({
    industry: leadFilters?.industry || "",
    location: leadFilters?.location || "",
    status: leadFilters?.status || "",
    stages: leadFilters?.stages || "",
    subStages: leadFilters?.subStages || "",
    engageWith: leadFilters?.engageWith || "",
    engagementType: leadFilters?.engagementType || "",
  });

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  //   const handleCheckboxChange = (key: keyof FilterClientData, value: string) => {
  //     setFilters((prev) => ({
  //       ...prev,
  //       [key]: prev[key]?.includes(value)
  //         ? prev[key]?.filter((item) => item !== value)
  //         : [...(prev[key] || []), value],
  //     }));
  //   };

  const handleSubmit = async () => {
    await dispatch(addFilters(formData));
    await dispatch(getfilteredClientsApi(formData));
    togglePopupHandler();

    // onApplyFilters(filters);
    // onClose();
  };

  // const resetFilters = () => {
  //   setFormData({
  //     industry: "",
  //     location: "",
  //     status: "",
  //     stages: "",
  //     subStages: "",
  //     engageWith:"",
  //     engagementType:"",
  //   //   serviceProvider: [],
  //   });
  //   dispatch(getfilteredClientsApi(formData))
  //   togglePopupHandler()
  // };

  const handleIndustryChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.target;
    setFormData({
      ...formData,
      industry: value,
      // subIndustry: "", // Reset sub-industry when industry changes
    });
  };

  const handleMultiSelectChange = (selectedOptions) => {
    const engagementType = selectedOptions
      ? selectedOptions.map((option) => option.value)
      : [];
    setFormData({ ...formData, engagementType });
  };

  return (
    <div className="p-6 w-[40%] bg-white rounded-lg shadow-md h-fit max-h-screenheight  overflow-auto">
      <h2 className="text-lg font-semibold mb-4">Filter Clients</h2>

      <div>
        <div className="grid grid-cols-1  gap-4">
          <select
            name="industry"
            value={formData.industry}
            onChange={handleIndustryChange}
            className="border border-gray-300 rounded-md p-2 w-full"
          >
            <option value="">Select Industry</option>
            {industries.map((industry, index) => (
              <option key={index} value={industry.Id}>
                {industry.Name}
              </option>
            ))}
          </select>

          <div>
            <select
              name="engageWith"
              value={formData.engageWith}
              onChange={handleChange}
              className="border border-gray-300 rounded-md p-2 w-full"
            >
              <option value="">Engagement With</option>
              {salesEngageWith.map((eng, index) => (
                <option key={index} value={eng.value}>
                  {eng.Title}
                </option>
              ))}
            </select>
          </div>

          <div>
            <select
              disabled={!formData.engageWith}
              name="engagementType"
              value={formData.engagementType}
              onChange={handleChange}
              className="border border-gray-300 rounded-md p-2 w-full"
            >
              <option value="">Select Engagement Type</option>
              {salesEngangementType
                .filter((ele) => ele.value == formData.engageWith)
                .map((eng, index) => (
                  <option key={index} value={eng.Id}>
                    {eng.Title}
                  </option>
                ))}
            </select>
          </div>
          {/* <select
              name="subIndustry"
              value={formData.subIndustry}
              onChange={handleChange}
              className="border border-gray-300 rounded-md p-2 w-full"
              disabled={!formData.industry} // Disable if no industry selected
            >
              <option value="">Select Sub-Industry</option>
              {formData.industry &&
                salesSubIndustry.map((sub, index) => (
                  <option key={index} value={sub.Id}>
                    {sub.Title}
                  </option>
                ))}
            </select> */}
          {/* <select
              name="engagementType"
              value={formData.engagementType}
              onChange={handleChange}
              className="border border-gray-300 rounded-md p-2 w-full"
            >
              <option value="">Select Engagement Type</option>
              {salesEngangementType.map((eng, index) => (
                <option key={index} value={eng.Title}>
                  {eng.Title}
                </option>
              ))}
            </select> */}
          {/* <Select
              isMulti
              name="engagementType"
              options={salesEngangementType?.map((eng) => ({
                label: eng.Title,
                value: eng.Title,
              }))}
              className="basic-multi-select"
              placeholder="Select Engagement Type"
              classNamePrefix="select"
              value={formData.engagementType.map((type) => ({
                label: type,
                value: type,
              }))}
              onChange={handleMultiSelectChange}
            /> */}

          <select
            name="stages"
            value={formData.stages}
            onChange={handleChange}
            className="border border-gray-300 rounded-md p-2 w-full"
          >
            <option value="">Select Stage</option>
            {salesStages.map((stage, index) => (
              <option key={index} value={stage.Id}>
                {stage.Title}
              </option>
            ))}
          </select>

          <select
            name="subStages"
            value={formData.subStages}
            onChange={handleChange}
            className="border border-gray-300 rounded-md p-2 w-full"
          >
            <option value="">Select Sub Stage</option>
            {salesSubStages
              .filter((ele) => ele.value == formData.stages)
              .map((stage, index) => (
                <option key={index} value={stage.Id}>
                  {stage.Title}
                </option>
              ))}
          </select>

          <select
            name="status"
            value={formData.status}
            onChange={handleChange}
            className="border border-gray-300 rounded-md p-2 w-full"
          >
            <option value="">Select Status</option>
            {salesStatus
              .filter((ele) => ele.value == formData.stages)
              .map((status, index) => (
                <option key={index} value={status.Id}>
                  {status.Title}
                </option>
              ))}
          </select>

          <input
            type="text"
            name="location"
            placeholder="Location"
            value={formData.location}
            onChange={handleChange}
            className="border border-gray-300 rounded-md p-2 w-full"
          />
        </div>
      </div>

      {/* Buttons */}
      <div className="flex justify-end gap-4 mt-4">
        <button
          onClick={handleSubmit}
          className="bg-primary-400 text-white px-4 py-2 rounded-md shadow hover:bg-primary-600"
        >
          Apply Filters
        </button>
        {/* <button
          onClick={resetFilters}
          className="bg-gray-100 text-gray-600 px-4 py-2 rounded-md shadow hover:bg-gray-200"
        >
          Reset
        </button> */}
        {/* <button
          // onClick={onClose}
          className="bg-red-100 text-red-600 px-4 py-2 rounded-md shadow hover:bg-red-200"
        >
          Cancel
        </button> */}
      </div>
    </div>
  );
};

export default FilterClientForm;
