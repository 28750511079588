import { useEffect } from "react";
import { toast } from "sonner";
import { Outlet, useParams } from "react-router-dom";
import SideMenu from "../../../components/side-menu/SideMenu";
import { useAppDispatch, useAppSelector } from "../../../config/hooks";
import { getMemberByIdApi } from "../../../slices/companyMemberSlice";
import { getMemberDetailsByIdApi } from "../../../slices/companySlice";
import * as constants from "../../../constant";

const Employee = () => {
  const dispatch = useAppDispatch();
  const { memberId } = useParams();
  const userId = useAppSelector((state) => state?.login?.session?.userId);
  const companyDetails = useAppSelector((state) => state.company.companyDetails);
  const loginSelector = useAppSelector((state) => state?.login?.session);

  useEffect(() => {
    try {
      dispatch(
        getMemberDetailsByIdApi(
          {
            memberId:
              loginSelector.who === constants.COMPANY.toLowerCase()
                ? companyDetails.employeeId
                : userId,
          })
      );
    } catch (error) {
      toast.error("Unable to fetch employees data");
    }
  }, []);

  return (
    <div className="">
      <div className="w-[98%] min-h-screenheight grid grid-cols-[300px_1fr] gap-x-5 mx-auto py-4">
        <div>
          <SideMenu />
        </div>
        <div className="flex-1 self-start overflow-x-scroll no-scrollbar ">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default Employee;
