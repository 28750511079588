import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as constants from "../../../constant";
import moment from "moment";
import { useAppDispatch, useAppSelector } from "../../../config/hooks";
import {
  getAllRegularizeRequestApi,
  updateRegularizationApi,
} from "../../../slices/attendanceSlice";
import Popup from "../../../components/popup/Popup";
import ReimbursementForm from "./ReimbursementForm";
import { useFieldArray, useForm } from "react-hook-form";

const MyRiembersmentRequest = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [togglePopup, setTogglePopup] = useState(false);
  const userId = useAppSelector((state) => state?.login?.session?.userId);
  const loginSelector = useAppSelector((state) => state?.login?.session);
  const memberDetail = useAppSelector((state) => state.company.memberDetails);

  // useEffect(() => {
  //   getAllRegularizationRequests();
  // }, []);

  // const getAllRegularizationRequests = () => {
  //   dispatch(
  //     getAllRegularizeRequestApi({
  //       size: 100,
  //       approverId: memberDetail?.id,
  //       companyId:
  //         loginSelector.who === constants.COMPANY.toLowerCase()
  //           ? userId
  //           : memberDetail?.companyId,
  //     })
  //   );
  // };

  const togglePopupHandler = () => {
    setTogglePopup(!togglePopup);
  };

  return (
    <>
      <div className="mt-3">
        <div className="w-fit ml-auto mr-0">
          {/* <button
            className="w-fit py-2 px-8 bg-secondary-400 text-base font-semibold text-exwhite-100 rounded-sm active:scale-95"
            onClick={togglePopupHandler}
          >
            Apply
          </button> */}
        </div>
      </div>

      <Popup onClose={() => setTogglePopup(false)} visible={togglePopup}>
        <ReimbursementForm />
      </Popup>
    </>
  );
};

export default MyRiembersmentRequest;
