import apiClient from "../config/axiosConfig";
import apiJobsClient from "../config/local-axios-config/jobsApiConfig";

class AiService {
  getCvDetails(req) {
    return apiClient.post(`/v1/resume_details`, req, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  getJdDetails(req) {
    return apiClient.post(`/v1/jd_autofilling`, req, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  dataBaseSearchCandidates(req) {
    return apiClient.post(`/v1/search-candidates`, req);
  }

  dataBaseSearchRelevantCandidates(req) {
    return apiClient.post(`/v1/search-similar`, req);
  }

  globalSearchCandidate(data) {
    return apiClient.post(
      `https://api.prod.linkcxo.com/v1/global_search`,
      data
    ); //You can add filter also like "/v1/jobs?filter={"expMin":5}"
  }

  getGlobalSearchCandidateAfterJobCreated(jobId: string) {
    return apiClient.get(`/v1/exo/global-search/jobs/${jobId}/users`); //You can add filter also like "/v1/jobs?filter={"expMin":5}"
  }
}

const aiService = new AiService();
export default aiService;
