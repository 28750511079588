import React, { useEffect, useState } from 'react'
import { toast } from 'sonner';
import SearchIcon from '../../assets/Icons/SearchIcon';
import FilterIcon from '../../assets/Icons/FilterIcon';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useAppDispatch, useAppSelector } from '../../config/hooks';
import { getfilteredClientsApi } from '../../slices/salesSlice';
import { Link } from 'react-router-dom';
import useSalesDropdown from '../../utils/dropdown-options/useSalesDropdown';

const OnboardingProcess = () => {
    const [search, setSearch] = useState("");
    const dispatch=useAppDispatch()
    const {  salesStatus ,salesSubStages} =useAppSelector((state) => state.appConfig);
    const  allClients = useAppSelector((state) => state.sales?.clients);
    const { clients } = allClients || {};
    const [filteredData, setFilteredData] = useState(clients || []);
    const {defaultSalesStages,defaultSalesSubStages,defaultStatus}=useSalesDropdown()
    const [currSubStage,setCurrSubStage]=useState("")
    const [currStatus,setCurrStatus]=useState("")
    
    useEffect(()=>{
      dispatch(getfilteredClientsApi({stages:'2'}))
    },[])

     useEffect(() => {
        setFilteredData(clients); // Sync filtered clients when clients change in Redux
      }, [clients]);


    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
       const value = event.target.value.toLowerCase();
       setSearch(value);
   
       const updatedData =  clients?.filter(
        (client) =>
          client.clientName.toLowerCase().includes(value) ||
          client.domain.toLowerCase().includes(value) ||
          client.location.toLowerCase().includes(value)
      );
       setFilteredData(updatedData);
     }; 

     const getMoreDataHandler = () => {
       try {
         console.log("HIT NEXT URL TABLE");
       //   dispatch(getNextClientsApi());
         // console.log(clients,"CLIENTSHHKDHJKDGH",filteredClients)
       } catch (error) {
         toast.error("Unable to fetch data");
       }
     };

  const handeCurrSubStage=(e)=>{
      const selectedValue = e.target.value;
      setCurrSubStage(selectedValue);
      dispatch(getfilteredClientsApi({stages:'2',subStages:selectedValue,status:currStatus}))
  }

  const handeCurrStatus=(e)=>{
    const selectedValue = e.target.value;
    setCurrStatus(selectedValue);
    dispatch(getfilteredClientsApi({stages:'2',status:selectedValue,subStages:currStatus}))
  }
 return (
   <div className="px-6 py-2 mx-auto  shadow-lg rounded-xl bg-white">
     <div className="py-2 flex justify-between items-center">
       <h1 className="text-xl font-semibold text-exgray-500">
         Onboarding List
       </h1>
       {/* <div className="flex gap-4">
         <button
           // onClick={toggleUploadPopupHandler}
           className="border  border-primary-400 text-primary-400 px-4 py-2 font-semibold rounded-md shadow hover:scale-95"
         >
          Export
         </button>
         <button
           onClick={togglePopupHandler}
           className=" bg-primary-400 text-white px-4 py-2 font-semibold rounded-md shadow hover:bg-primary-600 hover:scale-95"
         >
           <span className="">+</span> Add
         </button>
       </div> */}

      
     </div>

     <div className="flex items-center justify-between mb-4 relative">
       <div className="flex gap-2">
         <input
           type="text"
           placeholder="Search..."
           value={search}
           onChange={handleSearch}
           className="border border-gray-300 rounded-md pl-8 pr-4 py-2 w-72 focus:outline-none focus:ring-2 focus:ring-blue-500"
         />
         <div className="absolute top-2 pl-1">
           <SearchIcon/>
         </div>
        </div>
       {/* <button
       //   onClick={toggleFIlterPopupHandler}
         className="bg-gray-100 text-gray-600 px-4 py-2 rounded-md shadow hover:bg-gray-200 flex gap-2"
       >
         <FilterIcon/>
         Filter
       </button> */}
       <div className='flex gap-4'>

       <select
              name="subStages"
              value={currSubStage}
              onChange={(e)=>handeCurrSubStage(e)}
              className="border border-gray-300 rounded-md p-2 w-44 "
              >
              <option disabled value="">Sub-Stage</option>
              <option  value="">All SubStages</option>
              {salesSubStages.filter((ele)=>ele.value=='2').map((stage, index) => (
                <option key={index} value={stage.Id}>
                  {stage.Title}
                </option>
              ))}          
       </select>
      
       <select
              name="status"
              value={currStatus}
              onChange={(e)=>handeCurrStatus(e)}
              className="border border-gray-300 rounded-md p-2 w-44"
              >
              <option disabled value="">Status</option>
              <option  value="">All Status</option>
              {salesStatus.filter((ele)=>ele.value=='2').map((status, index) => (
                <option key={index} value={status.Id}>
                  {status.Title}
                </option>
              ))}           
       </select>


      </div>
     </div>

      <div className=" overflow-auto">
             <InfiniteScroll
               className=""
               dataLength={ 0}
               next={() => getMoreDataHandler()}
               hasMore={true}
               height={"calc(100vh - 155px)"}
               loader={""}
             >
               <div className="relative ">
                 <table className="min-w-full border border-gray-200 text-sm text-left text-gray-500 ">
                   <thead className="bg-primary-400 text-center   text-white sticky top-0 z-10">
                     <tr>
                       <th className="px-6 py-2 border-r border-gray-400 text-nowrap">S.No.</th>
                       <th className="px-6 py-2 border-r border-gray-400 text-nowrap">Lead Name</th>
                       <th className="px-6 py-2 border-r border-gray-400 text-nowrap">Stage</th>
                       <th className="px-6 py-2 border-r border-gray-400 text-nowrap">SubStage</th>
                       <th className="px-6 py-2 border-r border-gray-400 text-nowrap">Status</th>
                       <th className="px-6 py-2 border-r border-gray-400 text-nowrap">Remarks</th>
                       {/* <th className="px-6 py-2 border-r border-gray-400 text-nowrap">Attachment</th> */}
                     </tr>
                   </thead>
                   <tbody>
                     {filteredData?.map((client, index) => (
                       <tr
                         key={index}
                         className="bg-white hover:bg-gray-50 border-b text-gray-600 text-center "
                       >
                         <td className="px-6 py-3 border border-gray-200">{index+1}</td>
                         <td className="px-6 py-3 border border-gray-200 hover:text-primary-500 hover:font-semibold">
                                                  <Link
                                                 className="w-full block"
                                                 to={`/company-admin/sales/detail/${client.id}`}
                                               >
                                                 {client?.clientName}
                                               </Link></td>
                         <td className="px-6 py-3 border border-gray-200 text-nowrap">
                          {defaultSalesStages(client?.stages)}
                         </td> 
                         <td className="px-6 py-3 border border-gray-200 text-nowrap">{defaultSalesSubStages(client?.subStages)}</td>
                         <td className="px-6 py-3 border border-gray-200 text-nowrap">{defaultStatus(client?.status)}</td>
                         <td className="px-6 py-3 border border-gray-200">{client.remarks}</td>
                         {/* <td className="px-6 py-3 border border-gray-200">{asset.attachment}</td> */}
         
                       </tr>
                     ))} 
                   </tbody>
                 </table>
                 {/* {(filteredClients?.length === 0 || clients == null) && (
                   <div className="text-center py-4 text-gray-500">
                     No clients found.
                   </div>
                 )} */}
               </div>
             </InfiniteScroll>
           </div>

          
   </div>
 )
}

export default OnboardingProcess
