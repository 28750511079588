import React from "react";

const JobFilter = ({ selectedButton, selectButtonHandler }) => {
  return (
    <div className="space-x-2 border-b-2 border-b-exgray-100 pb-2">
      <button
        className={`py-3 px-12  rounded-md text-center text-lg ${
          selectedButton === 0
            ? "bg-expurple-800 text-exwhite-100"
            : "bg-expurple-100 text-expurple-800"
        } font-semibold`}
        onClick={() => selectButtonHandler(0)}
      >
        Applied Jobs
      </button>
      <button
        className={`py-3 px-12  rounded-md text-center text-lg ${
          selectedButton === 1
            ? "bg-expurple-800 text-exwhite-100"
            : "bg-expurple-100 text-expurple-800"
        } font-semibold`}
        onClick={() => selectButtonHandler(1)}
      >
        Saved Jobs
      </button>
      {/* <button
        className={`py-3 px-12  rounded-md text-center text-lg ${
          selectedButton === 2
            ? "bg-expurple-800 text-exwhite-100"
            : "bg-expurple-100 text-expurple-800"
        } font-semibold`}
        onClick={() => selectButtonHandler(2)}
      >
        Invitations
      </button> */}
    </div>
  );
};

export default JobFilter;
