import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as constants from "../../../constant";
import moment from "moment";
import { useAppDispatch, useAppSelector } from "../../../config/hooks";
import { getAllRegularizeRequestApi, updateRegularizationApi } from "../../../slices/attendanceSlice";



const ReimbersmentRequestList = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [togglePopup, setTogglePopup] = useState(false);
  const userId = useAppSelector((state) => state?.login?.session?.userId);
  //const attendanceList = useAppSelector((state) => state?.attendance?);
  const [startDateSelected, setStartDateSelected] = useState(null);
  const loginSelector = useAppSelector((state) => state?.login?.session);
  const memberDetail = useAppSelector((state) => state.company.memberDetails); //that is login user details
  const regularizeRequest = useAppSelector((state) => state.attendance.regularizationRequests);

  useEffect(() => {
    getAllRegularizationRequests();
  }, []);

  const getAllRegularizationRequests = () => {
    dispatch(
      getAllRegularizeRequestApi(
        {
          size: 100,
          approverId: memberDetail?.id,
          companyId: loginSelector.who === constants.COMPANY.toLowerCase()
            ? userId
            : memberDetail?.companyId,
        }
      )
    )
  }

  const updateRegularization = (Id, status) => {
    dispatch(
      updateRegularizationApi({
        regularizationId: Id,
        status: status
      })
    )
  }

  // useEffect(() => {
  //   var timer = null;
  //   if (!startDateSelected) {
  //     timer = setInterval(() => setDate(new Date()), 1000)
  //   }
  //   return function cleanup() {
  //     clearInterval(timer)
  //   }
  // });

  const togglePopupHandler = () => {
    setTogglePopup(!togglePopup);
  };

  // const regularizeAtten = (attendance) => {
  //   setRegularizeAttendance(attendance);
  //   setTogglePopup(true);
  // }

  return (
    <>
      <div className="mt-3">
        {/* <h1 className="text-lg text-exgray-900 font-medium">Regularization Requests</h1> */}

      </div >
    </>
  );
};

export default ReimbersmentRequestList;
