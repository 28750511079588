import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import "../../../src/App.css";

const CustomDatePicker = ({
  isMandatory = false,
  name,
  title,
  showTimeSelect = false,
  error,
  borderWidth = "border",
  borderColor = "border-exgray-200",
  ...props
}) => {
  return (
    <div className="space-y-1">
      <label
        className="text-exgray-200/70 text-base font-semibold"
        htmlFor={name}
      >
        {title}
        {isMandatory && " *"}
      </label>
      <div className="w-full">
        <DatePicker
          showTimeSelect={showTimeSelect}
          className={`w-full h-10 px-3  rounded-md outline-none ${borderWidth} ${
            error ? "border-[#dc2623]" : borderColor
          } text-exgray-800 text-base ${
            props.disabled
              ? "bg-exgray-100/50 placeholder:text-exgray-200"
              : "bg-exwhite-100 placeholder:text-gray-400"
          }`}
          {...props}
          autoComplete="off"
          id={name}
        />
      </div>
      <p className="text-red-400">{error}</p>
    </div>
  );
};

export default CustomDatePicker;
