import React, { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import UploadIcon from "../../../../assets/Icons/UploadIcon";
import { unwrapResult } from "@reduxjs/toolkit";
import uploadContent from "../../../../utils/upload-content/uploadContent";
import { useAppDispatch, useAppSelector } from "../../../../config/hooks";
import ExInput from "../../../../components/basic-components/ExInput";
import ExTextarea from "../../../../components/basic-components/ExTextarea";
import ExDropdown from "../../../../components/basic-components/ExDropdown";
import useCompanyTypes from "../../../../utils/dropdown-options/useCompanyTypes";
import useIndustries from "../../../../utils/dropdown-options/useIndustries";
import useCompanySize from "../../../../utils/dropdown-options/useCompanySize";
import CustomDatePicker from "../../../../components/basic-components/CustomDatePicker";
import useLocations from "../../../../utils/dropdown-options/useLocations";
import {
  getCompanyDetail,
  updateCompany,
} from "../../../../slices/companySlice";
import { createCompanyApi } from "../../../../slices/loginSlice";
import SuccessIcon from "../../../../assets/Icons/SuccessIcon";
import RightArrowIcon from "../../../../assets/Icons/RightArrowIcon";
import { Link, useNavigate, useParams } from "react-router-dom";
import * as constatnt from "../../../../constant";
import StandardEditIcon from "../../../../assets/Icons/StandardEditIcon";
import { toast } from "sonner";

const CompanyProfile = () => {
  const dispatch = useAppDispatch();
  const { companyId } = useParams();
  const navigate = useNavigate();

  const loginSelector = useAppSelector((state) => state.login);
  const companySelector = useAppSelector((state) => state.company);

  const profileImageInputRef = useRef(null);
  const bannerImageInputRef = useRef(null);
  const submitBtnRef = useRef(null);

  const { companyTypesArray, defaultCompanyType } = useCompanyTypes();
  const { industriesArray, defaultIndustry } = useIndustries();
  const { companySizeArray, defaultCompanySize } = useCompanySize();
  const { locationsArray, defaultLocation } = useLocations();

  const [content, setContent] = useState({
    profileImage: "",
    bannerImage: "",
  });

  const [companyDetails, setCompanyDetails] = useState(null);

  const form = useForm({
    defaultValues: {
      companyName: "",
      discription: "",
      domainName: companySelector?.companyDomain || "",
      tagline: "",
      organizationType: null,
      industryTypes: null,
      size: null,
      yearsOfEstablishment: "",
      companyWebSiteLink: "",
      headOfficeLocation: null,
    },
  });

  const { handleSubmit, control, formState, reset } = form;
  const { errors, isDirty } = formState;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await dispatch(
          getCompanyDetail({ id: companyId })
        ).then(unwrapResult);
        const data = response.data.data;
        setCompanyDetails(data);
        reset({
          companyName: data.title,
          discription: data.description,
          domainName: data.domain,
          tagline: data.tagline,
          organizationType: defaultCompanyType(data.registrationType),
          industryTypes: defaultIndustry(data.industry),
          size: defaultCompanySize(data.size),
          yearsOfEstablishment: data.yearOfEstablishment,
          companyWebSiteLink: data.pageName,
          headOfficeLocation: defaultLocation(data.location),
        });
      } catch (error) {}
    };

    if (companyId) fetchData();
  }, [dispatch, companyId, reset]);

  const profileHandleClick = () => {
    if (profileImageInputRef.current) profileImageInputRef.current.click();
  };

  const bannerHandleClick = () => {
    if (bannerImageInputRef.current) bannerImageInputRef.current.click();
  };

  const submitButtonHandler = () => {
    if (submitBtnRef.current) submitBtnRef.current.click();
  };

  const navigateHandler = () => {
    if (companyId) navigate("/company-admin");
    else navigate("/company/register");
  };

  const onChangeHandler = (e) => {
    const name = e.target.name;
    if (e.target.files && e.target.files.length > 0) {
      uploadContent.uploadCompanyProfileContent(
        companyId,
        e.target.files,
        (response) => {
          const url = response[0].url;
          let companyData;
          if (name === "bannerImage") {
            companyData = {
              domain: companyDetails.domain,
              emailId: companyDetails.emailId,
              bannerImage: url,
              profileImage: companyDetails.profileImage
                ? companyDetails.profileImage
                : "",
              title: companyDetails.title,
              description: companyDetails.description,
              tagline: companyDetails.tagline,
              location: companyDetails.location,
              registrationType: companyDetails.registrationType,
              size: companyDetails.size,
              industry: companyDetails.industry,
              authorization: true,
              pageName: companyDetails.pageName,
              isPhoneVerified: companyDetails.isPhoneVerified,
              isEmailVerified: companyDetails.isEmailVerified,
              yearsOfEstablishment: companyDetails.yearOfEstablishment,
            };
            setCompanyDetails({ ...companyDetails, bannerImage: url });
          } else {
            companyData = {
              domain: companyDetails.domain,
              emailId: companyDetails.emailId,
              bannerImage: companyDetails.bannerImage
                ? companyDetails.bannerImage
                : "",
              profileImage: url,
              title: companyDetails.title,
              description: companyDetails.description,
              tagline: companyDetails.tagline,
              location: companyDetails.location,
              registrationType: companyDetails.registrationType,
              size: companyDetails.size,
              industry: companyDetails.industry,
              authorization: true,
              pageName: companyDetails.pageName,
              isPhoneVerified: companyDetails.isPhoneVerified,
              isEmailVerified: companyDetails.isEmailVerified,
              yearsOfEstablishment: companyDetails.yearOfEstablishment,
            };
            setCompanyDetails({ ...companyDetails, profileImage: url });
          }

          dispatch(
            updateCompany({
              ...companyData,
              id: companyId,
            })
          );
        }
      );
    }
  };

  const onSubmit = async (formData) => {
    const companyData = {
      domain: formData.domainName,
      emailId: companySelector.companyEmail,
      requestId: loginSelector.ui.requestId,
      bannerImage: companyId
        ? content.bannerImage
          ? content.bannerImage
          : companySelector?.companyDetails?.bannerImage
        : "",
      profileImage: companyId
        ? content.profileImage
          ? content.profileImage
          : companySelector?.companyDetails?.profileImage
        : "",
      title: formData.companyName,
      description: formData.discription,
      tagline: formData.tagline,
      location: formData.headOfficeLocation.Id.toString(),
      registrationType: formData.organizationType.Id.toString(),
      size: formData.size.Id,
      industry: formData.industryTypes.Id.toString(),
      authorization: true,
      pageName: formData.companyWebSiteLink,
      yearOfEstablishment: formData.yearsOfEstablishment,
    };

    if (companyId) {
      await dispatch(
        updateCompany({
          ...companyData,
          isEmailVerified: companySelector?.companyDetails?.isEmailVerified,
          isPhoneVerified: companySelector?.companyDetails?.isPhoneVerified,
          id: companyId,
        })
      ).then(unwrapResult);
      navigateHandler();
      toast.success("Updated Successfully.");
    } else {
      await dispatch(createCompanyApi(companyData)).then(unwrapResult);
      toast.error("Something went wrong");
      // navigateHandler();
    }
  };

  if (
    loginSelector?.session?.userId &&
    loginSelector?.session?.who === "company" &&
    !companyId
  ) {
    return (
      <div className="min-h-screenheight w-[95%] mx-auto py-4 flex items-center justify-center">
        <div className="space-y-4">
          <div className="w-fit mx-auto">
            <SuccessIcon />
          </div>
          <div>
            <h1 className="text-2xl text-expurple-600 font-semibold text-center">
              🎉 Congratulations, You profile is 100% complete!
            </h1>
            <p className="text-base text-exgray-200 text-center max-w-lg mx-auto">
              Donec hendrerit, ante mattis pellentesque eleifend, tortor urna
              malesuada ante, eget aliquam nulla augue hendrerit ligula. Nunc
              mauris arcu, mattis sed sem vitae.
            </p>
          </div>
          <div className="flex items-center justify-center gap-4">
            <Link
              to={`/compnay-profile-edit/${loginSelector?.session?.userId}`}
              className="text-lg text-center font-semibold text-expurple-800 px-10 py-2 rounded-md bg-expurple-100"
            >
              View Dashboard
            </Link>

            <Link
              to={"/company-admin"}
              className="text-lg text-center font-semibold text-exwhite-100 px-10 py-2 rounded-md bg-expurple-800 flex items-center justify-between gap-4"
            >
              Post Job
              <div>
                <RightArrowIcon />
              </div>
            </Link>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screenheight w-[80%] mx-auto py-4 space-y-4">
      <div className="flex items-center justify-between">
        <h1 className="text-3xl text-expurple-700/50 font-semibold">
          Create Company
        </h1>
        <div className="space-x-3">
          <button
            className="px-10 py-2 rounded-md bg-exgray-100 text-expurple-800 text-base font-semibold text-center"
            onClick={navigateHandler}
          >
            Back
          </button>
          <button
            type="button"
            className="px-10 py-2 rounded-md bg-expurple-800 text-exwhite-100 text-base font-semibold text-center"
            onClick={submitButtonHandler}
          >
            Save
          </button>
        </div>
      </div>

      <div className="space-y-2">
        {companyId && (
          <div className="flex items-center justify-center gap-4">
            {companySelector?.companyDetails?.profileImage ? (
              <div className="w-72 h-72 rounded-lg flex items-center justify-center overflow-hidden relative">
                <img
                  src={`${constatnt.baseUrl}/${companySelector?.companyDetails?.profileImage}?t=${loginSelector?.session?.token}`}
                  alt="profile"
                  className="w-full h-full object-cover"
                />

                <div
                  className="h-10 w-10 bg-expurple-100 absolute bottom-2 right-2 flex items-center justify-center rounded-lg cursor-pointer"
                  onClick={profileHandleClick}
                >
                  <StandardEditIcon color="#223870" />
                  <input
                    ref={profileImageInputRef}
                    type="file"
                    name="profileImage"
                    multiple
                    accept="image/*"
                    onChange={onChangeHandler}
                    style={{ display: "none" }}
                  />
                </div>
              </div>
            ) : (
              <div className="w-72 h-72 border-2 border-dashed border-exgray-200 rounded-lg flex items-center justify-center">
                <div className="text-center w-[90%] mx-auto">
                  <div
                    className="cursor-pointer w-fit mx-auto"
                    onClick={profileHandleClick}
                  >
                    <div>
                      <UploadIcon />
                    </div>
                    <input
                      ref={profileImageInputRef}
                      type="file"
                      name="profileImage"
                      multiple
                      accept="image/*"
                      onChange={onChangeHandler}
                      style={{ display: "none" }}
                    />
                  </div>
                  <h2 className="text-lg text-black font-semibold">
                    Browse Photo
                  </h2>
                  <p className="text-base text-exgray-500">
                    A photo larger than 400 pixels work best. Max photo size 5
                    MB.
                  </p>
                </div>
              </div>
            )}

            {companySelector?.companyDetails?.bannerImage ? (
              <div className="flex-1 h-72 rounded-lg flex items-center justify-center overflow-hidden relative">
                <img
                  src={`${constatnt.baseUrl}/${companySelector?.companyDetails?.bannerImage}?t=${loginSelector?.session?.token}`}
                  alt="profile"
                  className="w-full h-full object-cover"
                />
                <div
                  className="h-10 w-10 bg-expurple-100 absolute bottom-2 right-2 flex items-center justify-center rounded-lg cursor-pointer"
                  onClick={bannerHandleClick}
                >
                  <input
                    ref={bannerImageInputRef}
                    type="file"
                    name="bannerImage"
                    accept="image/*"
                    onChange={onChangeHandler}
                    style={{ display: "none" }}
                  />
                  <StandardEditIcon color="#223870" />
                </div>
              </div>
            ) : (
              <div className="flex-1 h-72 rounded-lg flex items-center justify-center bg-expurple-100">
                <div className="text-center w-[90%] mx-auto">
                  <div
                    className="cursor-pointer w-fit mx-auto"
                    onClick={bannerHandleClick}
                  >
                    <div>
                      <UploadIcon />
                    </div>
                    <input
                      ref={bannerImageInputRef}
                      type="file"
                      name="bannerImage"
                      accept="image/*"
                      onChange={onChangeHandler}
                      style={{ display: "none" }}
                    />
                  </div>
                  <h2 className="text-lg text-black font-semibold">
                    Browse Photo
                  </h2>
                  <p className="text-base text-exgray-500">
                    A photo larger than 400 pixels work best. Max photo size 5
                    MB.
                  </p>
                </div>
              </div>
            )}
          </div>
        )}

        <form onSubmit={handleSubmit(onSubmit)} className="space-y-2">
          <div>
            <Controller
              name="companyName"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "Company name is required",
                },
              }}
              render={({ field: { onChange, value } }) => (
                <ExInput
                  placeholder="Enter your company name"
                  title="Company Name"
                  name="companyName"
                  value={value || ""}
                  onChange={onChange}
                  error={errors?.companyName?.message}
                />
              )}
            />
          </div>

          <div>
            <Controller
              name="discription"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "Description is required",
                },
                maxLength: {
                  value: 2600,
                  message: "Only 2600 characters are allowed",
                },
              }}
              render={({ field: { onChange, value } }) => (
                <ExTextarea
                  rows={8}
                  name="discription"
                  value={value || ""}
                  title="About Us"
                  maxLength={2600}
                  onChange={onChange}
                  placeholder="Enter here"
                  error={errors?.discription?.message}
                />
              )}
            />
          </div>

          <div>
            <Controller
              name="domainName"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "Domain name is required",
                },
              }}
              render={({ field: { onChange, value } }) => (
                <ExInput
                  placeholder="Enter your domain name"
                  title="Domain Name"
                  name="domainName"
                  value={companySelector?.companyDomain || value}
                  onChange={onChange}
                  readOnly={true}
                  error={errors?.domainName?.message}
                />
              )}
            />
          </div>

          <div>
            <Controller
              name="tagline"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "Company tagline is required",
                },
              }}
              render={({ field: { onChange, value } }) => (
                <ExInput
                  placeholder="Enter here"
                  title="Company Tagline"
                  name="tagline"
                  maxLength={50}
                  value={value || ""}
                  onChange={onChange}
                  error={errors?.tagline?.message}
                />
              )}
            />
          </div>

          <div className="space-y-2">
            <h1 className="text-xl font-semibold text-expurple-800">
              Founding Info
            </h1>
            <div className="grid grid-cols-3 auto-rows-auto gap-2">
              <div>
                <Controller
                  name="organizationType"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: "Organization Type is required",
                    },
                  }}
                  render={({ field: { onChange, value } }) => (
                    <ExDropdown
                      title="Organization Type *"
                      onChange={onChange}
                      placeholder="Select"
                      value={value || null}
                      optionValue="Id"
                      optionLabel="Title"
                      options={companyTypesArray}
                      dropDownHeight="40px"
                      dropDownBorderWidth="0px"
                      dropDownBorderColor="#696969"
                      error={errors?.organizationType?.message}
                    />
                  )}
                />
              </div>

              <div>
                <Controller
                  name="industryTypes"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: "Industry Type is required",
                    },
                  }}
                  render={({ field: { onChange, value } }) => (
                    <ExDropdown
                      title="Industry Type *"
                      onChange={onChange}
                      placeholder="Select"
                      value={value || null}
                      optionValue="Id"
                      optionLabel="Name"
                      options={industriesArray}
                      dropDownHeight="40px"
                      dropDownBorderWidth="0px"
                      dropDownBorderColor="#696969"
                      error={errors?.industryTypes?.message}
                    />
                  )}
                />
              </div>

              <div>
                <Controller
                  name="size"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: "Team size is required",
                    },
                  }}
                  render={({ field: { onChange, value } }) => (
                    <ExDropdown
                      title="Team Size *"
                      onChange={onChange}
                      placeholder="Select"
                      value={value || null}
                      optionValue="Id"
                      optionLabel="Title"
                      options={companySizeArray}
                      dropDownHeight="40px"
                      dropDownBorderWidth="0px"
                      dropDownBorderColor="#696969"
                      error={errors?.size?.message}
                    />
                  )}
                />
              </div>

              <div className="">
                <Controller
                  name="yearsOfEstablishment"
                  control={control}
                  rules={{
                    required: {
                      value: false,
                      message: "Start date is required",
                    },
                  }}
                  render={({ field: { onChange, value } }) => (
                    <CustomDatePicker
                      title="Year of Establishment"
                      name="yearsOfEstablishment"
                      onChange={onChange}
                      selected={value ? new Date(value) : null}
                      maxDate={new Date()}
                      showYearDropdown={true}
                      dropdownMode="select"
                      placeholderText="Ex. dd/mm/yy"
                      popperPlacement="bottom-end"
                      dateFormat="dd MMMM yyyy"
                      // showMonthYearPicker={false}
                      error={errors?.yearsOfEstablishment?.message}
                    />
                  )}
                />
              </div>

              <div>
                <Controller
                  name="companyWebSiteLink"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: "Company link is required",
                    },
                  }}
                  render={({ field: { onChange, value } }) => (
                    <ExInput
                      placeholder="Website url..."
                      title="Company Website"
                      name="companyWebSiteLink"
                      value={value || ""}
                      onChange={onChange}
                      error={errors?.companyWebSiteLink?.message}
                    />
                  )}
                />
              </div>

              <div>
                <Controller
                  name="headOfficeLocation"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: "Location is required",
                    },
                  }}
                  render={({ field: { onChange, value } }) => (
                    <ExDropdown
                      title="Head office Location *"
                      onChange={onChange}
                      placeholder="Select"
                      value={value || null}
                      optionValue="Id"
                      optionLabel="Name"
                      options={locationsArray}
                      dropDownHeight="40px"
                      dropDownBorderWidth="0px"
                      dropDownBorderColor="#696969"
                      error={errors?.headOfficeLocation?.message}
                    />
                  )}
                />
              </div>
            </div>
          </div>

          <button
            type="submit"
            className="hidden"
            disabled={!isDirty}
            ref={submitBtnRef}
          >
            Save
          </button>
        </form>
      </div>
    </div>
  );
};

export default CompanyProfile;
