import { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../config/hooks";
import { useParams } from "react-router-dom";
import {
  getApplicantHistoryByIdApi,
  sortLogsReducer,
} from "../../slices/jobSlice";
import NoDataFoundIcon from "../../assets/Icons/NoDataFoundIcon";
import LoadingIcon from "../../assets/Icons/LoadingIcon";
import { toast } from "sonner";
import { unwrapResult } from "@reduxjs/toolkit";
import Log from "./Log";
import { v4 as uuid4 } from "uuid";
import ArrowIcon from "../../assets/Icons/ArrowIcon";

const CandidateLogs = () => {
  const dispatch = useAppDispatch();
  const { applicationId } = useParams();

  const menuRef = useRef(null);
  const menuParentRef = useRef(null);

  const [isSortToggle, setIsSortToggle] = useState(false);

  const logsSelector = useAppSelector((state) => state?.jobs?.applicationLog);

  const { error, isLoading, logs, sort } = logsSelector || {};

  useEffect(() => {
    const fetchData = async () => {
      try {
        await dispatch(
          getApplicantHistoryByIdApi({
            applicantId: applicationId,
            sort: sort,
          })
        ).then(unwrapResult);
      } catch (error) {
        toast.error("Something went wrong");
      }
    };

    fetchData();
  }, [applicationId, dispatch, sort]);

  useEffect(() => {
    const toggleHandler = (e) => {
      if (
        menuRef.current &&
        menuParentRef &&
        !menuRef.current.contains(e.target) &&
        !menuParentRef.current.contains(e.target)
      ) {
        // close popup
        setIsSortToggle(false);
      }
    };
    document.addEventListener("mousedown", toggleHandler);

    return () => {
      document.removeEventListener("mousedown", toggleHandler);
    };
  });

  const toggleSortingPopupHandler = () => {
    setIsSortToggle(!isSortToggle);
  };

  const changeSortingHandler = (sortingWay) => {
    dispatch(sortLogsReducer(sortingWay));
    setIsSortToggle(false);
  };

  if (error) {
    return (
      <div className="w-full">
        <div className="mx-auto w-fit py-5">
          <div className="flex items-center justify-center">
            <NoDataFoundIcon />
          </div>
          <div>
            <h1 className="text-3xl text-expurple-800/50 font-semibold text-center">
              No Data Found
            </h1>
            <p className="text-base text-exgray-200 font-medium text-center max-w-sm mx-auto">
              Could not find any data in application history. Please try again.
            </p>
          </div>
        </div>
      </div>
    );
  }

  return isLoading ? (
    <div className="flex items-start justify-center py-8 h-popupScreenheight">
      <LoadingIcon />
    </div>
  ) : logs && logs?.length > 0 ? (
    <div className="">
      <div className="self-start pl-2 relative w-fit ml-auto mr-6">
        <h1
          className="flex items-center justify-start font-semibold cursor-pointer text-secondary-800"
          onClick={toggleSortingPopupHandler}
          ref={menuParentRef}
        >
          {sort === "asc" ? "Newest Last" : "Newest first"}
          <div className="pt-[2px] pl-[3px]">
            <ArrowIcon width="20" height="20" color="black" />
          </div>
        </h1>

        {isSortToggle && (
          <div
            className="w-40 bg-exwhite-100 absolute top-7 right-0 shadow-2xl shadow-black rounded-sm z-50"
            style={{
              boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
            }}
            ref={menuRef}
          >
            <button
              type="button"
              className={`py-2 w-full text-start text-sm hover:bg-exgray-100/20 pl-2 rounded-t-sm ${
                sort === "" && "bg-secondary-100 text-primary-400"
              }`}
              onClick={() => changeSortingHandler("")}
            >
              Newest First
            </button>
            <button
              type="button"
              className={`py-2 w-full text-start text-sm hover:bg-exgray-100/20 pl-2 ${
                sort === "asc" && "bg-secondary-100 text-primary-400"
              }`}
              onClick={() => changeSortingHandler("asc")}
            >
              Newest Last
            </button>
          </div>
        )}
      </div>
      <div className="max-h-popupScreenheight min-h-96 overflow-y-auto space-y-6 no-scrollbar">
        {logs.map((log, index) => (
          <Log log={log} index={index} logs={logs} key={uuid4()} />
        ))}
      </div>
    </div>
  ) : (
    <div className="w-full">
      <div className="mx-auto w-fit py-5">
        <div className="flex items-center justify-center">
          <NoDataFoundIcon />
        </div>
        <div>
          <h1 className="text-3xl text-expurple-800/50 font-semibold text-center">
            No Data Found
          </h1>
          <p className="text-base text-exgray-200 font-medium text-center max-w-sm mx-auto">
            Could not find any data in application history. Please try again.
          </p>
        </div>
      </div>
    </div>
  );
};

export default CandidateLogs;
