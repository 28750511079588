import React from "react";

const StarsIcon = () => {
  return (
    <svg
      width="14"
      height="18"
      viewBox="0 0 14 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.8974 12.1701C7.26496 12.1701 5.44872 10.3934 5.44872 6.84016C5.44872 10.3934 3.63248 12.1701 0 12.1701C3.45442 12.1701 5.23504 13.7725 5.44872 16.9426C5.44872 17.1168 5.44872 17.291 5.44872 17.5C5.44872 13.9467 7.26496 12.1701 10.8974 12.1701ZM7.12251 4.88934C7.12251 3.42623 7.87037 2.69467 9.3661 2.69467C7.87037 2.69467 7.12251 1.96311 7.12251 0.5C7.12251 1.96311 6.37464 2.69467 4.87892 2.69467C6.37464 2.69467 7.12251 3.42623 7.12251 4.88934Z"
        fill="#8833FF"
      />
      <path
        d="M10.5919 10.7872C10.5919 8.95646 11.5072 8.04108 13.338 8.04108C11.5072 8.04108 10.5919 7.12569 10.5919 5.29492C10.5919 7.12569 9.67647 8.04108 7.8457 8.04108C9.67647 8.04108 10.5919 8.95646 10.5919 10.7872Z"
        fill="#8833FF"
      />
    </svg>
  );
};

export default StarsIcon;
