import { AxiosPromise } from "axios";
import { Agreement, AgreementData, CreateInteractionReq, Interaction } from "../models/interaction.model";
import { ApiResponse } from "../models/base.model";
import apiClient from "../config/axiosConfig";

class InteractionService {
    createInteraction(
       interaction:CreateInteractionReq
      ): AxiosPromise<ApiResponse<Interaction>> {
        return apiClient.post(`/v1/exo/clients/${interaction.clientId}/interactions`, interaction);
    }

    getAllInteractions(clientId:string): AxiosPromise<ApiResponse<Array<Interaction>>> {
         return apiClient.get(`/v1/exo/clients/${clientId}/interactions?stages=1`);
     }
    
    getAllOnboardingInteractions(clientId:string): AxiosPromise<ApiResponse<Array<Interaction>>> {
        return apiClient.get(`/v1/exo/clients/${clientId}/interactions?stages=2`);
    }

    getAllAgreements(clientId:string) {
        return apiClient.get(`/v1/exo/clients/${clientId}/service-agreement`);
    }

    getAgreementById(data:AgreementData) {
        return apiClient.get(`/v1/exo/clients/${data.clientId}/service-agreement/${data.agreementId}`);
    }
    
    createAgreement(
        agreement:Agreement
       ): AxiosPromise<ApiResponse<Agreement>> {
         return apiClient.post(`/v1/exo/clients/${agreement.clientId}/service-agreement`, agreement);
     }

    updateAgreement(
        agreement:Agreement
       ): AxiosPromise<ApiResponse<Agreement>> {
         return apiClient.put(`/v1/exo/clients/${agreement.clientId}/service-agreement/${agreement.agreementId}`, agreement);
    }
    

    // getClientById(clientId:string):AxiosPromise<ApiResponse<Client>>{
    //     return apiClient.get(`/v1/exo/clients/${clientId}`);
    // }

    // checkDomain(domain:string):AxiosPromise<ApiResponse<CompanyProfile>>{
    //     return apiClient.get(`/v1/exo/companies/domain?domain=${domain}`)
    // }

}

const interactionService=new InteractionService();
export default interactionService;