import moment from "moment";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Popup from "../../../components/popup/Popup";
import ProfileAvatar from "../../../components/ProfileAvatar";
import { useAppDispatch, useAppSelector } from "../../../config/hooks";
import {
  getAllAttendanceApi,
  postAttedanceApi,
  updateOutTimeApi,
} from "../../../slices/attendanceSlice";
import DailyReportForm from "./DailyReportForm";
import * as constants from "../../../constant";

const EmployeeCommenView = (props) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const memberDetail = useAppSelector((state) => state.company.memberDetails);
  const totalLeaveBalance = useAppSelector((state) => state.leave.totalLeaveBalance);
  const [togglePopup, setTogglePopup] = useState(false);
  const [date, setDate] = useState(new Date());
  const token = useAppSelector((state) => state?.login?.session?.token);
  const [startDisabled, setStartDisabled] = useState(false);
  const [startDateSelected, setStartDateSelected] = useState(null);
  const togglePopupHandler = () => {
    setTogglePopup(!togglePopup);
  };

  if ("geolocation" in navigator) {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        // On success, update the state with the latitude and longitude
        // setLocation({
        //   latitude: position.coords.latitude,
        //   longitude: position.coords.longitude,
        // });
      },
      (err) => {
        // On error, set the error message
        //setError(err.message);
      }
    );
  } else {
  }

  const [location, setLocation] = useState({
    latitude: null,
    longitude: null,
  });

  useEffect(() => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLocation({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          });
        },
        (err) => { }
      );
    } else {
    }
  }, []);

  useEffect(() => {
    var timer = setInterval(() => setDate(new Date()), 1000);
    return function cleanup() {
      clearInterval(timer);
    };
  });

  const [device, setDevice] = useState("");

  // useEffect(() => {
  //   const checkDevice = () => {
  //     const width = window.innerWidth;
  //     if (width <= 768) {
  //       setDevice('Mobile');
  //     } else if (width <= 900) {
  //       setDevice('Tablet');
  //     } else {
  //       setDevice('Desktop');
  //     }
  //   };
  //   checkDevice();
  //   window.addEventListener('resize', checkDevice);
  //   return () => window.removeEventListener('resize', checkDevice);
  // }, []);
  const userAgent = navigator.userAgent.toLowerCase();

  const postAttedance = (selectedDate) => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLocation({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          });
        },
        (err) => { }
      );
    }

    // if (/mobile/i.test(userAgent)) {
    //   setDevice("Mobile");
    // } else {
    //   setDevice("Desktop");
    // }

    if (/mobile/i.test(userAgent)) {
      alert("Please checkIn from your official laptop");
    } else {
      setStartDisabled(true);
      setStartDateSelected(selectedDate);
      dispatch(
        postAttedanceApi({
          employeeId: memberDetail.id,
          date: new Date(),
          checkIn: selectedDate,
          //checkOut: undefined,
          status: "",
          checkInLocation: {
            type: "Point",
            coordinates: [location.latitude, location.longitude],
          },
          checkOutLocation: {
            type: "Point",
            coordinates: [],
          },
        })
      );
    }
  };

  const updateEndTime = () => {
    setTogglePopup(!togglePopup);
  };

  const markEndTime = () => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLocation({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          });
        },
        (err) => { }
      );
    }
    dispatch(
      updateOutTimeApi({
        employeeId: memberDetail.id,
        checkOut: new Date(),
        attendanceId: todayAttendance.id,
        status: "Present",
        checkOutLocation: {
          type: "Point",
          coordinates: [location.latitude, location.longitude],
        },
      })
    );
    setTogglePopup(!togglePopup);
    getAllAttendence();
  };

  const getAllAttendence = () => {
    let month =
      moment().month() > 8 ? moment().month() + 1 : 0 + moment().month() + 1;
    let year = moment().year();
    dispatch(
      getAllAttendanceApi({
        dateFrom: moment(`01/${month}/${year}`).format("YYYY-MM-DD"),
        dateTo: moment().format("YYYY-MM-DD"),
        employeeId: memberDetail.id,
      })
    );
  };

  const todayAttendance = useAppSelector(
    (state) => state?.attendance?.todayAttendance
  );

  var startTime = moment(todayAttendance?.checkIn);
  var endTime = moment(new Date());
  var dif = moment.duration(endTime?.diff(startTime));

  return (
    <>
      <div className="flex space-x-3">
        <div className="flex bg-exwhite-100 p-3 rounded-md w-5/12 items-center border border-secondary-200 shadow shadow-2">
          {memberDetail?.profileImage ? (
            <img
              src={`${constants.baseUrl}/${memberDetail?.profileImage}?t=${token}`}
              alt="profile"
              className="h-20 w-20 rounded-full"
            />
          ) : (
            memberDetail?.firstName && (
              <ProfileAvatar
                firstName={memberDetail?.firstName}
                lastName={memberDetail?.lastName}
              />
            )
          )}
          <div className="mx-3">
            <h1 className="text-lg font-semibold text-secondary-700">
              {memberDetail?.firstName} {memberDetail?.lastName}
            </h1>
            <h1 className="text-base">{memberDetail?.jobProfile}</h1>
            <h1 className="text-sm text-secondary-800 font-medium">
              {memberDetail?.employeeCode}
            </h1>
            <h1 className="text-sm text-secondary-800 font-medium">
              Reporting Manager :{" "}
              {memberDetail?.reportingManagerDetails?.firstName}{" "}
              {memberDetail?.reportingManagerDetails?.lastName}
            </h1>
          </div>
        </div>
        <div className="bg-exwhite-100 px-5 py-3 w-4/12 rounded-md border border-secondary-200 shadow shadow-2">
          <div className="flex items-center justify-between">
            <h1 className="text-2xl text-secondary-600 font-medium">
              {todayAttendance?.checkIn
                ? moment(todayAttendance?.checkIn).format("LTS")
                : moment(date).format("LTS")}
            </h1>
            <button
              disabled={todayAttendance?.checkIn || startDisabled}
              onClick={() => postAttedance(date)}
              className={`${todayAttendance?.checkIn ? "bg-exgray-400" : "bg-exgreen-200"
                } px-8 py-1 rounded-lg text-exwhite-100 text-lg`}
            >
              Start
            </button>
          </div>
          <div>
            <h1 className="text-base">Time-In</h1>
            <h1 className="text-sm text-exgray-400">{moment().format("LL")}</h1>
          </div>
        </div>
        <div className="bg-exwhite-100 px-5 py-3 w-4/12 rounded-md border border-secondary-200 shadow shadow-2">
          <div className="flex items-center justify-between">
            <h1 className="text-2xl text-secondary-600 font-medium">
              {todayAttendance?.status
                ? moment(todayAttendance?.checkOut).format("LTS")
                : moment(date).format("LTS")}
            </h1>
            <button
              disabled={
                !todayAttendance?.checkIn ||
                todayAttendance?.status === "Present"
              }
              onClick={() => updateEndTime()}
              className={`${todayAttendance?.checkIn != "" && todayAttendance?.status == ""
                ? "bg-exgreen-200"
                : "bg-exgray-400"
                } px-9 py-1 rounded-lg text-exwhite-100 text-lg`}
            >
              End
            </button>

          </div>
          <div className="flex justify-between">
            <div>
              <h1 className="text-base text-exgray-200">Time-out</h1>
              <h1 className="text-sm text-exgray-400">{moment().format("LL")}</h1>
            </div>
            <div className="text-center mt-2">
              <h1 className="text-sm font-bold text-secondary-500">{dif?.hours()}:{dif.minutes() < 10 ? "0" + dif.minutes() : dif.minutes()} hrs</h1>
              <h1 className="text-xs text-exgray-300">Completed</h1>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="flex space-x-2 mt-3">
        <div className="flex bg-primary-200 py-3 px-7 rounded-md w-3/12 items-center border border-secondary-200 shadow shadow-2">
          <h1 className="text-2xl text-secondary-500 font-medium w-4/12">95%</h1>
          <div className="w-8/12">
            <h1 className="text-sm text-exblue-600 font-medium">
              Attandence Score
            </h1>
            <h1 className="text-xs">
              For last month
            </h1>
          </div>
        </div>
        <div className="flex bg-primary-200 py-3 px-7 rounded-md w-3/12 items-center border border-secondary-200 shadow shadow-2">
          <h1 className="text-2xl text-secondary-500 font-medium w-4/12">03</h1>
          <div className="w-8/12">
            <h1 className="text-sm text-exblue-600 font-medium">
              Late Arrivals
            </h1>
            <h1 className="text-xs">
              For this month
            </h1>
          </div>
        </div>
        <div className="flex bg-primary-200 py-3 px-7 rounded-md w-3/12 items-center border border-secondary-200 shadow shadow-2">
          <h1 className="text-2xl text-secondary-500 font-medium w-4/12">{dif?.hours()}:{dif?.minutes()}</h1>
          <div className="w-8/12">
            <h1 className="text-sm text-exblue-600 font-medium">
              Todays Worked Hrs
            </h1>
          </div>
        </div>
        <div className="flex bg-exwhite-100 py-3 px-7 rounded-md w-3/12 items-center border border-secondary-200 shadow shadow-2">
          <h1 className="text-3xl text-secondary-500 font-medium w-4/12">{totalLeaveBalance}</h1>
          <div className="w-8/12">
            <h1 className="text-sm text-exblue-600 font-medium">
              Leave Balance
            </h1>
            <h1 className="text-xs">
              Till this month
            </h1>
          </div>
        </div>
      </div> */}
      {todayAttendance?.checkIn && (
        <Popup onClose={togglePopupHandler} visible={togglePopup}>
          <DailyReportForm
            attendanceId={todayAttendance?.id}
            onClose={togglePopupHandler}
            updateEndTime={markEndTime}
          />
        </Popup>
      )}
    </>
  );
};

export default EmployeeCommenView;
