import { useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../config/hooks";
import { unwrapResult } from "@reduxjs/toolkit";
import { getBulkCandidateDetailApi } from "../../slices/candidateProfileSlice";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "sonner";
import LoadingIcon from "../../assets/Icons/LoadingIcon";
import NoDataFoundIcon from "../../assets/Icons/NoDataFoundIcon";
import LocationIcon from "../../assets/Icons/LocationIcon";
import CheckedIcon from "../../assets/Icons/CheckedIcon";
import * as constants from "../../constant";
import useLocations from "../../utils/dropdown-options/useLocations";

const User = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { defaultLocation } = useLocations();

  const { userId } = useParams();
  const { pathname } = useLocation();

  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const token = useAppSelector((state) => state?.login?.session?.token);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await dispatch(
          getBulkCandidateDetailApi({ userId })
        ).then(unwrapResult);
        setUser(response.data.data);
        setLoading(false);
      } catch (error) {
        if (error?.error?.code === 404) toast.error("User Not Found");
        else toast.error("Something went wrong");
        setLoading(false);
        setError(true);
      }
    };

    fetchData();
  }, [dispatch, userId]);

  const defaultLoc = useMemo(() => {
    if (user?.userDetails?.location) {
      return defaultLocation(user.userDetails.location);
    } else {
      return null;
    }
  }, [defaultLocation, user?.userDetails?.location]);

  const navigationHandler = (pathName) => {
    navigate(pathName);
  };

  if (error) {
    return (
      <div className="w-full">
        <div className="mx-auto w-fit py-5">
          <div className="flex items-center justify-center">
            <NoDataFoundIcon />
          </div>
          <div>
            <h1 className="text-3xl text-expurple-800/50 font-semibold text-center">
              No Data Found
            </h1>
            <p className="text-base text-exgray-200 font-medium text-center max-w-sm mx-auto">
              Could not find any data. Please try again.
            </p>
          </div>
        </div>
      </div>
    );
  }

  if (loading) {
    return (
      <div className="py-20 flex items-center justify-center">
        <LoadingIcon />
      </div>
    );
  }

  return (
    <div className="space-y-1">
      <div className="space-y-2">
        <div className="bg-exwhite-100 p-4 flex items-center justify-start gap-4 rounded-sm">
          <div>
            {user?.userDetails?.profilePicture ? (
              <div
                className={`w-24 h-24 rounded-full flex items-center justify-center ${
                  (user?.userDetails?.isEmailVerified ||
                    user?.userDetails?.isMobileVerified) &&
                  "border-4 border-[#6DC680] relative"
                } mx-auto`}
              >
                <img
                  src={`${constants.baseUrl}/${user.userDetails.profilePicture}?t=${token}`}
                  alt="profile"
                  className="w-full h-full object-cover rounded-full"
                />
                {(user.userDetails.isEmailVerified ||
                  user.userDetails.isMobileVerified) && (
                  <div className="absolute bottom-0 right-0 w-6 h-6 bg-[#6DC680] rounded-full flex items-center justify-center">
                    <CheckedIcon />
                  </div>
                )}
              </div>
            ) : user.userDetails.firstName && user.userDetails.lastName ? (
              <div
                className={`w-24 h-24 rounded-full bg-primary-400 ${
                  (user.userDetails.isEmailVerified ||
                    user.userDetails.isMobileVerified) &&
                  "border-4 border-[#6DC680] relative"
                } mx-auto flex items-center justify-center`}
              >
                <h1 className="text-exwhite-100 text-4xl font-semibold tracking-wider">{`${user.userDetails.firstName[0].toUpperCase()}${user.userDetails.lastName[0].toUpperCase()}`}</h1>
                {(user.userDetails.isEmailVerified ||
                  user.userDetails.isMobileVerified) && (
                  <div className="absolute bottom-0 right-0 w-6 h-6 bg-[#6DC680] rounded-full flex items-center justify-center">
                    <CheckedIcon />
                  </div>
                )}
              </div>
            ) : (
              <div
                className={`w-24 h-w-24 rounded-full ${
                  (user.userDetails.isEmailVerified ||
                    user.userDetails.isMobileVerified) &&
                  "border-4 border-[#6DC680] relative"
                } bg-DefaultProfileImg bg-cover bg-no-repeat bg-center border-2 border-exgray-200/20 mx-auto relative`}
              >
                {(user.userDetails.isEmailVerified ||
                  user.userDetails.isMobileVerified) && (
                  <div className="absolute bottom-0 right-0 w-6 h-6 bg-[#6DC680] rounded-full flex items-center justify-center">
                    <CheckedIcon />
                  </div>
                )}
              </div>
            )}
          </div>
          <div className="self-start pt-2">
            <h1 className="text-base text-exgray-500 font-semibold text-start">
              {`${user.userDetails.firstName} ${user.userDetails.lastName}`}{" "}
              {user.userDetails.designation && "| "}{" "}
              <span className="text-secondary-400">
                {user.userDetails.designation}
              </span>
            </h1>
            <h1 className="text-sm text-exgray-500 font-semibold text-start">
              {`${user.userDetails.email}`}
              {user.userDetails.mobile && "| "}
              <span className="">
                {`+${user.userDetails.countryCode} ${user.userDetails.mobile}`}
              </span>
            </h1>
            {defaultLoc?.State && (
              <div className="flex items-center justify-start gap-1">
                <div>
                  <LocationIcon width="13" height="13" color="#6C7275" />
                </div>
                <h1 className="text-xs text-exgray-300 font-semibold leading-5">
                  {defaultLoc?.State}
                </h1>
              </div>
            )}
          </div>
        </div>
        <div className="flex items-center justify-start pt-2 gap-2">
          <button
            className={`text-base ${
              pathname === `/company-admin/candidate-search/user/${userId}`
                ? "border-b-2 border-secondary-400 text-secondary-400"
                : "text-exgray-200/80"
            } hover:text-secondary-400 font-semibold px-4`}
            onClick={() =>
              navigationHandler(
                `/company-admin/candidate-search/user/${userId}`
              )
            }
          >
            Profile Details
          </button>
          <button
            className={`text-base ${
              pathname ===
              `/company-admin/candidate-search/user/${userId}/resume`
                ? "border-b-2 border-secondary-400 text-secondary-400"
                : "text-exgray-200/80"
            } hover:text-secondary-400 font-semibold px-4`}
            onClick={() =>
              navigationHandler(
                `/company-admin/candidate-search/user/${userId}/resume`
              )
            }
          >
            CV / Resume
          </button>
        </div>
        <div>
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default User;
