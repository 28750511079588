import React, { useState } from "react";
import CrossIcon from "../../assets/Icons/CrossIcon";
import { useAppSelector } from "../../config/hooks";

const CommercialRateForm = ({ formData, setFormData }) => {
  const { AgCTCRange, AgSeniorityLevel } = useAppSelector(
    (state) => state.appConfig
  );
  
  const handleInputChange = (index, field, value, type, section) => {
    // const updatedData = { ...formData };
    // if (type === "ctc") {
    //   updatedData.ctcRange[section][index][field] = value;
    // } else if (type === "seniority") {
    //   updatedData.seniorityLevels[section][index][field] = value;
    // }
    
    // setFormData(updatedData);
    setFormData((prevState) => {
      // Create a deep copy of the entire formData object to avoid mutation
      const updatedData = {
        ...prevState,  // Copy the outermost formData object
        ctcRange: {
          ...prevState.ctcRange,  // Copy the ctcRange object
          [section]: prevState.ctcRange[section].map((entry, idx) => {
            // For each entry, create a new object with the updated field if the index matches
            if (idx === index) {
              return { ...entry, [field]: value };
            }
            return entry;
          })
        },
        seniorityLevels: {
          ...prevState.seniorityLevels,  // Copy the seniorityLevels object
          [section]: prevState.seniorityLevels[section].map((entry, idx) => {
            // For each entry, create a new object with the updated field if the index matches
            if (idx === index) {
              return { ...entry, [field]: value };
            }
            return entry;
          })
        }
      };
  
      return updatedData;  // Return the updated state to trigger a re-render
    });
  };

  const handleAddOption = (type, section) => {
    const updatedData = { ...formData };

    // Add a new entry based on type (ctc or seniority)
    const newEntry =
      type === "ctc"
        ? { minRange:"",maxRange:"", percentage: "", amount: "" }
        : { minLevel:"",maxLevel:"", percentage: "", amount: "" };

    // Push the new entry to the appropriate section
    if (type === "ctc") {
      updatedData.ctcRange[section].push(newEntry);
    } else if (type === "seniority") {
      updatedData.seniorityLevels[section].push(newEntry);
    }
    setFormData(updatedData);
  };

  const handleRemoveOption = (index, type, section) => {
    const updatedData = {
      ...formData,
      ctcRange: { ...formData.ctcRange },
      seniorityLevels: { ...formData.seniorityLevels },
    };

    if (type === "ctc") {
      updatedData.ctcRange[section] = [
        ...updatedData.ctcRange[section].slice(0, index),
        ...updatedData.ctcRange[section].slice(index + 1),
      ];
    } else if (type === "seniority") {
      updatedData.seniorityLevels[section] = [
        ...updatedData.seniorityLevels[section].slice(0, index),
        ...updatedData.seniorityLevels[section].slice(index + 1),
      ];
    }
    setFormData(updatedData);
  };

  // const handleRemoveOption = (index, type, section) => {
  //   // Create a copy of formData and update the appropriate section (CTC or Seniority)
  //   setFormData((prevFormData) => {
  //     // Deep clone formData to ensure immutability (copy all levels)
  //     const updatedFormData = {
  //       ...prevFormData,
  //       ctcRange: {
  //         ...prevFormData.ctcRange,  // Copy ctcRange object
  //         [section]: prevFormData.ctcRange[section].filter((_, i) => i !== index),  // Remove item from the selected section
  //       },
  //       seniorityLevels: {
  //         ...prevFormData.seniorityLevels,  // Copy seniorityLevels object
  //         [section]: prevFormData.seniorityLevels[section].filter((_, i) => i !== index),  // Remove item from the selected section
  //       },
  //     };
      
  //     // Return the updated form data to trigger a re-render
  //     return updatedFormData;
  //   });
  // };
  
  
  
  // const handleAddInternational = () => {
  //   // Trigger adding the international section by showing radio buttons for CTC or Seniority
  //   setFormData({ ...formData, internationalType: "ctc" }); // default to "ctc"
  // };

  // const handleCopyDomesticToInternational = () => {
  //   setFormData({
  //     ...formData,
  //     ctcRange: {
  //       ...formData.ctcRange,
  //       international: [...formData.ctcRange.domestic],
  //     },
  //     seniorityLevels: {
  //       ...formData.seniorityLevels,
  //       international: [...formData.seniorityLevels.domestic],
  //     },
  //     copyToInternational: true,
  //   });
  // };

  return (
    <div className="flex gap-2">
      <h2 className="w-1/5 text-exgray-400">Commercial Rates:</h2>
      <div className="flex gap-2 flex-col item-center flex-1">
        <div className="flex justify-end gap-4">
          {/* Add International Checkbox */}
          {/* <div>
            <input
              type="checkbox"
              id="internationalCheckbox"
              checked={
                formData.internationalType !== "" &&
                !formData.copyToInternational
              }
              onChange={(e) => {
                if (e.target.checked) {
                  setFormData({
                    ...formData,
                    internationalType: "ctc", // Default value for international type
                    copyToInternational: false, // Uncheck "Same as Domestic"
                    ctcRange: { ...formData.ctcRange, international: [] }, // Clear international values
                    seniorityLevels: {
                      ...formData.seniorityLevels,
                      international: [],
                    }, // Clear international values
                  });
                } else {
                  setFormData({
                    ...formData,
                    internationalType: "",
                  });
                }
              }}
            />
            <label htmlFor="internationalCheckbox">Add International</label>
          </div> */}

          {/* Same as Domestic Checkbox */}
          <div className="flex gap-1">
            <input
              type="checkbox"
              id="copyDomesticCheckbox"
              checked={formData.copyToInternational}
              onChange={(e) => {
                // if (e.target.checked) {
                //   setFormData({
                //     ...formData,
                //     ctcRange: {
                //       ...formData.ctcRange,
                //       international: JSON.parse(JSON.stringify(formData.ctcRange.domestic)),
                //     },
                //     seniorityLevels: {
                //       ...formData.seniorityLevels,
                //       international: JSON.parse(JSON.stringify(formData.seniorityLevels.domestic)),
                //     },
                //     copyToInternational: true, // Enable "Same as Domestic"
                //     internationalType:formData.domesticType, // Uncheck "Add International"
                //   });
                // } else {
                //   setFormData({
                //     ...formData,
                //     copyToInternational: false,
                //     ctcRange: { ...formData.ctcRange, international: [] }, // Clear international values
                //     seniorityLevels: {
                //       ...formData.seniorityLevels,
                //       international: [],
                //       // internationalType:""
                //     }, // Clear international values
                //   });
                // }
                const isChecked = e.target.checked;
    
                setFormData(prevState => {
                  const newFormData = { ...prevState };
            
                  if (isChecked) {
                    // Copy domestic to international
                    newFormData.ctcRange.international = JSON.parse(JSON.stringify(prevState.ctcRange.domestic));
                    newFormData.seniorityLevels.international = JSON.parse(JSON.stringify(prevState.seniorityLevels.domestic));
                    newFormData.copyToInternational = true; // Enable "Same as Domestic"
                    newFormData.internationalType = prevState.domesticType; // Set internationalType based on domesticType
                  } else {
                    // Clear international data
                    newFormData.copyToInternational = false;
                    newFormData.ctcRange.international = []; // Clear international CTC range
                    newFormData.seniorityLevels.international = []; // Clear international seniority levels
                  }
            
                  return newFormData;
                });
              }}
            />
            {/* <label htmlFor="copyDomesticCheckbox">Same as Domestic</label> */}
            <label htmlFor="copyDomesticCheckbox">International</label>
          </div>
        </div>

        {/* Domestic Section */}
        <div className="flex items-center gap-4">
          {/* <h3>Domestic Section</h3> */}
          <label>
            <input
              type="radio"
              name="domesticType"
              value={formData.domesticType || "ctc"}
              checked={formData.domesticType === "ctc"}
              onChange={() => setFormData({ ...formData, domesticType: "ctc" })}
            />
            {"  "}CTC Range
          </label>
          <label>
            <input
              type="radio"
              name="domesticType"
              value={formData.domesticType || "seniority"}
              checked={formData.domesticType === "seniority"}
              onChange={() =>
                setFormData({ ...formData, domesticType: "seniority" })
              }
            />{" "}
            Seniority Level
          </label>
        </div>

        {/* Show Inputs for Domestic CTC or Seniority */}
        {formData.domesticType === "ctc" && (
          <div className="flex flex-col gap-2">
            {/* <h3>CTC Range (Domestic)</h3> */}
            {formData.ctcRange.domestic.map((entry, index) => (
              <div key={index} className="flex items-center gap-3">
                <label htmlFor="">Min: </label>
                <select
                  className="border p-2 w-1/4"
                  onChange={(e) =>
                    handleInputChange(
                      index,
                      "minRange",
                      e.target.value,
                      "ctc",
                      "domestic"
                    )
                  }
                  value={entry.minRange}
                >
                  <option value="" disabled>
                    Select
                  </option>
                  {AgCTCRange.map((opt) => (
                    <option key={opt.Id} value={opt.Id.toString()}>
                      {opt.Title}
                    </option>
                  ))}
                </select>
                <label htmlFor="">Max: </label>
                <select
                  className="border p-2 w-1/4"
                  onChange={(e) =>
                    handleInputChange(
                      index,
                      "maxRange",
                      e.target.value,
                      "ctc",
                      "domestic"
                    )
                  }
                  value={entry.maxRange}
                >
                  <option value="" disabled>
                    Select
                  </option>
                  {AgCTCRange.map((opt) => (
                    <option key={opt.Id} value={opt.Id.toString()}>
                      {opt.Title}
                    </option>
                  ))}
                </select>
                <label htmlFor="" className="flex items-center">Percentage: {" "}
                <input
                  type="number"
                  placeholder="0"
                  value={entry.percentage}
                  onChange={(e) =>
                    handleInputChange(
                      index,
                      "percentage",
                      e.target.value,
                      "ctc",
                      "domestic"
                    )
                  }
                  className="border p-2 w-1/3"
                  onWheel={(e) => e.target.blur()}
                />
                 %
                 </label>
                <label htmlFor="">Amount: </label>
                <input
                  type="text"
                  placeholder="0"
                  value={entry.amount}
                  onChange={(e) =>
                    handleInputChange(
                      index,
                      "amount",
                      e.target.value,
                      "ctc",
                      "domestic"
                    )
                  }
                  className="border p-2 w-1/3"
                />
                {index !== 0 && (
                  <button
                    onClick={() => handleRemoveOption(index, "ctc", "domestic")}
                    className="text-red-500 hover:scale-95"
                  >
                    <CrossIcon color="red"/>
                  </button>
                )}
              </div>
            ))}
            <div className="flex justify-end">
              {/* {formData.ctcRange.domestic.some(
                (entry) => !entry.range || !entry.percentage || !entry.amount
              ) && ( */}
              <button
                type="button"
                onClick={() => handleAddOption("ctc", "domestic")}
                className="border text-end bg-primary-500 px-2 py-0.5 text-exgray-100 rounded hover:text-white"
              >
                Add More
              </button>
              {/* )} */}
            </div>
          </div>
        )}

        {formData.domesticType === "seniority" && (
          <div className="flex flex-col gap-2">
            {/* <h3>Seniority Level (Domestic)</h3> */}
            {formData.seniorityLevels.domestic.map((entry, index) => (
              <div key={index} className="flex items-center gap-3">
                <label htmlFor="">Min:</label>
                <select
                  className="border p-2 w-1/4"
                  onChange={(e) =>
                    handleInputChange(
                      index,
                      "minLevel",
                      e.target.value,
                      "seniority",
                      "domestic"
                    )
                  }
                  value={entry.minLevel}
                >
                  <option value="" disabled>
                    Select
                  </option>
                  {AgSeniorityLevel.map((opt) => (
                    <option key={opt.Id} value={opt.Id.toString()}>
                      {opt.Title}
                    </option>
                  ))}
                </select>
                <label htmlFor="">Max:</label>
                <select
                  className="border p-2 w-1/4"
                  onChange={(e) =>
                    handleInputChange(
                      index,
                      "maxLevel",
                      e.target.value,
                      "seniority",
                      "domestic"
                    )
                  }
                  value={entry.maxLevel}
                >
                  <option value="" disabled>
                    Select
                  </option>
                  {AgSeniorityLevel.map((opt) => (
                    <option key={opt.Id} value={opt.Id.toString()}>
                      {opt.Title}
                    </option>
                  ))}
                </select>
                <label htmlFor="" className="flex items-center">Percentage:{" "}
                <input
                  type="number"
                  placeholder="0"
                  value={entry.percentage}
                  onChange={(e) =>
                    handleInputChange(
                      index,
                      "percentage",
                      e.target.value,
                      "seniority",
                      "domestic"
                    )
                  }
                  className="border p-2 w-1/3"
                  onWheel={(e) => e.target.blur()}
                />
                 %
                 </label>
                 <label htmlFor="">Amount: </label>
                <input
                  type="text"
                  placeholder="0"
                  value={entry.amount}
                  onChange={(e) =>
                    handleInputChange(
                      index,
                      "amount",
                      e.target.value,
                      "seniority",
                      "domestic"
                    )
                  }
                  className="border p-2 w-1/3"
                />
                {index !== 0 && (
                  <button
                    onClick={() =>
                      handleRemoveOption(index, "seniority", "domestic")
                    }
                    className="text-red-500 hover:scale-95"
                  >
                    <CrossIcon color="red"/>
                  </button>
                )}
              </div>
            ))}
            <div className="flex justify-end">
              {formData.seniorityLevels.domestic.some(
                (entry) => !entry.level || !entry.percentage || !entry.amount
              ) && (
                <button
                  type="button"
                  onClick={() => handleAddOption("seniority", "domestic")}
                  className="border text-end bg-primary-500 px-2 py-0.5 text-exgray-100 rounded hover:text-white"
                >
                  Add More
                </button>
              )}
            </div>
          </div>
        )}

        {/* International Section */}
        {(formData.copyToInternational && formData.internationalType) && (
          <div className="flex gap-2 flex-col item-center flex-1">
            <h3>International Section</h3>

            <div className="flex items-center gap-5">
              <label>
                <input
                  type="radio"
                  name="internationalType"
                  value="ctc"
                  checked={formData.internationalType === "ctc"}
                  onChange={() =>
                    setFormData({ ...formData, internationalType: "ctc" })
                  }
                />
                {"  "}CTC Range
              </label>
              <label>
                <input
                  type="radio"
                  name="internationalType"
                  value="seniority"
                  checked={formData.internationalType === "seniority"}
                  onChange={() =>
                    setFormData({ ...formData, internationalType: "seniority" })
                  }
                />
                {"  "}Seniority Level
              </label>
            </div>

            {/* Show International CTC Range or Seniority Inputs */}
            {formData.internationalType === "ctc" && (
              <div className="flex flex-col gap-2">
                {/* <h3>CTC Range (International)</h3> */}
                {formData.ctcRange.international.map((entry, index) => (
                  <div key={index} className="flex items-center gap-3">
                    <label htmlFor="">Min: </label>
                    <select
                      className="border p-2 w-1/4"
                      onChange={(e) =>
                        handleInputChange(
                          index,
                          "minRange",
                          e.target.value,
                          "ctc",
                          "international"
                        )
                      }
                      value={entry.minRange}
                    >
                      <option value="" disabled>
                       Select
                      </option>
                      {AgCTCRange.map((opt) => (
                        <option key={opt.Id} value={opt.Id.toString()}>
                          {opt.Title}
                        </option>
                      ))}
                    </select>
                    <label htmlFor="">Max: </label>
                    <select
                      className="border p-2 w-1/4"
                      onChange={(e) =>
                        handleInputChange(
                          index,
                          "maxRange",
                          e.target.value,
                          "ctc",
                          "international"
                        )
                      }
                      value={entry.maxRange}
                    >
                      <option value="" disabled>
                        Select                      </option>
                      {AgCTCRange.map((opt) => (
                        <option key={opt.Id} value={opt.Id.toString()}>
                          {opt.Title}
                        </option>
                      ))}
                    </select>
                    <label htmlFor="" className="flex items-center">Percentage: {" "}
                    <input
                      type="number"
                      placeholder="0"
                      value={entry.percentage}
                      onChange={(e) =>
                        handleInputChange(
                          index,
                          "percentage",
                          e.target.value,
                          "ctc",
                          "international"
                        )
                      }
                      className="border p-2 w-full"
                      onWheel={(e) => e.target.blur()}
                    />
                    %
                    </label>
                    <label htmlFor="">Amount: </label>
                    <input
                      type="text"
                      placeholder="0"
                      value={entry.amount}
                      onChange={(e) =>
                        handleInputChange(
                          index,
                          "amount",
                          e.target.value,
                          "ctc",
                          "international"
                        )
                      }
                      className="border p-2 w-1/4"
                    />
                    <button
                      onClick={() =>
                        handleRemoveOption(index, "ctc", "international")
                      }
                      className="text-red-500 hover:scale-95"
                    >
                      <CrossIcon color="red"/>
                    </button>
                  </div>
                ))}
                <div className="flex justify-end">
                  <button
                    type="button"
                    onClick={() => handleAddOption("ctc", "international")}
                    className="border text-end bg-primary-500 px-2 py-0.5 text-exgray-100 rounded hover:text-white"
                  >
                    Add More
                  </button>
                </div>
              </div>
            )}

            {formData.internationalType === "seniority" && (
              <div className="flex flex-col gap-2">
                {/* <h3>Seniority Level (International)</h3> */}
                {formData.seniorityLevels.international.map((entry, index) => (
                  <div key={index} className="flex items-center gap-3">
                    <label htmlFor="">Min:</label>
                    <select
                      className="border p-2 w-1/4"
                      onChange={(e) =>
                        handleInputChange(
                          index,
                          "minLevel",
                          e.target.value,
                          "seniority",
                          "international"
                        )
                      }
                      value={entry.minLevel}
                    >
                      <option value="" disabled>
                         Select 
                      </option>
                      {AgSeniorityLevel.map((opt) => (
                        <option key={opt.Id} value={opt.Id.toString()}>
                          {opt.Title}
                        </option>
                      ))}
                    </select>
                    <label htmlFor="">Max:</label>
                    <select
                      className="border p-2 w-1/4"
                      onChange={(e) =>
                        handleInputChange(
                          index,
                          "maxLevel",
                          e.target.value,
                          "seniority",
                          "international"
                        )
                      }
                      value={entry.maxLevel}
                    >
                      <option value="" disabled>
                        Select 
                      </option>
                      {AgSeniorityLevel.map((opt) => (
                        <option key={opt.Id} value={opt.Id.toString()}>
                          {opt.Title}
                        </option>
                      ))}
                    </select>
                    <label htmlFor="" className="flex items-center">Percentage:{""}
                    <input
                      type="number"
                      placeholder="0"
                      value={entry.percentage}
                      onChange={(e) =>
                        handleInputChange(
                          index,
                          "percentage",
                          e.target.value,
                          "seniority",
                          "international"
                        )
                      }
                      className="border p-2 w-full"
                      onWheel={(e) => e.target.blur()}
                    />
                    %
                    </label>
                    <label htmlFor="">Amount: </label>
                    <input
                      type="text"
                      placeholder="0"
                      value={entry.amount}
                      onChange={(e) =>
                        handleInputChange(
                          index,
                          "amount",
                          e.target.value,
                          "seniority",
                          "international"
                        )
                      }
                      className="border p-2 w-1/3"
                    />
                    <button
                      onClick={() =>
                        handleRemoveOption(index, "seniority", "international")
                      }
                      className="text-red-500 hover:scale-95"
                    >
                      <CrossIcon color="red"/>
                    </button>
                  </div>
                ))}
                <div className="flex justify-end">
                  <button
                    type="button"
                    onClick={() =>
                      handleAddOption("seniority", "international")
                    }
                    className="border text-end bg-primary-500 px-2 py-0.5 text-exgray-100 rounded hover:text-white"
                  >
                    Add More
                  </button>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default CommercialRateForm;
