import { Controller, useFieldArray, useForm } from "react-hook-form";
import PlusCircleIcon from "../../../../../assets/Icons/PlusCircleIcon";
import ExMultiDropdown from "../../../../../components/basic-components/ExMultiDropdown";
import useLocations from "../../../../../utils/dropdown-options/useLocations";
import useIndustries from "../../../../../utils/dropdown-options/useIndustries";
import DeleteIcon from "../../../../../assets/Icons/DeleteIcon";
import UploadIcon from "../../../../../assets/Icons/UploadIcon";

const GlobalSearchForm = () => {
  const { locationsArray } = useLocations();
  const { industriesArray } = useIndustries();

  const form = useForm({
    defaultValues: {
      jobTitle: "",
      targetCompanies: [{ company: "" }],
      location: [],
    },
  });

  const { handleSubmit, control, formState } = form;
  const { errors } = formState;

  const { fields, append, remove } = useFieldArray({
    name: "targetCompanies",
    control,
  });

  const addOptionHandler = () => {
    append({ company: "" });
  };
  const onSubmit = (formData) => {};

  return (
    <div
      className="w-[50%] bg-exwhite-100 py-7 rounded-xl p-4 space-y-2"
      style={{
        background: "linear-gradient(85.26deg, #2E82C7 21.55%, #42A5F6 93.37%)",
      }}
    >
      <h1 className="text-exwhite-100 text-lg font-semibold">Global Search</h1>
      <form
        className="space-y-2 max-h-popupScreenheight overflow-y-auto no-scrollbar"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div>
          <button
            className="border-2 border-exwhite-100 rounded-md font-semibold px-8 py-2 text-base text-exwhite-100 active:scale-95"
            type="button"
          >
            Upload
          </button>
        </div>
        <div className="space-y-4">
          {fields.map((field, index) => {
            return (
              <div key={field.id}>
                <Controller
                  name={`targetCompanies.${index}.company`}
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: "Required",
                    },
                  }}
                  render={({ field: { onChange, value } }) => (
                    <div className="space-y-1">
                      <div
                        className={`w-full h-10 bg-exwhite-100 rounded-md outline-none border ${
                          errors?.targetCompanies?.[index]?.company?.message
                            ? "border-[#dc2623]"
                            : "border-transparent"
                        } flex items-center justify-between pr-2`}
                      >
                        <input
                          className="px-3 outline-none rounded-md w-full text-exgray-800 placeholder:text-gray-400 text-base flex-1"
                          id={"targetCompanies"}
                          name={`targetCompanies-${index}`}
                          autoComplete="off"
                          placeholder="Targeted Company"
                          onChange={onChange}
                          value={value || ""}
                        />

                        {index === 0 ? (
                          fields.length <= 5 && (
                            <button
                              className=""
                              type="button"
                              onClick={addOptionHandler}
                            >
                              <PlusCircleIcon />
                            </button>
                          )
                        ) : (
                          <button
                            className="group pr-2"
                            type="button"
                            onClick={() => remove(index)}
                          >
                            <DeleteIcon className="group-hover:fill-red-600" />
                          </button>
                        )}
                      </div>
                      {errors?.targetCompanies &&
                        errors?.targetCompanies[index] &&
                        errors?.targetCompanies[index]?.company?.message && (
                          <p className="text-red-400">
                            {errors?.targetCompanies[index]?.company?.message}
                          </p>
                        )}
                    </div>
                  )}
                />
              </div>
            );
          })}

          <div>
            <Controller
              name="location"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "Location is required",
                },
              }}
              render={({ field: { onChange, value } }) => (
                <ExMultiDropdown
                  title=""
                  onChange={onChange}
                  placeholder="Location *"
                  value={value || null}
                  optionValue="Id"
                  optionLabel="Name"
                  options={locationsArray}
                  closeMenuOnSelect={false}
                  isMandatory={true}
                  dropDownHeight="auto"
                  dropDownBorderWidth="2px"
                  dropDownBorderColor="transparent"
                  menuListHeight="150px"
                  error={errors?.location?.message}
                />
              )}
            />
          </div>

          <div>
            <Controller
              name="industry"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "Industry is required",
                },
              }}
              render={({ field: { onChange, value } }) => (
                <ExMultiDropdown
                  title=""
                  onChange={onChange}
                  placeholder="Industry *"
                  value={value || null}
                  optionValue="Id"
                  optionLabel="Name"
                  options={industriesArray}
                  closeMenuOnSelect={false}
                  isMandatory={true}
                  dropDownHeight="auto"
                  dropDownBorderWidth="0px"
                  dropDownBorderColor="transparent"
                  menuListHeight="150px"
                  error={errors?.industry?.message}
                />
              )}
            />
          </div>
        </div>

        <div>
          <div className="w-fit pt-2 ml-auto mr-0">
            <button
              className="border rounded-sm px-12 py-2 text-center text-base font-semibold bg-[#FEEE91] text-exgray-700 border-[#FEEE91]"
              type="submit"
            >
              Search
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default GlobalSearchForm;
