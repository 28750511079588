import { useAppSelector } from "../../config/hooks";

const useSalariesInThousands = () => {
  const salaryInThousands = useAppSelector(
    (state) => state.appConfig.salaryInThousands
  );

  const defualtSalaryInThousand = (defaultSalId) => {
    const defaultSalaryInThousand = salaryInThousands.find(
      (sal) => sal.Value == defaultSalId
    );
    return defaultSalaryInThousand;
  };
  return { salaryInThousands, defualtSalaryInThousand };
};

export default useSalariesInThousands;
