import React from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useAppSelector } from '../../config/hooks';

const RequestListTab = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { memberId } = useParams();

  const navigateHandler = (path) => {
    navigate(path);
  };

  const userId = useAppSelector((state) => state.login.session.userId);

  return (
    <div className="flex w-full items-center mt-3 border-b border-exgray-100">
      <button
        className={`px-4 py-1 ${pathname === "/employee-dashboard/request-list/my-regularization-request"
          ? "border-b-2 border-primary-600 text-primary-600 font-semibold"
          : "text-exgray-200"
          } text-base text-center font-normal`}
        onClick={() => navigateHandler("/employee-dashboard/request-list/my-regularization-request")}
      >
        Regularization
      </button>
      <button
        className={`px-4 py-1 ${pathname === "/employee-dashboard/request-list/my-leave-request"
          ? "border-b-2 border-primary-600 text-primary-600 font-semibold"
          : "text-exgray-200"
          } text-base text-center font-normal`}
        onClick={() => navigateHandler("/employee-dashboard/request-list/my-leave-request")}
      >
        Leave
      </button>
      <button
        className={`px-4 py-1 ${pathname === "/employee-dashboard/request-list/my-reimbursement-request"
          ? "border-b-2 border-primary-600 text-primary-600 font-semibold"
          : "text-exgray-200"
          } text-base text-center font-normal`}
        onClick={() => navigateHandler("/employee-dashboard/request-list/my-reimbursement-request")}
      >
        Reimbursement
      </button>
      {/* <div className="border-b-2 border-exgray-100 flex-1 self-end"></div> */}
    </div>
  )




}

export default RequestListTab
