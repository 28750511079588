import React, { useEffect, useMemo, useRef, useState } from "react";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import {
  getAssignMembersToJobApi,
  getInterviewStagesApi,
  getJobDetailApi,
  updateJobStatusApi,
} from "../../../../slices/jobSlice";
import { useAppDispatch, useAppSelector } from "../../../../config/hooks";
import { unwrapResult } from "@reduxjs/toolkit";

import useLocations from "../../../../utils/dropdown-options/useLocations";
import useSalaries from "../../../../utils/dropdown-options/useSalaries";
import useIndustries from "../../../../utils/dropdown-options/useIndustries";
import useJobTypes from "../../../../utils/dropdown-options/useJobTypes";
import useWorkPlaceTypes from "../../../../utils/dropdown-options/useWorkPlaceTypes";
import useMinExperiences from "../../../../utils/dropdown-options/useMinExperiences";

import LocationIcon from "../../../../assets/Icons/LocationIcon";
import moment from "moment";
import ClockIcon from "../../../../assets/Icons/ClockIcon";
import VerticleThreeDotsIcon from "../../../../assets/Icons/VerticleThreeDotsIcon";
import { v4 as uuid4 } from "uuid";
import IndustryIcon from "../../../../assets/Icons/IndustryIcon";
import SalaryRangeIcon from "../../../../assets/Icons/SalaryRangeIcon";
import JobTypeIcon from "../../../../assets/Icons/JobTypeIcon";
import WorkPlaceIcon from "../../../../assets/Icons/WorkPlaceIcon";
import ExperienceIcon from "../../../../assets/Icons/ExperienceIcon";
import BulbIcon from "../../../../assets/Icons/BulbIcon";
import { baseUrl } from "../../../../constant";
import Popup from "../../../../components/popup/Popup";
import { getJobAuthour_CompanyDetailsApi } from "../../../../slices/companySlice";
import * as constants from "../../../../constant";
import AssignJobForm from "./components/AssignJobForm";
import CreateReferForm from "./components/CreateReferForm";
import { getJobAuthour_CompanyMemberApi } from "../../../../slices/companyMemberSlice";
import { toast } from "sonner";
import DropdownArrowIcon from "../../../../assets/Icons/DropdownArrowIcon";
import JobPreview from "./components/JobPreview";
import Image from "../../../../components/Image";
import PlusCircleIcon from "../../../../assets/Icons/PlusCircleIcon";

const JobPage = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { jobId } = useParams();

  const menuRef = useRef(null);
  const menuParentRef = useRef(null);

  const jobSettingMenuRef = useRef(null);
  const jobSettingMenuParentRef = useRef(null);

  const token = useAppSelector((state) => state?.login?.session?.token);
  const loginSelector = useAppSelector((state) => state?.login?.session);
  const memberSelector = useAppSelector(
    (state) => state?.member?.memberDetails
  );

  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);
  const [jobDetails, setJobDetails] = useState(null);
  const [toggleAssignJobPopup, setToggleAssignJobPopup] = useState(false);
  const [toggleCreateReferPopup, setToggleCreateReferPopup] = useState(false);
  const [toggleMore, setToggleMore] = useState(false);
  const [author, setAuthor] = useState(null);
  const [assignedMembers, setAssignedMembers] = useState(null);
  const [isPreviewJobPopupOpen, setIsPreviewJobPopupOpen] = useState(false);
  const [isJobSettingOpen, setIsJobSettingOpen] = useState(false);

  useEffect(() => {
    const toggleHandler = (e) => {
      if (
        menuRef.current &&
        menuParentRef &&
        !menuRef.current.contains(e.target) &&
        !menuParentRef.current.contains(e.target)
      ) {
        // close popup
        setToggleMore(false);
      }
    };
    document.addEventListener("mousedown", toggleHandler);

    return () => {
      document.removeEventListener("mousedown", toggleHandler);
    };
  });

  useEffect(() => {
    const toggleHandler = (e) => {
      if (
        jobSettingMenuRef.current &&
        menuParentRef &&
        !jobSettingMenuRef.current.contains(e.target) &&
        !jobSettingMenuParentRef.current.contains(e.target)
      ) {
        // close popup
        setIsJobSettingOpen(false);
      }
    };
    document.addEventListener("mousedown", toggleHandler);

    return () => {
      document.removeEventListener("mousedown", toggleHandler);
    };
  });

  const getJobDetails = async () => {
    try {
      const response = await dispatch(getJobDetailApi({ jobId })).then(
        unwrapResult
      );
      setJobDetails(response.data.data[0]);
    } catch (error) {
      toast.error("Unable to fetch job details");
    }
  };

  const getAssignedMembersByJobId = async () => {
    try {
      const response = await dispatch(
        getAssignMembersToJobApi({ jobId: jobId })
      ).then(unwrapResult);
      setAssignedMembers(response.data.data);
    } catch (error) {
      toast.error("Failed to fetch assigned members. Please try again!");
    }
  };

  useEffect(() => {
    const getJobById = async () => {
      try {
        const response = await dispatch(getJobDetailApi({ jobId })).then(
          unwrapResult
        );
        if (response) {
          try {
            const stagesResponse = await dispatch(
              getInterviewStagesApi({ jobId })
            ).then(unwrapResult);
          } catch (error) {
            toast.error("Unabel to fetch tagged list");
          }
          if (response.data.data[0].authorType === constants.COMPANY) {
            try {
              const authorResponse = await dispatch(
                getJobAuthour_CompanyDetailsApi({
                  id: response.data.data[0].authorId,
                })
              ).then(unwrapResult);
              setJobDetails(response.data.data[0]);
              setIsLoading(false);
              setAuthor(authorResponse.data.data);
            } catch (error) {
              setIsLoading(false);
              setError(true);
            }
          } else if (
            response.data.data[0].authorType === constants.COMPANY_MEMBER
          ) {
            const responseMemberData = await dispatch(
              getJobAuthour_CompanyMemberApi({
                memberId: response.data.data[0].authorId,
              })
            ).then(unwrapResult);
            setAuthor(responseMemberData.data.data);
            setJobDetails(response.data.data[0]);
            setIsLoading(false);
          }
        }
      } catch (error) {
        setIsLoading(false);
        setError(true);
      }
    };

    if (
      loginSelector.who === constants.COMPANY.toLowerCase() ||
      loginSelector.who === constants.COMPANY_MEMBER.toLowerCase()
    ) {
      getAssignedMembersByJobId();
    }

    getJobById();
  }, []);

  const postedBy = useMemo(() => {
    return !isLoading ? moment(jobDetails?.createdAt).fromNow() : null;
  }, [isLoading, jobDetails?.createdAt]);

  const postedByDate = useMemo(() => {
    return !isLoading
      ? moment(jobDetails?.createdAt).format("DD MMMM YYYY")
      : null;
  }, [isLoading, jobDetails?.createdAt]);

  const advisors = useMemo(() => {
    if (assignedMembers && assignedMembers?.length > 0) {
      const advisorsArray = assignedMembers.filter(
        (member) => member.assignTo === constants.ADVISER
      );

      return advisorsArray && advisorsArray.length > 0 ? advisorsArray : [];
    }
    return [];
  }, [assignedMembers]);

  const assignedMembersArray = useMemo(() => {
    if (assignedMembers && assignedMembers?.length > 0) {
      const advisorsArray = assignedMembers.filter(
        (member) => member.assignTo !== constants.ADVISER
      );

      return advisorsArray && advisorsArray.length > 0 ? advisorsArray : [];
    }
    return [];
  }, [assignedMembers]);

  const createReferPopupHandler = () => {
    setToggleMore(false);
    setToggleCreateReferPopup(!toggleCreateReferPopup);
  };

  const toggleMoreHandler = () => {
    setToggleMore(!toggleMore);
  };

  const toggleAssignJobPopupHandler = () => {
    setToggleAssignJobPopup(!toggleAssignJobPopup);
    setIsJobSettingOpen(false);
    // setToggleMore(false);
  };

  const closeJobHandler = async () => {
    try {
      const response = await dispatch(
        updateJobStatusApi({ jobId: jobDetails.id, status: "CLOSED" })
      ).then(unwrapResult);
      toggleMoreHandler();
      toast.success("Job closed successfully");
      if (response) {
        if (loginSelector?.who === constants.COMPANY.toLowerCase())
          navigate("/company-admin/jobs");
        else if (loginSelector?.who === constants.COMPANY_MEMBER.toLowerCase())
          navigate("/company-admin/jobs");
      }
    } catch (error) {
      toast.error("Somthing went wrong");
    }
  };

  const openJobHandler = async () => {
    try {
      await dispatch(
        updateJobStatusApi({ jobId: jobDetails.id, status: "OPEN" })
      ).then(unwrapResult);
      setIsJobSettingOpen(false);
      // toggleMoreHandler();
      getJobDetails();
      toast.success("Status changed from closed to open");
      // if (response) {
      //   if (loginSelector?.who === constants.COMPANY.toLowerCase())
      //     navigate("/company-admin/jobs");
      //   else if (loginSelector?.who === constants.COMPANY_MEMBER.toLowerCase())
      //     navigate("/company-admin/jobs");
      // }
    } catch (error) {
      toast.error("Somthing went wrong");
    }
  };

  const nevigateJobHandler = (pathname) => {
    navigate(pathname);
  };

  const previewJobTogglePopupHandler = () => {
    setIsJobSettingOpen(false);
    setIsPreviewJobPopupOpen(!isPreviewJobPopupOpen);
  };

  const toggleJobSeetingsHandler = () => {
    setIsJobSettingOpen(!isJobSettingOpen);
  };

  if (error) {
    return <h1>Somthing went wrong</h1>;
  }

  return (
    !isLoading &&
    jobDetails &&
    author && (
      <div className="">
        <div className="space-y-2">
          <div className="space-y-4 px-4 pt-4 bg-exwhite-100 rounded-xl">
            <div className="flex items-center justify-between gap-2">
              <div className="flex-1 self-start space-y-2">
                <div className="flex items-center justify-start gap-4">
                  <h1
                    className="text-lg text-exgray-800 font-semibold cursor-pointer"
                    onClick={previewJobTogglePopupHandler}
                  >
                    {jobDetails?.title}
                  </h1>
                  <p className="self-start text-base font-semibold text-secondary-400">
                    {/* lnt6063 */}
                  </p>
                  {jobDetails?.jobStatus && (
                    <div className="self-start flex items-center justify-center gap-2 w-fit">
                      {/* <div className="h-2 w-2 rounded-full bg-[#6DC680]"></div> */}
                      <div
                        className={`h-2 w-2 rounded-full ${
                          jobDetails?.jobStatus === "CLOSED"
                            ? "bg-red-900"
                            : "bg-[#6DC680]"
                        }`}
                      ></div>
                      <p className="text-base text-exgray-200 font-semibold">
                        {jobDetails.jobStatus}
                      </p>
                    </div>
                  )}
                </div>

                {jobDetails.authorType === constants.COMPANY && (
                  <div className="flex items-center justify-start gap-4">
                    {author?.profileImage ? (
                      <img
                        className="h-8 w-8 rounded-full object-cover object-center"
                        src={`${baseUrl}/${author?.profileImage}?t=${token}`}
                        alt={`Profile`}
                      />
                    ) : (
                      <div className="h-8 w-8 rounded-sm bg-DefaultProfileImg bg-cover bg-center"></div>
                    )}

                    <p className="text-base text-exgray-200 font-semibold">
                      {author?.title}
                    </p>

                    <span className="px-3 py-1 rounded-full bg-secondary-100 text-secondary-400 text-sm">
                      Owner
                    </span>

                    <div className="flex items-center justify-center gap-2">
                      {loginSelector?.who === constants.USER.toLowerCase() ||
                      !loginSelector?.userId ? (
                        <>
                          <div>
                            <ClockIcon width="15" height="15" color="#8C8C8C" />
                          </div>
                          <p className="self-start text-base text-exgray-200">{`Posted ${postedBy}`}</p>
                        </>
                      ) : (
                        <p className="self-start text-base text-exgray-200">{`Posted On ${postedByDate}`}</p>
                      )}
                    </div>
                  </div>
                )}

                {jobDetails.authorType === constants.COMPANY_MEMBER && (
                  <div className="flex items-center justify-start gap-4">
                    {author?.profileImage ? (
                      <img
                        className="h-8 w-8 rounded-full object-cover object-center"
                        src={`${baseUrl}/${author?.profileImage}?t=${token}`}
                        alt={`Profile`}
                      />
                    ) : (
                      <div className="h-8 w-8 rounded-sm bg-DefaultProfileImg bg-cover bg-center"></div>
                    )}

                    <p className="text-base text-exgray-200 font-semibold">
                      {`${author?.firstName} ${author?.lastName}`}
                    </p>

                    <span className="px-3 py-1 rounded-full bg-secondary-100 text-secondary-400 text-sm">
                      Owner
                    </span>

                    <div className="flex items-center justify-center gap-2">
                      <div className="">
                        <ClockIcon width="15" height="15" color="#8C8C8C" />
                      </div>
                      <p className="text-base text-exgray-200">{`Posted ${postedBy}`}</p>
                    </div>
                  </div>
                )}

                <div className="space-y-4">
                  <div className="flex items-start justify-start gap-2">
                    {((loginSelector?.who ===
                      constants.COMPANY_MEMBER.toLowerCase() &&
                      memberSelector?.designation !== constants.ADVISER) ||
                      loginSelector?.who === constants.COMPANY.toLowerCase()) &&
                      assignedMembersArray &&
                      assignedMembersArray.length > 0 && (
                        <h1 className="text-base text-[#0BA02C] font-semibold bg-[#E7F6EA] px-4 rounded-full">
                          Assigned Members
                        </h1>
                      )}
                    <div className="flex-1 flex flex-wrap gap-x-4 gap-y-1">
                      {((loginSelector?.who ===
                        constants.COMPANY_MEMBER.toLowerCase() &&
                        memberSelector?.designation !== constants.ADVISER) ||
                        loginSelector?.who ===
                          constants.COMPANY.toLowerCase()) &&
                        assignedMembersArray &&
                        assignedMembersArray.length > 0 &&
                        assignedMembersArray.map(
                          (member) =>
                            member?.memberEmail && (
                              <div
                                className="flex items-center justify-start gap-1"
                                key={uuid4()}
                              >
                                {member?.memberDetails?.profileImage ? (
                                  <div className="w-5 h-5">
                                    <Image
                                      url={member?.memberDetails?.profileImage}
                                      alt="profile"
                                      borderRadius="4px"
                                    />
                                  </div>
                                ) : (
                                  member?.memberDetails?.firstName &&
                                  member?.memberDetails?.lastName && (
                                    <div className="h-5 w-5 rounded-sm bg-DefaultProfileImg bg-cover bg-center"></div>
                                  )
                                )}
                                <p className="self-start text-exgray-200 text-base">
                                  {/* {member?.memberEmail} */}
                                  {`${member?.memberDetails?.firstName} ${member?.memberDetails?.lastName}`}
                                </p>
                              </div>
                            )
                        )}
                    </div>
                  </div>

                  <div className="flex items-start justify-start gap-2">
                    {((loginSelector?.who ===
                      constants.COMPANY_MEMBER.toLowerCase() &&
                      memberSelector?.designation !== constants.ADVISER) ||
                      loginSelector?.who === constants.COMPANY.toLowerCase()) &&
                      advisors &&
                      advisors.length > 0 && (
                        <h1 className="text-base text-[#0BA02C] font-semibold bg-[#E7F6EA] px-5 rounded-full">
                          Assigned Advisers
                        </h1>
                      )}
                    <div className="flex-1 flex flex-wrap gap-x-4 gap-y-1">
                      {((loginSelector?.who ===
                        constants.COMPANY_MEMBER.toLowerCase() &&
                        memberSelector?.designation !== constants.ADVISER) ||
                        loginSelector?.who ===
                          constants.COMPANY.toLowerCase()) &&
                        advisors &&
                        advisors.length > 0 &&
                        advisors.map(
                          (member) =>
                            member?.memberEmail && (
                              <div
                                className="flex items-center justify-start gap-1"
                                key={uuid4()}
                              >
                                {member?.memberDetails?.profileImage ? (
                                  <div className="w-5 h-5">
                                    <Image
                                      url={member?.memberDetails?.profileImage}
                                      alt="profile"
                                      borderRadius="4px"
                                    />
                                  </div>
                                ) : (
                                  member?.memberDetails?.firstName &&
                                  member?.memberDetails?.lastName && (
                                    <div className="h-5 w-5 rounded-sm bg-DefaultProfileImg bg-cover bg-center"></div>
                                  )
                                )}
                                <h1 className="self-start text-exgray-200 text-base">
                                  {/* {member?.memberEmail} */}
                                  {`${member?.memberDetails?.firstName} ${member?.memberDetails?.lastName}`}
                                </h1>
                              </div>
                            )
                        )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="w-fit self-start flex items-center justify-end gap-4">
                {((loginSelector?.who ===
                  constants.COMPANY_MEMBER.toLowerCase() &&
                  memberSelector?.designation !== constants.ADVISER) ||
                  loginSelector?.who === constants.COMPANY.toLowerCase()) && (
                  <button
                    className="flex items-center justify-center gap-2 bg-secondary-800 py-2 px-4 text-exwhite-100 text-base text-center rounded-sm active:scale-95"
                    onClick={createReferPopupHandler}
                  >
                    Add Candidate
                  </button>
                )}

                <button
                  className="text-primary-500 px-2 border-b-2 border-primary-500 text-base text-center font-semibold"
                  onClick={previewJobTogglePopupHandler}
                >
                  Preview Job
                </button>

                <div className="relative">
                  <button
                    onClick={toggleJobSeetingsHandler}
                    ref={jobSettingMenuParentRef}
                    className={`${
                      (loginSelector?.who ===
                        constants.COMPANY_MEMBER.toLowerCase() &&
                        memberSelector?.designation !== constants.ADVISER) ||
                      loginSelector?.who === constants.COMPANY.toLowerCase()
                        ? ""
                        : "hidden"
                    }`}
                  >
                    <VerticleThreeDotsIcon />
                  </button>

                  {isJobSettingOpen && (
                    <div
                      className="absolute top-11 right-0 rounded-md w-40 z-50"
                      style={{
                        boxShadow: "0px 12px 32px 0px #18191C14",
                      }}
                      ref={jobSettingMenuRef}
                    >
                      {jobDetails?.jobStatus === "CLOSED" && (
                        <button
                          className="py-2 px-4 bg-exwhite-100 text-start rounded-t-md hover:bg-secondary-100 w-full hover:text-secondary-400"
                          onClick={openJobHandler}
                        >
                          Activate Job
                        </button>
                      )}

                      <button
                        className="py-2 px-4 bg-exwhite-100 text-start hover:bg-secondary-100 hover:text-secondary-400 w-full"
                        onClick={() =>
                          nevigateJobHandler(
                            `/company-admin/update-job/${jobId}`
                          )
                        }
                      >
                        Edit Job
                      </button>
                      <button
                        className="py-2 px-4 bg-exwhite-100 text-start hover:bg-secondary-100 hover:text-secondary-400 w-full"
                        onClick={toggleAssignJobPopupHandler}
                      >
                        Assign Job
                      </button>
                      {jobDetails?.jobStatus === "OPEN" && (
                        <button
                          className="py-2 px-4 bg-exwhite-100 text-start hover:bg-secondary-100 w-full hover:text-secondary-400"
                          onClick={closeJobHandler}
                        >
                          Close Job
                        </button>
                      )}
                      {/* <button
                        className="py-2 px-4 bg-exwhite-100 text-start rounded-b-md hover:bg-secondary-100 hover:text-secondary-400 w-full"
                        onClick={() =>
                          nevigateJobHandler(
                            `/company-admin/global-search/${jobId}`
                          )
                        }
                      >
                        Global Search
                      </button> */}
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="flex items-center justify-start">
              <div className="flex-1 flex items-center justify-start self-end gap-2 max-w-2xl">
                <button
                  className={`w-fit px-8 text-base  font-semibold py-1 hover:text-secondary-400 ${
                    pathname === `/company-admin/job/${jobId}` ||
                    pathname === `/company-admin/job/${jobId}/approvals`
                      ? "bg-secondary-100 text-secondary-400 border-b-2 border-secondary-400"
                      : "text-exgray-200/80"
                  }`}
                  onClick={() =>
                    nevigateJobHandler(
                      pathname === `/company-admin/job/${jobId}/approvals` ||
                        pathname ===
                          `/company-admin/job/${jobId}/tagged-list/approvals`
                        ? `/company-admin/job/${jobId}/approvals`
                        : `/company-admin/job/${jobId}`
                    )
                  }
                >
                  Source List
                </button>
                <button
                  className={`w-fit px-8 text-base  font-semibold py-1 hover:text-secondary-400 ${
                    pathname === `/company-admin/job/${jobId}/tagged-list` ||
                    pathname ===
                      `/company-admin/job/${jobId}/tagged-list/approvals`
                      ? "bg-secondary-100 text-secondary-400 border-b-2 border-secondary-400"
                      : "text-exgray-200/80"
                  }`}
                  onClick={() =>
                    nevigateJobHandler(
                      pathname === `/company-admin/job/${jobId}/approvals` ||
                        pathname ===
                          `/company-admin/job/${jobId}/tagged-list/approvals`
                        ? `/company-admin/job/${jobId}/tagged-list/approvals`
                        : `/company-admin/job/${jobId}/tagged-list`
                    )
                  }
                >
                  Tagged List
                </button>

                {(loginSelector?.who === constants.COMPANY.toLowerCase() ||
                  (loginSelector?.who ===
                    constants.COMPANY_MEMBER.toLowerCase() &&
                    memberSelector?.designation !== constants.ADVISER &&
                    !pathname.includes("approvals"))) && (
                  <button
                    className={`w-fit px-8 text-base  font-semibold py-1 hover:text-secondary-400 ${
                      pathname ===
                      `/company-admin/job/${jobId}/candidate-search`
                        ? "bg-secondary-100 text-secondary-400 border-b-2 border-secondary-400"
                        : "text-exgray-200/80"
                    }`}
                    onClick={() =>
                      nevigateJobHandler(
                        `/company-admin/job/${jobId}/candidate-search`
                      )
                    }
                  >
                    Candidate Search
                  </button>
                )}

                {(loginSelector?.who === constants.COMPANY.toLowerCase() ||
                  (loginSelector?.who ===
                    constants.COMPANY_MEMBER.toLowerCase() &&
                    memberSelector?.designation !== constants.ADVISER &&
                    !pathname.includes("approvals"))) && (
                  <button
                    className={`w-fit px-8 text-base  font-semibold py-1 hover:text-secondary-400 ${
                      pathname === `/company-admin/job/${jobId}/global-search`
                        ? "bg-secondary-100 text-secondary-400 border-b-2 border-secondary-400"
                        : "text-exgray-200/80"
                    }`}
                    onClick={() =>
                      nevigateJobHandler(
                        `/company-admin/job/${jobId}/global-search`
                      )
                    }
                  >
                    Global Search
                  </button>
                )}
              </div>

              {/* <div className="w-fit flex flex-wrap items-center justify-end ml-auto">
                <div className="w-fit px-2 flex items-center justify-start gap-2">
                  <div className="h-2 w-2 rounded-full bg-secondary-400"></div>
                  <p className="flex-1 text-secondary-400 font-semibold text-start text-base">{`5965 Applicants`}</p>
                </div>

                <div className="w-fit px-2 flex items-center justify-start gap-2">
                  <div className="h-2 w-2 rounded-full bg-[#0BA02C]"></div>
                  <p className="flex-1 text-[#0BA02C] font-semibold text-start text-base">{`565 Tagged`}</p>
                </div>

                <div className="w-fit px-2 flex items-center justify-start gap-2">
                  <div className="h-2 w-2 rounded-full bg-[#E62E2E]"></div>
                  <p className="flex-1 text-[#E62E2E] font-semibold text-start text-base">{`89 Rejected`}</p>
                </div>
              </div> */}
            </div>
          </div>

          <div>
            <Outlet />
          </div>
        </div>

        <Popup
          onClose={toggleAssignJobPopupHandler}
          visible={toggleAssignJobPopup}
        >
          <div className="w-[70%] bg-expurple-200 p-10 rounded-xl">
            <AssignJobForm
              onClose={toggleAssignJobPopupHandler}
              jobId={jobDetails.id}
              getAssignedMembersByJobId={getAssignedMembersByJobId}
            />
          </div>
        </Popup>

        <Popup onClose={() => {}} visible={toggleCreateReferPopup}>
          <div className="w-[70%] bg-exwhite-100 py-10 rounded-xl">
            <CreateReferForm
              onClose={createReferPopupHandler}
              jobId={jobDetails.id}
            />
          </div>
        </Popup>

        <Popup
          onClose={() => setIsPreviewJobPopupOpen(false)}
          visible={isPreviewJobPopupOpen}
        >
          <JobPreview
            jobDetails={jobDetails}
            author={author}
            assignedMembers={assignedMembers}
          />
        </Popup>
      </div>
    )
  );
};

export default JobPage;
