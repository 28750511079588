import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as constants from "../../constant";
import moment from "moment";
import { getAllAttendanceApi, getAllEmployeeAttendanceApi } from "../../slices/attendanceSlice";
import { useAppDispatch, useAppSelector } from "../../config/hooks";



const EmployeeAttendanceList = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [togglePopup, setTogglePopup] = useState(false);
  const userId = useAppSelector((state) => state?.login?.session?.userId);
  const attendanceList = useAppSelector((state) => state?.attendance?.allEmloyeeAttendance);
  const [startDateSelected, setStartDateSelected] = useState(null);
  const [date, setDate] = useState(new Date());
  const [regularizeAttendace, setRegularizeAttendance] = useState(null);
  const loginSelector = useAppSelector((state) => state?.login?.session);
  const memberSelector = useAppSelector((state) => state.member);

  useEffect(() => {
    getAllAttendanceList();
  }, []);

  const getAllAttendanceList = () => {
    dispatch(
      getAllEmployeeAttendanceApi(
        {
          dateFrom: moment().format('YYYY-MM-DD'),
          dateTo: moment().format('YYYY-MM-DD'),
          companyId: loginSelector.who === constants.COMPANY.toLowerCase()
            ? userId
            : memberSelector?.memberDetails?.companyId,
        }
      )
    )
  }

  useEffect(() => {
    var timer = null;
    if (!startDateSelected) {
      timer = setInterval(() => setDate(new Date()), 1000)
    }
    return function cleanup() {
      clearInterval(timer)
    }
  });

  const togglePopupHandler = () => {
    setTogglePopup(!togglePopup);
  };

  const regularizeAtten = (attendance) => {
    setRegularizeAttendance(attendance);
    setTogglePopup(true);
  }

  return (
    <>
      <div className="mt-3">
        <h1 className="text-lg text-exgray-900 font-medium">Today's Attendance</h1>
        <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
          <table className="w-full mt-2 text-center rounded-sm">
            <thead className="py-3">
              <tr className="bg-secondary-800 rounded-lg ">
                <th className="text-sm text-exwhite-100 font-medium py-3">Name</th>
                <th className="text-sm text-exwhite-100 font-medium py-3">Designation</th>
                <th className="text-sm text-exwhite-100 font-medium py-3">Date</th>
                <th className="text-sm text-exwhite-100 font-medium py-3">Day</th>
                <th className="text-sm text-exwhite-100 font-medium py-3">Clock In</th>
                <th className="text-sm text-exwhite-100 font-medium py-3">Clock Out</th>
                <th className="text-sm text-exwhite-100 font-medium py-3">Working Hrs</th>
                <th className="text-sm text-exwhite-100 font-medium py-3">Reporting Manager</th>
              </tr>
            </thead>
            <tbody className="items-center justify-center ">
              {
                attendanceList?.map((attendance, index) => {
                  var startTime = moment(attendance.checkIn);
                  var endTime = moment(attendance.checkOut);
                  var dif = moment.duration(endTime.diff(startTime));
                  return (
                    <tr key={attendance.id} className="items-center border-b border-l border-r border-exgray-100">
                      <td className="text-sm items-center py-3">{attendance.employee.firstName} {attendance.employee.lastName}</td>
                      <td className="text-sm items-center py-3 text-secondary-500 font-medium"></td>
                      <td className="text-sm items-center py-3">{moment(attendance.date).format('DD/MM/YYYY')}</td>
                      <td className="text-sm items-center py-3">{moment(attendance.date).format('dddd')}</td>
                      <td className="text-sm items-center py-3">{moment(attendance.checkIn).format('LT')}</td>
                      <td className="text-sm items-center py-3">{attendance?.status ? moment(attendance.checkOut).format('LT') : ''}</td>
                      {/* <td className="items-center py-3">
                        <div className="flex justify-center">
                          {
                            !attendance?.isRegularized ?
                              <div className="flex items-center border border-secondary-500 px-2 rounded-md" onClick={() => regularizeAtten(attendance)}>
                                <EditIcon />
                                <h1 className="ml-2 text-secondary-500">Edit</h1>
                              </div>
                              :
                              <h1 className="ml-2 text-secondary-500">InReview</h1>
                          }
                        </div>
                      </td> */}
                      <td className="text-sm items-center py-3">{attendance?.status ? `${dif.hours()}:${dif.minutes() < 10 ? "0" + dif.minutes() : dif.minutes()}` : ''}</td>
                      <td className="text-sm items-center py-3">{attendance?.reportingManagerDetails?.firstName} {attendance?.reportingManagerDetails?.lastName}</td>
                    </tr>
                  )
                })
              }


            </tbody>
          </table>
        </div>

        {/* <Popup onClose={togglePopupHandler} visible={togglePopup}>
          <RegularizationForm
            onClose={togglePopupHandler}
            attendance={regularizeAttendace}
            updateEndTime={undefined}
          />
        </Popup> */}


      </div>
    </>
  );
};

export default EmployeeAttendanceList;
