import Select from "react-select";

const ExDropdown = ({
  title,
  error,
  optionValue,
  optionLabel,
  searchJob = false,
  dropDownHeight = "3.5rem",
  dropDownBorderWidth = "1px",
  menuListHeight = "250px",
  // menuPortalTarget = null,
  dropDownBorderColor = "#8C8C8C",
  isMandatory = false,
  reference = null,
  ...props
}) => {
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      cursor: "pointer",
      paddingLeft: searchJob && "30px",
      height: dropDownHeight,
      //   maxWidth: "150px",
      fontSize: "16px",
      lineHeight: "24px",
      color: state.isSelected ? "#696969" : "#696969",
      border: `${dropDownBorderWidth} solid ${
        state.isFocused
          ? error
            ? "#dc2623"
            : dropDownBorderColor
          : error
          ? "#dc2623"
          : dropDownBorderColor
      }`,
      borderRadius: "6px",
      backgroundColor: "#FFFFFF",
      boxShadow: state.isFocused ? "none" : "none",
      ":hover": {
        border: `${dropDownBorderWidth} solid ${
          state.isFocused
            ? error
              ? "#dc2623"
              : dropDownBorderColor
            : error
            ? "#dc2623"
            : dropDownBorderColor
        }`,
      },
    }),
    input: (provided) => ({
      ...provided,
      fontSize: "16px",
      color: "#181919",
    }),
    singleValue: (provided) => ({
      ...provided,
      fontSize: "16px",
      color: "#181919",
    }),
    option: (provided, state) => ({
      ...provided,
      fontSize: "16px",
      color: state.isSelected ? "#0A65CC" : "#0A65CC",
      backgroundColor: state.isSelected
        ? "#E7F0FA"
        : state.isFocused
        ? "#f1f1f1" // Highlight when focused (via keyboard)
        : "#FFFFFF",
      ":hover": {
        backgroundColor: "#f1f1f1",
        color: "#0A65CC",
        cursor: "pointer",
      },
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: "#FFFFFF",
      // zIndex: 99999,
    }),
    menuList: (provided) => ({
      ...provided,
      maxHeight: `${menuListHeight}`,
      "::-webkit-scrollbar": {
        width: 0,
      },
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      display: "none",
    }),

    dropdownIndicator: (provided) => ({
      ...provided,
      // display: "none",
      color: "#CDCED2",
      ":hover": {
        color: "#CDCED2",
      },
    }),

    dropdownIndicatorContainer: (provided) => ({
      ...provided,
      cursor: "pointer",
      paddingRight: "8px",
    }),
    indicatorContainer: (provided) => ({
      ...provided,
      "& svg": {
        display: "none",
      },
    }),
    clearIndicator: (provided) => ({
      ...provided,
      display: "none",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#8C8C8C", // Adjust the placeholder text color
      fontSize: "16px", // Adjust the font size as needed
    }),
  };

  return (
    <div className="space-y-1">
      {title && (
        <label className="text-exgray-200/70 text-base font-semibold">
          {title}
          {isMandatory && " *"}
        </label>
      )}
      <Select
        {...props}
        getOptionValue={(position) => position[optionValue]}
        getOptionLabel={(position) => position[optionLabel]}
        isClearable={true}
        backspaceRemovesValue={true}
        styles={customStyles}
        isSearchable={true}
        ref={reference}
        // menuPortalTarget={menuPortalTarget}
        theme={(theme) => ({
          ...theme,
          borderRadius: 0,
          colors: {
            ...theme.colors,
            text: "#FDCF57",
            primary: "black",
          },
        })}
      />
      {error && <p className="text-red-400">{error}</p>}
    </div>
  );
};

export default ExDropdown;
