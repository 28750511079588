import React, { useEffect, useState } from "react";
import PlusCircleIcon from "../../assets/Icons/PlusCircleIcon";
import Popup from "../../components/popup/Popup";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import CreateMember from "../ats/pages/company-dashboard/components/CreateMember";
import { useAppDispatch, useAppSelector } from "../../config/hooks";
import { employeeSearchApi, getMembersApi } from "../../slices/companyMemberSlice";
import SearchIcon from "../../assets/Icons/SearchIcon";
import * as constants from "../../constant";

const EmployeeList = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const userId = useAppSelector((state) => state?.login?.session?.userId);
  const loginSelector = useAppSelector((state) => state?.login?.session);
  const memberSelector = useAppSelector((state) => state.member);
  const [togglePopup, setTogglePopup] = useState(false);

  useEffect(() => {
    dispatch(
      getMembersApi({
        companyId:
          loginSelector.who === constants.COMPANY.toLowerCase()
            ? userId
            : memberSelector?.memberDetails?.companyId,

      })
    );
  }, []);

  const onChange = (e) => {
    dispatch(
      employeeSearchApi({
        companyId:
          loginSelector.who === constants.COMPANY.toLowerCase()
            ? userId
            : memberSelector?.memberDetails?.companyId,
        searchText: e.target.value
      })
    );

  };

  const togglePopupHandler = () => {
    setTogglePopup(!togglePopup);
  };

  const navigateHandler = (path) => {
    navigate(path);
  };

  return (
    <div>
      <div className="min-h-screenheight bg-exwhite-100 p-4 space-y-4">
        <div className="flex items-center justify-between">
          <div className="w-[50%] flex items-center justify-start gap-4">
            <h1 className="text-lg text-exgray-900 font-semibold">Employees</h1>
            <div className="flex-1 relative">
              <input
                type="text"
                name="title"
                onChange={onChange}
                // value={value}
                placeholder="Search Name/ Designation"
                className="outline outline-2 outline-exgray-100 bg-transparent py-2 w-full text-base px-10 placeholder:text-exgray-100 rounded-full text-exgray-400"
              />
              <div className="absolute top-0 left-2 h-full flex items-center justify-center">
                <SearchIcon color="#042347" />
              </div>
            </div>
          </div>
          <div className="flex items-center justify-end gap-4">
            <button
              className="flex items-center justify-center gap-2 px-4 py-2 bg-expurple-800 text-base text-exwhite-100 font-semibold rounded-md"
              onClick={togglePopupHandler}
            >
              <PlusCircleIcon color="white" /> Create Employee
            </button>
          </div>
        </div>
        <div className="flex items-center justify-start">
          <button
            className={`px-4 py-1 border-b-2 ${pathname === "/company-admin/employee-list"
              ? "border-primary-600 text-primary-600"
              : "text-exgray-100 border-exgray-100"
              } text-base text-center font-semibold`}
            onClick={() => navigateHandler("/company-admin/employee-list")}
          >
            Employees
          </button>
          <button
            className={`px-4 py-1 border-b-2 ${pathname === "/company-admin/employee-list/adviser"
              ? "border-primary-600 text-primary-600"
              : "text-exgray-100 border-exgray-100"
              } text-base text-center font-semibold`}
            onClick={() =>
              navigateHandler("/company-admin/employee-list/adviser")
            }
          >
            Advisers
          </button>
          <div className="border-b-2 border-exgray-100 flex-1 self-end"></div>
        </div>
        <div>
          <Outlet />
        </div>
      </div>

      <Popup onClose={togglePopupHandler} visible={togglePopup}>
        <CreateMember onClose={togglePopupHandler} />
      </Popup>
    </div>
  );
};

export default EmployeeList;
