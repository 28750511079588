import React from "react";
import EditIcon from "../../assets/Icons/EditIcon";
import VerticleThreeDotsIcon from "../../assets/Icons/VerticleThreeDotsIcon";
import CrossIcon from "../../assets/Icons/CrossIcon";
import moment from "moment";
import FileIcon from "../../assets/Icons/FileIcon";
import useSalesDropdown from "../../utils/dropdown-options/useSalesDropdown" 
import { baseUrl } from "../../constant";
import { useAppSelector } from "../../config/hooks";
import ClockIcon from "../../assets/Icons/ClockIcon";



const Interaction = ({data}) => {
  const {defaultSalesInteractionType}=useSalesDropdown()
  const {session}=useAppSelector((state)=>state.login)
  return (
    <div className="bg-exwhite-100 border border-gray-300 rounded-lg p-6  mx-auto my-2">
      {/* Header Section */}
      <div className="flex justify-between items-center mb-4">
       
        <div className="text-secondary-500 text-xl font-semibold flex gap-2 items-center"> <ClockIcon color="#095bb8" width="14" height="16" />{moment(data?.interactionDate).format('D MMM YYYY, hh:mm A')}</div>
        
        <div className="flex gap-2"> 
         <h3 className="text-red-500 border border-red-200 px-4 rounded-lg bg-red-100">{defaultSalesInteractionType(data?.interactionType)}</h3>
              {/* <VerticleThreeDotsIcon/> */}
        </div>
      </div>

      {/* Participants */}
      <div className="flex items-center gap-[15%]">
        <div>
          <span className="text-exgray-400 ">Created by :</span>{" "}
          {/* <span className="text-exgray-800 font-semibold">{data?.createdBy}</span> */}
        <span className="text-exgray-800 font-semibold ">{data?.authorDetails?.firstName?`${data?.authorDetails?.firstName} ${data?.authorDetails?.lastName}`:`Super admin`}</span>

        </div>
      { data?.interactionPerson?.length>0 && 
        <div className=" flex flex-row  gap-2  ">
          <span className="text-exgray-500 font-semibold text-nowrap">Participated :</span>
          <div className="flex flex-wrap gap-2 ">
           { data?.interactionPerson?.split(',').map((ele,i)=>{
            return (
              <span key={i+ele} className="border border-exgray-200 text-exgray-300 px-4 rounded-full text-sm">
              {ele}
             </span>
            )
           })
          }     
          </div>
        </div>}
      </div>

      

       {/* Follow-up */}
       <div className="my-2">
        {/* <div className="text-primary-800 ">Follow-up</div> */}
        <div className="bg-exgray-100/25   p-1 py-2 rounded flex justify-between items-center w-full">
          <div className="mb-2 ">
          <span className="text-exgray-400 ">FollowUp Title :</span>{" "}
          <span className="text-secondary-800 font-semibold">{data?.followUptitle} </span>  
           
            {/* <EditIcon/> */}
          </div>
          <div className="text-gray-600 text-sm border border-exgray-200 w-fit  rounded-full py-1 px-4 font-semibold ">
           {moment(data?.nextFollowUpDate).format('D MMM YYYY, hh:mm A')}
          </div>
        </div>
      </div>

      {/* Title */}
      <div className=" ">
        <span className="text-exgray-400 ">Purpose :</span>{" "}
        <span className="text-exgray-800 font-semibold">{data?.purpose} </span>        
      </div>

      {/* Description */}
      <div className="">
      <span className="text-exgray-400 ">Remarks :</span>{" "}
      <span className="text-exgray-800 font-semibold "> <span className="">{data?.remarks}</span> </span>      
      </div>

      {/* Attached Document */}
      <div className="flex gap-1 flex-wrap mt-2">
       {data?.documents?.map((ele)=>{
        return (
          <a href={`${baseUrl}/${ele.documentURL}?t=${session.token}`}>
          <div className="flex  items-center  gap-4  w-fit bg-secondary-200 py-1 px-3 rounded-lg mb-2">
          <FileIcon/>
          <div>
            <span className="font-semibold text-primary-700">{ele.documentName}</span>
            <div className="text-gray-500 text-xs">{moment(data?.uploadedAt).format('D MMM YYYY')}</div>
          </div>
          {/* <div>
              <CrossIcon color="#095BB8" width="12"/>
              </div> */}
        </div>
        </a>
        )
      })     
     }
    </div>

     
    </div>
  );
};

export default Interaction;
