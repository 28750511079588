import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import aiService from "../services/ai.service";
import { logout } from "./loginSlice";

const initialState = {
  globalSearch: {
    mappedCandidates: [],
    isLoading: false,
    error: false,
  },
  databaseSearch: {
    mappedCandidates: [],
    searchedFields: null,
    metadata: {
      current_page: null,
      next_page: null,
      per_page: null,
      previous_page: null,
      total_pages: null,
      total_results: null,
    },
    isRelaventSearchOn: false,
    isLoading: false,
    error: false,
  },
  databaseRelevantSearch: {
    mappedRelevantCandidates: [],
    // searchedFieldsJobLevel: null,
    metadataRelevant: {
      current_page: null,
      next_page: null,
      per_page: null,
      previous_page: null,
      total_pages: null,
      total_results: null,
    },
    // isRelaventSearchOnJobLevel: false,
    isLoadingRelevant: false,
    errorRelevant: false,
  },
  databaseSearchJobLevel: {
    mappedCandidatesJobLevel: [],
    searchedFieldsJobLevel: null,
    metadataJobLevel: {
      current_page: null,
      next_page: null,
      per_page: null,
      previous_page: null,
      total_pages: null,
      total_results: null,
    },
    isRelaventSearchOnJobLevel: false,
    isLoadingJobLevel: false,
    errorJobLevel: false,
  },

  databaseRelevantSearchJobLevel: {
    mappedRelevantCandidatesJobLevel: [],
    // searchedFieldsJobLevel: null,
    metadataRelevantJobLevel: {
      current_page: null,
      next_page: null,
      per_page: null,
      previous_page: null,
      total_pages: null,
      total_results: null,
    },
    // isRelaventSearchOnJobLevel: false,
    isLoadingRelevantJobLevel: false,
    errorRelevantJobLevel: false,
  },
};

export const getCvDetailsApi = createAsyncThunk(
  "ai/getCvDetailsApi",
  async (data, { getState, rejectWithValue }) => {
    try {
      const response = await aiService.getCvDetails(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getJdDetailsApi = createAsyncThunk(
  "ai/getJdDetailsApi",
  async (data, { getState, rejectWithValue }) => {
    try {
      const response = await aiService.getJdDetails(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getMappedCandidatesAPi = createAsyncThunk(
  "ai/getMappedCandidatesApi",
  async (data: any, { getState, rejectWithValue }) => {
    try {
      const response = await aiService.globalSearchCandidate(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const dataBaseSearchCandidatesApi = createAsyncThunk(
  "ai/dataBaseSearchCandidatesApi",
  async (data: any, { getState, rejectWithValue }) => {
    try {
      const response = await aiService.dataBaseSearchCandidates(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const dataBaseSearchRelevantCandidatesApi = createAsyncThunk(
  "ai/dataBaseSearchRelevantCandidatesApi",
  async (data: any, { getState, rejectWithValue }) => {
    try {
      const response = await aiService.dataBaseSearchRelevantCandidates(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const dataBaseSearchCandidatesJobLevelApi = createAsyncThunk(
  "ai/dataBaseSearchCandidatesJobLevelApi",
  async (data: any, { getState, rejectWithValue }) => {
    try {
      const response = await aiService.dataBaseSearchCandidates(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const dataBaseSearchRelevantCandidatesJobLevelApi = createAsyncThunk(
  "ai/dataBaseSearchRelevantCandidatesJobLevelApi",
  async (data: any, { getState, rejectWithValue }) => {
    try {
      const response = await aiService.dataBaseSearchRelevantCandidates(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const dataBaseSearchCandidatesOnFormsubmitApi = createAsyncThunk(
  "ai/dataBaseSearchCandidatesOnFormsubmitApi",
  async (data: any, { getState, rejectWithValue }) => {
    try {
      const response = await aiService.dataBaseSearchCandidates(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const dataBaseSearchRelevantCandidatesOnFormsubmitApi = createAsyncThunk(
  "ai/dataBaseSearchRelevantCandidatesOnFormsubmitApi",
  async (data: any, { getState, rejectWithValue }) => {
    try {
      const response = await aiService.dataBaseSearchRelevantCandidates(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const dataBaseSearchCandidatesOnFormsubmitJobLevelApi = createAsyncThunk(
  "ai/dataBaseSearchCandidatesOnFormsubmitJobLevelApi",
  async (data: any, { getState, rejectWithValue }) => {
    try {
      const response = await aiService.dataBaseSearchCandidates(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const dataBaseSearchRelevantCandidatesOnFormsubmitJobLevelApi =
  createAsyncThunk(
    "ai/dataBaseSearchRelevantCandidatesOnFormsubmitJobLevelApi",
    async (data: any, { getState, rejectWithValue }) => {
      try {
        const response = await aiService.dataBaseSearchRelevantCandidates(data);
        return response;
      } catch (error) {
        return rejectWithValue(error.response?.data);
      }
    }
  );

export const getGlobalSearchCandidateAfterJobCreatedApi = createAsyncThunk(
  "ai/getGlobalSearchCandidateAfterJobCreatedApi",
  async (jobId: string, { getState, rejectWithValue }) => {
    try {
      const response = await aiService.getGlobalSearchCandidateAfterJobCreated(
        jobId
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const aiSlice = createSlice({
  name: "ai",
  initialState,
  reducers: {
    searchFieldsReducer: (state, action) => {
      state.databaseSearch.searchedFields = action.payload;
    },
    searchFieldsJobLevelReducer: (state, action) => {
      state.databaseSearchJobLevel.searchedFieldsJobLevel = action.payload;
    },
    clearSearchResultReducer: (state) => {
      state.databaseSearch.mappedCandidates = [];
      state.databaseSearch.searchedFields = null;
      state.databaseSearch.isRelaventSearchOn = false;
      state.databaseSearch.metadata = {
        current_page: null,
        next_page: null,
        per_page: null,
        previous_page: null,
        total_pages: null,
        total_results: null,
      };

      state.databaseRelevantSearch.mappedRelevantCandidates = [];
      state.databaseRelevantSearch.metadataRelevant = {
        current_page: null,
        next_page: null,
        per_page: null,
        previous_page: null,
        total_pages: null,
        total_results: null,
      };
    },
    clearSearchResultJobLevelReducer: (state) => {
      state.databaseSearchJobLevel.mappedCandidatesJobLevel = [];
      state.databaseSearchJobLevel.searchedFieldsJobLevel = null;
      state.databaseSearchJobLevel.isRelaventSearchOnJobLevel = false;
      state.databaseSearchJobLevel.metadataJobLevel = {
        current_page: null,
        next_page: null,
        per_page: null,
        previous_page: null,
        total_pages: null,
        total_results: null,
      };

      state.databaseRelevantSearchJobLevel.mappedRelevantCandidatesJobLevel =
        [];
      state.databaseRelevantSearchJobLevel.metadataRelevantJobLevel = {
        current_page: null,
        next_page: null,
        per_page: null,
        previous_page: null,
        total_pages: null,
        total_results: null,
      };
    },
    toggleSearchCriteria: (state) => {
      state.databaseSearch.isRelaventSearchOn =
        !state.databaseSearch.isRelaventSearchOn;
    },
    toggleSearchCriteriaJobLevel: (state) => {
      state.databaseSearchJobLevel.isRelaventSearchOnJobLevel =
        !state.databaseSearchJobLevel.isRelaventSearchOnJobLevel;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getMappedCandidatesAPi.pending, (state) => {
      state.globalSearch.isLoading = true;
    });

    builder.addCase(getMappedCandidatesAPi.fulfilled, (state, action) => {
      state.globalSearch.isLoading = false;
      state.globalSearch.error = false;
      const response = action?.payload?.data;
      state.globalSearch.mappedCandidates = [
        ...state.globalSearch.mappedCandidates,
        ...response,
      ];
    });

    builder.addCase(getMappedCandidatesAPi.rejected, (state) => {
      state.globalSearch.isLoading = false;
      state.globalSearch.error = true;
    });

    builder.addCase(dataBaseSearchCandidatesApi.pending, (state, action) => {
      state.databaseSearch.isLoading = true;
    });

    builder.addCase(dataBaseSearchCandidatesApi.fulfilled, (state, action) => {
      const response = action?.payload?.data;
      state.databaseSearch.mappedCandidates = [
        ...state.databaseSearch.mappedCandidates,
        ...response?.candidates,
      ];
      state.databaseSearch.metadata = {
        current_page: response?.current_page,
        next_page: response?.next_page,
        per_page: response?.per_page,
        previous_page: response?.previous_page,
        total_pages: response?.total_pages,
        total_results: response?.total_results,
      };
      state.databaseSearch.isLoading = false;
      state.databaseSearch.error = false;
    });

    builder.addCase(dataBaseSearchCandidatesApi.rejected, (state, action) => {
      state.databaseSearch.isLoading = false;
      state.databaseSearch.error = true;
    });

    //Job level candidate search start
    builder.addCase(
      dataBaseSearchCandidatesJobLevelApi.pending,
      (state, action) => {
        state.databaseSearchJobLevel.isLoadingJobLevel = true;
      }
    );

    builder.addCase(
      dataBaseSearchCandidatesJobLevelApi.fulfilled,
      (state, action) => {
        const response = action?.payload?.data;
        state.databaseSearchJobLevel.mappedCandidatesJobLevel = [
          ...state.databaseSearchJobLevel.mappedCandidatesJobLevel,
          ...response?.candidates,
        ];
        state.databaseSearchJobLevel.metadataJobLevel = {
          current_page: response?.current_page,
          next_page: response?.next_page,
          per_page: response?.per_page,
          previous_page: response?.previous_page,
          total_pages: response?.total_pages,
          total_results: response?.total_results,
        };
        state.databaseSearchJobLevel.isLoadingJobLevel = false;
        state.databaseSearchJobLevel.errorJobLevel = false;
      }
    );

    builder.addCase(
      dataBaseSearchCandidatesJobLevelApi.rejected,
      (state, action) => {
        state.databaseSearchJobLevel.isLoadingJobLevel = false;
        state.databaseSearchJobLevel.errorJobLevel = true;
      }
    );

    builder.addCase(
      dataBaseSearchCandidatesOnFormsubmitJobLevelApi.pending,
      (state, action) => {
        state.databaseRelevantSearchJobLevel.isLoadingRelevantJobLevel = true;
      }
    );

    builder.addCase(
      dataBaseSearchCandidatesOnFormsubmitJobLevelApi.fulfilled,
      (state, action) => {
        const response = action?.payload?.data;
        state.databaseSearchJobLevel.mappedCandidatesJobLevel = [
          ...response?.candidates,
        ];
        state.databaseSearchJobLevel.metadataJobLevel = {
          current_page: response?.current_page,
          next_page: response?.next_page,
          per_page: response?.per_page,
          previous_page: response?.previous_page,
          total_pages: response?.total_pages,
          total_results: response?.total_results,
        };
        state.databaseSearchJobLevel.isLoadingJobLevel = false;
        state.databaseSearchJobLevel.errorJobLevel = false;
      }
    );

    builder.addCase(
      dataBaseSearchCandidatesOnFormsubmitJobLevelApi.rejected,
      (state, action) => {
        state.databaseSearchJobLevel.isLoadingJobLevel = false;
        state.databaseSearchJobLevel.errorJobLevel = true;
      }
    );

    //Job Level candidate search end

    //Relevant Search at Job level Starts here
    builder.addCase(
      dataBaseSearchRelevantCandidatesJobLevelApi.pending,
      (state, action) => {
        state.databaseRelevantSearchJobLevel.isLoadingRelevantJobLevel = true;
        state.databaseRelevantSearchJobLevel.errorRelevantJobLevel = false;
      }
    );

    builder.addCase(
      dataBaseSearchRelevantCandidatesJobLevelApi.fulfilled,
      (state, action) => {
        const response = action?.payload?.data;
        state.databaseRelevantSearchJobLevel.mappedRelevantCandidatesJobLevel =
          [
            ...state.databaseRelevantSearchJobLevel
              .mappedRelevantCandidatesJobLevel,
            ...response?.candidates,
          ];
        state.databaseRelevantSearchJobLevel.metadataRelevantJobLevel = {
          current_page: response?.current_page,
          next_page: response?.next_page,
          per_page: response?.per_page,
          previous_page: response?.previous_page,
          total_pages: response?.total_pages,
          total_results: response?.total_results,
        };
        state.databaseRelevantSearchJobLevel.isLoadingRelevantJobLevel = false;
        state.databaseRelevantSearchJobLevel.errorRelevantJobLevel = false;
      }
    );

    builder.addCase(
      dataBaseSearchRelevantCandidatesJobLevelApi.rejected,
      (state, action) => {
        state.databaseRelevantSearchJobLevel.isLoadingRelevantJobLevel = false;
        state.databaseRelevantSearchJobLevel.errorRelevantJobLevel = true;
      }
    );

    builder.addCase(
      dataBaseSearchRelevantCandidatesOnFormsubmitJobLevelApi.pending,
      (state, action) => {
        state.databaseRelevantSearchJobLevel.isLoadingRelevantJobLevel = true;
      }
    );

    builder.addCase(
      dataBaseSearchRelevantCandidatesOnFormsubmitJobLevelApi.fulfilled,
      (state, action) => {
        const response = action?.payload?.data;
        state.databaseRelevantSearchJobLevel.mappedRelevantCandidatesJobLevel =
          [...response?.candidates];
        state.databaseRelevantSearchJobLevel.metadataRelevantJobLevel = {
          current_page: response?.current_page,
          next_page: response?.next_page,
          per_page: response?.per_page,
          previous_page: response?.previous_page,
          total_pages: response?.total_pages,
          total_results: response?.total_results,
        };
        state.databaseRelevantSearchJobLevel.isLoadingRelevantJobLevel = false;
        state.databaseRelevantSearchJobLevel.errorRelevantJobLevel = false;
      }
    );

    builder.addCase(
      dataBaseSearchRelevantCandidatesOnFormsubmitJobLevelApi.rejected,
      (state, action) => {
        state.databaseRelevantSearchJobLevel.isLoadingRelevantJobLevel = false;
        state.databaseRelevantSearchJobLevel.errorRelevantJobLevel = true;
      }
    );

    //Relevant Search at Job level ends here

    builder.addCase(
      dataBaseSearchCandidatesOnFormsubmitApi.pending,
      (state, action) => {
        state.databaseSearch.isLoading = true;
      }
    );

    builder.addCase(
      dataBaseSearchCandidatesOnFormsubmitApi.fulfilled,
      (state, action) => {
        const response = action?.payload?.data;
        state.databaseSearch.mappedCandidates = [...response?.candidates];
        state.databaseSearch.metadata = {
          current_page: response?.current_page,
          next_page: response?.next_page,
          per_page: response?.per_page,
          previous_page: response?.previous_page,
          total_pages: response?.total_pages,
          total_results: response?.total_results,
        };
        state.databaseSearch.isLoading = false;
        state.databaseSearch.error = false;
      }
    );

    builder.addCase(
      dataBaseSearchCandidatesOnFormsubmitApi.rejected,
      (state, action) => {
        state.databaseSearch.isLoading = false;
        state.databaseSearch.error = true;
      }
    );

    // database search relavent candidates starts here
    builder.addCase(
      dataBaseSearchRelevantCandidatesOnFormsubmitApi.pending,
      (state, action) => {
        state.databaseRelevantSearch.isLoadingRelevant = true;
      }
    );

    builder.addCase(
      dataBaseSearchRelevantCandidatesOnFormsubmitApi.fulfilled,
      (state, action) => {
        const response = action?.payload?.data;
        state.databaseRelevantSearch.mappedRelevantCandidates = [
          ...response?.candidates,
        ];
        state.databaseRelevantSearch.metadataRelevant = {
          current_page: response?.current_page,
          next_page: response?.next_page,
          per_page: response?.per_page,
          previous_page: response?.previous_page,
          total_pages: response?.total_pages,
          total_results: response?.total_results,
        };
        state.databaseRelevantSearch.isLoadingRelevant = false;
        state.databaseRelevantSearch.errorRelevant = false;
      }
    );

    builder.addCase(
      dataBaseSearchRelevantCandidatesOnFormsubmitApi.rejected,
      (state, action) => {
        state.databaseRelevantSearch.isLoadingRelevant = false;
        state.databaseRelevantSearch.errorRelevant = true;
      }
    );

    builder.addCase(
      dataBaseSearchRelevantCandidatesApi.pending,
      (state, action) => {
        state.databaseRelevantSearch.isLoadingRelevant = true;
      }
    );

    builder.addCase(
      dataBaseSearchRelevantCandidatesApi.fulfilled,
      (state, action) => {
        const response = action?.payload?.data;
        state.databaseRelevantSearch.mappedRelevantCandidates = [
          ...state.databaseRelevantSearch.mappedRelevantCandidates,
          ...response?.candidates,
        ];
        state.databaseRelevantSearch.metadataRelevant = {
          current_page: response?.current_page,
          next_page: response?.next_page,
          per_page: response?.per_page,
          previous_page: response?.previous_page,
          total_pages: response?.total_pages,
          total_results: response?.total_results,
        };
        state.databaseRelevantSearch.isLoadingRelevant = false;
        state.databaseRelevantSearch.errorRelevant = false;
      }
    );

    builder.addCase(
      dataBaseSearchRelevantCandidatesApi.rejected,
      (state, action) => {
        state.databaseRelevantSearch.isLoadingRelevant = false;
        state.databaseRelevantSearch.errorRelevant = true;
      }
    );
    // database search relavent candidates ends here
    builder.addCase(logout, (state) => {
      // Reset this slice's state to initial state
      return initialState;
    });
  },
});

export const {
  searchFieldsReducer,
  clearSearchResultReducer,
  searchFieldsJobLevelReducer,
  clearSearchResultJobLevelReducer,
  toggleSearchCriteriaJobLevel,
  toggleSearchCriteria,
} = aiSlice.actions;
export default aiSlice.reducer;
