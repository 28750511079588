import React, { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import ExInput from "../../../../../components/basic-components/ExInput";
import { useAppDispatch, useAppSelector } from "../../../../../config/hooks";
import ExDropdown from "../../../../../components/basic-components/ExDropdown";
import ExRadioBtnGroup from "../../../../../components/basic-components/ExRadioBtnGroup";
import useCountryCodes from "../../../../../utils/dropdown-options/useCountryCodes";
import useSalaries from "../../../../../utils/dropdown-options/useSalaries";
import useIndustries from "../../../../../utils/dropdown-options/useIndustries";
import useLocations from "../../../../../utils/dropdown-options/useLocations";
import { applyJobApi } from "../../../../../slices/jobSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { updateReferalStatusApi } from "../../../../../slices/referSlice";
import LoadingIcon from "../../../../../assets/Icons/LoadingIcon";
import uploadContent from "../../../../../utils/upload-content/uploadContent";
import PlusCircleIcon from "../../../../../assets/Icons/PlusCircleIcon";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";
import {
  createAdditionalDetailsApi,
  updateAdditionalDetailsApi,
} from "../../../../../slices/candidateProfileSlice";
import useSalariesInThousands from "../../../../../utils/dropdown-options/useSalariesInThousands";

const ApplyJobForm = ({
  jobDetails,
  closePopupHandler,
  status,
  referalId,
  recruiterId,
  referralType,
  job = null,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const inputRef = useRef(null);

  const { id } = jobDetails;

  const { modifiedCountryCodes, defaultCountryCode } = useCountryCodes();
  const { minSalaries, defualtSalary } = useSalaries();
  const { industriesArray, defaultIndustry } = useIndustries();
  const { locationsArray, defaultLocation } = useLocations();
  const { salaryInThousands, defualtSalaryInThousand } =
    useSalariesInThousands();

  const [isFileLoading, setIsFileLoading] = useState(false);
  const [isFormLoading, setIsFormLoading] = useState(false);

  const userId = useAppSelector((state) => state?.login?.session?.userId);
  const userDetails = useAppSelector(
    (state) => state.candidateProfile.userDetails
  );
  const additionalDetails = useAppSelector(
    (state) => state.candidateProfile.userAdditionalInfo
  );

  const { email, countryCode, mobile } = userDetails;

  const form = useForm({
    defaultValues: {
      phoneNo:
        mobile > 0
          ? additionalDetails?.mobile > 0
            ? additionalDetails?.mobile.toString()
            : mobile.toString()
          : additionalDetails?.mobile > 0
          ? additionalDetails.mobile.toString()
          : "",
      emailId: email
        ? additionalDetails?.email
          ? additionalDetails?.email
          : email
        : additionalDetails?.email
        ? additionalDetails.email
        : "",
      countryCode:
        countryCode > 0
          ? additionalDetails?.countryCode > 0
            ? defaultCountryCode(additionalDetails.countryCode)
            : defaultCountryCode(countryCode)
          : additionalDetails?.countryCode > 0
          ? defaultCountryCode(additionalDetails.countryCode)
          : null,
      openForRelocation: additionalDetails?.relocation
        ? "Yes"
        : additionalDetails?.relocation === false
        ? "No"
        : "Yes",
      noticePeriod: additionalDetails.isServingNoticePeriod ? "Yes" : "No",
      noticePeriodDuration: additionalDetails.noticePeriod
        ? additionalDetails.noticePeriod
        : "",
      salary: additionalDetails.id
        ? additionalDetails?.lastSalary
          ? defualtSalary(additionalDetails?.lastSalary)
          : null
        : null,
      currentCtcInThousand:
        additionalDetails?.id && additionalDetails?.lastSalaryInThousand
          ? defualtSalaryInThousand(additionalDetails?.lastSalaryInThousand)
          : {
              Title: 0,
              Value: 0,
            },
      expectedCtc: additionalDetails?.expectation
        ? defualtSalary(additionalDetails?.expectation)
        : null,
      expectedCtcInThousand:
        additionalDetails?.id && additionalDetails?.expectedSalaryInThousand
          ? defualtSalaryInThousand(additionalDetails?.expectedSalaryInThousand)
          : {
              Title: 0,
              Value: 0,
            },
      industry: additionalDetails.id
        ? additionalDetails?.preferredIndustry[0]
          ? defaultIndustry(additionalDetails?.preferredIndustry[0])
          : null
        : null,
      location: additionalDetails.id
        ? additionalDetails?.preferredLocation[0]
          ? defaultLocation(additionalDetails?.preferredLocation[0])
          : null
        : null,
      file: {
        name: additionalDetails.resume.name
          ? additionalDetails.resume.name
          : "",
        url: additionalDetails.resume.url ? additionalDetails.resume.url : "",
        contentType: additionalDetails.resume.contentType
          ? additionalDetails.resume.contentType
          : "",
        size: additionalDetails?.resume?.size
          ? additionalDetails?.resume?.size
          : 0,
      },
    },
  });

  const { handleSubmit, control, formState, watch, setValue } = form;
  const { errors } = formState;

  const uploadedFile = watch("file");
  const isServingNoticePeriodTrack = watch("noticePeriod");

  useEffect(() => {
    if (isServingNoticePeriodTrack === "No") {
      setValue("noticePeriodDuration", "0");
    } else {
      setValue(
        "noticePeriodDuration",
        additionalDetails.noticePeriod ? additionalDetails.noticePeriod : ""
      );
    }
  }, [isServingNoticePeriodTrack, setValue]);

  const triggerInputfileHanlder = () => {
    if (inputRef.current) inputRef.current.click();
  };

  const onSubmit = async (formData) => {
    setIsFormLoading(true);
    const data = {
      jobId: id,
      userId: userId,
      mobile: parseInt(formData.phoneNo),
      emailId: formData.emailId,
      location: [formData.location.Id.toString()],
      lastSalary: formData.salary.value,
      lastSalaryThousand: formData?.currentCtcInThousand?.Value,
      preferredIndustry: [formData.industry.Id.toString()],
      openForRelocation: formData.openForRelocation === "Yes" ? true : false,
      countryCode: parseInt(formData.countryCode.PhoneCode),
      noOfServingDays: parseInt(formData.noticePeriodDuration),
      status: "APPLIED",
      subStatus: "APPLICATION_SEND",
      attachment: formData.file,
      recruiterId: recruiterId,
      referralId: referalId,
      referralType: referalId ? referralType : "user",
      applied: true,
      comments: job?.comments,
      expectation: formData?.expectedCtc?.value,
      expectationThousand: formData?.expectedCtcInThousand?.Value,
      preferredJobLocation: job?.preferredJobLocation,
      previousCompany: job?.previousCompany,
      qualification: job?.qualification,
      reasonForJobChange: job?.reasonForJobChange,
      reportingTo: job?.reportingTo,
      teamSize: job?.teamSize,
      workMode: job?.workMode,
      skills: job?.skills,
    };

    try {
      await dispatch(applyJobApi(data)).then(unwrapResult);

      if (additionalDetails?.id) {
        const data = {
          userId: userId,
          id: additionalDetails?.id,
          mobile: parseInt(formData.phoneNo),
          email: formData.emailId,
          preferredLocation: [formData.location.Id.toString()],
          lastSalary: formData.salary.value.toString(),
          lastSalaryInThousand: formData.currentCtcInThousand.Value.toString(),
          expectation: formData.expectedCtc.value.toString(),
          expectedSalaryInThousand:
            formData.expectedCtcInThousand.Value.toString(),
          preferredIndustry: [formData.industry.Id.toString()],
          relocation: formData.openForRelocation === "Yes" ? true : false,
          countryCode: parseInt(formData.countryCode.PhoneCode),
          resume: formData.file,
          isServingNoticePeriod: formData.noticePeriod === "Yes" ? true : false,
          noticePeriod: formData.noticePeriodDuration,
        };

        try {
          await dispatch(updateAdditionalDetailsApi(data)).then(unwrapResult);
        } catch (error) {
          toast.error("Something went wrong");
        }
      } else {
        const data = {
          userId: userId,
          mobile: parseInt(formData.phoneNo),
          email: formData.emailId,
          preferredLocation: [formData.location.Id.toString()],
          lastSalary: formData.salary.value.toString(),
          lastSalaryThousand: formData?.currentCtcInThousand?.Value.toString(),
          expectation: formData?.expectedCtc?.value.toString(),
          expectationThousand:
            formData?.expectedCtcInThousand?.Value.toString(),
          preferredIndustry: [formData.industry.Id.toString()],
          relocation: formData.openForRelocation === "Yes" ? true : false,
          countryCode: parseInt(formData.countryCode.PhoneCode),
          resume: formData.file,
          isServingNoticePeriod: formData.noticePeriod === "Yes" ? true : false,
          noticePeriod: formData.noticePeriodDuration,
        };

        try {
          await dispatch(createAdditionalDetailsApi(data)).then(unwrapResult);
        } catch (error) {
          toast.error("Something went wrong");
        }
      }

      if (referalId && status === "pending") {
        await dispatch(
          updateReferalStatusApi({ referalId: referalId, status: "APPLIED" })
        ).then(unwrapResult);
      }
      setIsFormLoading(false);
      closePopupHandler();
      toast.success("Job applied successfully");
      navigate("/applied-jobs");
    } catch (error) {
      setIsFormLoading(false);
      toast.error("Something went wrong");
      closePopupHandler();
    }
  };

  return (
    <div className="w-full bg-expurple-200 rounded-xl overflow-y-auto no-scrollbar space-y-5">
      <h1 className="text-3xl text-expurple-800 font-semibold text-center">
        Additional Information
      </h1>
      <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
        <div className="grid grid-cols-2 auto-rows-auto gap-4">
          <div className="flex items-center justify-between gap-2">
            <div className="min-w-[120px] self-start">
              <Controller
                name="countryCode"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: "required",
                  },
                }}
                render={({ field: { onChange, value } }) => (
                  <ExDropdown
                    title="Country"
                    onChange={onChange}
                    placeholder="Select"
                    value={value || null}
                    optionValue="PhoneCode"
                    optionLabel="PhoneCodeLabel"
                    options={modifiedCountryCodes}
                    dropDownHeight="40px"
                    dropDownBorderWidth="0px"
                    dropDownBorderColor="#696969"
                    error={errors?.countryCode?.message}
                  />
                )}
              />
            </div>
            <div className="flex-1 self-start">
              <Controller
                name="phoneNo"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: "Mobile no is required",
                  },
                  pattern: {
                    value: /^[0-9]+$/,
                    message: "Please enter a valid mobile number",
                  },
                }}
                render={({ field: { onChange, value } }) => (
                  <ExInput
                    title="Phone Number"
                    placeholder="Enter your phone number"
                    name="phoneNo"
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    onChange={onChange}
                    value={value}
                    error={errors?.phoneNo?.message}
                  />
                )}
              />
            </div>
          </div>

          <div>
            <Controller
              name="emailId"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "Email ID is required",
                },
                pattern: {
                  value:
                    /^([a-zA-Z0-9_.+-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/,
                  message: "Please enter a valid email ID",
                },
              }}
              render={({ field: { onChange, value } }) => (
                <ExInput
                  title="Email ID"
                  placeholder="eg. abc@abc.com"
                  name="emailId"
                  onChange={onChange}
                  value={value}
                  error={errors?.emailId?.message}
                />
              )}
            />
          </div>

          <div className="flex items-start justify-start gap-2">
            <div
              className={`self-center flex items-center justify-end  ${
                errors?.salary?.message || errors?.currentCtcInThousand?.message
                  ? ""
                  : "pt-6"
              }`}
            >
              <h1 className="text-exgray-200 text-base font-semibold">
                Current CTC
              </h1>
            </div>

            <div className="flex-1">
              <Controller
                name="salary"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: "Last salary is required",
                  },
                }}
                render={({ field: { onChange, value } }) => (
                  <ExDropdown
                    title="Lacs"
                    onChange={onChange}
                    placeholder="Select"
                    value={value || null}
                    optionValue="Id"
                    optionLabel="Title"
                    options={minSalaries}
                    isMandatory={true}
                    dropDownHeight="40px"
                    dropDownBorderWidth="0px"
                    dropDownBorderColor="#696969"
                    error={errors?.salary?.message}
                  />
                )}
              />
            </div>
            <div className="flex-1">
              <Controller
                name="currentCtcInThousand"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: "Required",
                  },
                }}
                render={({ field: { onChange, value } }) => (
                  <ExDropdown
                    title="Thousand"
                    onChange={onChange}
                    placeholder="Select"
                    value={value || null}
                    optionValue="Value"
                    optionLabel="Title"
                    options={salaryInThousands}
                    isMandatory={true}
                    dropDownHeight="40px"
                    dropDownBorderWidth="2px"
                    dropDownBorderColor="#CDCED2"
                    error={errors?.currentCtcInThousand?.message}
                  />
                )}
              />
            </div>
          </div>

          <div className="flex items-start justify-start gap-2">
            <div
              className={`self-center flex items-center justify-end  ${
                errors?.expectedCtc?.message ||
                errors?.expectedCtcInThousand?.message
                  ? ""
                  : "pt-6"
              }`}
            >
              <h1 className="text-exgray-200 text-base font-semibold">
                Expectation
              </h1>
            </div>

            <div className="flex-1">
              <Controller
                name="expectedCtc"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: "Expected CTC is required",
                  },
                }}
                render={({ field: { onChange, value } }) => (
                  <ExDropdown
                    title="Lacs"
                    onChange={onChange}
                    placeholder="Select"
                    value={value || null}
                    optionValue="value"
                    optionLabel="Title"
                    isMandatory={true}
                    options={minSalaries}
                    dropDownHeight="40px"
                    dropDownBorderWidth="2px"
                    dropDownBorderColor="#CDCED2"
                    error={errors?.expectedCtc?.message}
                  />
                )}
              />
            </div>

            <div className="flex-1">
              <Controller
                name="expectedCtcInThousand"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: "Required",
                  },
                }}
                render={({ field: { onChange, value } }) => (
                  <ExDropdown
                    title="Thousand"
                    onChange={onChange}
                    placeholder="Select"
                    value={value || null}
                    optionValue="Value"
                    optionLabel="Title"
                    options={salaryInThousands}
                    isMandatory={true}
                    dropDownHeight="40px"
                    dropDownBorderWidth="2px"
                    dropDownBorderColor="#CDCED2"
                    error={errors?.expectedCtcInThousand?.message}
                  />
                )}
              />
            </div>
          </div>

          <div>
            <Controller
              name="industry"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "Preffered industry is required",
                },
              }}
              render={({ field: { onChange, value } }) => (
                <ExDropdown
                  title="Preffered Industry *"
                  onChange={onChange}
                  placeholder="Select"
                  value={value || null}
                  optionValue="Id"
                  optionLabel="Name"
                  options={industriesArray}
                  dropDownHeight="40px"
                  dropDownBorderWidth="0px"
                  dropDownBorderColor="#696969"
                  error={errors?.industry?.message}
                />
              )}
            />
          </div>

          {/* <div>
            <Controller
              name="industry"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "required",
                },
              }}
              render={({ field: { onChange, value } }) => (
                <ExMultiDropdown
                  title="Email ID"
                  onChange={onChange}
                  placeholder="Select"
                  value={value || null}
                  optionValue="emailId"
                  optionLabel="emailId"
                  options={industriesArray}
                  dropDownHeight="40px"
                  dropDownBorderWidth="0px"
                  dropDownBorderColor="#696969"
                  error={errors?.industry?.message}
                />
              )}
            />
          </div> */}

          <div>
            <Controller
              name="location"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "Preffered location is required",
                },
              }}
              render={({ field: { onChange, value } }) => (
                <ExDropdown
                  title="Preffered Location *"
                  onChange={onChange}
                  placeholder="Select"
                  value={value || null}
                  optionValue="Id"
                  optionLabel="Name"
                  options={locationsArray}
                  dropDownHeight="40px"
                  dropDownBorderWidth="0px"
                  dropDownBorderColor="#696969"
                  menuListHeight="250px"
                  error={errors?.location?.message}
                />
              )}
            />
          </div>

          <div>
            <Controller
              name="openForRelocation"
              control={control}
              rules={{
                required: {
                  value: false,
                  message: "Employment Type is required",
                },
              }}
              render={({ field: { onChange, value } }) => (
                <ExRadioBtnGroup
                  title="Open For Relocation"
                  onChange={onChange}
                  selectedOption={value}
                  options={["Yes", "No"]}
                  error={errors?.openForRelocation?.message}
                />
              )}
            />
          </div>

          <div>
            <Controller
              name="noticePeriod"
              control={control}
              rules={{
                required: {
                  value: false,
                  message: "Notice period is required",
                },
              }}
              render={({ field: { onChange, value } }) => (
                <ExRadioBtnGroup
                  title="Serving Notice Period"
                  onChange={onChange}
                  selectedOption={value}
                  options={["Yes", "No"]}
                  error={errors?.openForRelocation?.message}
                />
              )}
            />
          </div>

          <div>
            <Controller
              name="noticePeriodDuration"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "Notice period required",
                },
              }}
              render={({ field: { onChange, value } }) => (
                <ExInput
                  title="No. of Days Serving"
                  placeholder="Ex. 30"
                  name="noticePeriodDuration"
                  type="number"
                  onChange={onChange}
                  onWheel={(e) => e.target.blur()}
                  value={value}
                  error={errors?.noticePeriodDuration?.message}
                />
              )}
            />
          </div>

          <div>
            <Controller
              name="file"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "Required",
                },
                validate: {
                  hasName: (value) => value?.name !== "" || "File is required",
                  hasUrl: (value) => value?.url !== "" || "File is required",
                  hasContentType: (value) =>
                    value?.contentType !== "" || "File is required",
                  // validSize: (value) => value?.size > 0 || "File is required",
                },
              }}
              render={({ field: { onChange, value } }) => (
                <div>
                  <label
                    htmlFor="file"
                    className={`text-lg font-normal text-expurple-800`}
                  >
                    Upload Resume / CV
                  </label>
                  <div className="hidden">
                    <input
                      title="Add Resume / CV"
                      placeholder="Browse file"
                      name="file"
                      id="file"
                      type="file"
                      ref={inputRef}
                      accept=".pdf, .doc, .docx"
                      onChange={(e) => {
                        setIsFileLoading(true);
                        uploadContent.uploadProfileContent(
                          userId,
                          e.target.files,
                          (response) => {
                            setIsFileLoading(false);

                            if (!response[0].error) {
                              onChange({
                                name: response[0].orginalFileName,
                                url: response[0].url,
                                contentType: response[0].contentType,
                                size: response[0].size,
                              });
                            }
                          }
                        );
                      }}
                      // value={value}
                    />
                  </div>
                  <div className="flex items-center justify-between gap-2">
                    <div className="flex-1 h-10 bg-expurple-100 px-3 flex items-center justify-start gap-1">
                      <div
                        className="cursor-pointer"
                        onClick={triggerInputfileHanlder}
                      >
                        <PlusCircleIcon />
                      </div>
                      <p className="text-expurple-700 text-base line-clamp-1">
                        {uploadedFile.name
                          ? uploadedFile.name
                          : "Browse file. Only pdf"}
                      </p>
                    </div>
                    {isFileLoading && (
                      <div>
                        <LoadingIcon />
                      </div>
                    )}
                  </div>
                  {errors?.file?.message && (
                    <p className="text-red-400">{errors?.file?.message}</p>
                  )}
                </div>
              )}
            />
          </div>
        </div>

        <div>
          <div className="w-fit ml-auto mr-0">
            <button
              className={`text-base text-center px-8 py-2 ${
                isFileLoading || isFormLoading
                  ? "bg-exgray-400/50 text-exwhite-100"
                  : "bg-expurple-800 text-golden"
              } rounded-lg`}
              disabled={isFileLoading || isFormLoading}
            >
              Apply
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default ApplyJobForm;
