import { useEffect, useMemo, useRef, useState } from "react";
import { Controller } from "react-hook-form";
import { v4 as uuidv4 } from "uuid";
import CrossIcon from "../../../../assets/Icons/CrossIcon";
import StarIcon from "../../../../assets/Icons/StarIcon";
import ExDropdown from "../../../../components/basic-components/ExDropdown";
import useMinExperiences from "../../../../utils/dropdown-options/useMinExperiences";
import useSalaries from "../../../../utils/dropdown-options/useSalaries";
import useCurrencies from "../../../../utils/dropdown-options/useCurrencies";
import ExMultiDropdown from "../../../../components/basic-components/ExMultiDropdown";
import useLocations from "../../../../utils/dropdown-options/useLocations";
import ArrowIcon from "../../../../assets/Icons/ArrowIcon";
import useIndustries from "../../../../utils/dropdown-options/useIndustries";
import useDesignation from "../../../../utils/dropdown-options/useDesignation";
import ExInput from "../../../../components/basic-components/ExInput";
import ExRadioBtnGroup from "../../../../components/basic-components/ExRadioBtnGroup";
import { useAppDispatch } from "../../../../config/hooks";
import {
  dataBaseSearchCandidatesApi,
  dataBaseSearchCandidatesOnFormsubmitApi,
  dataBaseSearchCandidatesOnFormsubmitJobLevelApi,
  dataBaseSearchRelevantCandidatesOnFormsubmitApi,
  dataBaseSearchRelevantCandidatesOnFormsubmitJobLevelApi,
  searchFieldsJobLevelReducer,
  searchFieldsReducer,
} from "../../../../slices/aiSlice";
import { toast } from "sonner";
import useQualifications from "../../../../utils/dropdown-options/useQualifications";
import { useParams } from "react-router-dom";

const CandidateSearchForm = ({ form }) => {
  const dispatch = useAppDispatch();

  const { jobId } = useParams();

  const [keyword, setKeyword] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [excludeCompanyName, setExcludeCompanyName] = useState("");
  const [sectionId, setSectionId] = useState(null);
  const [companyStatus, setCompanyStatus] = useState("Current Company");
  const [excludeCompanyStatus, setExcludeCompanyStatus] =
    useState("Current Company");
  const [isCompanyModalOpen, setIsCompanyModalOpen] = useState(false);
  const [isExcludedCompanyModalOpen, setIsExcludedCompanyModalOpen] =
    useState(false);
  // const [selectedOptions, setSelectedOptions] = useState([
  //   { id: "any", label: "Any" },
  // ]);

  // const options = [
  //   { id: "any", label: "Any" },
  //   { id: "15", label: "0 - 15 days" },
  //   { id: "30", label: "1 month" },
  //   { id: "60", label: "2 months" },
  //   { id: "90", label: "3 months" },
  //   { id: "180", label: "More than 3 months" },
  //   { id: "notice-period", label: "Currently serving notice period" },
  // ];

  const menuRef = useRef(null);
  const menuParentRef = useRef(null);
  const menuRefExcludeCompany = useRef(null);
  const menuParentRefExcludeCompany = useRef(null);

  const { minExperienceArray } = useMinExperiences();
  const { minSalaries } = useSalaries();
  const { currenciesArray } = useCurrencies();
  const { locationsArray } = useLocations();
  const { industriesArray } = useIndustries();
  const { designationList } = useDesignation();
  const { qualificationsArray } = useQualifications();

  const { handleSubmit, control, formState, watch, setValue } = form;
  const { errors } = formState;

  const allKeywords = watch("keywords");
  const allMandatoryKeywords = watch("mandatoryKeywords");
  const allCompanies = watch("company");
  const allExludedCompanies = watch("excludecompany");
  const selectedMinExperience = watch("minExperience");
  const selectedMaxExperience = watch("maxExperience");
  const selectedMinSalary = watch("minSalary");
  const selectedMaxSalary = watch("maxSalary");

  useEffect(() => {
    const toggleHandler = (e) => {
      if (
        menuRef.current &&
        menuParentRef &&
        !menuRef.current.contains(e.target) &&
        !menuParentRef.current.contains(e.target)
      ) {
        // close popup
        setIsCompanyModalOpen(false);
      }
    };
    document.addEventListener("mousedown", toggleHandler);

    return () => {
      document.removeEventListener("mousedown", toggleHandler);
    };
  });

  useEffect(() => {
    const toggleHandler = (e) => {
      if (
        menuRefExcludeCompany.current &&
        menuParentRefExcludeCompany &&
        !menuRefExcludeCompany.current.contains(e.target) &&
        !menuParentRefExcludeCompany.current.contains(e.target)
      ) {
        // close popup
        setIsExcludedCompanyModalOpen(false);
      }
    };
    document.addEventListener("mousedown", toggleHandler);

    return () => {
      document.removeEventListener("mousedown", toggleHandler);
    };
  });

  const maxExpRange = useMemo(() => {
    if (selectedMinExperience) {
      const targetIndex = minExperienceArray.findIndex(
        (exp) => exp.Id === selectedMinExperience.Id
      );
      if (targetIndex) return minExperienceArray.slice(targetIndex + 1);
      return minExperienceArray;
    }
    return minExperienceArray;
  }, [minExperienceArray, selectedMinExperience]);

  const minExpRange = useMemo(() => {
    if (selectedMaxExperience) {
      const targetIndex = minExperienceArray.findIndex(
        (exp) => exp.Id === selectedMaxExperience.Id
      );
      if (targetIndex) return minExperienceArray.slice(0, targetIndex);
      else return minExperienceArray;
    }
    return minExperienceArray;
  }, [minExperienceArray, selectedMaxExperience]);

  const maxSalRange = useMemo(() => {
    if (selectedMinSalary) {
      const targetIndex = minSalaries.findIndex(
        (sal) => sal.Id === selectedMinSalary.Id
      );
      if (targetIndex) return minSalaries.slice(targetIndex + 1);
      else return minSalaries;
    }
    return minSalaries;
  }, [minSalaries, selectedMinSalary]);

  const minSalRange = useMemo(() => {
    if (selectedMaxSalary) {
      const targetIndex = minSalaries.findIndex(
        (sal) => sal.Id === selectedMaxSalary.Id
      );
      if (targetIndex) return minSalaries.slice(0, targetIndex);
      else return minSalaries;
    }
    return minSalaries;
  }, [minSalaries, selectedMaxSalary]);

  const addKeywordHandler = () => {
    setValue("keywords", [...allKeywords, keyword], {
      shouldDirty: true,
    });
    setKeyword("");
  };

  const addCompanyHandler = () => {
    setValue("company", [...allCompanies, companyName], {
      shouldDirty: true,
    });
    setCompanyName("");
  };

  const addExcludedCompanyHandler = () => {
    setValue("excludecompany", [...allExludedCompanies, excludeCompanyName], {
      shouldDirty: true,
    });
    setExcludeCompanyName("");
  };

  const addMandatoryKeywordHandler = (key) => {
    if (allMandatoryKeywords.includes(key)) {
      const filteredArray = allMandatoryKeywords.filter(
        (keyword) => keyword !== key
      );
      setValue("mandatoryKeywords", filteredArray, {
        shouldDirty: true,
      });
    } else {
      setValue("mandatoryKeywords", [...allMandatoryKeywords, key], {
        shouldDirty: true,
      });
    }
  };

  const removeKeywordHandler = (index) => {
    const newInterestArray = allKeywords?.filter((_, i) => i !== index);
    setValue("keywords", newInterestArray, { shouldDirty: true });
  };

  const removeCompanyNameHandler = (index) => {
    const newCompanyArray = allCompanies?.filter((_, i) => i !== index);
    setValue("company", newCompanyArray, { shouldDirty: true });
  };

  const removeExcludedCompanyNameHandler = (index) => {
    const newCompanyArray = allExludedCompanies?.filter((_, i) => i !== index);
    setValue("excludecompany", newCompanyArray, { shouldDirty: true });
  };

  const onChangeKeywordValue = (e) => {
    const fieldValue = e.target.value;
    setKeyword(fieldValue);
  };

  const onChangeCompanyValue = (e) => {
    const fieldValue = e.target.value;
    setCompanyName(fieldValue);
  };

  const onChangeExcludedCompanyValue = (e) => {
    const fieldValue = e.target.value;
    setExcludeCompanyName(fieldValue);
  };

  const toggleSectionHandler = (selectedSectionId) => {
    if (selectedSectionId === sectionId) setSectionId(null);
    else setSectionId(selectedSectionId);
  };

  const toggleCompanyStatusHandler = () => {
    setIsCompanyModalOpen(!isCompanyModalOpen);
  };

  const toggleExcludeCompanyStatusHandler = () => {
    setIsExcludedCompanyModalOpen(!isExcludedCompanyModalOpen);
  };

  const changeCompanyStatusHandler = (status) => {
    setCompanyStatus(status);
    toggleCompanyStatusHandler();
  };

  const changeExcludedCompanyStatusHandler = (status) => {
    setExcludeCompanyStatus(status);
    toggleExcludeCompanyStatusHandler();
  };

  // const handleSelection = (selectedOption) => {
  //   setSelectedOptions((prevSelected) => {
  //     const isSelected = prevSelected.some(
  //       (sel) => sel.id === selectedOption.id
  //     );

  //     // If "Any" is selected, clear all selections except "notice-period"
  //     if (selectedOption.id === "any") {
  //       return prevSelected.some((sel) => sel.id === "notice-period")
  //         ? [
  //             { id: "any", label: "Any" },
  //             { id: "notice-period", label: "Currently serving notice period" },
  //           ]
  //         : [{ id: "any", label: "Any" }];
  //     }

  //     // If "Notice Period" is selected, just toggle its selection
  //     if (selectedOption.id === "notice-period") {
  //       return isSelected
  //         ? prevSelected.filter((opt) => opt.id !== "notice-period")
  //         : [...prevSelected, selectedOption];
  //     }

  //     // If any other option is selected, remove "Any" but keep "notice-period" if it's selected
  //     const newSelection = isSelected
  //       ? prevSelected.filter((opt) => opt.id !== selectedOption.id) // Remove if already selected
  //       : [...prevSelected.filter((opt) => opt.id !== "any"), selectedOption]; // Remove "Any" if adding new options

  //     // If no options are left (except "notice-period"), revert to "Any"
  //     return newSelection.length > 0
  //       ? newSelection
  //       : [{ id: "any", label: "Any" }];
  //   });
  // };

  const onSubmit = async (formData) => {
    const toastId = toast.loading("Fetching Data . . .");

    if (jobId) {
      try {
        const response = await dispatch(
          dataBaseSearchCandidatesOnFormsubmitJobLevelApi({
            keywords: formData.keywords,
            requiredKeywords: formData.mandatoryKeywords,
            minExperience: formData?.minExperience
              ? formData?.minExperience?.value
              : 0,
            maxExperience: formData?.maxExperience
              ? formData?.maxExperience?.value
              : 60,
            minSalary: formData?.minSalary ? formData?.minSalary?.value : 0,
            maxSalary: formData?.maxSalary ? formData?.maxSalary?.value : 300,
            candidateLocation:
              formData?.location && formData?.location?.length > 0
                ? formData.location.map((loc) => loc.Id.toString())
                : [],
            page: 1,
            // per_page: 10,

            industry:
              formData?.industry && formData?.industry?.length > 0
                ? formData?.industry.map((ind) => ind.Id.toString())
                : [],
            designation: formData?.designation ? [formData?.designation] : [],
            preferredLocation: [],
            company:
              formData?.company && formData?.company?.length > 0
                ? formData?.company
                : [],
            excludeCompany:
              formData?.excludecompany && formData?.excludecompany?.length > 0
                ? formData?.excludecompany
                : [],
            relocation: formData?.openForRelocation === "Yes" ? true : false,
            noticePeriod: formData?.noticePeriodDuration
              ? formData?.noticePeriodDuration
              : "",
          })
        );

        dispatch(searchFieldsJobLevelReducer(formData));
        toast.dismiss(toastId);
        if (response?.error && response?.error?.message) {
          toast.error("Something went wrong!");
        }
      } catch (error) {
        toast.dismiss(toastId);
        toast.error("Something went wrong!");
      }

      try {
        const response = await dispatch(
          dataBaseSearchRelevantCandidatesOnFormsubmitJobLevelApi({
            keywords: formData.keywords,
            requiredKeywords: formData.mandatoryKeywords,
            minExperience: formData?.minExperience
              ? formData?.minExperience?.value
              : 0,
            maxExperience: formData?.maxExperience
              ? formData?.maxExperience?.value
              : 60,
            minSalary: formData?.minSalary ? formData?.minSalary?.value : 0,
            maxSalary: formData?.maxSalary ? formData?.maxSalary?.value : 300,
            candidateLocation:
              formData?.location && formData?.location?.length > 0
                ? formData.location.map((loc) => loc.Id.toString())
                : [],
            page: 1,
            // per_page: 10,

            industry:
              formData?.industry && formData?.industry?.length > 0
                ? formData?.industry.map((ind) => ind.Id.toString())
                : [],
            designation: formData?.designation ? [formData?.designation] : [],
            preferredLocation: [],
            company:
              formData?.company && formData?.company?.length > 0
                ? formData?.company
                : [],
            excludeCompany:
              formData?.excludecompany && formData?.excludecompany?.length > 0
                ? formData?.excludecompany
                : [],
            relocation: formData?.openForRelocation === "Yes" ? true : false,
            noticePeriod: formData?.noticePeriodDuration
              ? formData?.noticePeriodDuration
              : "",
          })
        );

        if (response?.error && response?.error?.message) {
          toast.error("Something went wrong!");
        }
      } catch (error) {
        toast.dismiss(toastId);
        toast.error("Something went wrong!");
      }
    } else {
      try {
        const response = await dispatch(
          dataBaseSearchCandidatesOnFormsubmitApi({
            keywords: formData.keywords,
            requiredKeywords: formData.mandatoryKeywords,
            minExperience: formData?.minExperience
              ? formData?.minExperience?.value
              : 0,
            maxExperience: formData?.maxExperience
              ? formData?.maxExperience?.value
              : 60,
            minSalary: formData?.minSalary ? formData?.minSalary?.value : 0,
            maxSalary: formData?.maxSalary ? formData?.maxSalary?.value : 300,
            candidateLocation:
              formData?.location && formData?.location?.length > 0
                ? formData.location.map((loc) => loc.Id.toString())
                : [],
            page: 1,
            // per_page: 10,

            industry:
              formData?.industry && formData?.industry?.length > 0
                ? formData?.industry.map((ind) => ind.Id.toString())
                : [],
            designation: formData?.designation ? [formData?.designation] : [],
            preferredLocation: [],
            company:
              formData?.company && formData?.company?.length > 0
                ? formData?.company
                : [],
            excludeCompany:
              formData?.excludecompany && formData?.excludecompany?.length > 0
                ? formData?.excludecompany
                : [],
            relocation: formData?.openForRelocation === "Yes" ? true : false,
            noticePeriod: formData?.noticePeriodDuration
              ? formData?.noticePeriodDuration
              : "",
          })
        );

        dispatch(searchFieldsReducer(formData));
        toast.dismiss(toastId);
        if (response?.error && response?.error?.message) {
          toast.error("Something went wrong!");
        }
      } catch (error) {
        toast.dismiss(toastId);
        toast.error("Something went wrong!");
      }

      try {
        const response = await dispatch(
          dataBaseSearchRelevantCandidatesOnFormsubmitApi({
            keywords: formData.keywords,
            requiredKeywords: formData.mandatoryKeywords,
            minExperience: formData?.minExperience
              ? formData?.minExperience?.value
              : 0,
            maxExperience: formData?.maxExperience
              ? formData?.maxExperience?.value
              : 60,
            minSalary: formData?.minSalary ? formData?.minSalary?.value : 0,
            maxSalary: formData?.maxSalary ? formData?.maxSalary?.value : 300,
            candidateLocation:
              formData?.location && formData?.location?.length > 0
                ? formData.location.map((loc) => loc.Id.toString())
                : [],
            page: 1,
            // per_page: 10,

            industry:
              formData?.industry && formData?.industry?.length > 0
                ? formData?.industry.map((ind) => ind.Id.toString())
                : [],
            designation: formData?.designation ? [formData?.designation] : [],
            preferredLocation: [],
            company:
              formData?.company && formData?.company?.length > 0
                ? formData?.company
                : [],
            excludeCompany:
              formData?.excludecompany && formData?.excludecompany?.length > 0
                ? formData?.excludecompany
                : [],
            relocation: formData?.openForRelocation === "Yes" ? true : false,
            noticePeriod: formData?.noticePeriodDuration
              ? formData?.noticePeriodDuration
              : "",
          })
        );

        if (response?.error && response?.error?.message) {
          toast.error("Something went wrong!");
        }
      } catch (error) {
        toast.error("Something went wrong!");
      }
    }
  };

  return (
    <div className="">
      <form onSubmit={handleSubmit(onSubmit)} className="space-y-2">
        <div className="px-2 space-y-2">
          <div>
            <Controller
              name="keywords"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "Keyword is required",
                },
              }}
              render={({ field: { onChange, value } }) => (
                <div className="space-y-1">
                  <label
                    htmlFor={"keywords"}
                    className={`text-exgray-200/70 text-base font-semibold`}
                  >
                    Keywords *
                  </label>
                  <div className="relative">
                    <input
                      type="text"
                      className={`w-full h-10 px-3 bg-exwhite-100 rounded-md outline-none border-2 ${
                        errors?.keywords?.message && allKeywords.length === 0
                          ? "border-[#dc2623]"
                          : "border-exgray-100"
                      } text-exgray-800 placeholder:text-gray-400 text-base`}
                      id="keywords"
                      placeholder="Keyword ..."
                      autoComplete="off"
                      name="keywords"
                      onKeyDown={(e) => {
                        if (e.key === "Enter" && keyword) {
                          e.preventDefault();
                          addKeywordHandler();
                        }
                      }}
                      onChange={onChangeKeywordValue}
                      value={keyword}
                    />
                    <div className="absolute h-full top-0 flex items-center justify-center right-2">
                      <button
                        type="button"
                        disabled={!keyword}
                        onClick={addKeywordHandler}
                        className="py-1 w-fit px-4  text-exgray-400 font-bold underline text-sm rounded-sm"
                      >
                        Add
                      </button>
                    </div>
                  </div>
                  {errors?.keywords?.message && (
                    <p className="text-red-400">
                      {errors?.keywords?.message &&
                        allKeywords.length === 0 &&
                        errors?.keywords?.message}
                    </p>
                  )}
                  {allKeywords && allKeywords.length > 0 && (
                    <div className="flex flex-wrap gap-2">
                      {allKeywords?.map((keyword, index) => (
                        <div
                          className="px-3 py-1 bg-secondary-100 text-secondary-400 font-semibold w-fit rounded-full text-sm flex items-center justify-center gap-3 cursor-pointer"
                          key={uuidv4()}
                        >
                          <div
                            onClick={() => addMandatoryKeywordHandler(keyword)}
                          >
                            <StarIcon
                              width="16"
                              height="16"
                              color={
                                allMandatoryKeywords.includes(keyword)
                                  ? "#0A65CC"
                                  : "#CDCED2"
                              }
                            />
                          </div>
                          <h1 className="">{keyword}</h1>
                          <div
                            className="cursor-pointer"
                            onClick={() => removeKeywordHandler(index)}
                          >
                            <CrossIcon width="10" height="10" color="#90A9C5" />
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              )}
            />
          </div>

          <div className="grid grid-cols-2 auto-rows-auto gap-12">
            <div className="flex items-start justify-between gap-2">
              <div className="flex-1">
                <Controller
                  name="minExperience"
                  control={control}
                  rules={{
                    required: {
                      value: false,
                      message: "Experience is required",
                    },
                  }}
                  render={({ field: { onChange, value } }) => (
                    <ExDropdown
                      title="Min Experience"
                      onChange={onChange}
                      placeholder="Select"
                      value={value || null}
                      optionValue="Id"
                      optionLabel="Title"
                      options={minExpRange}
                      dropDownHeight="40px"
                      dropDownBorderWidth="2px"
                      dropDownBorderColor="#CDCED2"
                      error={errors?.minExperience?.message}
                      isMandatory={true}
                    />
                  )}
                />
              </div>
              <div className="flex-1 self-start">
                <Controller
                  name="maxExperience"
                  control={control}
                  rules={{
                    required: {
                      value: false,
                      message: "Experience is required",
                    },
                  }}
                  render={({ field: { onChange, value } }) => (
                    <ExDropdown
                      title="Max Experience"
                      onChange={onChange}
                      placeholder="Select"
                      value={value || null}
                      optionValue="Id"
                      optionLabel="Title"
                      options={maxExpRange}
                      dropDownHeight="40px"
                      dropDownBorderWidth="2px"
                      dropDownBorderColor="#CDCED2"
                      error={errors?.maxExperience?.message}
                      isMandatory={true}
                    />
                  )}
                />
              </div>
            </div>
            <div className="flex items-start justify-between gap-2">
              <div>
                <Controller
                  name="currency"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: "Currency is required",
                    },
                  }}
                  render={({ field: { onChange, value } }) => (
                    <ExDropdown
                      title="Currency"
                      onChange={onChange}
                      placeholder="Select"
                      value={value || null}
                      optionValue="Title"
                      optionLabel="Title"
                      options={currenciesArray}
                      dropDownHeight="40px"
                      dropDownBorderWidth="2px"
                      dropDownBorderColor="#CDCED2"
                      error={errors?.currency?.message}
                      isMandatory={true}
                    />
                  )}
                />
              </div>
              <div className="flex-1">
                <Controller
                  name="minSalary"
                  control={control}
                  rules={{
                    required: {
                      value: false,
                      message: "Minimum salary is required",
                    },
                  }}
                  render={({ field: { onChange, value } }) => (
                    <ExDropdown
                      title="Min Salary"
                      onChange={onChange}
                      placeholder="Select"
                      value={value || null}
                      optionValue="value"
                      optionLabel="Title"
                      options={minSalRange}
                      isMandatory={true}
                      dropDownHeight="40px"
                      dropDownBorderWidth="2px"
                      dropDownBorderColor="#CDCED2"
                      error={errors?.minSalary?.message}
                    />
                  )}
                />
              </div>
              <div className="flex-1">
                <Controller
                  name="maxSalary"
                  control={control}
                  rules={{
                    required: {
                      value: false,
                      message: "Max salary is required",
                    },
                  }}
                  render={({ field: { onChange, value } }) => (
                    <ExDropdown
                      title="Max Salary"
                      onChange={onChange}
                      isMandatory={true}
                      placeholder="Select"
                      value={value || null}
                      optionValue="value"
                      optionLabel="Title"
                      options={maxSalRange}
                      dropDownHeight="40px"
                      dropDownBorderWidth="2px"
                      dropDownBorderColor="#CDCED2"
                      error={errors?.maxSalary?.message}
                    />
                  )}
                />
              </div>
            </div>
          </div>
          <div>
            <Controller
              name="location"
              control={control}
              rules={{
                required: {
                  value: false,
                  message: "Location is required",
                },
              }}
              render={({ field: { onChange, value } }) => (
                <ExMultiDropdown
                  title="Current location of candidate"
                  onChange={onChange}
                  placeholder="Select"
                  value={value || null}
                  isMandatory={true}
                  optionValue="Id"
                  optionLabel="Name"
                  options={locationsArray}
                  closeMenuOnSelect={false}
                  dropDownHeight="auto"
                  dropDownBorderWidth="2px"
                  dropDownBorderColor="#CDCED2"
                  menuListHeight="250px"
                  error={errors?.location?.message}
                />
              )}
            />
          </div>
        </div>

        <div>
          <div
            className="flex items-center justify-between p-2 border-b border-exgray-200 cursor-pointer hover:bg-exgray-100/10"
            onClick={() => toggleSectionHandler(0)}
          >
            <h1 className="text-lg text-exgray-700 font-semibold">
              Employment Details
            </h1>
            <div>
              <ArrowIcon strokeWidth="2" />
            </div>
          </div>

          {sectionId === 0 && (
            <div className="space-y-2 py-2">
              <div>
                <Controller
                  name="industry"
                  control={control}
                  rules={{
                    required: {
                      value: false,
                      message: "Industry is required",
                    },
                  }}
                  render={({ field: { onChange, value } }) => (
                    <ExMultiDropdown
                      title="Industry"
                      onChange={onChange}
                      placeholder="Select"
                      value={value || null}
                      optionValue="Id"
                      optionLabel="Name"
                      options={industriesArray}
                      closeMenuOnSelect={false}
                      dropDownHeight="auto"
                      dropDownBorderWidth="2px"
                      dropDownBorderColor="#CDCED2"
                      menuListHeight="250px"
                      error={errors?.industry?.message}
                    />
                  )}
                />
              </div>

              <div>
                <Controller
                  name="company"
                  control={control}
                  rules={{
                    required: {
                      value: false,
                      message: "Company is required",
                    },
                  }}
                  render={({ field: { onChange, value } }) => (
                    <div className="space-y-1">
                      <label
                        htmlFor={"company"}
                        className={`text-exgray-200/70 text-base font-semibold`}
                      >
                        Company
                      </label>
                      <div className="relative">
                        <input
                          type="text"
                          className={`w-full h-10 px-3 bg-exwhite-100 rounded-md outline-none border-2 ${
                            errors?.company?.message &&
                            allCompanies.length === 0
                              ? "border-[#dc2623]"
                              : "border-exgray-100"
                          } text-exgray-800 placeholder:text-gray-400 text-base`}
                          id="company"
                          placeholder="Company name ..."
                          autoComplete="off"
                          name="company"
                          onKeyDown={(e) => {
                            if (e.key === "Enter" && companyName) {
                              e.preventDefault();
                              addCompanyHandler();
                            }
                          }}
                          onChange={onChangeCompanyValue}
                          value={companyName}
                        />
                        <div className="absolute h-full top-0 flex items-center justify-center right-2">
                          <button
                            type="button"
                            disabled={!companyName}
                            onClick={addCompanyHandler}
                            className="py-1 w-fit px-4  text-exgray-400 font-bold underline text-sm rounded-sm"
                          >
                            Add
                          </button>
                        </div>
                      </div>
                      {errors?.company?.message && (
                        <p className="text-red-400">
                          {errors?.company?.message &&
                            allCompanies.length === 0 &&
                            errors?.company?.message}
                        </p>
                      )}
                      <div className="flex items-center justify-start gap-2">
                        <div className="self-start pl-2 relative w-fi">
                          <h1 className="text-xs flex items-center justify-start gap-1 font-thin">
                            Search in
                            <span
                              className="flex items-center justify-start font-normal cursor-pointer"
                              onClick={toggleCompanyStatusHandler}
                              ref={menuParentRef}
                            >
                              {companyStatus}
                              <div className="pt-[2px] pl-[1px]">
                                <ArrowIcon
                                  width="15"
                                  height="15"
                                  color="black"
                                />
                              </div>
                            </span>
                          </h1>

                          {isCompanyModalOpen && (
                            <div
                              className="w-52 bg-exwhite-100 absolute top-5 -right-24 shadow-2xl shadow-black rounded-sm z-50"
                              style={{
                                boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
                              }}
                              ref={menuRef}
                            >
                              <button
                                type="button"
                                className={`py-2 w-full text-start text-sm hover:bg-exgray-100/20 pl-2 rounded-t-sm ${
                                  companyStatus === "Current Company" &&
                                  "bg-secondary-100 text-primary-400"
                                }`}
                                onClick={() =>
                                  changeCompanyStatusHandler("Current Company")
                                }
                              >
                                Current Company
                              </button>
                              <button
                                type="button"
                                className={`py-2 w-full text-start text-sm hover:bg-exgray-100/20 pl-2 ${
                                  companyStatus === "Previous Company" &&
                                  "bg-secondary-100 text-primary-400"
                                }`}
                                onClick={() =>
                                  changeCompanyStatusHandler("Previous Company")
                                }
                              >
                                Previous Company
                              </button>
                              <button
                                type="button"
                                className={`py-2 w-full text-start text-sm hover:bg-exgray-100/20 pl-2 rounded-b-sm ${
                                  companyStatus ===
                                    "Current/Previous Company" &&
                                  "bg-secondary-100 text-primary-400"
                                }`}
                                onClick={() =>
                                  changeCompanyStatusHandler(
                                    "Current/Previous Company"
                                  )
                                }
                              >
                                Current/Previous Company
                              </button>
                            </div>
                          )}
                        </div>
                        {allCompanies && allCompanies.length > 0 && (
                          <div className="flex-1 flex flex-wrap gap-2">
                            {allCompanies?.map((companyName, index) => (
                              <div
                                className="px-3 py-1 bg-secondary-100 text-secondary-400 font-semibold w-fit rounded-full text-sm flex items-center justify-center gap-3 cursor-pointer"
                                key={uuidv4()}
                                // onClick={() =>
                                //   addMandatoryKeywordHandler(keyword)
                                // }
                              >
                                {/* <div>
                                  <StarIcon
                                    width="16"
                                    height="16"
                                    color={
                                      allMandatoryKeywords.includes(keyword)
                                        ? "#0A65CC"
                                        : "#CDCED2"
                                    }
                                  />
                                </div> */}
                                <h1 className="">{companyName}</h1>
                                <div
                                  className="cursor-pointer"
                                  onClick={() =>
                                    removeCompanyNameHandler(index)
                                  }
                                >
                                  <CrossIcon
                                    width="10"
                                    height="10"
                                    color="#90A9C5"
                                  />
                                </div>
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                />
              </div>

              <div>
                <Controller
                  name="excludecompany"
                  control={control}
                  rules={{
                    required: {
                      value: false,
                      message: "Company is required",
                    },
                  }}
                  render={({ field: { onChange, value } }) => (
                    <div className="space-y-1">
                      <label
                        htmlFor={"excludecompany"}
                        className={`text-exgray-200/70 text-base font-semibold`}
                      >
                        Exclude Company
                      </label>
                      <div className="relative">
                        <input
                          type="text"
                          className={`w-full h-10 px-3 bg-exwhite-100 rounded-md outline-none border-2 ${
                            errors?.excludecompany?.message &&
                            allExludedCompanies.length === 0
                              ? "border-[#dc2623]"
                              : "border-exgray-100"
                          } text-exgray-800 placeholder:text-gray-400 text-base`}
                          id="excludecompany"
                          placeholder="Company name ..."
                          autoComplete="off"
                          name="excludecompany"
                          onKeyDown={(e) => {
                            if (e.key === "Enter" && excludeCompanyName) {
                              e.preventDefault();
                              addExcludedCompanyHandler();
                            }
                          }}
                          onChange={onChangeExcludedCompanyValue}
                          value={excludeCompanyName}
                        />
                        <div className="absolute h-full top-0 flex items-center justify-center right-2">
                          <button
                            type="button"
                            disabled={!excludeCompanyName}
                            onClick={addExcludedCompanyHandler}
                            className="py-1 w-fit px-4  text-exgray-400 font-bold underline text-sm rounded-sm"
                          >
                            Add
                          </button>
                        </div>
                      </div>
                      {errors?.excludecompany?.message && (
                        <p className="text-red-400">
                          {errors?.excludecompany?.message &&
                            allExludedCompanies.length === 0 &&
                            errors?.excludecompany?.message}
                        </p>
                      )}
                      <div className="flex items-center justify-start gap-2">
                        <div className="self-start pl-2 relative w-fi">
                          <h1 className="text-xs flex items-center justify-start gap-1 font-thin">
                            Search in
                            <span
                              className="flex items-center justify-start font-normal cursor-pointer"
                              onClick={toggleExcludeCompanyStatusHandler}
                              ref={menuParentRefExcludeCompany}
                            >
                              {excludeCompanyStatus}
                              <div className="pt-[2px] pl-[1px]">
                                <ArrowIcon
                                  width="15"
                                  height="15"
                                  color="black"
                                />
                              </div>
                            </span>
                          </h1>

                          {isExcludedCompanyModalOpen && (
                            <div
                              className="w-52 bg-exwhite-100 absolute top-5 -right-24 shadow-2xl shadow-black rounded-sm z-50"
                              style={{
                                boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
                              }}
                              ref={menuRefExcludeCompany}
                            >
                              <button
                                type="button"
                                className={`py-2 w-full text-start text-sm hover:bg-exgray-100/20 pl-2 rounded-t-sm ${
                                  excludeCompanyStatus === "Current Company" &&
                                  "bg-secondary-100 text-primary-400"
                                }`}
                                onClick={() =>
                                  changeExcludedCompanyStatusHandler(
                                    "Current Company"
                                  )
                                }
                              >
                                Current Company
                              </button>
                              <button
                                type="button"
                                className={`py-2 w-full text-start text-sm hover:bg-exgray-100/20 pl-2 ${
                                  excludeCompanyStatus === "Previous Company" &&
                                  "bg-secondary-100 text-primary-400"
                                }`}
                                onClick={() =>
                                  changeExcludedCompanyStatusHandler(
                                    "Previous Company"
                                  )
                                }
                              >
                                Previous Company
                              </button>
                              <button
                                type="button"
                                className={`py-2 w-full text-start text-sm hover:bg-exgray-100/20 pl-2 rounded-b-sm ${
                                  excludeCompanyStatus ===
                                    "Current/Previous Company" &&
                                  "bg-secondary-100 text-primary-400"
                                }`}
                                onClick={() =>
                                  changeExcludedCompanyStatusHandler(
                                    "Current/Previous Company"
                                  )
                                }
                              >
                                Current/Previous Company
                              </button>
                            </div>
                          )}
                        </div>
                        {allExludedCompanies &&
                          allExludedCompanies.length > 0 && (
                            <div className="flex-1 flex flex-wrap gap-2">
                              {allExludedCompanies?.map(
                                (companyName, index) => (
                                  <div
                                    className="px-3 py-1 bg-secondary-100 text-secondary-400 font-semibold w-fit rounded-full text-sm flex items-center justify-center gap-3 cursor-pointer"
                                    key={uuidv4()}
                                    // onClick={() =>
                                    //   addMandatoryKeywordHandler(keyword)
                                    // }
                                  >
                                    {/* <div>
                                  <StarIcon
                                    width="16"
                                    height="16"
                                    color={
                                      allMandatoryKeywords.includes(keyword)
                                        ? "#0A65CC"
                                        : "#CDCED2"
                                    }
                                  />
                                </div> */}
                                    <h1 className="">{companyName}</h1>
                                    <div
                                      className="cursor-pointer"
                                      onClick={() =>
                                        removeExcludedCompanyNameHandler(index)
                                      }
                                    >
                                      <CrossIcon
                                        width="10"
                                        height="10"
                                        color="#90A9C5"
                                      />
                                    </div>
                                  </div>
                                )
                              )}
                            </div>
                          )}
                      </div>
                    </div>
                  )}
                />
              </div>

              <div>
                <Controller
                  name="designation"
                  control={control}
                  rules={{
                    required: {
                      value: false,
                      message: "Designation is required",
                    },
                  }}
                  render={({ field: { onChange, value } }) => (
                    <ExInput
                      name="designation"
                      title="Designation"
                      onChange={onChange}
                      placeholder="Enter Here"
                      value={value}
                      isMandatory={false}
                      optionValue="Id"
                      optionLabel="Title"
                      options={designationList}
                      closeMenuOnSelect={false}
                      dropDownHeight="auto"
                      dropDownBorderWidth="2px"
                      dropDownBorderColor="#CDCED2"
                      menuListHeight="250px"
                      error={errors?.designation?.message}
                    />
                  )}
                />
              </div>

              <div className="space-y-1">
                {/* <h1 className={`text-exgray-200/70 text-base font-semibold`}>
                    Notice Period/Availability to join
                  </h1> */}

                <div>
                  <Controller
                    name="noticePeriodDuration"
                    control={control}
                    rules={{
                      required: {
                        value: false,
                        message: "Notice period required",
                      },
                    }}
                    render={({ field: { onChange, value } }) => (
                      <ExInput
                        title="Notice Period/Availability to join"
                        placeholder="Ex. 30"
                        name="noticePeriodDuration"
                        type="number"
                        onChange={onChange}
                        onWheel={(e) => e.target.blur()}
                        value={value}
                        error={errors?.noticePeriodDuration?.message}
                      />
                    )}
                  />
                </div>

                <div>
                  <Controller
                    name="openForRelocation"
                    control={control}
                    rules={{
                      required: {
                        value: false,
                        message: "Employment Type is required",
                      },
                    }}
                    render={({ field: { onChange, value } }) => (
                      <ExRadioBtnGroup
                        title="Open For Relocation"
                        onChange={onChange}
                        selectedOption={value}
                        options={["Yes", "No"]}
                        error={errors?.openForRelocation?.message}
                      />
                    )}
                  />
                </div>

                {/* <div className="flex flex-wrap gap-2">
                    {options.map((option) => (
                      <button
                        key={option.id}
                        className={`text-sm px-4 py-[2px] rounded-full border ${
                          selectedOptions.some(
                            (selected) => selected.id === option.id
                          )
                            ? "bg-blue-500 text-white"
                            : "bg-white text-gray-700 border-gray-300"
                        }`}
                        type="button"
                        onClick={() => handleSelection(option)}
                      >
                        {option.label}
                      </button>
                    ))}
                  </div> */}
              </div>
            </div>
          )}
        </div>

        <div>
          {/* <div
            className="flex items-center justify-between p-2 border-b border-exgray-200 cursor-pointer hover:bg-exgray-100/10"
            onClick={() => toggleSectionHandler(1)}
          >
            <h1 className="text-lg text-exgray-700 font-semibold">
              Education Details
            </h1>
            <div>
              <ArrowIcon strokeWidth="2" />
            </div>
          </div> */}

          {sectionId === 1 && (
            <div className="space-y-2 py-2">
              <div>
                <Controller
                  name="qualification"
                  control={control}
                  rules={{
                    required: {
                      value: false,
                      message: "Qualification is required",
                    },
                  }}
                  render={({ field: { onChange, value } }) => (
                    <ExMultiDropdown
                      title="Qualification"
                      onChange={onChange}
                      placeholder="Select"
                      value={value || null}
                      optionValue="Id"
                      optionLabel="Title"
                      options={qualificationsArray}
                      closeMenuOnSelect={false}
                      dropDownHeight="auto"
                      isMandatory={false}
                      dropDownBorderWidth="2px"
                      dropDownBorderColor="#CDCED2"
                      menuListHeight="200px"
                      error={errors?.qualification?.message}
                    />
                  )}
                />
              </div>
            </div>
          )}
        </div>

        <div className="">
          <div className="ml-auto mr-0 w-fit">
            <button
              type="submit"
              className="py-2 px-8 bg-primary-400 text-exwhite-100 font-semibold text-base text-center rounded-sm w-fit"
            >
              Search
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default CandidateSearchForm;
