import React, { useEffect, useState } from 'react'
import OnboardingTab from './components/OnboardingTab'
import { getMembersApi } from '../../slices/companyMemberSlice';
import { useAppDispatch, useAppSelector } from '../../config/hooks';
import { Outlet, useLocation } from 'react-router-dom';
import * as constants from "../../constant";
import MemberCard from '../../components/company-member-card/MemberCard';
import PendingApprovalTab from './PendingApprovalTab';

const PendingApproval = () => {
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const userId = useAppSelector((state) => state?.login?.session?.userId);
  const loginSelector = useAppSelector((state) => state?.login?.session);
  const memberSelector = useAppSelector((state) => state.member);
  const [togglePopup, setTogglePopup] = useState(false);
  const members = memberSelector.members || [];

  useEffect(() => {
    dispatch(
      getMembersApi({
        companyId:
          loginSelector.who === constants.COMPANY.toLowerCase()
            ? userId
            : memberSelector?.memberDetails?.companyId,

      })
    );
  }, []);

  return (
    <div className="min-h-screenheight bg-exwhite-100 p-4 space-y-4">
      <div className='flex'>
        <PendingApprovalTab />
      </div>
      <div>
        <Outlet />
      </div>
    </div>
  )
}

export default PendingApproval
