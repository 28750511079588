import React, { useEffect, useMemo, useState } from "react";
import WorkPlaceIcon from "../../../assets/Icons/WorkPlaceIcon";
import { useNavigate } from "react-router-dom";

import CreateMember from "../../ats/pages/company-dashboard/components/CreateMember";
import EmployeeCommenView from "../components/EmployeeCommenView";
import { useAppDispatch, useAppSelector } from "../../../config/hooks";
import { getAllAttendanceApi } from "../../../slices/attendanceSlice";
import moment from "moment";
import { getRecommendedJobApi } from "../../../slices/jobSlice";
import EditIcon from "../../../assets/Icons/EditIcon";
import Popup from "../../../components/popup/Popup";
import RegularizationForm from "../components/RegularizationForm";


const EmployeeAttendance = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [togglePopup, setTogglePopup] = useState(false);
  const memberDetail = useAppSelector((state) => state.company.memberDetails);
  const userId = useAppSelector((state) => state?.login?.session?.userId);
  const attendanceList = useAppSelector((state) => state?.attendance?.attendanceList);
  const [startDateSelected, setStartDateSelected] = useState(null);
  const [date, setDate] = useState(new Date());
  const [regularizeAttendace, setRegularizeAttendance] = useState(null);

  useEffect(() => {
    getAllAttendanceList();
  }, []);

  const getAllAttendanceList = () => {
    let month = moment().month() > 8 ? moment().month() + 1 : 0 + moment().month() + 1;
    let year = moment().year();
    dispatch(
      getAllAttendanceApi(
        {
          dateFrom: moment(`${month}/01/${year}`).format('YYYY-MM-DD'),
          dateTo: moment().format('YYYY-MM-DD'),
          employeeId: userId
        }
      )
    )
  }

  useEffect(() => {
    var timer = null;
    if (!startDateSelected) {
      timer = setInterval(() => setDate(new Date()), 1000)
    }
    return function cleanup() {
      clearInterval(timer)
    }
  });

  const togglePopupHandler = () => {
    setRegularizeAttendance(null);
    setTogglePopup(!togglePopup);
    //getAllAttendanceList();
  };

  const regularizeAtten = (attendance) => {
    setRegularizeAttendance(attendance);
    setTogglePopup(true);
  }

  return (
    <>
      <EmployeeCommenView />
      <div className="mt-3">
        <div className="flex items-center justify-between">
          <h1 className="text-lg text-exgray-300">Attendance Details</h1>
          <button onClick={togglePopupHandler} className="bg-secondary-700 text-exwhite-100 py-1 px-3 rounded-lg">
            Add Attendance
          </button>
        </div>
        <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
          <table className="w-full mt-2 text-center rounded-sm">
            <thead className="py-3">
              <tr className="bg-secondary-800 rounded-lg ">
                <th className="text-sm text-exwhite-100 font-medium py-3">Day</th>
                <th className="text-sm text-exwhite-100 font-medium py-3">Date</th>
                <th className="text-sm text-exwhite-100 font-medium py-3">In-Time</th>
                <th className="text-sm text-exwhite-100 font-medium py-3">Out-Time</th>
                <th className="text-sm text-exwhite-100 font-medium py-3">Working hrs</th>
                <th className="text-sm text-exwhite-100 font-medium py-3">Status</th>
                <th className="text-sm text-exwhite-100 font-medium py-3">Regularization</th>
                <th className="text-sm text-exwhite-100 font-medium py-3">Approver</th>
              </tr>
            </thead>
            <tbody className="items-center justify-center ">
              {
                attendanceList?.map((attendance, index) => {
                  var startTime = moment(attendance.checkIn);
                  var endTime = moment(attendance.checkOut);
                  var dif = moment.duration(endTime.diff(startTime));
                  return (
                    <tr key={attendance.id} className="items-center border-b border-l border-r border-exgray-100">
                      <td className="items-center py-3">{moment(attendance.date).format('dddd')}</td>
                      <td className="items-center py-3 text-secondary-500 font-medium">{moment(attendance.date).format('DD/MM/YYYY')}</td>
                      <td className="items-center py-3">{moment(attendance.checkIn).format('LT')}</td>
                      <td className="items-center py-3">{attendance?.status ? moment(attendance.checkOut).format('LT') : ''}</td>
                      <td className="items-center py-3">{attendance?.status ? `${dif.hours()}:${dif.minutes() < 10 ? "0" + dif.minutes() : dif.minutes()}` : ''}</td>
                      <td className="items-center py-3">Present</td>
                      <td className="items-center py-3">
                        <div className="flex justify-center">
                          {
                            !attendance?.isRegularized ?
                              <div className="flex items-center border border-secondary-500 px-2 rounded-md" onClick={() => regularizeAtten(attendance)}>
                                <EditIcon />
                                <h1 className="ml-2 text-secondary-500">Edit</h1>
                              </div>
                              :
                              <h1 className={`ml-2 ${attendance?.regularization?.status == "Rejected" ? "text-red-700" : attendance?.regularization?.status == "Approved" ? "text-green-600" : "text-secondary-500"} font-medium`}>
                                {attendance?.regularization?.status}
                              </h1>
                          }
                        </div>
                      </td>
                      <td className="items-center py-3">{attendance?.reportingManagerDetails?.firstName} {attendance?.reportingManagerDetails?.lastName}</td>
                    </tr>
                  )
                })
              }


            </tbody>
          </table>
        </div>

        <Popup onClose={togglePopupHandler} visible={togglePopup}>
          <RegularizationForm
            onClose={togglePopupHandler}
            attendance={regularizeAttendace}
            updateEndTime={undefined}
          />
        </Popup>


      </div>
    </>
  );
};

export default EmployeeAttendance;
