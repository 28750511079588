
import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../config/hooks';
import { getAgreementByIdApi } from '../../slices/salesSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import EditIcon from '../../assets/Icons/EditIcon';
import ServiceAgreementForm from './ServiceAgreementForm';
import Popup from '../../components/popup/Popup';

const AgreementDetail = ({ clientId, agreementId, agreementPopupHandler}) => {
  const [agreementData, setAgreementData] = useState(null);
  const {AgCTCRange,AgFreeReplacementPeriod,AgPaymentSchedule,AgPaymentTerms,AgProfileValidity,AgSeniorityLevel}=useAppSelector((state)=>state.appConfig)
  const [togglePopup, setTogglePopup] = useState(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const res = await dispatch(getAgreementByIdApi({ clientId, agreementId })).then(unwrapResult);
    setAgreementData(res.data);
  };

  const togglePopupHandler = () => {
    setTogglePopup(!togglePopup);
  };

  return (
    <>
      <div className="h-[80vh] w-[85vw] p-8 bg-white shadow-xl rounded-2xl overflow-auto">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-4xl font-semibold text-gray-800">Agreement Details</h2>
          <div
            onClick={togglePopupHandler}
            className="p-2  hover:bg-primary-500/20 rounded-full cursor-pointer"
          >
            <EditIcon />
          </div>
        </div>

        <div className="grid grid-cols-3 gap-6 text-gray-700">
          {/* Agreement Header */}
          <div className="space-y-3">
            <p>
              <span className="font-semibold text-gray-800">Agreement ID:</span> {agreementData?.id}
            </p>
            {/* <p>
              <span className="font-semibold text-gray-800">Client ID:</span> {agreementData?.clientId}
            </p> */}
            <p>
              <span className="font-semibold text-gray-800">Agreement Type:</span> {agreementData?.agreementType}
            </p>
            <p>
              <span className="font-semibold text-gray-800">New or Renew:</span> {agreementData?.newOrRenew}
            </p>
            <p>
              <span className="font-semibold text-gray-800">Start Date:</span> {new Date(agreementData?.agreementStartDate).toLocaleDateString()}
            </p>
            <p>
              <span className="font-semibold text-gray-800">End Date:</span> {new Date(agreementData?.agreementEndDate).toLocaleDateString()}
            </p>
          </div>

          {/* Payment Details */}
          <div className="space-y-3">
            <p>
              <span className="font-semibold text-gray-800">Payment Schedule:</span> {agreementData?.paymentSchedule}
            </p>
            <p>
              <span className="font-semibold text-gray-800">Payment Schedule Percentage:</span> {agreementData?.paymentSchedulePercentage}%
            </p>
            <p>
              <span className="font-semibold text-gray-800">Payment Schedule Amount:</span> ₹{agreementData?.paymentScheduleAmount}
            </p>
            <p>
              <span className="font-semibold text-gray-800">Late Payment Clause:</span> {agreementData?.latePaymentClause}
            </p>
            <p>
              <span className="font-semibold text-gray-800">Non-Disclosure Agreement:</span> {agreementData?.nonDisclosureAgreement}
            </p>
          </div>

          {/* Free Replacement */}
          <div className="space-y-3">
            <p>
              <span className="font-semibold text-gray-800">Free Replacement Period:</span> {agreementData?.freeReplacementPeriod} 
            </p>
            <p>
              <span className="font-semibold text-gray-800">Conditions for Replacement:</span> {agreementData?.onditionsForReplacement || "No conditions provided"}
            </p>
            <p>
              <span className="font-semibold text-gray-800">Total Revenue from Agreement:</span> ₹{agreementData?.totalRevenueFromAgreement}
            </p>
            <p>
              <span className="font-semibold text-gray-800">Bonus Commission:</span> ₹{agreementData?.bonusCommission}
            </p>
          </div>  

        </div>
        
        <div className="my-2">
            <p>
              <span className="font-semibold text-gray-800">Agreement File:</span> {agreementData?.agreementFile || "No file provided"}
            </p>
        </div>


          <div className="flex  justify-center gap-24  border-black">
            <div className='flex gap-2 flex-col items-center flex-1 '>
           { agreementData?.ctcRange?.domestic[0].percentage && <h3 className="text-xl font-semibold text-gray-800 ">CTC Range (Domestic)</h3>}
            {agreementData?.ctcRange?.domestic[0].percentage && agreementData?.ctcRange?.domestic.map((item, index) => (
              <div key={index} className=" p-4 bg-gray-100 rounded-lg shadow-sm w-2/5">
                <p>
                  <span className="font-semibold text-gray-800">Range:</span> {item.range}
                </p>
                <p>
                  <span className="font-semibold text-gray-800">Percentage:</span> {item.percentage}%
                </p>
                <p>
                  <span className="font-semibold text-gray-800">Amount:</span> ₹ {item.amount}
                </p>
              </div>
            ))}
            </div>

            <div className='flex   gap-2 flex-col items-center flex-1'>
            <h3 className="text-xl  font-semibold text-gray-800 ">CTC Range (International)</h3>
            {agreementData?.ctcRange?.international.map((item, index) => (
              <div key={index} className=" p-4 bg-gray-100 rounded-lg shadow-sm w-2/5">
                <p>
                  <span className="font-semibold text-gray-800">Range:</span> {item.range || "N/A"}
                </p>
                <p>
                  <span className="font-semibold text-gray-800">Percentage:</span> {item.percentage || "N/A"}
                </p>
                <p>
                  <span className="font-semibold text-gray-800">Amount:</span> ₹ {item.amount || "N/A"}
                </p>
              </div>
            ))}
            </div>
          </div>

             {/* Seniority Levels (Domestic & International) */}
             <div className=" flex  justify-center gap-24  border-black">
                <div className='flex gap-2 flex-col items-center border-black flex-1'>
                {agreementData?.seniorityLevels?.domestic[0].percentage && <h3 className="text-xl font-semibold text-gray-800 mt-6">Seniority Levels (Domestic)</h3>}
                {agreementData?.seniorityLevels?.domestic[0].percentage && agreementData?.seniorityLevels?.domestic.map((item, index) => (
                  <div key={index} className=" p-4 bg-gray-100 rounded-lg shadow-sm w-2/5">
                    <p>
                      <span className="font-semibold text-gray-800">Level:</span> {item.level}
                    </p>
                    <p>
                      <span className="font-semibold text-gray-800">Percentage:</span> {item.percentage || "N/A"}
                    </p>
                    <p>
                      <span className="font-semibold text-gray-800">Amount:</span> ₹{item.amount || "N/A"}
                    </p>
                  </div>
                ))}
                </div>

              <div className='flex flex-col items-center gap-2 flex-1'>
              { agreementData?.seniorityLevels?.international[0].percentage &&<h3 className="text-xl font-semibold text-gray-800 mt-6">Seniority Levels (International)</h3>}
                { agreementData?.seniorityLevels?.international[0].percentage && agreementData?.seniorityLevels?.international.map((item, index) => (
                  <div key={index} className=" p-4 bg-gray-100 rounded-lg shadow-sm  w-2/5">
                    <p>
                      <span className="font-semibold text-gray-800">Level:</span> {item.level}
                    </p>
                    <p>
                      <span className="font-semibold text-gray-800">Percentage:</span> {item.percentage || "N/A"}
                    </p>
                    <p>
                      <span className="font-semibold text-gray-800">Amount:</span> ₹ {item.amount || "N/A"}
                    </p>
                  </div>
                ))}
                </div>
          </div>
          
      </div>

      <Popup
        visible={togglePopup}
        onClose={togglePopupHandler}
        children={
          <ServiceAgreementForm
            update={true}
            agreementData={agreementData}
            togglePopupHandler={togglePopupHandler}
            agreementPopupHandler={agreementPopupHandler}
          />
        }
      />
    </>
  );
};

export default AgreementDetail;

