import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../config/hooks";
import { useEffect, useMemo, useState } from "react";
import useIndustries from "../../utils/dropdown-options/useIndustries";
import useSalaries from "../../utils/dropdown-options/useSalaries";
import useLocations from "../../utils/dropdown-options/useLocations";
import { getBulkCandidateDetailApi } from "../../slices/candidateProfileSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import Pdf from "../pdf-viewer/Pdf";
import DownloadIcon from "../../assets/Icons/DownloadIcon";
import CandidateEducationCard from "../candidate-view/CandidateEducationCard";
import CandidateExperienceCard from "../candidate-view/CandidateExperienceCard";
import { v4 as uuidV4 } from "uuid";
import { toast } from "sonner";
import axios from "axios";
import * as constants from "../../constant";
import LoadingIcon from "../../assets/Icons/LoadingIcon";
import NoDataFoundIcon from "../../assets/Icons/NoDataFoundIcon";

const UserBasicInfo = () => {
  const dispatch = useAppDispatch();
  const token = useAppSelector((state) => state?.login?.session?.token);
  const loginSelector = useAppSelector((state) => state?.login?.session);

  const { userId } = useParams();

  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const { defaultIndustry } = useIndustries();
  const { defualtSalary } = useSalaries();
  const { defaultLocation, defaultLocations } = useLocations();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await dispatch(
          getBulkCandidateDetailApi({ userId })
        ).then(unwrapResult);
        setUser(response.data.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        setError(true);
      }
    };

    fetchData();
  }, []);

  const defaultCandidateIndustry = useMemo(() => {
    return user?.userAdditionalInfo?.preferredIndustry &&
      user?.userAdditionalInfo?.preferredIndustry.length > 0
      ? defaultIndustry(user?.userAdditionalInfo?.preferredIndustry[0])
      : null;
  }, [defaultIndustry, user?.userAdditionalInfo?.preferredIndustry]);

  const defaultCurrentSal = useMemo(() => {
    return user?.userAdditionalInfo?.lastSalary ||
      user?.userAdditionalInfo?.lastSalary === 0
      ? defualtSalary(user?.userAdditionalInfo?.lastSalary)
      : null;
  }, [defualtSalary, user?.userAdditionalInfo?.lastSalary]);

  const defaultExpectedSal = useMemo(() => {
    return user?.userAdditionalInfo?.expectation ||
      user?.userAdditionalInfo?.expectation === 0
      ? defualtSalary(user?.userAdditionalInfo?.expectation)
      : null;
  }, [defualtSalary, user?.userAdditionalInfo?.expectation]);

  const defaultLoc = useMemo(() => {
    return user?.userDetails?.location
      ? defaultLocation(user?.userDetails?.location)
      : null;
  }, [defaultLocation, user?.userDetails?.location]);

  const defaultPreferedJobLocations = useMemo(() => {
    return user?.userAdditionalInfo?.preferredLocation &&
      user?.userAdditionalInfo?.preferredLocation.length > 0
      ? defaultLocations(user?.userAdditionalInfo?.preferredLocation)
      : null;
  }, [defaultLocations, user?.userAdditionalInfo?.preferredLocation]);

  const handleDownload = async () => {
    if (user?.userAdditionalInfo?.resume?.url) {
      axios
        .get(
          `${constants.baseUrl}/${user?.userAdditionalInfo?.resume?.url}?t=${token}`,
          {
            headers: {
              "Content-Type": "application/octet-stream",
            },
            responseType: "blob",
          }
        )
        .then((response) => {
          const a = document.createElement("a");
          const url = window.URL.createObjectURL(response.data);
          a.href = url;
          a.download = user?.userAdditionalInfo?.resume?.name;
          a.click();
        })
        .catch((err) => {
          toast.error("Unable to download resume");
        });
    } else {
      toast("Resume not found");
    }
  };

  if (error) {
    return (
      <div className="w-full">
        <div className="mx-auto w-fit py-5">
          <div className="flex items-center justify-center">
            <NoDataFoundIcon />
          </div>
          <div>
            <h1 className="text-3xl text-expurple-800/50 font-semibold text-center">
              No Data Found
            </h1>
            <p className="text-base text-exgray-200 font-medium text-center max-w-sm mx-auto">
              Could not find any data. Please try again.
            </p>
          </div>
        </div>
      </div>
    );
  }

  if (loading) {
    return (
      <div className="py-20 flex items-center justify-center">
        <LoadingIcon />
      </div>
    );
  }

  return (
    <div>
      <div className="space-y-2 h-screenheight overflow-y-auto no-scrollbar">
        <div className="bg-exwhite-100 p-4 space-y-4 rounded-sm">
          <h1 className="text-sm text-primary-400 font-semibold pb-1 border-b border-exgray-100">
            Basic Information
          </h1>

          <div className="grid grid-cols-2 auto-rows-auto gap-y-4">
            {/* <div className="flex items-start justify-start gap-1">
              <h1 className="w-fit text-sm text-exgray-200 font-medium leading-none">
                Gender :
              </h1>
              <p className="flex-1 text-base text-primary-400 font-semibold leading-none">
                Male
              </p>
            </div> */}

            {
              <div className="flex items-start justify-start gap-1">
                <h1 className="w-fit text-sm text-exgray-200 font-medium leading-none">
                  Year of Experience :
                </h1>

                <p className="flex-1 text-base text-primary-400 font-semibold leading-none">
                  {`${user?.userDetails?.totalExperience} Yrs`}
                </p>
              </div>
            }

            {
              <div className="flex items-start justify-start gap-1">
                <h1 className="w-fit text-sm text-exgray-200 font-medium leading-none">
                  Industry :{" "}
                </h1>
                <p className="flex-1 text-base text-primary-400 font-semibold leading-none">
                  {defaultCandidateIndustry?.Name}
                </p>
              </div>
            }

            <div className="flex items-start justify-start gap-2">
              <h1 className="w-fit text-sm text-exgray-200 font-medium leading-none">
                Current Salary :
              </h1>
              <p className="flex-1 text-base text-primary-400 font-semibold leading-none">
                {`${
                  defaultCurrentSal?.Title ? defaultCurrentSal?.Title : ""
                }${`${
                  user?.userAdditionalInfo?.lastSalaryInThousand
                    ? `.${user?.userAdditionalInfo?.lastSalaryInThousand}`
                    : ""
                } ${
                  defaultCurrentSal?.Title ||
                  user?.userAdditionalInfo?.lastSalaryInThousand
                    ? `${defaultCurrentSal?.Title === "0" ? "Lac" : "Lacs"}`
                    : ""
                }`}`}
              </p>
            </div>

            {
              <div className="flex items-start justify-start gap-1">
                <h1 className="w-fit text-sm text-exgray-200 font-medium leading-none">
                  Expected Salary :
                </h1>
                <p className="flex-1 text-base text-primary-400 font-semibold leading-none">
                  {`${
                    defaultExpectedSal?.Title ? defaultExpectedSal?.Title : ""
                  }${`${
                    user?.userAdditionalInfo?.expectedSalaryInThousand
                      ? `.${user?.userAdditionalInfo?.expectedSalaryInThousand}`
                      : ""
                  } ${
                    defaultExpectedSal?.Title ||
                    user?.userAdditionalInfo?.expectedSalaryInThousand
                      ? `${defaultExpectedSal?.Title === "0" ? "Lac" : "Lacs"}`
                      : ""
                  }`}`}
                </p>
              </div>
            }

            <div className="flex items-start justify-start gap-1">
              <h1 className="w-fit text-sm text-exgray-200 font-medium leading-none">
                Location :
              </h1>
              <p className="flex-1 text-base text-primary-400 font-semibold leading-none">
                {defaultLoc?.Name}
              </p>
            </div>

            <div className="flex items-start justify-start gap-1">
              <h1 className="w-fit text-sm text-exgray-200 font-medium leading-none">
                Serving Notice Period :
              </h1>

              <p className="flex-1 text-base text-primary-400 font-semibold leading-none">
                {user?.userAdditionalInfo?.isServingNoticePeriod ? "Yes" : "No"}
              </p>
            </div>

            {/* <div className="flex items-start justify-start gap-1">
              <h1 className="w-fit text-sm text-exgray-200 font-medium leading-none">
                Last Working Day :
              </h1>
              <p className="flex-1 text-base text-primary-400 font-semibold leading-none">
                10/10/2010
              </p>
            </div> */}

            <div className="flex items-start justify-start gap-1">
              <h1 className="w-fit text-sm text-exgray-200 font-medium leading-none">
                No of Days :
              </h1>
              <p className="flex-1 text-base text-primary-400 font-semibold leading-none">
                {user?.userAdditionalInfo?.noticePeriod}
              </p>
            </div>

            <div className="flex items-start justify-start gap-1">
              <h1 className="w-fit text-sm text-exgray-200 font-medium leading-none">
                Work Mode :
              </h1>
              <p className="flex-1 text-base text-primary-400 font-semibold leading-none">
                {user?.userAdditionalInfo?.workMode}
              </p>
            </div>

            <div className="flex items-start justify-start gap-1">
              <h1 className="w-fit text-sm text-exgray-200 font-medium leading-none">
                Preferred /Job Location :
              </h1>

              {defaultPreferedJobLocations &&
                defaultPreferedJobLocations.length > 0 &&
                defaultPreferedJobLocations.map((loc) => (
                  <p
                    className="flex-1 text-base text-primary-400 font-semibold leading-none"
                    key={uuidV4()}
                  >
                    {loc?.Name}
                  </p>
                ))}
            </div>
          </div>
        </div>

        {!loading &&
          user?.userExperience &&
          user?.userExperience?.length > 0 && (
            <div className="bg-exwhite-100 p-4 space-y-2">
              <h1 className="text-sm text-primary-400 font-semibold pb-1 border-b border-exgray-100">
                Experience Details
              </h1>

              <div className="max-h-[500px] overflow-y-auto no-scrollbar divide-y-2 divide-exgray-100/50">
                {user.userExperience.map((experience) => (
                  <CandidateExperienceCard
                    experience={experience}
                    key={uuidV4()}
                  />
                ))}
              </div>
            </div>
          )}

        {!loading && user?.userEducation && user?.userEducation?.length > 0 && (
          <div className="bg-exwhite-100 p-4 space-y-2">
            <h1 className="text-sm text-primary-400 font-semibold pb-1 border-b border-exgray-100">
              Education Details
            </h1>

            <div className="max-h-[500px] overflow-y-auto no-scrollbar divide-y-2 divide-exgray-100/50">
              {user.userEducation.map((education) => (
                <CandidateEducationCard education={education} key={uuidV4()} />
              ))}
            </div>
          </div>
        )}

        <div className="bg-exwhite-100 p-4 space-y-4">
          <h1 className="text-sm text-primary-400 font-semibold pb-1 border-b border-exgray-100">
            Key Skills
          </h1>
          {user?.userDetails?.skills &&
            user?.userDetails?.skills.length > 0 && (
              <div className="flex flex-wrap gap-2">
                {user?.userDetails?.skills.map((skill) => (
                  <p
                    className="px-4 py-1 text-start text-base text-exgray-200 font-semibold rounded-full bg-exgray-100/50"
                    key={uuidV4()}
                  >
                    {skill}
                  </p>
                ))}
              </div>
            )}
        </div>

        {!loading && (
          <div className="bg-exwhite-100 p-4 space-y-4">
            <div className="flex items-center justify-between pb-2 border-b border-exgray-100">
              <h1 className="text-sm text-primary-400 font-semibold">
                CV / Resume
              </h1>

              <button
                className="p-1 border-2 border-secondary-400 mr-4 rounded-md active:scale-95"
                onClick={handleDownload}
              >
                <DownloadIcon />
              </button>
            </div>

            <div className="w-fit mx-auto pt-2">
              <Pdf
                src={`${constants.baseUrl}/${user?.userAdditionalInfo?.resume?.url}?t=${token}`}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default UserBasicInfo;
