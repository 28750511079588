import React, { useEffect, useState } from "react";

import PlusCircleIcon from "../../../../../assets/Icons/PlusCircleIcon";
import FilterIcon from "../../../../../assets/Icons/FilterIcon";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import JobCard from "../../job-search/components/JobCard";
import { useAppDispatch, useAppSelector } from "../../../../../config/hooks";
import { v4 as uuid4 } from "uuid";
import {
  getCreatedJobsApi,
  getNextCreatedCompanyJobsApi,
} from "../../../../../slices/companySlice";
import InfiniteScroll from "react-infinite-scroll-component";
import { toast } from "sonner";
import SearchIcon from "../../../../../assets/Icons/SearchIcon";
import {
  getAssignJobsToMemberApi,
  getNextAssignJobsToMemberApi,
} from "../../../../../slices/jobSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import * as constants from "../../../../../constant";
import LoadingIcon from "../../../../../assets/Icons/LoadingIcon";
import NoDataFoundIcon from "../../../../../assets/Icons/NoDataFoundIcon";

const CompanyJobsDashboard = () => {
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();

  const companySelector = useAppSelector((state) => state.company);
  const loginSelector = useAppSelector((state) => state?.login?.session);
  const userId = useAppSelector((state) => state?.login?.session?.userId);
  const assignedJobCards = useAppSelector((state) => state?.jobs?.jobCards);
  const memberSelector = useAppSelector(
    (state) => state?.member?.memberDetails
  );
  const assignedJobsMemberNextUrl = useAppSelector(
    (state) => state.jobs.assignedJobsMemberNextUrl
  );

  const [isLoadingAssignedJob, setIsLoadingAssignedJob] = useState(true);
  const [query, setQuery] = useState("");
  const [debouncedQuery, setDebouncedQuery] = useState("");

  const { createdJobs, companyDetails } = companySelector;
  const { jobCards, isLoading, error } = createdJobs;

  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedQuery(query);
    }, 500); // Adjust delay as needed

    return () => clearTimeout(timer);
  }, [query]);

  useEffect(() => {
    if (loginSelector?.who === constants.COMPANY.toLowerCase()) {
      try {
        dispatch(
          getCreatedJobsApi({
            companyId: companyDetails.id,
            jobStatus: "OPEN",
            size: "2",
            s: debouncedQuery,
          })
        );
      } catch (error) {
        toast.error("Something went wrong");
      }
    } else if (
      loginSelector?.who === constants.COMPANY_MEMBER.toLowerCase() &&
      memberSelector.designation === constants.SUPER_ADMIN
    ) {
      if (pathname === `/company-admin/jobs/approvals`) {
        fetchAssignedJobsData();
      } else {
        try {
          dispatch(
            getCreatedJobsApi({
              companyId: memberSelector?.companyId,
              jobStatus: "OPEN",
              size: "2",
              s: debouncedQuery,
            })
          );
        } catch (error) {
          toast.error("Something went wrong");
        }
      }
    } else if (
      loginSelector?.who === constants.COMPANY_MEMBER.toLowerCase() ||
      (loginSelector?.who === constants.COMPANY_MEMBER.toLowerCase() &&
        memberSelector.designation !== "Admin")
    ) {
      fetchAssignedJobsData();
    } else if (
      loginSelector?.who === constants.COMPANY_MEMBER.toLowerCase() &&
      memberSelector.designation === "Admin"
    ) {
      fetchAssignedJobsData();
    }
  }, [debouncedQuery, pathname]);

  const fetchAssignedJobsData = async () => {
    setIsLoadingAssignedJob(true);
    try {
      await dispatch(
        getAssignJobsToMemberApi({
          memberId: userId,
          search: debouncedQuery,
          adviserId:
            pathname === `/company-admin/jobs/approvals` ||
            (loginSelector?.who === constants.COMPANY_MEMBER.toLowerCase() &&
              memberSelector?.designation === constants.ADVISER)
              ? userId
              : "",
        })
      ).then(unwrapResult);
      setIsLoadingAssignedJob(false);
    } catch (error) {
      setIsLoadingAssignedJob(false);
      toast.error("Something went wrong while fetching jobs");
    }
  };

  useEffect(() => {
    if (loginSelector?.who === constants.COMPANY.toLowerCase()) {
      try {
        dispatch(
          getCreatedJobsApi({
            companyId: companyDetails.id,
            jobStatus: "OPEN",
            size: "2",
          })
        );
      } catch (error) {
        toast.error("Something went wrong");
      }
    } else if (
      loginSelector?.who === constants.COMPANY_MEMBER.toLowerCase() &&
      memberSelector.designation === constants.SUPER_ADMIN
    ) {
      try {
        dispatch(
          getCreatedJobsApi({
            companyId: memberSelector?.companyId,
            jobStatus: "OPEN",
            size: "2",
          })
        );
      } catch (error) {
        toast.error("Something went wrong");
      }
    } else if (
      loginSelector?.who === constants.COMPANY_MEMBER.toLowerCase() ||
      (loginSelector?.who === constants.COMPANY_MEMBER.toLowerCase() &&
        memberSelector.designation !== "Admin")
    ) {
      fetchAssignedJobsData();
    } else if (
      loginSelector?.who === constants.COMPANY_MEMBER.toLowerCase() &&
      memberSelector.designation === "Admin"
    ) {
      fetchAssignedJobsData();
    }
  }, []);

  const getMoreDataHandler = () => {
    try {
      dispatch(getNextCreatedCompanyJobsApi());
    } catch (error) {
      toast.error("Something went wrong");
    }
  };

  const getMoreAssignedJobsDataHandler = () => {
    try {
      dispatch(getNextAssignJobsToMemberApi());
    } catch (error) {
      toast.error("Unable to load jobs");
    }
  };

  const onChange = (e) => {
    setQuery(e.target.value);
  };

  return (
    <div className="space-y-4">
      <div className="flex items-center justify-between gap-2">
        <div className="w-[50%] relative pl-2 mt-2">
          <input
            type="text"
            name="title"
            onChange={onChange}
            // value={value}
            placeholder="Search"
            className="outline outline-2 outline-exgray-100 bg-exwhite-100 py-2 w-full text-base pr-10 placeholder:text-exgray-100 rounded-full text-exgray-400 pl-5 focus:outline-secondary-300"
          />
          <div className="absolute top-0 right-2 h-full flex items-center justify-center">
            <SearchIcon color="#042347" />
          </div>
        </div>

        <div className="flex items-center justify-between gap-2">
          <button className="p-2">{/* <FilterIcon /> */}</button>

          {(loginSelector?.who === constants.COMPANY.toLowerCase() ||
            (loginSelector?.who === constants.COMPANY_MEMBER.toLowerCase() &&
              memberSelector?.designation !== constants.ADVISER &&
              pathname === `/company-admin/jobs`)) && (
            <Link
              to={"/company-admin/create-job"}
              className="px-5 py-2 text-base text-exwhite-100 font-semibold flex items-center justify-center gap-2 bg-secondary-600 rounded-md"
            >
              <PlusCircleIcon width="25" height="25" color="white" /> Create Job
            </Link>
          )}
        </div>
      </div>

      {loginSelector.who === constants.COMPANY.toLowerCase() ||
      (loginSelector?.who === constants.COMPANY_MEMBER.toLowerCase() &&
        memberSelector.designation === constants.SUPER_ADMIN &&
        pathname !== `/company-admin/jobs/approvals`) ? (
        <div className="">
          {error ? (
            <div className="w-full">
              <div className="mx-auto w-fit py-5">
                <div className="flex items-center justify-center">
                  <NoDataFoundIcon />
                </div>
                <div>
                  <h1 className="text-3xl text-expurple-800/50 font-semibold text-center">
                    No Data Found
                  </h1>
                  <p className="text-base text-exgray-200 font-medium text-center max-w-sm mx-auto">
                    Could not find any data in job list. Please try again.
                  </p>
                  <p className="text-base text-exgray-200 font-medium text-center max-w-sm mx-auto">
                    Something Went Wrong !
                  </p>
                </div>
              </div>
            </div>
          ) : !isLoading ? (
            <InfiniteScroll
              className=""
              dataLength={jobCards?.jobs?.length ? jobCards?.jobs?.length : 0}
              next={() => getMoreDataHandler()}
              hasMore={jobCards?.metadata?.nextResultURL}
              // hasMore={true}
              // height={"calc(100vh - 155px)"}
              loader={""}
            >
              {jobCards?.jobs?.length > 0 ? (
                <div className="grid grid-cols-tab_autofit xl:grid-cols-autofit justify-center gap-3">
                  {jobCards.jobs.map((job) => (
                    <div className="" key={uuid4()}>
                      <Link
                        to={
                          pathname === `/company-admin/jobs/approvals`
                            ? `/company-admin/job/${job.id}/approvals`
                            : `/company-admin/job/${job.id}`
                        }
                      >
                        <JobCard job={job} />
                      </Link>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="w-full">
                  <div className="mx-auto w-fit py-5">
                    <div className="flex items-center justify-center">
                      <NoDataFoundIcon />
                    </div>
                    <div>
                      <h1 className="text-3xl text-expurple-800/50 font-semibold text-center">
                        No Data Found
                      </h1>
                      <p className="text-base text-exgray-200 font-medium text-center max-w-sm mx-auto">
                        Could not find any data in job list. Please try again.
                      </p>
                    </div>
                  </div>
                </div>
              )}
            </InfiniteScroll>
          ) : (
            <div className="flex items-center justify-center py-20">
              <LoadingIcon />
            </div>
          )}
        </div>
      ) : !isLoadingAssignedJob ? (
        <div className="">
          <InfiniteScroll
            className=""
            dataLength={
              assignedJobCards?.jobs?.length
                ? assignedJobCards?.jobs?.length
                : 0
            }
            next={() => getMoreAssignedJobsDataHandler()}
            // hasMore={
            //   loginSelector?.who === constants.COMPANY_MEMBER.toLowerCase() &&
            //   memberSelector.designation === constants.ADMIN
            //     ? jobCards?.metadata?.nextResultURL
            //     : assignedJobsMemberNextUrl
            // }
            hasMore={assignedJobsMemberNextUrl}
            // height={"calc(100vh - 155px)"}
            loader={""}
          >
            {assignedJobCards?.jobs?.length > 0 ? (
              <div className="grid grid-cols-autofit justify-center gap-3">
                {assignedJobCards?.jobs?.map((job) => (
                  <div key={uuid4()}>
                    <Link
                      to={
                        pathname === `/company-admin/jobs/approvals`
                          ? `/company-admin/job/${job.id}/approvals`
                          : `/company-admin/job/${job.id}`
                      }
                    >
                      <JobCard job={job} />
                    </Link>
                  </div>
                ))}
              </div>
            ) : (
              <div className="w-full">
                <div className="mx-auto w-fit py-5">
                  <div className="flex items-center justify-center">
                    <NoDataFoundIcon />
                  </div>
                  <div>
                    <h1 className="text-3xl text-expurple-800/50 font-semibold text-center">
                      No Data Found
                    </h1>
                    <p className="text-base text-exgray-200 font-medium text-center max-w-sm mx-auto">
                      Could not find any data in job list. Please try again.
                    </p>
                  </div>
                </div>
              </div>
            )}
          </InfiniteScroll>
        </div>
      ) : (
        <div className="flex items-center justify-center py-20">
          <LoadingIcon />
        </div>
      )}
    </div>
  );
};

export default CompanyJobsDashboard;
