import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Accordian from "../../../components/Accordian";
import { useAppDispatch, useAppSelector } from "../../../config/hooks";
import { getMemberByIdApi } from "../../../slices/companyMemberSlice";
import EmployeeDetail from "../components/EmployeeDetail";
import BasicDataView from "../../../components/BasicDataView";
import moment from "moment";
import { getAssignJobsToMemberApi } from "../../../slices/jobSlice";
import JobCard from "../../ats/pages/job-search/components/JobCard";

const AdviserProfile = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { memberId } = useParams();
  const dispatch = useAppDispatch();
  const [memberDetail, setMemberDetail] = useState(undefined);
  const [togglePopup, setTogglePopup] = useState(false);
  const [isProfessional, setIsProfessional] = useState(false);
  const assignJobList = useAppSelector((state) => state.jobs.assignJobList);

  useEffect(() => {
    getMember();
    getAssignJobs();
  }, [togglePopup, isProfessional]);

  const getMember = () => {
    dispatch(
      getMemberByIdApi({
        memberId: memberId,
      })
    ).then((result) => {
      setMemberDetail(result?.payload?.data?.data);
    });
  };

  const getAssignJobs = () => {
    dispatch(
      getAssignJobsToMemberApi({
        memberId: memberId,
        //search: debouncedQuery,
      })
    );
  };

  const onClickOnJob = (jobId) => {
    navigate(`/company-admin/adviser-assign-candidates/${jobId}`, {
      state: { memberId: memberId },
    });
  };

  return (
    <>
      <div className="bg-exwhite-100">
        <Accordian
          isEditable={false}
          title="Adviser Details"
          onPressEdit={() => setTogglePopup(true)}
        >
          <div className="w-full py-5">
            <div className="flex items-center">
              <div className="flex w-6/12">
                <BasicDataView
                  title={"First Name"}
                  value={memberDetail?.firstName}
                />
              </div>
              <div className="flex w-6/12">
                <BasicDataView
                  title={"Last Name"}
                  value={memberDetail?.lastName}
                />
              </div>
            </div>
            <div className="flex items-center">
              <div className="flex w-6/12">
                <BasicDataView
                  title={"Designation"}
                  value={memberDetail?.jobProfile}
                />
              </div>
              <div className="flex w-6/12">
                <BasicDataView
                  title={"Job Role"}
                  value={memberDetail?.designation}
                />
              </div>
            </div>
            {/* <div className="flex items-center">
            <div className="flex w-6/12">
              <BasicDataView
                title={"Department"}
                value={memberDetail?.firstName}
              />
            </div>

          </div> */}
          </div>
        </Accordian>
      </div>
      <div>
        <h1 className="text-base font-medium my-2">Assign Jobs</h1>
        {
          <div className="grid grid-cols-tab_autofit xl:grid-cols-autofit justify-center gap-3">
            {assignJobList?.map((job) => (
              <div
                onClick={() => onClickOnJob(job.id)}
                className=""
                key={job.id}
              >
                <JobCard job={job} />
              </div>
            ))}
          </div>
        }
      </div>
    </>
  );
};

export default AdviserProfile;
