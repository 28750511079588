import { useMemo, useState } from "react";
import useLocations from "../../utils/dropdown-options/useLocations";
import useIndustries from "../../utils/dropdown-options/useIndustries";
import useSalaries from "../../utils/dropdown-options/useSalaries";
import { useAppSelector } from "../../config/hooks";
import axios from "axios";
import * as constants from "../../constant";
import { toast } from "sonner";
import { Link, useParams } from "react-router-dom";
import ForwardIcon from "../../assets/Icons/ForwardIcon";
import Popup from "../popup/Popup";
import CreateReferForm from "../../modules/ats/pages/company-job-page/components/CreateReferForm";
import DownloadIcon from "../../assets/Icons/DownloadIcon";

const marked = "bg-[#32CD32]";
// const marked = "bg-[#FFFF00]";
const CandidateSearchCardJobLevel = ({ candidateDetails }) => {
  const { jobId } = useParams();

  const { defaultLocation, defaultLocations } = useLocations();
  const { defaultIndustries } = useIndustries();
  const { defualtSalary } = useSalaries();

  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const databaseSearchSelector = useAppSelector(
    (state) => state.ai.databaseSearchJobLevel
  );
  const token = useAppSelector((state) => state?.login?.session?.token);

  const { searchedFieldsJobLevel } = databaseSearchSelector || {};

  const {
    _id,
    firstName,
    lastName,
    designation,
    email,
    countryCode,
    phoneNo,
    location,
    resume,
    totalYears,
    industry,
    education,
    currentSalary,
    expectedSalary,
    preferredLocation,
    isServingNoticePeriod,
    noticePeriod,
    skills,
  } = candidateDetails || {};

  const defaultLoc = useMemo(() => {
    if (location) {
      const selectedLoc = defaultLocation(location);
      if (selectedLoc?.Id) {
        return selectedLoc;
      }
      return null;
    }
    return null;
  }, [defaultLocation, location]);

  const defaultSal = useMemo(() => {
    if (currentSalary) {
      const selectedSal = defualtSalary(currentSalary);
      if (selectedSal?.Id) {
        return selectedSal;
      }
      return null;
    }
    return null;
  }, [currentSalary, defualtSalary]);

  const defaultExpectedSal = useMemo(() => {
    if (expectedSalary) {
      const selectedSal = defualtSalary(expectedSalary);
      if (selectedSal?.Id) {
        return selectedSal;
      }
      return null;
    }
    return null;
  }, [expectedSalary, defualtSalary]);

  let defaultIndust = useMemo(() => {
    if (industry && industry.length > 0) {
      const industries = defaultIndustries(industry);
      return industries;
    }
    return null;
  }, [defaultIndustries, industry]);

  let defaultPrefferedLoc = useMemo(() => {
    if (preferredLocation && preferredLocation.length > 0) {
      const loc = defaultLocations(preferredLocation);
      return loc;
    }
    return null;
  }, [defaultLocations, preferredLocation]);

  const isLocationMatched = useMemo(() => {
    if (
      searchedFieldsJobLevel?.location[0] &&
      searchedFieldsJobLevel?.location[0]?.Name &&
      defaultLoc
    ) {
      return searchedFieldsJobLevel.location.some(
        (loc) => loc?.Name === defaultLoc.Name
      );
    }
    return false;
  }, [defaultLoc, searchedFieldsJobLevel?.location]);

  const isExperienceMatched = useMemo(() => {
    if (
      searchedFieldsJobLevel?.minExperience &&
      searchedFieldsJobLevel?.maxExperience &&
      totalYears
    ) {
      return (
        totalYears >= searchedFieldsJobLevel?.minExperience?.value &&
        totalYears <= searchedFieldsJobLevel?.maxExperience?.value
      );
    }
    return false;
  }, [
    searchedFieldsJobLevel?.maxExperience,
    searchedFieldsJobLevel?.minExperience,
    totalYears,
  ]);

  const isSalaryMatched = useMemo(() => {
    if (
      searchedFieldsJobLevel?.minSalary?.value &&
      searchedFieldsJobLevel?.maxSalary?.value &&
      defaultSal
    ) {
      return (
        defaultSal?.value >= searchedFieldsJobLevel?.minSalary?.value &&
        defaultSal?.value <= searchedFieldsJobLevel?.maxSalary?.value
      );
    }
    return false;
  }, [
    defaultSal,
    searchedFieldsJobLevel?.maxSalary?.value,
    searchedFieldsJobLevel?.minSalary?.value,
  ]);

  const handleDownload = async () => {
    if (resume?.url) {
      axios
        .get(`${constants.baseUrl}/${resume?.url}?t=${token}`, {
          headers: {
            "Content-Type": "application/octet-stream",
          },
          responseType: "blob",
        })
        .then((response) => {
          const a = document.createElement("a");
          const url = window.URL.createObjectURL(response.data);
          a.href = url;
          a.download = resume?.name;
          a.click();
        })
        .catch((err) => {
          toast.error("Download failed. Please try again");
        });
    } else {
      toast.error("Resume not found");
    }
  };

  const togglePopupHandler = () => {
    setIsPopupOpen(!isPopupOpen);
  };

  // const regex = new RegExp(`(${searchedFieldsJobLevel?.keywords.join("|")})`, "gi");
  const regex = new RegExp(
    `(${searchedFieldsJobLevel?.keywords.join("|")})`,
    "gi"
  );

  return (
    <div>
      <div className="p-4 bg-exwhite-100 border border-exgray-100 rounded-md space-y-4">
        <div className="flex items-center">
          <div className="flex-1">
            <div className="flex items-center justify-start gap-1">
              <Link
                to={`/company-admin/candidate-search/user/${_id}`}
                target="_blank"
                className="text-base text-exgray-800 font-semibold"
              >{`${firstName} ${lastName} ${designation && "|"}`}</Link>
              {designation && (
                <h1>
                  {designation.split(regex).map((part, index) =>
                    searchedFieldsJobLevel?.keywords.some(
                      (keyword) => keyword.toLowerCase() === part.toLowerCase()
                    ) ? (
                      <span key={index} className={`${marked}`}>
                        {part}
                      </span>
                    ) : (
                      part
                    )
                  )}
                </h1>
              )}
            </div>

            <div className="flex items-center justify-start gap-1">
              <h1 className="text-sm text-exgray-800">{`${email} |`}</h1>
              <h1 className="text-sm line-clamp-1 text-exgray-800">
                {`+${countryCode} ${phoneNo}`}
              </h1>
            </div>

            <div className="">
              {defaultLoc && (
                <h1
                  className={`${
                    isLocationMatched && marked
                  }  text-sm line-clamp-1 text-exgray-800 w-fit`}
                >
                  {`${defaultLoc?.Name}, ${defaultLoc?.State}, India`}
                </h1>
              )}
            </div>
          </div>
          <div className="flex items-center justify-end gap-4">
            <button
              className="flex items-center justify-center gap-1 px-4 text-center text-secondary-800 font-semibold"
              onClick={togglePopupHandler}
            >
              <ForwardIcon width="18" height="16" color="#063D7A" /> Tag
              Candidate
            </button>

            {resume?.url && (
              <div className="">
                <button
                  className="flex items-center justify-start gap-1 border border-secondary-400 rounded-sm text-start text-secondary-400 bg-secondary-100 px-2 py-1 w-52  self-start active:scale-95"
                  onClick={handleDownload}
                >
                  <div className="w-fit pt-1">
                    <DownloadIcon width="20" height="20" />
                  </div>
                  <div className="break-words line-clamp-1">{resume?.name}</div>
                </button>
              </div>
            )}
          </div>
        </div>

        <div className="pl-[5%]">
          <h1 className="text-base text-exgray-800 font-semibold">
            Candidate Details
          </h1>

          {/* <div className="grid grid-cols-2 auto-rows-auto">
            <div className="flex items-center justify-start gap-1 text-sm text-exgray-800">
              <h1>Total Yr of Experience :</h1>
              <h1 className={`${isExperienceMatched && marked}`}>
                {totalYears}
              </h1>
            </div>

            <div className="flex items-center justify-start gap-1 text-sm text-exgray-800">
              <h1>Education :</h1>
              <h1>{education}</h1>
            </div>

            <div className="flex items-center justify-start gap-1 text-sm text-exgray-800">
              <h1>Industry :</h1>
              {defaultIndust && defaultIndust.length > 0 && (
                <h1>
                  {defaultIndust.map(
                    (ind, index) =>
                      `${ind?.Name}${
                        index === defaultIndust.length - 1 ? "" : ", "
                      } `
                  )}
                </h1>
              )}
            </div>

            <div className="flex items-center justify-start gap-1 text-sm text-exgray-800">
              <h1>Current Salary :</h1>
              <h1 className={`${isSalaryMatched && marked}`}>
                {defaultSal?.Title}
              </h1>
            </div>

            <div className="flex items-center justify-start gap-1 text-sm text-exgray-800">
              <h1>Expected Salary :</h1>
              <h1>{defaultExpectedSal?.Title}</h1>
            </div>

            <div className="flex items-center justify-start gap-1 text-sm text-exgray-800">
              <h1>Preferred Location :</h1>
              {defaultPrefferedLoc && defaultPrefferedLoc.length > 0 && (
                <h1>
                  {defaultPrefferedLoc.map(
                    (loc, index) =>
                      `${loc?.Name}${
                        index === defaultPrefferedLoc.length - 1 ? "" : ", "
                      } `
                  )}
                </h1>
              )}
            </div>

            <div className="flex items-center justify-start gap-1 text-sm text-exgray-800">
              <h1>Serving Notice Period :</h1>
              <h1>{isServingNoticePeriod ? "Yes" : "No"}</h1>
            </div>

            <div className="flex items-center justify-start gap-1 text-sm text-exgray-800">
              <h1>Notice Period :</h1>
              <h1>{noticePeriod}</h1>
            </div>
          </div> */}

          <div className="flex items-start justify-start w-[90%]">
            <div className="flex-1">
              <div className="flex items-center justify-start">
                <h1 className="w-44 text-exgray-200 text-sm">
                  Total Yr of Experience :
                </h1>
                <h1
                  className={`${
                    isExperienceMatched && marked
                  } flex-1 text-exgray-800 text-sm font-medium`}
                >
                  {totalYears}
                </h1>
              </div>

              <div className="flex items-center justify-start">
                <h1 className="w-44 text-exgray-200 text-sm">Education :</h1>
                <h1 className="flex-1 text-exgray-800 text-sm font-medium">
                  {education}
                </h1>
              </div>

              <div className="flex items-center justify-start">
                <h1 className="w-44 text-exgray-200 text-sm">Industry :</h1>
                {defaultIndust && defaultIndust.length > 0 && (
                  <h1 className="flex-1 text-exgray-800 text-sm font-medium">
                    {defaultIndust.map(
                      (ind, index) =>
                        `${ind?.Name}${
                          index === defaultIndust.length - 1 ? "" : ", "
                        } `
                    )}
                  </h1>
                )}
              </div>

              <div className="flex items-center justify-start">
                <h1 className="w-44 text-exgray-200 text-sm">
                  Current Salary :
                </h1>
                <h1
                  className={`${
                    isSalaryMatched && marked
                  } flex-1 text-exgray-800 text-sm font-medium`}
                >
                  {defaultSal?.Title}
                </h1>
              </div>
            </div>
            <div className="flex-1">
              <div className="flex items-center justify-start">
                <h1 className="w-44 text-exgray-200 text-sm">
                  Expected Salary :
                </h1>
                <h1 className="flex-1 text-exgray-800 text-sm font-medium">
                  {defaultExpectedSal?.Title}
                </h1>
              </div>

              <div className="flex items-center justify-start">
                <h1 className="w-44 text-exgray-200 text-sm">
                  Preferred Location :
                </h1>
                {defaultPrefferedLoc && defaultPrefferedLoc.length > 0 && (
                  <h1 className="flex-1 text-exgray-800 text-sm font-medium">
                    {defaultPrefferedLoc.map(
                      (loc, index) =>
                        `${loc?.Name}${
                          index === defaultPrefferedLoc.length - 1 ? "" : ", "
                        } `
                    )}
                  </h1>
                )}
              </div>

              <div className="flex items-center justify-start">
                <h1 className="w-44 text-exgray-200 text-sm">
                  Serving Notice Period :
                </h1>
                <h1 className="flex-1 text-exgray-800 text-sm font-medium">
                  {isServingNoticePeriod ? "Yes" : "No"}
                </h1>
              </div>

              <div className="flex items-center justify-start">
                <h1 className="w-44 text-exgray-200 text-sm">
                  Notice Period :
                </h1>
                <h1 className="flex-1 text-exgray-800 text-sm font-medium">
                  {noticePeriod}
                </h1>
              </div>
            </div>
          </div>

          {skills && skills.length > 0 && (
            <div className="flex items-center justify-start gap-1 text-sm text-exgray-800 mt-5 w-[90%]">
              <h1 className="w-44 self-start text-exgray-200 text-sm">
                Key Skills :
              </h1>
              <h1 className="flex-1 text-exgray-800 text-sm font-medium">
                {skills.map((skill, index) => {
                  // Check if the skill matches any keyword (case-insensitive)
                  const isKeyword = searchedFieldsJobLevel?.keywords.some(
                    (keyword) => keyword.toLowerCase() === skill.toLowerCase()
                  );

                  return (
                    <span key={index} className={`${isKeyword && marked}`}>
                      {skill}
                      {index === skills.length - 1 ? (
                        ""
                      ) : (
                        <span className=" font-semibold text-exgray-300 bg-exwhite-100">{` | `}</span>
                      )}
                    </span>
                  );
                })}
              </h1>
            </div>
          )}
        </div>
      </div>
      <Popup onClose={() => setIsPopupOpen(false)} visible={isPopupOpen}>
        <div className="w-[70%] bg-exwhite-100 py-10 rounded-xl">
          <CreateReferForm
            jobId={jobId}
            candidateId={_id}
            onClose={() => setIsPopupOpen(false)}
            searchedcandidateDetails={candidateDetails}
          />
        </div>
      </Popup>
    </div>
  );
};

export default CandidateSearchCardJobLevel;
