import React, { useState } from 'react'
import UploadIcon from '../../assets/Icons/UploadIcon';
import uploadContent from '../../utils/upload-content/uploadContent';
import { useAppDispatch, useAppSelector } from '../../config/hooks';
import { createClientApi, getClientsDataApi } from '../../slices/salesSlice';
import { toast } from 'sonner';
import { unwrapResult } from '@reduxjs/toolkit';
import CrossIcon from '../../assets/Icons/CrossIcon';

const SalesBulkUpload = ({togglePopupHandler}) => {
     const dispatch=useAppDispatch()
     const {  salesStages, salesSubStages, salesStatus,  } = useAppSelector((state) => state.appConfig);
     const { userId ,token} = useAppSelector((state) => state.login.session);
     const [file, setFile] = useState(null);
     const [uploading,setUploading]=useState(false)
     const [arr,setArr]=useState([])
    
    const handleFileChange = (event) => {
      const toastId= toast.loading("Uploading")
      const selectedFile = event.target.files[0];
  
      // Check if the file is an xlsx file
      if (selectedFile && selectedFile.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
        setFile(selectedFile);
        console.log("File selected:", selectedFile.name);
      } else {
        alert("Please upload a valid .xlsx file.");
        setFile(null);
      }

    uploadContent.uploadCRMSContent(userId, event.target.files, (response) => {
    //console.log(response,jobId);
    const { contentType, name, size, url, id } = response[0];
    const attachment = {
        contentType: contentType,
        name: name,
        size: size,
        url: url,
        id: id,
    };


   const createClient=async (ele)=>{
      try{
        ele.createdBy=userId
        ele.stages= salesStages[0].Id.toString()
        ele.subStages=salesSubStages[0].Id.toString()
        ele.status= salesStatus[0].Id.toString()
        // ele.leadConversion="LEAD"
        const response=await dispatch(createClientApi(ele)).then(unwrapResult)  
        setArr(prev=>[...prev,{...ele, isUpload:true, errorType:""}])
      }
      catch(err){
        console.log(err,"EERRRR")
        if(err?.error==="An internal server error occurred."){
          
        }
        if (err?.code === 409) {
          setArr(prev=>[...prev,{...ele, isUpload:false, errorType:"Already exist"}])
        }else {
          setArr(prev=>[...prev,{...ele, isUpload:false, errorType:"Something went wrong"}])

        }
      }
               
              
   }
     
    dispatch(getClientsDataApi({text:`https://api.dev.exotalent.in/files/${url}?t=${token}`} )).then(
        (res)=>{
            console.log(res,"RESPONSE FROM CRET")
            if(res.payload==="Failed to upload data"){
              toast.message(res.payload)
              toast.dismiss(toastId)
              return ;
            }
            if(res.payload){
                res.payload.forEach((ele,index)=>

                  setTimeout(async() => {
                               await createClient(ele)                              
                            }, index*80)              
                 )

                 

                toast.dismiss(toastId)
              //   const createClientWithDelay = (client: any, delay: number): Promise<void> => {
              //     return new Promise<void>((resolve) => {
              //         setTimeout(() => {
              //             dispatch(createClientApi(client));
              //             resolve(); // resolve without passing anything, which is fine
              //         }, delay);
              //     });
              // };

              // const clientPromises = res.payload.map(client => createClientWithDelay(client, 10));

              // Promise.allSettled(clientPromises).then(() => {
              //   console.log("All clients created with delay.");
              //  });

            }


        }
    ).catch(error => {
      console.error("Error fetching client data:", error);
     });

    })

    };
  
    return (
      <div className="flex flex-col items-center justify-center relative p-8 bg-exwhite-100 rounded-lg w-[80%] h-screen max-h-popupScreenheight">
       <div  onClick={togglePopupHandler} className='absolute top-6 right-6 hover:scale-90 cursor-pointer'>
        <CrossIcon color='black'/>
       </div>
       <div className='w-fit bg-primary-400 rounded p-5'>
        <label 
          htmlFor="file-upload" 
          className="cursor-pointer "
          >
          <UploadIcon/>
        </label>
        
        <input
          id="file-upload"
          type="file"
          accept=".xlsx"
          onChange={handleFileChange}
          className="hidden"
          />
        </div> 
        {!file && <h1 className="mt-2 text-exgray-300 mb-2">Upload your document</h1>}
        {file && <p className="mt-2 text-exgray-300 mb-2">File Selected: {file.name}</p>}
        {arr?.length>0 &&
            <div className='w-full overflow-y-scroll'>
              {
                arr.map((ele)=>{
                  return (
                    <div className={` flex  justify-between items-center px-4 mb-2 ${ele.isUpload?"bg-exgreen-200":(ele.errorType==="Already exist"?"bg-orange-000":"bg-red-000")}`}>
                     <h1 className='flex-1'>{ele.clientName}</h1>
                     <h1 className='flex-1 '>{ele.errorType}</h1>
                     <h1 className=''>{ele.errorType.length>0?<CrossIcon color='red'/>:"SuccessFully Added"}</h1>
                    </div>
                  )
                })
              }

            </div>}
      </div>
    );
}

export default SalesBulkUpload