import { Controller, useFieldArray, useForm } from "react-hook-form";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import ExDropdown from "../../../../../components/basic-components/ExDropdown";
import { useAppDispatch, useAppSelector } from "../../../../../config/hooks";
import { unwrapResult } from "@reduxjs/toolkit";
import { getMembersApi } from "../../../../../slices/companyMemberSlice";
import * as constants from "../../../../../constant";
import { assignApplicantApi } from "../../../../../slices/jobSlice";
import { toast } from "sonner";
import Image from "../../../../../components/Image";
import DeleteIcon from "../../../../../assets/Icons/DeleteIcon";
import ReactQuill from "react-quill";

const AdvisorForm = ({
  selectedCandidates,
  onClose = () => {},
  setSelectedCandidates,
}) => {
  const dispatch = useAppDispatch();

  const { jobId } = useParams();

  const buttonRef = useRef();

  const [companyMembers, setCompanyMembers] = useState(null);

  const userId = useAppSelector((state) => state?.login?.session?.userId);
  const loginSelector = useAppSelector((state) => state?.login?.session);
  const memberSelector = useAppSelector(
    (state) => state?.member?.memberDetails
  );

  const form = useForm({
    defaultValues: {
      applicants: selectedCandidates.map((candidate) => ({
        ...candidate,
        advisorComment: "",
      })),
      advisor: null,
    },
  });

  const { handleSubmit, control, formState } = form;
  const { errors } = formState;

  const { fields, remove } = useFieldArray({
    name: "applicants",
    control,
  });

  useEffect(() => {
    const fetchData = async () => {
      if (loginSelector?.who === constants.COMPANY.toLowerCase()) {
        try {
          const response = await dispatch(
            getMembersApi({ companyId: userId })
          ).then(unwrapResult);
          const data = response.data.data;
          const advisors =
            data && data.length > 0
              ? data
                  .map((adv) => {
                    if (adv.designation === "Adviser") {
                      return {
                        ...adv,
                        label: `${adv.firstName} ${adv.lastName} - ${adv.emailId}`,
                      };
                    }
                  })
                  .filter((record) => record !== undefined)
              : [];
          setCompanyMembers(advisors);
        } catch (error) {}
      } else if (
        loginSelector?.who === constants.COMPANY_MEMBER.toLowerCase()
      ) {
        try {
          const response = await dispatch(
            getMembersApi({ companyId: memberSelector?.companyId })
          ).then(unwrapResult);
          const data = response.data.data;
          const advisors =
            data && data.length > 0
              ? data
                  .map((adv) => {
                    if (adv.designation === "Adviser") {
                      return {
                        ...adv,
                        label: `${adv.firstName} ${adv.lastName} - ${adv.emailId}`,
                      };
                    }
                  })
                  .filter((record) => record !== undefined)
              : [];
          setCompanyMembers(advisors);
        } catch (error) {}
      }
    };

    fetchData();
  }, []);

  const removeApplicantHandler = (index) => {
    remove(index);
    if (fields.length === 1) {
      onClose();
    }
  };

  const submitButtonHandler = () => {
    if (buttonRef.current) {
      buttonRef.current.click();
    }
  };

  const onSubmit = async (formData) => {
    const requestData = selectedCandidates.map((data) => ({
      memberId: formData.advisor.id,
      applicationId: data?.id,
      jobId: jobId,
      authorId: userId,
    }));

    try {
      await dispatch(assignApplicantApi(requestData));
      toast.success("Assigned to an Adviser");
      onClose();
      setSelectedCandidates([]);
    } catch (error) {
      toast.error("Something went wrong");
    }
  };

  return (
    <div className="w-[50%] bg-exwhite-100 p-6 rounded-md space-y-2 max-h-popupScreenheight">
      <h1 className="text-lg text-primary-600 font-semibold text-start border-b border-exgray-100 pb-1">
        Assign Candidates
      </h1>
      <form className="" onSubmit={handleSubmit(onSubmit)}>
        <div>
          <Controller
            name="advisor"
            control={control}
            rules={{
              required: {
                value: true,
                message: "required",
              },
            }}
            render={({ field: { onChange, value } }) => (
              <ExDropdown
                title="Assign Candidate to"
                onChange={onChange}
                placeholder="Select"
                value={value || null}
                optionValue="emailId"
                optionLabel="label"
                dropDownHeight="40px"
                dropDownBorderWidth="2px"
                dropDownBorderColor="#CDCED2"
                menuListHeight="200px"
                error={errors?.advisor?.message}
                options={
                  companyMembers
                    ? companyMembers
                    : [{ emailId: "Loading...", isDisabled: true }]
                }
              />
            )}
          />
        </div>

        {/* {fields.map((field, index) => {
          return (
            <div key={field.id}>
              <Controller
                name={`applicants.${index}.advisorComment`}
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: "Required",
                  },
                }}
                render={({ field: { onChange, value } }) => (
                  <div className="space-y-1">
                    <div className="flex-1 flex items-center justify-start gap-1">
                      {field?.author?.profileImage ? (
                        <div className="w-8 h-8">
                          <Image
                            url={field?.author?.profileImage}
                            alt="profile"
                            borderRadius="4px"
                          />
                        </div>
                      ) : (
                        field?.author?.firstName &&
                        field?.author?.lastName && (
                          <div className="w-8 h-8 flex items-center justify-center bg-primary-400 rounded-full">
                            <h1 className="text-exwhite-100 text-base font-semibold">{`${field?.author?.firstName[0].toUpperCase()}${field?.author?.lastName[0].toUpperCase()}`}</h1>
                          </div>
                        )
                      )}
                      <div className="flex-1 flex items-center justify-start gap-1">
                        <h1 className="text-base font-semibold text-exgray-400">{`${field?.author?.firstName} ${field?.author?.lastName}`}</h1>
                      </div>
                      <button
                        className="group mr-2"
                        type="button"
                        onClick={() => removeApplicantHandler(index)}
                      >
                        <DeleteIcon className="group-hover:fill-red-600" />
                      </button>
                    </div>
                    <ReactQuill
                      placeholder="Type Here"
                      value={value}
                      onChange={onChange}
                      theme="snow" // You can choose between 'snow' and 'bubble' themes
                    />
                  </div>
                )}
              />
            </div>
          );
        })} */}
        <button className="hidden" type="submit" ref={buttonRef}>
          Assign
        </button>
      </form>

      <div className="flex items-center justify-end pt-2">
        <button
          className="px-7 py-1 bg-primary-400 rounded-sm text-exwhite-100 font-semibold"
          onClick={submitButtonHandler}
        >
          Assign
        </button>
      </div>
    </div>
  );
};

export default AdvisorForm;
