import React from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useAppSelector } from '../../config/hooks';

const PendingApprovalTab = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { memberId } = useParams();

  const navigateHandler = (path) => {
    navigate(path);
  };

  const userId = useAppSelector((state) => state.login.session.userId);

  return (
    <div className="flex w-full items-center mt-3 border-b border-exgray-100">
      <button
        className={`px-4 py-1 ${pathname === "/employee-dashboard/pending-aprovals/regularization-request"
          ? "border-b-2 border-primary-600 text-primary-600 font-semibold mr-3"
          : "text-exgray-200"
          } text-base text-center font-normal`}
        onClick={() => navigateHandler("/employee-dashboard/pending-aprovals/regularization-request")}
      >
        Regularization
      </button>
      <button
        className={`px-4 py-1 ${pathname === "/employee-dashboard/pending-aprovals/leave-request-list"
          ? "border-b-2 border-primary-600 text-primary-600 font-semibold mr-3"
          : "text-exgray-200"
          } text-base text-center font-normal`}
        onClick={() => navigateHandler("/employee-dashboard/pending-aprovals/leave-request-list")}
      >
        Leave
      </button>
      <button
        className={`px-4 py-1 ${pathname === "/employee-dashboard/pending-aprovals/reimbursement-request-list"
          ? "border-b-2 border-primary-600 text-primary-600 font-semibold mr-3"
          : "text-exgray-200"
          } text-base text-center font-normal`}
        onClick={() => navigateHandler("/employee-dashboard/pending-aprovals/reimbursement-request-list")}
      >
        Reimbursement
      </button>
      {/* <div className="border-b-2 border-exgray-100 flex-1 self-end"></div> */}
    </div>
  )




}

export default PendingApprovalTab
