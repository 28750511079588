import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../config/hooks";
import { getAllAgreementsApi } from "../../slices/salesSlice";
import { useParams } from "react-router-dom";
import moment from "moment";
import Popup from "../../components/popup/Popup";
import AgreementDetail from "./AgreementDetail";

const AllAgreements = () => {
  const dispatch = useAppDispatch();
  const { agreements } = useAppSelector((state) => state.sales);
  const [togglePopup, setTogglePopup] = useState(false);
  const [agreementId, setAgreementId] = useState("");
  
  const { clientId } = useParams();

  useEffect(() => {
    dispatch(getAllAgreementsApi(clientId));
  }, []);

  const togglePopupHandler = (Id) => {
    setAgreementId(Id)
    setTogglePopup(!togglePopup);
  };

  return (
    <>
    <div className="py-10">
      {agreements && agreements.length > 0 ? (
        <div className="flex items-center flex-wrap gap-10 ">
          {agreements.map((ele, index) => {
            return (<div onClick={()=>togglePopupHandler(ele.id)} className="p-8 space-y-1 bg-exgray-600/70 text-center rounded hover:bg-primary-500/50 cursor-pointer">
              <h1 className="font-semibold text-white">{index == 0 ? "Latest" : `Previous ${index}`}</h1>
              <h3 className="text-sm text-primary-200">{moment(ele.createdAt).format(`DD/MM/YYYY`).toString()}</h3>
            </div>);
          })}
        </div>
      ) : (
        <div className="text-center text-exgray-500">
          <h1>No agreements found...</h1>
        </div>
      )}
    </div>
    <Popup
        visible={togglePopup}
        onClose={togglePopupHandler}
        children={
          <AgreementDetail clientId={clientId} agreementId={agreementId} agreementPopupHandler={togglePopupHandler} />
        }
      />
      </>
  );
};

export default AllAgreements;
