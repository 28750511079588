import React, { useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../config/hooks";
import { createInteractionApi } from "../../slices/salesSlice";
import { useParams } from "react-router-dom";
import moment from "moment";
//import moment2 from 'moment-timezone';

import DatePicker from "react-datepicker";
import AttachmentIcon from '../../assets/Icons/AttachmentIcon';
import uploadContent from '../../utils/upload-content/uploadContent';
import * as constants from "../../constant"
import { toast } from 'sonner';

const OnboardingForm = ({ togglePopupHandler }) => {
  const { userId, who } = useAppSelector((state) => state.login?.session)
  const { salesInteractionType ,salesStages,salesStatus,salesSubStages} = useAppSelector((state) => state.appConfig);
  const {clientDetail}=useAppSelector((state)=>state.sales)
  const [uploadedDoc, setUploadedDoc] = useState([])
  const dispatch = useAppDispatch()
  const clientId = useParams()
  const imageInputRef = useRef(null);
  const [formData, setFormData] = useState({
    interaction: {
    stages: "2",
    clientId: clientDetail.id,
    subStages:clientDetail.subStages||"",
    status:clientDetail.status||"",
    remarks:"",
    authorId: userId,
    createdBy: userId,
  },
  documents: []
}
);



  const [isUpdate, setIsUpdate] = useState(false); // Determine if it's an update or create

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      interaction: {
        ...formData.interaction,
        [name]: value
      }
    });
  };

  
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("formData ", formData)
    togglePopupHandler()
    try {
      dispatch(createInteractionApi(formData))
    } catch (error) {
      alert("There was an error submitting the form.");
    }
  };

  const handleFileChange = (e) => {
    const files = e.target.files;
    // console.log(files, "FIles")
   const toastId= toast.loading('Uploading...')
    uploadContent.uploadCRMSContent(userId, e.target.files, (response) => {
      // console.log(response, "Response")
      setFormData({
        ...formData,
        documents: response.map((ele) => {
          return {
            documentType: "INTERACTION",
            documentName: ele.orginalFileName,
            documentURL: ele.url,
            uploadedBy: userId
          }
        })
        
      });
      toast.dismiss(toastId)
    })

    
  };

  const handleAttachmentClick = () => {
    if (imageInputRef.current) {
      imageInputRef.current.click();
    }
  };
  return (
    <form
      onSubmit={handleSubmit}
      className=" p-6 w-[70%] max-h-screenheight overflow-y-auto bg-white shadow-md rounded-lg"
    >
      <div className="mb-4  ">
        <label className="text-gray-700  mb-2" htmlFor="">
          Stage *
        </label>
        <select
          name="stages"
          disabled
          value={formData.interaction.stages}
          onChange={handleChange}
          className="font-semibold bg-secondary-200/30 border-gray-300 rounded-md p-2 w-full"
        >
          <option value="">Select Stage</option>
          {salesStages?.map((ele, index) => (
            <option className="" key={index} value={ele.Id}>
              {ele.Title}
            </option>
          ))}
        </select>
      </div>

      <div className='mb-4  '>
        <label className="text-gray-700  mb-2" htmlFor="">Sub Stages  *</label>
        <select
          name="subStages"
          value={formData.interaction.subStages}
          onChange={handleChange}
          className="border border-gray-300 rounded-md p-2 w-full"
        >
          <option value="">Select Sub-Stage</option>
          {salesSubStages.filter((ele)=>ele.value==formData.interaction.stages)?.map((ele, index) => (
            <option className='' key={index} value={ele.Id}>
              {ele.Title}
            </option>
          ))}
        </select>
      </div>

      <div className='mb-4  '>
        <label className="text-gray-700  mb-2" htmlFor="">Status  *</label>
        <select
          name="status"
          value={formData.interaction.status}
          onChange={handleChange}
          className="border border-gray-300 rounded-md p-2 w-full"
        >
          <option value="">Select Status</option>
          {salesStatus.filter((ele)=>ele.value==formData.interaction.stages)?.map((ele, index) => (
            <option className='' key={index} value={ele.Id}>
              {ele.Title}
            </option>
          ))}
        </select>
      </div>

      <input
        ref={imageInputRef}
        type="file"
        name="files"
        multiple
        accept=".pdf, .doc, .docx, .csv, .xlsx"
        onChange={handleFileChange}
        style={{ display: "none" }}
      />

      <div className="mb-4 relative ">
        <label className="block text-gray-700  mb-2">Comments *</label>
        <textarea
          name="remarks"
          value={formData.interaction.remarks}
          placeholder='Enter here'
          onChange={handleChange}
          className="w-full pl-3 pr-8 py-2 border rounded-lg focus:outline-none focus:ring focus:ring-blue-300 no-scrollbar"
        />
        <div
          onClick={handleAttachmentClick}
          className="absolute bottom-3 right-2 hover:scale-90 hover:cursor-pointer"
        >
          <AttachmentIcon color="#142243" />
        </div>
      </div>

      {formData.documents.length > 0 && <div className="mb-2 ">
        <h1 className='text-primary-500 bg-secondary-300 w-fit rounded-full p-2 font-semibold '>{formData.documents.map((ele) => ele.documentName).join(" , ")}</h1>
      </div>}

      <div className="flex justify-end">
        <button
          type="submit"
          className="w-fit bg-primary-500 text-white py-2 px-8 rounded-lg hover:bg-primary-400 focus:outline-none focus:ring focus:ring-blue-300"
        >
          Submit
        </button>
      </div>
    </form>
  );
};

export default OnboardingForm;
