const CopyIcon = ({ width = "19", height = "23", color = "#0851A3" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 19 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.96377 16.3291C4.22817 16.3291 4.44215 16.5492 4.44215 16.8213C4.44215 17.0934 4.22819 17.3136 3.96377 17.3136H3.54073C2.84188 17.3136 2.20651 17.0197 1.74603 16.5459C1.28556 16.0721 1 15.4183 1 14.6992V3.61441C1 2.89529 1.28556 2.2415 1.74603 1.76766C2.20649 1.29384 2.84188 1 3.54073 1H10.9327C11.6315 1 12.2669 1.29384 12.7274 1.76766C13.1878 2.24148 13.4734 2.89529 13.4734 3.61441V4.07903C13.4734 4.35026 13.2594 4.57128 12.995 4.57128C12.7306 4.57128 12.5167 4.35112 12.5167 4.07903V3.61441C12.5167 3.16654 12.3385 2.75884 12.0513 2.46249C11.7641 2.16698 11.3679 1.98364 10.9318 1.98364H3.5399C3.10466 1.98364 2.70844 2.16697 2.42045 2.46249C2.13326 2.758 1.95427 3.16569 1.95427 3.61441V14.6992C1.95427 15.147 2.13326 15.5539 2.42045 15.8502C2.70763 16.1457 3.10382 16.3299 3.5399 16.3299H3.96294L3.96377 16.3291ZM8.06733 5.68644H15.4593C16.1581 5.68644 16.7935 5.98028 17.254 6.4541C17.7144 6.92792 18 7.58173 18 8.30085V19.3856C18 20.1047 17.7144 20.7585 17.254 21.2323C16.7935 21.7062 16.1581 22 15.4593 22H8.06733C7.36848 22 6.7331 21.7062 6.27262 21.2323C5.81215 20.7585 5.52659 20.1047 5.52659 19.3856V8.30085C5.52659 7.58173 5.81215 6.92794 6.27262 6.4541C6.73308 5.98028 7.36848 5.68644 8.06733 5.68644ZM15.4593 6.67094H8.06733C7.63208 6.67094 7.23587 6.85427 6.94787 7.15063C6.66069 7.44614 6.48251 7.85382 6.48251 8.30171V19.3864C6.48251 19.8343 6.66068 20.242 6.94787 20.5384C7.23506 20.8339 7.63125 21.0172 8.06733 21.0172H15.4593C15.8945 21.0172 16.2907 20.8339 16.5787 20.5384C16.8659 20.2429 17.0449 19.8352 17.0449 19.3864V8.30171C17.0449 7.85384 16.8659 7.44697 16.5787 7.15063C16.2915 6.85511 15.8953 6.67094 15.4593 6.67094Z"
        fill={color}
        stroke={color}
        strokeWidth="0.5"
      />
    </svg>
  );
};

export default CopyIcon;
