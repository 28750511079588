import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as constants from "../../../constant";
import moment from "moment";
import { useAppDispatch, useAppSelector } from "../../../config/hooks";
import { getAllRegularizeRequestApi, updateRegularizationApi } from "../../../slices/attendanceSlice";



const MyRegularizationRequest = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [togglePopup, setTogglePopup] = useState(false);
  const userId = useAppSelector((state) => state?.login?.session?.userId);
  //const attendanceList = useAppSelector((state) => state?.attendance?);
  const [startDateSelected, setStartDateSelected] = useState(null);
  const loginSelector = useAppSelector((state) => state?.login?.session);
  const memberDetail = useAppSelector((state) => state.company.memberDetails); //that is login user details
  const regularizeRequest = useAppSelector((state) => state.attendance.regularizationRequests);

  useEffect(() => {
    getAllRegularizationRequests();
  }, []);

  const getAllRegularizationRequests = () => {
    dispatch(
      getAllRegularizeRequestApi(
        {
          size: 100,
          employeeId: memberDetail?.id,
          companyId: loginSelector.who === constants.COMPANY.toLowerCase()
            ? userId
            : memberDetail?.companyId,
        }
      )
    )
  }

  const updateRegularization = (Id, status) => {
    dispatch(
      updateRegularizationApi({
        regularizationId: Id,
        status: status
      })
    )
  }

  // useEffect(() => {
  //   var timer = null;
  //   if (!startDateSelected) {
  //     timer = setInterval(() => setDate(new Date()), 1000)
  //   }
  //   return function cleanup() {
  //     clearInterval(timer)
  //   }
  // });

  const togglePopupHandler = () => {
    setTogglePopup(!togglePopup);
  };

  // const regularizeAtten = (attendance) => {
  //   setRegularizeAttendance(attendance);
  //   setTogglePopup(true);
  // }

  return (
    <>
      <div className="mt-3">
        {/* <h1 className="text-lg text-exgray-900 font-medium">Regularization Requests</h1> */}
        <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
          <table className="w-full mt-2 text-center rounded-sm">
            <thead className="py-3">
              <tr className="bg-secondary-800 rounded-lg">
                <th className="text-sm text-exwhite-100 border border-exgray-100 font-medium py-3">Name</th>
                <th className="text-sm text-exwhite-100 border border-exgray-100 font-medium py-3">Date</th>
                {/* <th className="text-sm text-exwhite-100 border border-exgray-100 font-medium py-3">Day</th> */}
                <th className="text-sm text-exwhite-100 border border-exgray-100 font-medium py-3">In-Time</th>
                <th className="text-sm text-exwhite-100 border border-exgray-100 font-medium py-3">Out-Time</th>
                <th className="text-sm text-exwhite-100 border border-exgray-100 font-medium py-3">Req. In</th>
                <th className="text-sm text-exwhite-100 border border-exgray-100 font-medium py-3">Req. Out</th>
                <th className="text-sm text-exwhite-100 border border-exgray-100 font-medium py-3">Reason</th>
                <th className="text-sm text-exwhite-100 border border-exgray-100 font-medium py-3">Status</th>
              </tr>
            </thead>
            <tbody className="items-center justify-center ">
              {
                regularizeRequest?.map((attendance, index) => {
                  var startTime = moment(attendance.checkIn);
                  var endTime = moment(attendance.checkOut);
                  var dif = moment.duration(endTime.diff(startTime));
                  return (
                    <tr key={attendance.id} className="items-center border border-exgray-100">
                      <td className="text-sm items-center border border-exgray-100 py-3">{attendance?.employeeDetails?.firstName} {attendance?.employeeDetails?.lastName}</td>
                      {/* <td className="text-sm items-center py-3 text-secondary-500 font-medium">{attendance?.originalCheckIn}</td> */}
                      <td className="text-sm items-center border border-exgray-100 py-3">{moment(attendance.originalCheckIn).format('DD/MM/YYYY')}</td>
                      {/* <td className="text-sm items-center border border-exgray-100 py-3">{moment(attendance.requestedIn).format('dddd')}</td> */}
                      <td className="text-sm items-center border border-exgray-100 py-3">{moment(attendance.originalCheckIn).format('LT')}</td>
                      <td className="text-sm items-center border border-exgray-100 py-3">{moment(attendance.originalCheckOut).format('DD/MM/YYYY') !== "01/01/0001" && moment(attendance.originalCheckOut).format('LT')}</td>
                      <td className="text-sm items-center border border-exgray-100 py-3">{moment(attendance.requestedIn).format('LT')}</td>
                      <td className="text-sm items-center border border-exgray-100 py-3">{moment(attendance.requestedOut).format('LT')}</td>
                      <td className="text-sm items-center border border-exgray-100 py-3 w-2/12">{attendance.reason}</td>
                      <td className="text-sm items-center border border-exgray-100 py-3 px-3 w-2/12">
                        {
                          <label className={`${attendance.status == "Approved" ? "text-green-600" : attendance.status == "Rejected" ? "text-red-700" : "text-secondary-500"} font-medium text-sm`}>
                            {attendance.status}
                          </label>
                        }
                      </td>
                    </tr>
                  )
                })
              }
            </tbody>
          </table>
        </div>
      </div >
    </>
  );
};

export default MyRegularizationRequest;
