import moment from "moment";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../config/hooks";
import {
  getAllAttendanceApi,
  getDailyReportApi,
} from "../../slices/attendanceSlice";
import { getMembersApi } from "../../slices/companyMemberSlice";
import * as constants from "../../constant";
import ProfileAvatar from "../../components/ProfileAvatar";
import EditIcon from "../../assets/Icons/EditIcon";

const content =
  "\u003col\u003e\u003cli\u003esdfgddgdhh\u003c/li\u003e\u003cli\u003efjghjgjgjkh\u003c/li\u003e\u003cli\u003ehjkhkhkhl\u003c/li\u003e\u003cli\u003ekjljkljkljkl\u003c/li\u003e\u003cli\u003ejljkljkljlj\u003c/li\u003e\u003cli\u003elkjljljkljkljk\u003c/li\u003e\u003cli\u003eljkljljlkjljkl\u003c/li\u003e\u003cli\u003ejkljljljljl\u003c/li\u003e\u003cli\u003eljkljljkljl\u003c/li\u003e\u003cli\u003ejljljljlj\u003c/li\u003e\u003cli\u003eljljlj\u003c/li\u003e\u003c/ol\u003e";

const EmplpoyeeDashboard = () => {
  const navigate = useNavigate();

  let month = moment().month();
  let year = moment().year();
  const [togglePopup, setTogglePopup] = useState(false);
  const dispatch = useAppDispatch();
  const [date, setDate] = useState(new Date());
  const [startDateSelected, setStartDateSelected] = useState(null);
  const userId = useAppSelector((state) => state?.login?.session?.userId);
  const loginSelector = useAppSelector((state) => state?.login?.session);
  const memberSelector = useAppSelector((state) => state.member);
  const attendanceList = useAppSelector(
    (state) => state?.attendance?.attendanceList
  );

  useEffect(() => {
    let month =
      moment().month() > 8 ? moment().month() + 1 : 0 + moment().month() + 1;
    let year = moment().year();
    dispatch(
      getAllAttendanceApi({
        dateFrom: moment(`01/${month}/${year}`).format("YYYY-MM-DD"),
        dateTo: moment().format("YYYY-MM-DD"),
        employeeId: userId,
      })
    );
    dispatch(
      getDailyReportApi({
        employeeId: userId,
      })
    );
  }, []);

  useEffect(() => {
    var timer = null;
    if (!startDateSelected) {
      timer = setInterval(() => setDate(new Date()), 1000);
    }
    return function cleanup() {
      clearInterval(timer);
    };
  });

  useEffect(() => {
    dispatch(
      getMembersApi({
        companyId:
          loginSelector.who === constants.COMPANY.toLowerCase()
            ? userId
            : memberSelector?.memberDetails?.companyId,
      })
    );
  }, []);

  const togglePopupHandler = () => {
    setTogglePopup(!togglePopup);
  };

  const navigateHandler = (path) => {
    navigate(path);
  };

  const dailyReport = useAppSelector((state) => state.attendance.dailyReport);

  return (
    <>
      <div className="flex">
        <div className="w-8/12 mr-1">
          <div className="flex px-3 py-4 bg-exwhite-100 justify-between rounded-lg border border-secondary-200 shadow shadow-2">
            <div className="flex items-center">
              <ProfileAvatar firstName={"kailas"} lastName={"surve"} />
            </div>
            <div className="flex items-center">
              <ProfileAvatar firstName={"kailas"} lastName={"surve"} />
            </div>
          </div>
          <div className="grid grid-cols-2 gap-1 mt-1">
            <div className="px-3 py-4 bg-exwhite-100 rounded-lg border border-secondary-200 shadow shadow-2">
              <div className="flex items-center justify-between">
                <h1 className="font-medium">To Do List</h1>
                <div className="text-secondary-500 text-sm font-semibold">
                  <h1>Add To Do</h1>
                </div>
              </div>
              <div className="border-b border-exgray-100 pb-2">
                <h1 className="text-secondary-500 text-sm font-medium">
                  15 Dec 2024
                </h1>
                <h1 className="text-sm font-medium">
                  Pending Interviews for Job ID ...
                </h1>
                <h1 className="text-xs">
                  3 candidates have interviews scheduled for tomorrow. Ensure
                  feedback forms are ready...
                </h1>
              </div>
              <div className="border-b border-exgray-100 pb-2 pt-2">
                <h1 className="text-secondary-500 text-sm font-medium">
                  15 Dec 2024
                </h1>
                <h1 className="text-sm font-medium">
                  Pending Interviews for Job ID ...
                </h1>
                <h1 className="text-xs">
                  3 candidates have interviews scheduled for tomorrow. Ensure
                  feedback forms are ready...
                </h1>
              </div>
              <div className="">
                <h1 className="text-secondary-500 text-sm font-medium pt-2">
                  15 Dec 2024
                </h1>
                <h1 className="text-sm font-medium">
                  Pending Interviews for Job ID ...
                </h1>
                <h1 className="text-xs">
                  3 candidates have interviews scheduled for tomorrow. Ensure
                  feedback forms are ready...
                </h1>
              </div>
            </div>
            <div className="px-3 py-4 bg-exwhite-100 rounded-lg border border-secondary-200 shadow shadow-2"></div>
          </div>
        </div>
        <div className="w-4/12">
          <div className="px-3 py-4 bg-exwhite-100 rounded-lg border border-secondary-200 shadow shadow-2">
            <h1>Holiday List</h1>
            <div className="flex items-center">
              <h1 className="text-lg font-medium text-secondary-500">26</h1>
              <h1>January 2025</h1>
            </div>
            <div className="flex items-center">
              <h1 className="text-lg font-medium text-secondary-500">30</h1>
              <h1>January 2025</h1>
            </div>
            <div className="flex items-center">
              <h1 className="text-lg font-medium text-secondary-500">30</h1>
              <h1>January 2025</h1>
            </div>
          </div>
          <div className="px-3 py-4 bg-exwhite-100 rounded-lg border border-secondary-200 shadow shadow-2">
            <h1>Request List</h1>
            <div className=""></div>
          </div>
        </div>
      </div>
      {/* <div className="mt-3">
        <h1 className="text-base font-medium">Emplpyee Attendance</h1>
      </div>
      <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
        <table className="w-full mt-2 text-center rounded-sm">
          <thead className="py-3">
            <tr className="bg-secondary-800 rounded-lg ">
              <th className="text-sm text-exwhite-100 font-medium py-3">Day</th>
              <th className="text-sm text-exwhite-100 font-medium py-3">Date</th>
              <th className="text-sm text-exwhite-100 font-medium py-3">In-Time</th>
              <th className="text-sm text-exwhite-100 font-medium py-3">Out-Time</th>
              <th className="text-sm text-exwhite-100 font-medium py-3">Working hrs</th>
              <th className="text-sm text-exwhite-100 font-medium py-3">Status</th>
              <th className="text-sm text-exwhite-100 font-medium py-3">Regularization</th>
              <th className="text-sm text-exwhite-100 font-medium py-3">Approver</th>
            </tr>
          </thead>
          <tbody className="items-center justify-center ">
            {
              attendanceList?.map((attendance, index) => {
                var startTime = moment(attendance.checkIn);
                var endTime = moment(attendance.checkOut);
                var dif = moment.duration(endTime.diff(startTime));
                return (
                  <tr key={attendance.id} className="items-center border-b border-l border-r border-exgray-100">
                    <td className="items-center py-3">{moment(attendance.date).format('dddd')}</td>
                    <td className="items-center py-3 text-secondary-500 font-medium">{moment(attendance.date).format('DD/MM/YYYY')}</td>
                    <td className="items-center py-3">{moment(attendance.checkIn).format('LT')}</td>
                    <td className="items-center py-3">{attendance?.status ? moment(attendance.checkOut).format('LT') : ''}</td>
                    <td className="items-center py-3">{attendance?.status ? `${dif.hours()}:${dif.minutes() < 10 ? "0" + dif.minutes() : dif.minutes()}` : ''}</td>
                    <td className="items-center py-3">{attendance?.status}</td>
                    <td className="items-center py-3">
                      <div className="flex justify-center">
                        {
                          !attendance?.isRegularized ?
                            <div className="flex items-center border border-secondary-500 px-2 rounded-md" >
                              <EditIcon />
                              <h1 className="ml-2 text-secondary-500">Edit</h1>
                            </div>
                            :
                            <h1 className="ml-2 text-secondary-500">InReview</h1>
                        }
                      </div>
                    </td>
                    <td className="items-center py-3">{attendance?.reportingManagerDetails?.firstName} {attendance?.reportingManagerDetails?.lastName}</td>
                  </tr>
                )
              })
            }


          </tbody>
        </table>
      </div> */}
    </>
  );
};

export default EmplpoyeeDashboard;
