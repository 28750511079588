import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../config/hooks";
import { Controller, useForm } from "react-hook-form";
import ExInput from "../../../components/basic-components/ExInput";
import CrossIcon from "../../../assets/Icons/CrossIcon";
import CheckedIcon from "../../../assets/Icons/CheckedIcon";
import {
  Attendance,
  attendanceRegularizeRequestApi,
  getAllAttendanceApi,
  postAttedanceApi,
  postDailyReportApi
} from "../../../slices/attendanceSlice";
import { useEffect, useState } from "react";
import ReactQuill from 'react-quill'; // Import the ReactQuill component
import 'react-quill/dist/quill.snow.css';
import CustomDatePicker from "../../../components/basic-components/CustomDatePicker";
import moment from "moment";

export interface RegularizationFormProps {
  onClose: Function;
  attendance: Attendance;
  updateEndTime: Function;
}

const RegularizationForm = ({ onClose, attendance, updateEndTime }: RegularizationFormProps) => {
  const navigate = useNavigate();
  const memberDetail = useAppSelector((state) => state.company.memberDetails);
  const dispatch = useAppDispatch();
  const attendanceList = useAppSelector((state) => state?.attendance?.attendanceList);

  const form = useForm({
    defaultValues: {
      reason: "",
      requestedIn: attendance ? new Date(attendance?.date) : null,
      requestedOut: null,
      date: attendance ? new Date(attendance?.date) : null,
      dailyReport: ""
    },
  });
  const { handleSubmit, control, formState, setValue } = form;
  const { errors } = formState;
  const [inTime, setIntime] = useState(null);

  const handleInTimeChange = (time) => {
    const updatedDate = attendance ? new Date(attendance?.date) : new Date(inTime);
    updatedDate.setHours(time.getHours());
    updatedDate.setMinutes(time.getMinutes());
    updatedDate.setSeconds(time.getSeconds());
    setValue("requestedIn", updatedDate);
  };

  const handleOutTimeChange = (time) => {
    const updatedDate = attendance ? new Date(attendance?.date) : new Date(inTime);
    updatedDate.setHours(time.getHours());
    updatedDate.setMinutes(time.getMinutes());
    updatedDate.setSeconds(time.getSeconds());
    setValue("requestedOut", updatedDate);
  };


  const onSubmit = async (formData) => {
    if (attendance) {
      dispatch(attendanceRegularizeRequestApi({
        attendanceId: attendance.id,
        employeeId: attendance.employeeId,
        originalCheckIn: attendance.checkIn,
        originalCheckOut: attendance.checkOut,
        requestedIn: formData.requestedIn,
        requestedOut: formData.requestedOut,
        reason: formData.reason,
        status: "InReview"
      }))

      dispatch(postDailyReportApi({
        employeeId: attendance.employeeId,
        attendanceId: attendance.id,
        dailyReport: [formData.dailyReport],
        approvedBy: "",
        approvalStatus: ""
      }))

    }
    else {
      dispatch(postAttedanceApi({
        employeeId: memberDetail.id,
        date: formData.requestedIn,
        checkIn: formData.requestedIn,
        status: "",
      })).then((result) => {
        console.log(result.payload.data.id, "result.payload.data.id")
        dispatch(attendanceRegularizeRequestApi({
          attendanceId: result.payload.data.id,
          employeeId: memberDetail.id,
          originalCheckIn: formData.requestedIn,
          //originalCheckOut: attendance.checkOut,
          requestedIn: formData.requestedIn,
          requestedOut: formData.requestedOut,
          reason: formData.reason,
          status: "InReview"
        }))
        dispatch(postDailyReportApi({
          employeeId: memberDetail.id,
          attendanceId: result.payload.data.id,
          dailyReport: [formData.dailyReport],
          approvedBy: "",
          approvalStatus: ""
        }))
      })
      // getAllAttendence();
    }
    onClose();
  };

  // const getAllAttendence = () => {
  //   let month = moment().month() > 8 ? moment().month() + 1 : 0 + moment().month() + 1;
  //   let year = moment().year();
  //   dispatch(
  //     getAllAttendanceApi({
  //       dateFrom: moment(`01/${month}/${year}`).format("YYYY-MM-DD"),
  //       dateTo: moment().format("YYYY-MM-DD"),
  //       employeeId: memberDetail.id,
  //     })
  //   );
  // };

  const handleDateChange = (date) => {
    console.log("date", date);
    console.log(moment(date).format("DD/MM/YYYY"), "moment(date).format");

    let checkAtt = attendanceList?.filter((att) => moment(att.date).format("DD/MM/YYYY") == moment(date).format("DD/MM/YYYY"));
    if (checkAtt?.length > 0) {
      alert("This date's attendance already exists.")
    }
    else {
      setIntime(new Date(date));
      setValue("date", new Date(date));
    }
    // if (att.date == new Date(date)) {
    // }
    // else {
    //   setIntime(new Date(date));
    // }
    //})
  }

  return (
    <div className="px-10 py-6 w-[60%] mx-auto bg-white shadow-md rounded-lg overflow-y-auto no-scrollbar">
      <div className="flex flex-row items-center justify-between">
        <h1 className="text-xl text-primary-400 font-medium mb-1">
          Regularization
        </h1>
        <div onClick={() => onClose()}>
          <CrossIcon />
        </div>
      </div>
      <div className="h-full flex-1 justify-between">
        <form onSubmit={handleSubmit(onSubmit)} className="space-y-2 mt-4">
          <div className="w-full">
            <Controller
              name="date"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "Please select date for regularization",
                },
              }}
              render={({ field: { onChange, value } }) => (
                <CustomDatePicker
                  disabled={attendance}
                  title="Date"
                  name="date"
                  onChange={(date) => handleDateChange(date)}
                  selected={attendance ? new Date(attendance?.date) : inTime ? inTime : null}
                  maxDate={new Date()}
                  minDate={moment().subtract(25, 'days').format()}
                  showYearDropdown={true}
                  dropdownMode="select"
                  placeholderText="Ex. mm/dd/yyyy"
                  popperPlacement="bottom-end"
                  dateFormat="dd/MM/yyyy"
                  showMonthYearPicker={false}
                  error={errors?.date?.message}
                  isMandatory={true}
                />
              )}
            />
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            <Controller
              name="requestedIn"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "Select requested in time",
                },
              }}
              render={({ field: { onChange, value } }) => (
                <CustomDatePicker
                  title="New Time In"
                  name="Select time"
                  onChange={(date) => handleInTimeChange(date)}
                  selected={value ? value : attendance ? new Date(attendance?.date) : null}
                  maxDate={attendance ? new Date(attendance?.date) : new Date(inTime)}
                  minDate={attendance ? new Date(attendance?.date) : new Date(inTime)}
                  showYearDropdown={true}
                  dropdownMode="select"
                  placeholderText="Enter new in time"
                  popperPlacement="bottom-end"
                  dateFormat="p"
                  showMonthYearPicker={false}
                  error={errors?.requestedIn?.message}
                  isMandatory={true}
                  showTimeSelect={true}
                  timeIntervals={1}
                //inline
                />
              )}
            />
            <Controller
              name="requestedOut"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "Select requested out time",
                },
              }}
              render={({ field: { onChange, value } }) => (
                <CustomDatePicker
                  title="New Time Out"
                  name="Select time"
                  onChange={(date) => handleOutTimeChange(date)}
                  selected={value ? value : null}
                  maxDate={attendance ? new Date(attendance?.date) : new Date(inTime)}
                  minDate={attendance ? new Date(attendance?.date) : new Date(inTime)}
                  showYearDropdown={true}
                  dropdownMode="select"
                  placeholderText="Enter new out time"
                  popperPlacement="bottom-end"
                  dateFormat="p"
                  showMonthYearPicker={false}
                  showTimeSelect={true}
                  error={errors?.requestedOut?.message}
                  isMandatory={true}
                  timeIntervals={1}

                //inline
                />
              )}
            />
          </div>
          <div>
            <Controller
              name="reason"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "Please enter reason",
                },
              }}
              render={({ field: { onChange, value } }) => (
                <ExInput
                  title={"Reason for Update *"}
                  name={"type"}
                  onChange={onChange}
                  error={errors?.reason?.message}
                />
              )}
            />
          </div>
          <div className="w-full">
            <label
              htmlFor={"dailyReport"}
              className={`text-exgray-200/70 text-base font-semibold pb-2`}
            >
              Daily Report  *
            </label>
            <Controller
              name="dailyReport"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "Please enter your task on that day",
                },
              }}
              render={({ field: { onChange, value } }) => (
                <ReactQuill
                  //onFocus={ }
                  placeholder="Type Here"
                  //style={{ height: 100 }}
                  value={value}
                  onChange={onChange}
                  theme="snow" // You can choose between 'snow' and 'bubble' themes
                />
              )}
            />
            <h1 className="text-red-400">{errors?.dailyReport?.message}</h1>
          </div>
          <div className="flex items-end justify-end pt-5">
            <button
              onClick={() => handleSubmit(onSubmit)}
              //disabled={(startDateSelected == null && todayAttendance?.length == 0 || (todayAttendance?.length > 0 && todayAttendance[0]?.status))}
              className={`bg-primary-500 w-2/12 h-11 rounded-lg text-exwhite-100 text-lg`}>
              Submit
            </button>
          </div>
        </form>
      </div>


    </div>
  );
};

export default RegularizationForm;
