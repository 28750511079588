import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import { PostDailyReport } from "../models/employee.model";
import attendanceService from "../services/attendance.service";
import { BasicUser } from "../models/candidate.model";
import ToastNotification from "../components/toast-notification/ToastNotification";
import { toast } from "sonner";

export interface Attendance {
  id?: string;
  employeeId: string;
  date?: Date;
  checkIn?: Date;
  checkOut?: Date;
  status?: string;
  attendanceId?: string;
  isRegularized?: boolean;
  reportingManagerDetails?: ReportingManager;
  checkInLocation?: {
    type: "Point";
    coordinates: Array<number>;
  };
  checkOutLocation?: {
    type: "Point";
    coordinates: Array<number>;
  };
}

export interface ReportingManager {
  firstName: string;
  lastName: string;
  profileImage: string;
  designation: string;
}

export interface GetDailyReport {
  employeeId: string;
}

export interface DailyReport {
  approvalStatus: string;
  approvedBy: string;
  attendanceId: string;
  createdAt: Date;
  dailyReport: Array<string>;
  employeeId: string;
  id: string;
  updatedAt: string;
}

export interface Regularization {
  id?: string;
  attendanceId: string;
  employeeId: string;
  originalCheckIn: Date;
  originalCheckOut?: Date;
  requestedIn: Date;
  requestedOut: Date;
  reason: string;
  status?: string;
  approverId?: string;
}

export interface AttendanceFilter {
  status?: string;
  dateFrom?: string;
  dateTo?: string;
  page?: number;
  employeeId?: string;
  companyId?: string;
}

export interface RegularizeFilter {
  approverId?: string;
  size?: number;
  companyId?: string;
  regularizationId?: string;
  status?: string;
  employeeId?:string;
}

export interface FilterDataForConsolidateView{
  month:number;
  year:number;
  companyId:string;
}

export interface ConsolidateViewResponse{
  employeeId:string;
  name:string;
  doj:Date;
  team:string;
  companyName:string;
  location:string;
  noOfDaysInMonth:number;
  noOfWorkingDays:number;
  noOfDaysWorked:number;
  leaveAvailed:number;
  leaveCarryForward:number;
  leaveEarned:number;
  earnedSickLeave:number;
  leaveBalance:number;
  payrollAmount:number;
  lossOfPay:number;
}

export interface Timesheet{
  id:string;
  employeeId:string;
  companyId:string;
  companyName:string;
  date:string;
  checkIn:string;
  checkOut:string;
  status:string;
  isRegularized:boolean;
  createdAt:string;
  updatedAt:string;
  employee:BasicUser;
  approvedBy:string;
  approvalStatus:string;
}

export interface attendanceReduxState {
  attendanceList:Array<Attendance>;
  todaysReports:Array<string>;
  todayAttendance:Attendance;
  dailyReport:Array<DailyReport>;
  timesheet:Array<Timesheet>;
  allEmloyeeAttendance:Array<Attendance>;
  regularizationRequests:Array<Attendance>;
  consolidateTimesheetList:Array<ConsolidateViewResponse>;
}

const initialState = {
  attendanceList:[],
  todaysReports:[],
  todayAttendance:null,
  dailyReport:[],
  timesheet:[],
  allEmloyeeAttendance:[],
  regularizationRequests:[],
  consolidateTimesheetList:[]
};

export const postAttedanceApi = createAsyncThunk(
  "attendance/postAttedanceApi",
  async (data: Attendance, { getState, rejectWithValue }) => {
    try {
      const response = await attendanceService.postAttedance(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateOutTimeApi = createAsyncThunk(
  "attendance/updateOutTimeApi",
  async (data: Attendance, { getState, rejectWithValue }) => {
    try {
      const response = await attendanceService.updateOutTime(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getAllAttendanceApi = createAsyncThunk(
  "attendance/getAllAttendanceApi",
  async (data: AttendanceFilter, { getState, rejectWithValue }) => {
    try {
      const response = await attendanceService.getAllAttendance(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const postDailyReportApi = createAsyncThunk(
  "attendance/postDailyReportApi",
  async (data: PostDailyReport, { getState, rejectWithValue }) => {
    try {
      const response = await attendanceService.postDailyReport(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getDailyReportApi = createAsyncThunk(
  "attendance/getDailyReportApi",
  async (data: GetDailyReport, { getState, rejectWithValue }) => {
    try {
      const response = await attendanceService.getDailyReport(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const attendanceRegularizeRequestApi = createAsyncThunk(
  "attendance/attendanceRegularizeRequestApi",
  async (data: Regularization, { getState, rejectWithValue }) => {
    try {
      const response = await attendanceService.regularizeRequest(data);
      toast("Attendence regularization done successfuly",{duration:3000})
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getTimesheetApi = createAsyncThunk(
  "attendance/getTimesheetApi",
  async (data: AttendanceFilter, { getState, rejectWithValue }) => {
    try {
      const response = await attendanceService.getTimesheet(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getAllEmployeeAttendanceApi = createAsyncThunk(
  "attendance/getAllEmployeeAttendanceApi",
  async (data: AttendanceFilter, { getState, rejectWithValue }) => {
    try {
      const response = await attendanceService.getAllEmployeeAttendance(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getAllRegularizeRequestApi = createAsyncThunk(
  "attendance/getAllRegularizeRequestApi",
  async (data: RegularizeFilter, { getState, rejectWithValue }) => {
    try {
      const response = await attendanceService.getAllRegularizeRequest(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateRegularizationApi = createAsyncThunk(
  "attendance/updateRegularizationApi",
  async (data: RegularizeFilter, { getState, rejectWithValue }) => {
    try {
      const response = await attendanceService.updateRegularization(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getConsolidateViewApi = createAsyncThunk(
  "attendance/getConsolidateViewApi",
  async (data:FilterDataForConsolidateView, { getState, rejectWithValue }) => {
    try {
      const response = await attendanceService.getConsolidateView(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const attendanceSlice = createSlice({
  name: "attendance",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllAttendanceApi.fulfilled, (state, action) => {
      state.attendanceList = action.payload.data.data;
      if (
        action.meta.arg.employeeId !== state.todayAttendance?.employeeId ||
        moment(state.todayAttendance?.checkIn).format("DD/MM/YYYY") !==
          moment().format("DD/MM/YYYY")
      ) 
      {
        state.todayAttendance = null;
        if (
          state.attendanceList?.length > 0 &&
          moment(state.attendanceList[0]?.checkIn).format("DD/MM/YYYY") ===
            moment().format("DD/MM/YYYY")
        ) {
          state.todayAttendance = state.attendanceList[0];
        }
      }
    });

    builder.addCase(getAllRegularizeRequestApi.fulfilled, (state, action) => {
      state.regularizationRequests = action.payload.data.data.regularizations;
    });
    builder.addCase(getConsolidateViewApi.fulfilled, (state, action) => {
      state.consolidateTimesheetList = action.payload.data.data; 
      //state.consolidateTimesheet = action.payload.data.data.regularizations;
    });

    builder.addCase(postAttedanceApi.fulfilled, (state, action) => {
      if (
        moment(action.meta.arg.date).format("DD/MM/YYYY") ===
        moment().format("DD/MM/YYYY")
      ) {
        state.todayAttendance = action.payload.data;
      }
      if (state.attendanceList?.length > 0) {
        state.attendanceList = [action.payload.data, ...state.attendanceList];
      } else {
        state.attendanceList = [action.payload.data];
      }
    });
    builder.addCase(updateOutTimeApi.fulfilled, (state, action) => {
      state.todayAttendance = action.payload.data;
      let attendenceList = state.attendanceList;
      attendenceList.map((list)=>{
        if(list.id == state.todayAttendance.id){
          list = state.todayAttendance;
        }
      })

    });
    builder.addCase(updateRegularizationApi.fulfilled, (state, action) => {
      let regularizationList = state.regularizationRequests;
      regularizationList.map((regu) => {
        if (regu.id == action.meta.arg.regularizationId) {
          regu.status = action.meta.arg.status;
        }
      });
    });
    builder.addCase(getAllEmployeeAttendanceApi.fulfilled, (state, action) => {
      state.allEmloyeeAttendance = action.payload.data.data;
    });
    builder.addCase(getTimesheetApi.fulfilled, (state, action) => {
      state.timesheet = action.payload.data.data;
    });
    builder.addCase(
      attendanceRegularizeRequestApi.fulfilled,
      (state, action) => {
        let attendanceListNew = state.attendanceList;
        attendanceListNew.map((attendance) => {
          if (attendance.id == action.meta.arg.attendanceId) {
            attendance.isRegularized = true;
            attendance.regularization = action.payload.data.data;
          }
        });
        state.attendanceList = attendanceListNew;
      }
    );
    builder.addCase(getDailyReportApi.fulfilled, (state, action) => {
      state.dailyReport = action.payload?.data?.reverse();
    });
    builder.addCase(postDailyReportApi.fulfilled, (state, action) => {});
  },
});

//export const { removeRecruiterDetails } = attendanceSlice.actions;
export default attendanceSlice.reducer;
