
import React, { useEffect, useState } from "react";
import FilterIcon from "../../assets/Icons/FilterIcon";
import ArrowIcon from "../../assets/Icons/ArrowIcon";
import { useAppDispatch, useAppSelector } from "../../config/hooks";
import { getAllClientsApi, getfilteredClientsApi, getNextClientsApi } from "../../slices/salesSlice";
import InfiniteScroll from "react-infinite-scroll-component";
import { toast } from "sonner";
import { Link } from "react-router-dom";
import useSalesDropdown from "../../utils/dropdown-options/useSalesDropdown";

const sections = [
  {
    name: "Clients Details",
    columns: [
      // { key: "clientID", label: "Client ID / Account Number" },
      // { key: "clientAddress", label: "Client Address" },
      // { key: "leadOwner", label: "Lead Owner" },
      // { key: "clientIndustry", label: "Client Industry" },
      // { key: "onboardingDate", label: "On boarding Date" },
      // { key: "location", label: "Location" },
      { key: "clientName", label: "Client Name" },
      { key: "clientEmail", label: "Client EMail" },
      { key: "domain", label: "Client Domain" },
      {key:"engageWith",label:"Engage With"},
      {key:"status",label:"Status"}
    ],
  },
  {
    name: "Key Terms",
    columns: [
      { key: "contractType", label: "Contract Type" },
      { key: "paymentTerms", label: "Payment Terms" },
    ],
  },
  {
    name: "Agreements",
    columns: [{ key: "agreementStatus", label: "Agreement Status" }],
  },
  {
    name: "Revenue",
    columns: [{ key: "revenue", label: "Revenue" }],
  },
];

const data = [
  {
    clientID: "#526941",
    clientAddress: "123 Main St, NY, USA",
    leadOwner: "Gyanesh Kumar",
    clientIndustry: "Technology",
    onboardingDate: "01/02/2025",
    location: "Mumbai",
    contractType: "Annual",
    paymentTerms: "Net 30",
    agreementStatus: "Active",
    revenue: "$50,000",
  },
  {
    clientID: "#526942",
    clientAddress: "456 Elm St, NY, USA",
    leadOwner: "Rahul Sharma",
    clientIndustry: "Finance",
    onboardingDate: "02/10/2025",
    location: "Delhi",
    contractType: "Monthly",
    paymentTerms: "Net 15",
    agreementStatus: "Pending",
    revenue: "$30,000",
  },
  {
    clientID: "#526942",
    clientAddress: "456 Elm St, NY, USA",
    leadOwner: "Rahul Sharma",
    clientIndustry: "Finance",
    onboardingDate: "02/10/2025",
    location: "Delhi",
    contractType: "Monthly",
    paymentTerms: "Net 15",
    agreementStatus: "Pending",
    revenue: "$30,000",
  },
  {
    clientID: "#526942",
    clientAddress: "456 Elm St, NY, USA",
    leadOwner: "Rahul Sharma",
    clientIndustry: "Finance",
    onboardingDate: "02/10/2025",
    location: "Delhi",
    contractType: "Monthly",
    paymentTerms: "Net 15",
    agreementStatus: "Pending",
    revenue: "$30,000",
  },
];

const ClientManagement = () => {
  const dispatch=useAppDispatch()
  const {clients} = useAppSelector((state)=>state.sales)
  const [selectedColumns, setSelectedColumns] = useState(
    sections.reduce((acc, section) => {
      section.columns.forEach((col) => {
        acc[col.key] = true;
      });
      return acc;
    }, {})
  );

  const [openSection, setOpenSection] = useState(sections[0].name); // Track only the currently open section
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const {
    defaultRevenue,
    defaultEngageWith,
    defaultSalesIndustry,
    defaultStatus,
    defaultSalesStages,
    defaultSalesSubStages,
    defaultEngagementType,
  } = useSalesDropdown();
  useEffect(()=>{
    // dispatch(getAllClientsApi())
    dispatch(getfilteredClientsApi({stages:'3'}));
  },[])

  const toggleColumn = (key) => {
    setSelectedColumns((prev) => ({
      ...prev,
      [key]: !prev[key],
    }));
  };

  const toggleSection = (name) => {
    setOpenSection((prev) => (prev === name ? null : name)); // If the same section is clicked, close it; else open the clicked section
  };

  const toggleSectionCheckbox = (sectionName) => {
    const section = sections.find((section) => section.name === sectionName);
    const allSelected = section.columns.every(
      (col) => selectedColumns[col.key]
    );

    const newSelectedColumns = { ...selectedColumns };
    section.columns.forEach((col) => {
      newSelectedColumns[col.key] = !allSelected; // If all selected, deselect, else select
    });
    
    newSelectedColumns["clientName"]=true
    setSelectedColumns(newSelectedColumns);
  };

  const toggleSpecificSection = (sectionName) => {
    const section = sections.find((section) => section.name === sectionName);
    setOpenSection(section.name);

    const newSelectedColumns = {};

    // Loop through all sections
  sections.forEach((sec) => {
    console.log(sec,"SEC")
    // If the current section is the one we want to toggle, handle its columns
    if (sec.name === sectionName) {
      sec.columns.forEach((col) => {
        newSelectedColumns[col.key] = true; // Toggle the columns for this section
      });
    } else {
      // If the section is not the one we're toggling, set all columns to false
      sec.columns.forEach((col) => {
        newSelectedColumns[col.key] = false;
      });
    }
  });
    
  newSelectedColumns["clientName"]=true
    setSelectedColumns(newSelectedColumns);
  };

  const resetColumns = () => {
    // Reset selected columns to the initial state (all selected)
    const initialColumns = sections.reduce((acc, section) => {
      section.columns.forEach((col) => {
        acc[col.key] = true; // Set all columns as selected
      });
      return acc;
    }, {});
    setSelectedColumns(initialColumns);
  };

  const getMoreDataHandler = () => {
    try {
      console.log("HIT NEXT URL TABLE");
      dispatch(getNextClientsApi());
      // console.log(clients,"CLIENTSHHKDHJKDGH",filteredClients)
    } catch (error) {
      toast.error("Unable to fetch data");
    }
  };
  
  return (
    <div className="flex flex-col relative h-screen">
      <div className="flex justify-between items-center py-2 ">
        <h1 className="text-xl font-semibold">Client List</h1>
        <div className="flex gap-2 items-center">
          <FilterIcon />
          <button className="bg-primary-600 text-white px-6 py-1 rounded">
            Export
          </button>
        </div>
      </div>

      <div className="flex gap-8 py-3 px-4 bg-white">
        {sections.map((section) => (
          <div key={section.name} className="flex items-center gap-2">
            <h1
              className={`text-exgray-300 cursor-pointer ${
                openSection === section.name
                  ? "text-primary-600 font-semibold"
                  : ""
              }`}
              onClick={() => toggleSpecificSection(section.name)}
            >
              {section.name}
            </h1>
          </div>
        ))}
      </div>

      {/* Table Section */}
      <div className={`transition-all ${sidebarOpen ? "w-3/4 overflow-y-scroll" : "w-full overflow-y-scroll"}`}>
       
        <InfiniteScroll
                 className=""
                 dataLength={clients?.clients?.length || 0}
                 next={() => getMoreDataHandler()}
                 hasMore={clients?.metadata?.nextResultURL ? true : false}
                 height={"calc(100vh - 155px)"}
                 loader={""}
               >
        <table className="min-w-full bg-white relative ">
          <thead className="sticky top-0">
            <tr className="bg-primary-600">
              <th></th>
              {sections.flatMap((section) =>
                section.columns.map(
                  (col) =>
                    selectedColumns[col.key] && (
                      <th
                        key={col.key}
                        className="border-r font-normal border-white px-4 py-2 text-left text-nowrap text-white"
                      >
                        {col.label}
                      </th>
                    )
                )
              )}
            </tr>
          </thead>
          {/* <tbody>
            {clients?.clients?.map((row, index) => (
              <tr key={index} className="border text-exgray-500">
                <td className="border px-2 py-2 text-center">{index+1}</td>
                {sections.flatMap((section) =>
                  section.columns.map(
                    (col) =>
                      selectedColumns[col.key]  && (
                        selectedColumns[col.key]==="clientName"?
                        <td key={col.key} className="border px-4 py-2">
                          {row[col.key]}
                        </td>
                        :
                       
                        <td key={col.key} className="border px-4 py-2 hover:text-secondary-600">
                          <Link to={`/company-admin/sales/detail/${row.id}`}>
                          {row[col.key]}
                          </Link>
                        </td>
                       

                      )
                  )
                )}
              </tr>
            ))}
          </tbody> */}
          <tbody>
            {clients?.clients?.map((row, index) => (
              <tr key={index} className="border text-exgray-500">
                <td className="border px-2 py-2 text-center">{index + 1}</td>
                {sections.flatMap((section) =>
                  section.columns.map((col) => {
                    if (selectedColumns[col.key]) {
                      if (col.key === "clientName") {
                        return (
                          <td key={col.key} className="border px-4 py-2 hover:text-secondary-600">
                             <Link to={`/company-admin/sales/detail/${row.id}`}>
                              {row[col.key]}
                            </Link>
                          </td>
                        );
                      } 
                      if (col.key === "engageWith") {
                        return (
                          <td key={col.key} className="border px-4 py-2 ">
                              {defaultEngageWith(row[col.key])}
                           </td>
                        );
                      } 
                      if (col.key === "status") {
                        return (
                          <td key={col.key} className="border px-4 py-2 text-nowrap ">
                              {defaultStatus(row[col.key])}
                           </td>
                        );
                      } 
                      else {
                        return (
                          <td key={col.key} className="border px-4 py-2 ">
                              {row[col.key]}
                          </td>
                        );
                      }
                    }
                    return null; // This ensures that if the condition is not met, nothing is rendered
                  })
                )}
              </tr>
            ))}
          </tbody>
        </table>
        </InfiniteScroll>
      </div>

      {/* Column Selector Sidebar */}
      <div
        className={`fixed top-32 right-0 transition-all border-l border-gray-300 bg-white shadow-md ${
          sidebarOpen ? "w-1/4 p-4 overflow-auto " : "w-0 overflow-hidden"
        }`}
        style={{ height: "calc(100vh - 100px)" }}
      >
        {sidebarOpen && (
          <div className="overflow-auto">
            <h3 className="text-lg font-semibold mb-2">Select Columns</h3>
            {sections.map((section) => (
              <div key={section.name} className="">
                <div className="flex items-center gap-1">
                  <input
                    type="checkbox"
                    checked={section.columns.every(
                      (col) => selectedColumns[col.key]
                    )}
                    onChange={() => toggleSectionCheckbox(section.name)}
                    className=""
                  />
                  <button
                    className="w-full flex justify-between items-center font-semibold p-2"
                    onClick={() => toggleSection(section.name)}
                  >
                    {section.name}
                    <span>
                      {openSection === section.name ? (
                        <div className="rotate-180">
                          <ArrowIcon />
                        </div>
                      ) : (
                        <div>
                          <ArrowIcon />
                        </div>
                      )}
                    </span>
                  </button>
                </div>
                {openSection === section.name && (
                  <div className="p-4 ">
                    {section.columns.map(
                      (col) =>
                        col.key !== "clientID" && (
                          <div key={col.key} className="flex items-center ">
                            <input
                              type="checkbox"
                              id={col.key}
                              checked={selectedColumns[col.key]}
                              onChange={() => toggleColumn(col.key)}
                              className="mr-2"
                            />
                            <label htmlFor={col.key}>{col.label}</label>
                          </div>
                        )
                    )}
                  </div>
                )}
              </div>
            ))}

            <div className="flex  justify-end">
              <button
                onClick={resetColumns}
                className="w-fit text-end  text-primary-500  font-semibold px-4 py-2 rounded mt-2 hover:scale-90"
              >
                Reset
              </button>
            </div>
          </div>
        )}
      </div>

      {/* Sidebar Toggle Button */}
      <button
        className="fixed top-[9.5rem] -translate-y-1/2 right-0 bg-yellow-300 px-3 py-1 rounded-l-full shadow-md "
        onClick={() => setSidebarOpen(!sidebarOpen)}
      >
        {sidebarOpen ? (
          <div className="-rotate-90">
            <ArrowIcon color="#142243" strokeWidth="3" />
          </div>
        ) : (
          <div className="rotate-90">
            <ArrowIcon color="#142243" strokeWidth="3" />
          </div>
        )}
      </button>
    </div>
  );
};

export default ClientManagement;
