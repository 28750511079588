import { createAsyncThunk, createSlice, isAction } from "@reduxjs/toolkit";
import nocoService from "../services/noco.service";
import salesnocoService from "../services/sales.noco.service";

const initialState = {
  designations: [],
  industries: [],
  categories: [],
  functions: [],
  countries: [],
  cities: [],
  languages: [],
  currencies: [],
  jobTypes: [],
  workplaceTypes: [],
  minSalaries: [],
  maxSalaries: [],
  companySize: [],
  companyTypes: [],
  maxExperience: [],
  minExperience: [],
  salesEngageWith: [],
  salesEngangementType: [],
  // salesIndustry: [],
  // salesSubIndustry: [],
  salesStages: [],
  salesSubStages: [],
  salesStatus: [],
  salesInteractionType: [],
  bandList: [],
  genderList: [],
  departmentList: [],
  roleList: [],
  designationList: [],
  qualifications: [],
  employementType: [],
  AgPaymentTerms:[],
  AgFreeReplacementPeriod:[],
  AgPaymentSchedule:[],
  AgProfileValidity:[],
  AgCTCRange:[],
  AgSeniorityLevel:[],
  leaveTypes:[]
};

export const getDesignationsApi = createAsyncThunk(
  "appSlice/getDesignationsApi",
  async (data, { getState, rejectWithValue }) => {
    try {
      const response = await nocoService.getAllDesignations();
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getLanguagesApi = createAsyncThunk(
  "appSlice/getLanguagesApi",
  async (data, { getState, rejectWithValue }) => {
    try {
      const response = await nocoService.getLanguageList();
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getCountriesApi = createAsyncThunk(
  "appSlice/getCountriesApi",
  async (data, { getState, rejectWithValue }) => {
    try {
      const response = await nocoService.getAllCountries();
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getCurrencyTypeApi = createAsyncThunk(
  "appSlice/getCurrencyTypeApi",
  async (data, { getState, rejectWithValue }) => {
    try {
      const response = await nocoService.getCurrencyType();
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getCitiesApi = createAsyncThunk(
  "appSlice/getCitiesApi",
  async (data: { offset: number }, { getState, rejectWithValue }) => {
    try {
      const response = await nocoService.getAllCities(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getIndustriesApi = createAsyncThunk(
  "appSlice/getIndustriesApi",
  async (data, { getState, rejectWithValue }) => {
    try {
      const response = await nocoService.getAllIndustries();
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getCategoriesApi = createAsyncThunk(
  "appSlice/getCategoriesApi",
  async (data, { getState, rejectWithValue }) => {
    try {
      const response = await nocoService.getAllCategories();
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getFunctionsApi = createAsyncThunk(
  "appSlice/getFunctionsApi",
  async (data, { getState, rejectWithValue }) => {
    try {
      const response = await nocoService.getAllFunctions();
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getJobTypeApi = createAsyncThunk(
  "appSlice/getJobTypeApi",
  async (data, { getState, rejectWithValue }) => {
    try {
      const response = await nocoService.getAllJobType();
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getWorkplaceTypeApi = createAsyncThunk(
  "appSlice/getWorkplaceTypeApi",
  async (data, { getState, rejectWithValue }) => {
    try {
      const response = await nocoService.getAllWorkplaceType();
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getSalaryRangeMinApi = createAsyncThunk(
  "appSlice/getSalaryRangeMinApi",
  async (data, { getState, rejectWithValue }) => {
    try {
      const response = await nocoService.getSalaryRange();
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getSalaryInThousandRangeApi = createAsyncThunk(
  "appSlice/getSalaryInThousandRangeApi",
  async (data, { getState, rejectWithValue }) => {
    try {
      const response = await nocoService.getSalaryInThousandRange();
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getSalaryMaxRangeApi = createAsyncThunk(
  "appSlice/getSalaryMaxRangeApi",
  async (data, { getState, rejectWithValue }) => {
    try {
      const response = await nocoService.getMaxSalaryRange();
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getCompanySizeRangeApi = createAsyncThunk(
  "appSlice/getCompanySizeRangeApi",
  async (data, { getState, rejectWithValue }) => {
    try {
      const response = await nocoService.getCompanySizeRange();
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getCompanyTypesApi = createAsyncThunk(
  "appSlice/getCompanyTypesApi",
  async (data, { getState, rejectWithValue }) => {
    try {
      const response = await nocoService.getCompanyTypes();
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getExperienceMaxApi = createAsyncThunk(
  "appSlice/getExperienceMaxApi",
  async (data, { getState, rejectWithValue }) => {
    try {
      const response = await nocoService.getExperienceMaxRange();
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getExperienceMinApi = createAsyncThunk(
  "appSlice/getExperienceMinApi",
  async (data, { getState, rejectWithValue }) => {
    try {
      const response = await nocoService.getExperienceMinRange();
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getDepartmentApi = createAsyncThunk(
  "appSlice/getDepartmentApi",
  async (data, { getState, rejectWithValue }) => {
    try {
      const response = await nocoService.getDepartment();
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getBandListApi = createAsyncThunk(
  "appSlice/getBandListApi",
  async (data, { getState, rejectWithValue }) => {
    try {
      const response = await nocoService.getBandList();
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getRoleListApi = createAsyncThunk(
  "appSlice/getRoleListApi",
  async (data, { getState, rejectWithValue }) => {
    try {
      const response = await nocoService.getAccessRole();
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getGenderListApi = createAsyncThunk(
  "appSlice/getGenderListApi",
  async (data, { getState, rejectWithValue }) => {
    try {
      const response = await nocoService.getGenderList();
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getSalesEngangementTypeApi = createAsyncThunk(
  "appSlice/getSalesEngangementTypeApi",
  async (data, { getState, rejectWithValue }) => {
    try {
      const response = await salesnocoService.getSalesEngangementType();
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

// export const getSalesIndustryApi = createAsyncThunk(
//   "appSlice/getSalesIndustryApi",
//   async (data, { getState, rejectWithValue }) => {
//     try {
//       const response = await salesnocoService.getSalesIndustry();
//       return response;
//     } catch (error) {
//       return rejectWithValue(error.response?.data);
//     }
//   }
// );

// export const getSalesSubIndustryApi = createAsyncThunk(
//   "appSlice/getSalesSubIndustryApi",
//   async (data, { getState, rejectWithValue }) => {
//     try {
//       const response = await salesnocoService.getSalesSubIndustry();
//       return response;
//     } catch (error) {
//       return rejectWithValue(error.response?.data);
//     }
//   }
// );

export const getSalesEngageWithApi = createAsyncThunk(
  "appSlice/getSalesEngageWithApi",
  async (data, { getState, rejectWithValue }) => {
    try {
      const response = await salesnocoService.getSalesEngageWith();
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getSalesStagesApi = createAsyncThunk(
  "appSlice/getSalesStagesApi",
  async (data, { getState, rejectWithValue }) => {
    try {
      const response = await salesnocoService.getSalesStages();
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getSalesSubStagesApi = createAsyncThunk(
  "appSlice/getSalesSubStagesApi",
  async (data, { getState, rejectWithValue }) => {
    try {
      const response = await salesnocoService.getSalesSubStages();
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getSalesStatusApi = createAsyncThunk(
  "appSlice/getSalesStatusApi",
  async (data, { getState, rejectWithValue }) => {
    try {
      const response = await salesnocoService.getSalesStatus();
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getSalesInteractionTypeApi = createAsyncThunk(
  "appSlice/getSalesInteractionTypeApi",
  async (data, { getState, rejectWithValue }) => {
    try {
      const response = await salesnocoService.getSalesInteractionType();
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getDesignationListApi = createAsyncThunk(
  "appSlice/getDesignationListApi",
  async (data, { getState, rejectWithValue }) => {
    try {
      const response = await nocoService.getDesignationList();
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getQualificationsApi = createAsyncThunk(
  "appSlice/getQualificationsApi",
  async (data, { getState, rejectWithValue }) => {
    try {
      const response = await nocoService.getQualification();
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getEmloymentTypeApi = createAsyncThunk(
  "appSlice/getEmloymentTypeApi",
  async (data, { getState, rejectWithValue }) => {
    try {
      const response = await nocoService.getEmployement();
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getWorkLocationsApi = createAsyncThunk(
  "appSlice/getWorkLocationsApi",
  async (data, { getState, rejectWithValue }) => {
    try {
      const response = await nocoService.getWorkLocation();
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getHolidayListApi = createAsyncThunk(
  "appSlice/getHolidayListApi",
  async (data: string, { getState, rejectWithValue }) => {
    try {
      const response = await nocoService.getHolidayList(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);


export const getAgreementPaymentTermsApi = createAsyncThunk(
  "appSlice/getAgreementPaymentTermsApi",
  async (data, { getState, rejectWithValue }) => {
    try {
      const response = await salesnocoService.getAgreementPaymentTerms();
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
 );

export const getAgreementFreeReplacementPeriodApi = createAsyncThunk(
  "appSlice/getAgreementFreeReplacementPeriodApi",
  async (data, { getState, rejectWithValue }) => {
    try {
      const response = await salesnocoService.getAgreementFreeReplacementPeriod();
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
 );
export const getAgreementPaymentScheduleApi = createAsyncThunk(
  "appSlice/getAgreementPaymentScheduleApi",
  async (data, { getState, rejectWithValue }) => {
    try {
      const response = await salesnocoService.getAgreementPaymentSchedule();
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
 );
export const getAgreementProfileValidityApi = createAsyncThunk(
  "appSlice/getAgreementProfileValidityApi",
  async (data, { getState, rejectWithValue }) => {
    try {
      const response = await salesnocoService.getAgreementProfileValidity();
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
 );
export const getAgreementCTCRangeApi = createAsyncThunk(
  "appSlice/getAgreementCTCRangeApi",
  async (data, { getState, rejectWithValue }) => {
    try {
      const response = await salesnocoService.getAgreementCTCRange();
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
 );

export const getAgreementSeniorityLevelApi = createAsyncThunk(
  "appSlice/getAgreementSeniorityLevelApi",
  async (data, { getState, rejectWithValue }) => {
    try {
      const response = await salesnocoService.getAgreementSeniorityLevel();
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
 );

 export const getLeaveTypeApi = createAsyncThunk(
  "appSlice/getLeaveTypeApi",
  async (data, { getState, rejectWithValue }) => {
    try {
      const response = await nocoService.getLeaveType();
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
 );



export const appSlice = createSlice({
  name: "appConfig",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getLeaveTypeApi.fulfilled, (state, action) => {
      state.leaveTypes = action.payload.data.list;
    });
    builder.addCase(getHolidayListApi.fulfilled, (state, action) => {
      state.holidayList = action.payload.data.list;
    });
    builder.addCase(getWorkLocationsApi.fulfilled, (state, action) => {
      state.workLocations = action.payload.data.list;
    });
    builder.addCase(getEmloymentTypeApi.fulfilled, (state, action) => {
      state.employementType = action.payload.data.list;
    });
    builder.addCase(getDesignationsApi.fulfilled, (state, action) => {
      state.designations = action.payload.data.list;
    });
    builder.addCase(getDesignationListApi.fulfilled, (state, action) => {
      state.designationList = action.payload.data.list;
    });

    builder.addCase(getBandListApi.fulfilled, (state, action) => {
      state.bandList = action.payload.data.list;
    });
    builder.addCase(getGenderListApi.fulfilled, (state, action) => {
      state.genderList = action.payload.data.list;
    });
    builder.addCase(getRoleListApi.fulfilled, (state, action) => {
      state.roleList = action.payload.data.list;
    });
    builder.addCase(getDepartmentApi.fulfilled, (state, action) => {
      state.departmentList = action.payload.data.list;
    });

    builder.addCase(getLanguagesApi.fulfilled, (state, action) => {
      state.languages = action.payload.data.list;
    });

    builder.addCase(getCountriesApi.fulfilled, (state, action) => {
      state.countries = action.payload.data.list;
    });

    builder.addCase(getCitiesApi.fulfilled, (state, action) => {
      // state.cities = [...state.cities, ...action.payload.data.list];
      const combinedCities: any = [
        ...state.cities,
        ...action.payload.data.list,
      ];

      const uniqueCities: any = [
        ...new Map(combinedCities.map((city) => [city.Id, city])).values(),
      ];

      uniqueCities.sort((a: { Name: string }, b: { Name: string }) =>
        a.Name.localeCompare(b.Name)
      );

      state.cities = uniqueCities;
    });

    builder.addCase(getIndustriesApi.fulfilled, (state, action) => {
      state.industries = action.payload.data.list;
    });

    builder.addCase(getCategoriesApi.fulfilled, (state, action) => {
      state.categories = action.payload.data.list;
    });

    builder.addCase(getFunctionsApi.fulfilled, (state, action) => {
      state.functions = action.payload.data.list;
    });

    builder.addCase(getJobTypeApi.fulfilled, (state, action) => {
      state.jobTypes = action.payload.data.list;
    });

    builder.addCase(getWorkplaceTypeApi.fulfilled, (state, action) => {
      state.workplaceTypes = action.payload.data.list;
    });

    builder.addCase(getSalaryRangeMinApi.fulfilled, (state, action) => {
      state.minSalaries = action.payload.data.list;
    });

    builder.addCase(getSalaryInThousandRangeApi.fulfilled, (state, action) => {
      state.salaryInThousands = action.payload.data.list;
    });

    builder.addCase(getSalaryMaxRangeApi.fulfilled, (state, action) => {
      state.maxSalaries = action.payload.data.list;
    });

    builder.addCase(getCompanySizeRangeApi.fulfilled, (state, action) => {
      state.companySize = action.payload.data.list;
    });

    builder.addCase(getCompanyTypesApi.fulfilled, (state, action) => {
      state.companyTypes = action.payload.data.list;
    });

    builder.addCase(getExperienceMaxApi.fulfilled, (state, action) => {
      state.maxExperience = action.payload.data.list;
    });

    builder.addCase(getQualificationsApi.fulfilled, (state, action) => {
      state.qualifications = action.payload.data.list;
    });

    builder.addCase(getExperienceMinApi.fulfilled, (state, action) => {
      state.minExperience = action.payload.data.list;
    });
    builder.addCase(getCurrencyTypeApi.fulfilled, (state, action) => {
      state.currencies = action.payload.data.list;
    });
    builder.addCase(getSalesEngangementTypeApi.fulfilled, (state, action) => {
      state.salesEngangementType = action.payload.data.list;
    });
    builder.addCase(getSalesEngageWithApi.fulfilled, (state, action) => {
      state.salesEngageWith = action.payload.data.list;
    });
    builder.addCase(getSalesSubStagesApi.fulfilled, (state, action) => {
      state.salesSubStages = action.payload.data.list;
    });
    builder.addCase(getSalesStagesApi.fulfilled, (state, action) => {
      state.salesStages = action.payload.data.list;
    });
    builder.addCase(getSalesStatusApi.fulfilled, (state, action) => {
      state.salesStatus = action.payload.data.list;
    });
    builder.addCase(getSalesInteractionTypeApi.fulfilled, (state, action) => {
      state.salesInteractionType = action.payload.data.list;
    });
    builder.addCase(getAgreementPaymentTermsApi.fulfilled, (state, action) => {
      state.AgPaymentTerms = action.payload.data.list;
    });
    builder.addCase(getAgreementFreeReplacementPeriodApi.fulfilled, (state, action) => {
      state.AgFreeReplacementPeriod = action.payload.data.list;
    });
    builder.addCase(getAgreementPaymentScheduleApi.fulfilled, (state, action) => {
      state.AgPaymentSchedule = action.payload.data.list;
    });
    builder.addCase(getAgreementProfileValidityApi.fulfilled, (state, action) => {
      state.AgProfileValidity = action.payload.data.list;
    });
    builder.addCase(getAgreementCTCRangeApi.fulfilled, (state, action) => {
      state.AgCTCRange = action.payload.data.list;
    });
    builder.addCase(getAgreementSeniorityLevelApi.fulfilled, (state, action) => {
      state.AgSeniorityLevel = action.payload.data.list;
    });
  },
});

export default appSlice.reducer;
