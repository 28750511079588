import React, { useEffect, useMemo, useRef, useState } from "react";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../config/hooks";
import { getBulkCandidateDetailApi } from "../../slices/candidateProfileSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import * as constants from "../../constant";
import CheckedIcon from "../../assets/Icons/CheckedIcon";
import { v4 as uuidV4 } from "uuid";
import CandidateAdditionalInfo from "./CandidateAdditionalInfo";
import CandidateProfileTabs from "./CandidateProfileTabs";
import PlusCircleIcon from "../../assets/Icons/PlusCircleIcon";
import axios from "axios";
import { toast } from "sonner";
import LoadingIcon from "../../assets/Icons/LoadingIcon";
import useLocations from "../../utils/dropdown-options/useLocations";
import LocationIcon from "../../assets/Icons/LocationIcon";
import VerticleThreeDotsIcon from "../../assets/Icons/VerticleThreeDotsIcon";
import ScheduleCalenderIcon from "../../assets/Icons/ScheduleCalenderIcon";
import TagIcon from "../../assets/Icons/TagIcon";
import ShareIcon from "../../assets/Icons/ShareIcon";
import ForwardIcon from "../../assets/Icons/ForwardIcon";
import {
  getApplicantDetailsByIdApi,
  toggleApplicationToProfile,
  updateApplicantStatusApi,
} from "../../slices/jobSlice";

const CandidateView = () => {
  const navigate = useNavigate();
  const { applicantId, jobId, applicationId } = useParams();
  const { pathname, state } = useLocation();

  const toggleStatusMenuRef = useRef(null);
  const toggleStatusMenuParentRef = useRef(null);

  const dispatch = useAppDispatch();

  const token = useAppSelector((state) => state?.login?.session?.token);
  const loginSelector = useAppSelector((state) => state?.login?.session);
  const toggleSwitch = useAppSelector(
    (state) => state.jobs.isCandidateViewEnable
  );
  const memberSelector = useAppSelector((state) => state.member.memberDetails);

  const { defaultLocation } = useLocations();

  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [applicantDetails, setApplicantDetails] = useState(null);
  const [toggleStatusModal, setToggleStatusModal] = useState(false);

  useEffect(() => {
    const toggleHandler = (e) => {
      if (
        toggleStatusMenuRef.current &&
        toggleStatusMenuParentRef &&
        !toggleStatusMenuRef.current.contains(e.target) &&
        !toggleStatusMenuParentRef.current.contains(e.target)
      ) {
        // close popup
        setToggleStatusModal(false);
      }
    };
    document.addEventListener("mousedown", toggleHandler);

    return () => {
      document.removeEventListener("mousedown", toggleHandler);
    };
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await dispatch(
          getBulkCandidateDetailApi({ userId: applicantId })
        ).then(unwrapResult);
        setUser(response.data.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        setError(true);
      }
    };

    fetchData();
  }, [applicantId, dispatch]);

  useEffect(() => {
    getApplicantDetails();
  }, []);

  const getApplicantDetails = async () => {
    try {
      const response = await dispatch(
        getApplicantDetailsByIdApi({ jobId, applicantId: state.candidate.id })
      ).then(unwrapResult);
      setApplicantDetails(response.data.data);
    } catch (error) {
      toast.error("Unable to fetch applicant details");
    }
  };

  const defaultLoc = useMemo(() => {
    if (user?.userDetails?.location) {
      return defaultLocation(user.userDetails.location);
    } else {
      return null;
    }
  }, [defaultLocation, user?.userDetails?.location]);

  const navigationHandler = (pathName, data) => {
    const navigationData = {
      candidateAddInfo: data,
      candidate: state.candidate,
    };
    navigate(pathName, { state: navigationData });
  };

  const toggleSwitchHandler = () => {
    dispatch(toggleApplicationToProfile());
  };

  const toggleStatusHandler = () => {
    setToggleStatusModal(!toggleStatusModal);
  };

  const updateStateHandler = async (applicantStatus) => {
    setToggleStatusModal(false);
    if (
      loginSelector?.who === constants.COMPANY.toLowerCase() ||
      loginSelector?.who === constants.COMPANY_MEMBER.toLowerCase()
    ) {
      if (state?.candidate?.job?.jobStatus === "OPEN") {
        try {
          await dispatch(
            updateApplicantStatusApi({
              jobId: jobId,
              applicationId: state.candidate.id,
              status: applicantStatus,
              subStatus: "APPLICATION_VIEWED", //APPLICATION_CONTACT_VIEWED
            })
          ).then(unwrapResult);
          getApplicantDetails();
          // fetchJobInterviewStages();
          toast.success("Status updated successfuly");
        } catch (error) {
          toast.error("Something went wrong");
        }
      } else {
        toast.error("Job Closed");
      }
    }
    //  else if (
    //   (loginSelector?.who === constants.COMPANY_MEMBER.toLowerCase() &&
    //     memberSelector.designation === constants.CANDIDATE_MANEGMENT) ||
    //   loginSelector?.who === constants.RECRUITER.toLowerCase()
    // ) {
    //   const resp = await dispatch(
    //     updateApplicantStatusByMemberApi({
    //       jobId: candidate.jobId,
    //       applicationId: candidate.applications[0].id,
    //       status: applicantStatus,
    //       subStatus: "APPLICATION_VIEWED", //APPLICATION_CONTACT_VIEWED
    //     })
    //   ).then(unwrapResult);
    //   const newArray = appliedCadidateViaRefer.referrals.map((ref) => {
    //     return {
    //       ...ref,
    //       applications: ref.applications.map((apps) => {
    //         if (apps.id === resp.data.data.id) {
    //           return { ...apps, status: resp.data.data.status };
    //         }
    //       }),
    //     };
    //   });
    //   setAppliedCadidateViaRefer({
    //     ...appliedCadidateViaRefer,
    //     referrals: newArray,
    //   });
    //   dispatch(
    //     updateReferalStatusApi({
    //       referalId: candidate.id,
    //       status: applicantStatus,
    //       subStatus: "APPLICATION_VIEWED",
    //     })
    //   ).then(unwrapResult);
    // }
  };

  if (error) {
    return <h1>Something went wronf</h1>;
  }

  if (loading) {
    return (
      <div className="py-20 flex items-center justify-center">
        <LoadingIcon />
      </div>
    );
  }

  return (
    <div className="space-y-1">
      <div>
        <div className="flex items-center justify-center gap-1">
          <div
            className={`h-5 w-10 p-1 ${
              toggleSwitch ? "bg-expurple-700" : "bg-exgray-100"
            } rounded-full flex items-center transition-all cursor-pointer ${
              toggleSwitch ? "justify-end" : "justify-start"
            }`}
            onClick={toggleSwitchHandler}
          >
            <div className="h-[12px] w-[12px] rounded-full bg-exwhite-100 shadow-xl shadow-black"></div>
          </div>
          <h1 className="text-base font-semibold text-exgray-200/60 flex-1">
            {toggleSwitch ? "Candiate Profile" : "Application"}
          </h1>
        </div>
      </div>
      <div className="space-y-2">
        <div className="bg-exwhite-100 p-4 flex items-center justify-center gap-4 rounded-sm">
          <div>
            {user?.userDetails?.profilePicture ? (
              <div
                className={`w-24 h-24 rounded-full flex items-center justify-center ${
                  (user?.userDetails?.isEmailVerified ||
                    user?.userDetails?.isMobileVerified) &&
                  "border-4 border-[#6DC680] relative"
                } mx-auto`}
              >
                <img
                  src={`${constants.baseUrl}/${user.userDetails.profilePicture}?t=${token}`}
                  alt="profile"
                  className="w-full h-full object-cover rounded-full"
                />
                {(user.userDetails.isEmailVerified ||
                  user.userDetails.isMobileVerified) && (
                  <div className="absolute bottom-0 right-0 w-6 h-6 bg-[#6DC680] rounded-full flex items-center justify-center">
                    <CheckedIcon />
                  </div>
                )}
              </div>
            ) : user.userDetails.firstName && user.userDetails.lastName ? (
              <div
                className={`w-24 h-24 rounded-full bg-primary-400 ${
                  (user.userDetails.isEmailVerified ||
                    user.userDetails.isMobileVerified) &&
                  "border-4 border-[#6DC680] relative"
                } mx-auto flex items-center justify-center`}
              >
                <h1 className="text-exwhite-100 text-4xl font-semibold tracking-wider">{`${user.userDetails.firstName[0].toUpperCase()}${user.userDetails.lastName[0].toUpperCase()}`}</h1>
                {(user.userDetails.isEmailVerified ||
                  user.userDetails.isMobileVerified) && (
                  <div className="absolute bottom-0 right-0 w-6 h-6 bg-[#6DC680] rounded-full flex items-center justify-center">
                    <CheckedIcon />
                  </div>
                )}
              </div>
            ) : (
              <div
                className={`w-24 h-w-24 rounded-full ${
                  (user.userDetails.isEmailVerified ||
                    user.userDetails.isMobileVerified) &&
                  "border-4 border-[#6DC680] relative"
                } bg-DefaultProfileImg bg-cover bg-no-repeat bg-center border-2 border-exgray-200/20 mx-auto relative`}
              >
                {(user.userDetails.isEmailVerified ||
                  user.userDetails.isMobileVerified) && (
                  <div className="absolute bottom-0 right-0 w-6 h-6 bg-[#6DC680] rounded-full flex items-center justify-center">
                    <CheckedIcon />
                  </div>
                )}
              </div>
            )}
          </div>
          <div className="self-start pt-2">
            <h1 className="text-base text-exgray-500 font-semibold text-start">
              {`${user.userDetails.firstName} ${user.userDetails.lastName}`}{" "}
              {user.userDetails.designation && "| "}{" "}
              <span className="text-secondary-400">
                {user.userDetails.designation}
              </span>
            </h1>
            <h1 className="text-sm text-exgray-500 font-semibold text-start">
              {`${user.userDetails.email}`}
              {user.userDetails.mobile && "| "}
              <span className="">
                {`+${user.userDetails.countryCode} ${user.userDetails.mobile}`}
              </span>
            </h1>
            {defaultLoc?.State && (
              <div className="flex items-center justify-start gap-1">
                <div>
                  <LocationIcon width="13" height="13" color="#6C7275" />
                </div>
                <h1 className="text-xs text-exgray-300 font-semibold leading-5">
                  {defaultLoc?.State}
                </h1>
              </div>
            )}
          </div>
          <div className="flex-1 self-stretch pt-2 space-y-4">
            <div className="flex items-center justify-end gap-5">
              {state?.candidate?.percentageMatch && (
                <div className="bg-[#8833FF33] text-[#8833FF] text-base font-semibold text-center rounded-full px-4 py-1 ">
                  {`${state?.candidate?.percentageMatch} %`}
                </div>
              )}
              {/* <div className="cursor-pointer">
                <VerticleThreeDotsIcon />
              </div> */}
              <div className="">
                <div className="relative">
                  <button
                    className="text-[#0BA02C] bg-[#E7F6EA]/50 w-fit px-4 py-1 rounded-full"
                    onClick={toggleStatusHandler}
                    ref={toggleStatusMenuParentRef}
                  >
                    {applicantDetails?.status}
                  </button>
                  {toggleStatusModal && (
                    <div
                      className="absolute bg-exwhite-100 top-8 right-2 w-60 rounded-lg overflow-hidden z-40 max-h-56 overflow-y-auto"
                      style={{
                        boxShadow: "0px 0px 15px 1px rgba(0, 0, 0, 0.4)",
                      }}
                      ref={toggleStatusMenuRef}
                    >
                      {constants.CANDIDATE_STATUS.map((status) => (
                        <button
                          className="p-2 w-full text-start text-base text-expurple-600 font-semibold hover:bg-expurple-700/50"
                          key={uuidV4()}
                          onClick={() => updateStateHandler(status)}
                        >
                          {status}
                        </button>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </div>
            {/* <div className="flex items-center justify-end gap-5">
              <button className="text-base text-secondary-400 font-semibold flex items-center justify-center gap-1">
                <ScheduleCalenderIcon />
                Schedule Interview
              </button>
              <button className="text-base text-secondary-400 font-semibold flex items-center justify-center gap-1">
                <TagIcon /> Tag Candidate
              </button>
              <button className="text-base text-secondary-400 font-semibold flex items-center justify-center gap-1">
                <ForwardIcon /> Share
              </button>
            </div> */}
          </div>
        </div>
        <div className="flex items-center justify-start pt-2 gap-2">
          <button
            className={`text-base ${
              pathname ===
              `/company-admin/job/${jobId}/applicant/${applicationId}/user/${applicantId}`
                ? "border-b-2 border-secondary-400 text-secondary-400"
                : "text-exgray-200/80"
            } hover:text-secondary-400 font-semibold px-4`}
            onClick={() =>
              navigationHandler(
                `/company-admin/job/${jobId}/applicant/${applicationId}/user/${applicantId}`,
                state
              )
            }
          >
            Profile Details
          </button>
          <button
            className={`text-base ${
              pathname ===
              `/company-admin/job/${jobId}/applicant/${applicationId}/user/${applicantId}/resume`
                ? "border-b-2 border-secondary-400 text-secondary-400"
                : "text-exgray-200/80"
            } hover:text-secondary-400 font-semibold px-4`}
            onClick={() =>
              navigationHandler(
                `/company-admin/job/${jobId}/applicant/${applicationId}/user/${applicantId}/resume`,
                user?.userAdditionalInfo?.resume
              )
            }
          >
            CV / Resume
          </button>
          {/* <button
            className={`text-base ${
              pathname ===
              `/company-admin/job/${jobId}/applicant/${applicantId}/interview-stages`
                ? "border-b-2 border-secondary-400 text-secondary-400"
                : "text-exgray-200/80"
            } hover:text-secondary-400 font-semibold px-4`}
            onClick={() =>
              navigationHandler(
                `/company-admin/job/${jobId}/applicant/${applicantId}/interview-stages`,
                null
              )
            }
          >
            Interview Stages
          </button> */}

          {(loginSelector?.who === constants.COMPANY.toLowerCase() ||
            (loginSelector?.who === constants.COMPANY_MEMBER.toLowerCase() &&
              memberSelector?.designation !== constants.ADVISER)) && (
            <button
              className={`text-base ${
                pathname ===
                `/company-admin/job/${jobId}/applicant/${applicationId}/user/${applicantId}/history`
                  ? "border-b-2 border-secondary-400 text-secondary-400"
                  : "text-exgray-200/80"
              } hover:text-secondary-400 font-semibold px-4`}
              onClick={() =>
                navigationHandler(
                  `/company-admin/job/${jobId}/applicant/${applicationId}/user/${applicantId}/history`,
                  null
                )
              }
            >
              History
            </button>
          )}
        </div>
        <div>
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default CandidateView;
