import { useAppSelector } from "../../config/hooks";

const useSalesDropdown = () => {
  const { salesStatus,salesStages,salesSubStages, industries, salesInteractionType ,salesEngangementType ,minSalaries,salesEngageWith,AgCTCRange} =
    useAppSelector((state) => state.appConfig);

  const defaultSalesIndustry = (id) => {
    return industries.find((ele) => ele.Id == id)?.Name;
  };

  const defaultSalesSubIndustry = (id) => {
    return industries.find((ele) => ele.Id == id)?.Name;
  };

  const defaultSalesInteractionType = (id) => {
    return salesInteractionType.find((ele) => ele.Id == id)?.Title;
  };

  const defaultRevenue= (value) => {
    return AgCTCRange.find((ele) => ele.value == value)?.Title;
  };

  const defaultEngageWith= (id) => {
    return salesEngageWith.find((ele) => ele.value == id)?.Title;
  };

  const defaultEngagementType= (id) => {
    return salesEngangementType.find((ele) => ele.Id == id)?.Title;
  };

  const defaultSalesStages= (id) => {
    return salesStages.find((ele) => ele.Id == id)?.Title;
  };
  const defaultSalesSubStages= (id) => {
    return salesSubStages.find((ele) => ele.Id == id)?.Title;
  };
  const defaultStatus= (id) => {
    return salesStatus.find((ele) => ele.Id == id)?.Title;
  };

  return {
    defaultSalesIndustry,
    defaultSalesSubIndustry,
    defaultSalesInteractionType,
    defaultRevenue,
    defaultEngageWith,
    defaultEngagementType,
    defaultSalesStages,
    defaultSalesSubStages,
    defaultStatus
  };
};

export default useSalesDropdown;
