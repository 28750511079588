import React, { useEffect, useState } from "react";
import SearchIcon from "../../../assets/Icons/SearchIcon";
import FilterIcon from "../../../assets/Icons/FilterIcon";
import InfiniteScroll from "react-infinite-scroll-component";
import { toast } from "sonner";
import AssetForm from "./AssetForm";
import Popup from "../../../components/popup/Popup";

const assetsData = [
  {
    assetName: "Laptop A",
    assetCode: "LT-001",
    serialNo: "SN-123456",
    vendor: "Vendor A",
    warrantyPeriod: "2 years",
    dateOfIssue: "2023-01-01",
    assetValue: "$1200",
    remarks: "Good condition",
    attachment: "attachment1.pdf",
  },
  {
    assetName: "Projector B",
    assetCode: "PR-002",
    serialNo: "SN-789012",
    vendor: "Vendor B",
    warrantyPeriod: "1 year",
    dateOfIssue: "2024-02-15",
    assetValue: "$800",
    remarks: "Under warranty",
    attachment: "attachment2.pdf",
  },
  {
    assetName: "Tablet C",
    assetCode: "TB-003",
    serialNo: "SN-345678",
    vendor: "Vendor C",
    warrantyPeriod: "1.5 years",
    dateOfIssue: "2023-05-20",
    assetValue: "$400",
    remarks: "Good condition",
    attachment: "attachment3.pdf",
  },
  {
    assetName: "Smartphone D",
    assetCode: "SP-004",
    serialNo: "SN-901234",
    vendor: "Vendor D",
    warrantyPeriod: "1 year",
    dateOfIssue: "2022-11-10",
    assetValue: "$600",
    remarks: "Under warranty",
    attachment: "attachment4.pdf",
  },
];

const AllAssets = () => {
  const [search, setSearch] = useState("");
  const [filteredData, setFilteredData] = useState(assetsData || []);
  const [togglePopup, setTogglePopup] = useState(false);

  useEffect(() => {
    setFilteredData(assetsData); // Sync filtered clients when clients change in Redux
  }, [assetsData]);

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value.toLowerCase();
    setSearch(value);

    const updatedData = assetsData?.filter(
      (asset) =>
        asset.assetName.toLowerCase().includes(value) ||
        asset.assetCode.toLowerCase().includes(value) ||
        asset.serialNo.toLowerCase().includes(value)
    );
    setFilteredData(updatedData);
  };

  const getMoreDataHandler = () => {
    try {
      //   dispatch(getNextClientsApi());
    } catch (error) {
      toast.error("Unable to fetch data");
    }
  };

  const togglePopupHandler = () => {
    setTogglePopup(!togglePopup);
  };
  return (
    <div className="px-6 py-2 mx-auto  shadow-lg rounded-xl bg-white">
      <div className="py-2 flex justify-between items-center">
        <h1 className="text-xl font-semibold text-exgray-500">
          Asset Management
        </h1>
        <div className="flex gap-4">
          <button
            // onClick={toggleUploadPopupHandler}
            className="border  border-primary-400 text-primary-400 px-4 py-2 font-semibold rounded-md shadow hover:scale-95"
          >
            Export
          </button>
          <button
            onClick={togglePopupHandler}
            className=" bg-primary-400 text-white px-4 py-2 font-semibold rounded-md shadow hover:bg-primary-600 hover:scale-95"
          >
            <span className="">+</span> Add
          </button>
        </div>
      </div>

      <div className="flex items-center justify-between mb-4 relative">
        <div className="flex gap-2">
          <input
            type="text"
            placeholder="Search..."
            value={search}
            onChange={handleSearch}
            className="border border-gray-300 rounded-md pl-8 pr-4 py-2 w-72 focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          <div className="absolute top-2 pl-1">
            <SearchIcon />
          </div>
        </div>
        <button
          //   onClick={toggleFIlterPopupHandler}
          className="bg-gray-100 text-gray-600 px-4 py-2 rounded-md shadow hover:bg-gray-200 flex gap-2"
        >
          <FilterIcon />
          Filter
        </button>
      </div>

      <div className=" overflow-auto">
        <InfiniteScroll
          className=""
          dataLength={0}
          next={() => getMoreDataHandler()}
          hasMore={true}
          height={"calc(100vh - 155px)"}
          loader={""}
        >
          <div className="relative ">
            <table className="min-w-full border border-gray-200 text-sm text-left text-gray-500 ">
              <thead className="bg-primary-400 text-center   text-white sticky top-0 z-10">
                <tr>
                  <th className="px-6 py-2 border-r border-gray-400 text-nowrap">
                    S.No.
                  </th>
                  <th className="px-6 py-2 border-r border-gray-400 text-nowrap">
                    Asset Name
                  </th>
                  <th className="px-6 py-2 border-r border-gray-400 text-nowrap">
                    Asset Code
                  </th>
                  <th className="px-6 py-2 border-r border-gray-400 text-nowrap">
                    Serial No
                  </th>
                  <th className="px-6 py-2 border-r border-gray-400 text-nowrap">
                    Vendor
                  </th>
                  <th className="px-6 py-2 border-r border-gray-400 text-nowrap">
                    Warranty Period{" "}
                  </th>
                  <th className="px-6 py-2 border-r border-gray-400 text-nowrap">
                    Date of Issue
                  </th>
                  <th className="px-6 py-2 border-r border-gray-400 text-nowrap">
                    Asset Value
                  </th>
                  <th className="px-6 py-2 border-r border-gray-400 text-nowrap">
                    Remarks
                  </th>
                  <th className="px-6 py-2 border-r border-gray-400 text-nowrap">
                    Attachment
                  </th>
                </tr>
              </thead>
              <tbody>
                {filteredData?.map((asset, index) => (
                  <tr
                    key={index}
                    className="bg-white hover:bg-gray-50 border-b text-gray-600 text-center "
                  >
                    <td className="px-6 py-3 border border-gray-200">
                      {index + 1}
                    </td>
                    <td className="px-6 py-3 border border-gray-200">
                      {asset.assetName}
                    </td>
                    <td className="px-6 py-3 border border-gray-200 hover:text-primary-500 hover:font-semibold">
                      {asset.assetCode}
                    </td>
                    <td className="px-6 py-3 border border-gray-200">
                      {asset.serialNo}
                    </td>
                    <td className="px-6 py-3 border border-gray-200">
                      {asset.vendor}
                    </td>
                    <td className="px-6 py-3 border border-gray-200">
                      {asset.warrantyPeriod}
                    </td>
                    <td className="px-6 py-3 border border-gray-200">
                      {asset.dateOfIssue}
                    </td>

                    <td className="px-6 py-3 border border-gray-200">
                      {asset.assetValue}
                    </td>

                    <td className="px-6 py-3 border border-gray-200">
                      {asset.remarks}
                    </td>
                    <td className="px-6 py-3 border border-gray-200">
                      {asset.attachment}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {/* {(filteredClients?.length === 0 || clients == null) && (
                    <div className="text-center py-4 text-gray-500">
                      No clients found.
                    </div>
                  )} */}
          </div>
        </InfiniteScroll>
      </div>

      <Popup
        visible={togglePopup}
        onClose={togglePopupHandler}
        children={<AssetForm togglePopupHandler={togglePopupHandler} />}
      />
    </div>
  );
};

export default AllAssets;
